const estados = [
  {ibge: 11, estado: "Rondônia"           , sigla:"RO"},
  {ibge: 12, estado: "Acre"               , sigla: "AC" },
  {ibge: 13, estado: "Amazonas"           , sigla: "AM"},
  {ibge: 14, estado: "Roraima"            , sigla: "RR"},
  {ibge: 15, estado: "Pará"               , sigla: "PA"},
  {ibge: 16, estado: "Amapá"              , sigla: "AP"},
  {ibge: 17, estado: "Tocantins"          , sigla: "TO"},
  {ibge: 21, estado: "Maranhão"           , sigla: "MA"},
  {ibge: 22, estado: "Piauí"              ,	sigla: "PI"},
  {ibge: 23, estado: "Ceará"              , sigla: "CE"},
  {ibge: 24, estado: "Rio Grande do Norte", sigla: "RN"},
  {ibge: 25, estado: "Paraíba"            , sigla: "PB"}, 
  {ibge: 26, estado: "Pernambuco"         , sigla: "PE"}, 
  {ibge: 27, estado: "Alagoas"            , sigla: "AL"}, 
  {ibge: 28, estado: "Sergipe"            , sigla: "SE"},
  {ibge: 29, estado: "Bahia"              , sigla: "BA"},
  {ibge: 31, estado: "Minas Gerais"       , sigla: "MG"},
  {ibge: 32, estado: "Espírito Santo"     , sigla: "ES"},
  {ibge: 33, estado: "Rio de Janeiro"     , sigla: "RJ"},
  {ibge: 35, estado: "São Paulo"          , sigla: "SP"},
  {ibge: 41, estado: "Paraná"             , sigla: "PR"},
  {ibge: 42, estado: "Santa Catarina"     , sigla: "SC"},
  {ibge: 43, estado: "Rio Grande do Sul"  , sigla: "RS"},
  {ibge: 50, estado: "Mato Grosso do Sul" , sigla: "MS"},
  {ibge: 51, estado: "Mato Grosso"        , sigla: "MT"},
  {ibge: 52, estado: "Goiás"              , sigla: "GO"},
  {ibge: 53, estado: "Distrito Federal"   , sigla: "DF"},  
];

const tabelaIBGE = [
[1101559, "TEIXEIROPOLIS", "RONDONIA", "RO", 11],
[1101609, "THEOBROMA", "RONDONIA", "RO", 11],
[1101708, "URUPA", "RONDONIA", "RO", 11],
[1101757, "VALE DO ANARI", "RONDONIA", "RO", 11],
[1101807, "VALE DO PARAISO", "RONDONIA", "RO", 11],
[1200013, "ACRELANDIA", "ACRE", "AC", 12],
[1200054, "ASSIS BRASIL", "ACRE", "AC", 12],
[1200104, "BRASILEIA", "ACRE", "AC", 12],
[1200138, "BUJARI", "ACRE", "AC", 12],
[1200179, "CAPIXABA", "ACRE", "AC", 12],
[1200203, "CRUZEIRO DO SUL", "ACRE", "AC", 12],
[1200252, "EPITACIOLANDIA", "ACRE", "AC", 12],
[1200302, "FEIJO", "ACRE", "AC", 12],
[1200328, "JORDAO", "ACRE", "AC", 12],
[1200336, "MANCIO LIMA", "ACRE", "AC", 12],
[1200344, "MANOEL URBANO", "ACRE", "AC", 12],
[1200351, "MARECHAL THAUMATURGO", "ACRE", "AC", 12],
[1200385, "PLACIDO DE CASTRO", "ACRE", "AC", 12],
[1200393, "PORTO WALTER", "ACRE", "AC", 12],
[1200401, "RIO BRANCO", "ACRE", "AC", 12],
[1200427, "RODRIGUES ALVES", "ACRE", "AC", 12],
[1200435, "SANTA ROSA DO PURUS", "ACRE", "AC", 12],
[1200450, "SENADOR GUIOMARD", "ACRE", "AC", 12],
[1200500, "SENA MADUREIRA", "ACRE", "AC", 12],
[1200609, "TARAUACA", "ACRE", "AC", 12],
[1200708, "XAPURI", "ACRE", "AC", 12],
[1200807, "PORTO ACRE", "ACRE", "AC", 12],
[1300029, "ALVARAES", "AMAZONAS", "AM", 13],
[1300060, "AMATURA", "AMAZONAS", "AM", 13],
[1300086, "ANAMA", "AMAZONAS", "AM", 13],
[1300102, "ANORI", "AMAZONAS", "AM", 13],
[1300144, "APUI", "AMAZONAS", "AM", 13],
[1300201, "ATALAIA DO NORTE", "AMAZONAS", "AM", 13],
[1300300, "AUTAZES", "AMAZONAS", "AM", 13],
[1300409, "BARCELOS", "AMAZONAS", "AM", 13],
[1300508, "BARREIRINHA", "AMAZONAS", "AM", 13],
[1300607, "BENJAMIN CONSTANT", "AMAZONAS", "AM", 13],
[1300631, "BERURI", "AMAZONAS", "AM", 13],
[1300680, "BOA VISTA DO RAMOS", "AMAZONAS", "AM", 13],
[1300706, "BOCA DO ACRE", "AMAZONAS", "AM", 13],
[1300805, "BORBA", "AMAZONAS", "AM", 13],
[1300839, "CAAPIRANGA", "AMAZONAS", "AM", 13],
[1300904, "CANUTAMA", "AMAZONAS", "AM", 13],
[1301001, "CARAUARI", "AMAZONAS", "AM", 13],
[1301100, "CAREIRO", "AMAZONAS", "AM", 13],
[1301159, "CAREIRO DA VARZEA", "AMAZONAS", "AM", 13],
[1301209, "COARI", "AMAZONAS", "AM", 13],
[1301308, "CODAJAS", "AMAZONAS", "AM", 13],
[1301407, "EIRUNEPE", "AMAZONAS", "AM", 13],
[1301506, "ENVIRA", "AMAZONAS", "AM", 13],
[1301605, "FONTE BOA", "AMAZONAS", "AM", 13],
[1301654, "GUAJARA", "AMAZONAS", "AM", 13],
[1301704, "HUMAITA", "AMAZONAS", "AM", 13],
[1301803, "IPIXUNA", "AMAZONAS", "AM", 13],
[1301852, "IRANDUBA", "AMAZONAS", "AM", 13],
[1301902, "ITACOATIARA", "AMAZONAS", "AM", 13],
[1301951, "ITAMARATI", "AMAZONAS", "AM", 13],
[1302009, "ITAPIRANGA", "AMAZONAS", "AM", 13],
[1302108, "JAPURA", "AMAZONAS", "AM", 13],
[1302207, "JURUA", "AMAZONAS", "AM", 13],
[1302306, "JUTAI", "AMAZONAS", "AM", 13],
[1302405, "LABREA", "AMAZONAS", "AM", 13],
[1302504, "MANACAPURU", "AMAZONAS", "AM", 13],
[1302553, "MANAQUIRI", "AMAZONAS", "AM", 13],
[1302603, "MANAUS", "AMAZONAS", "AM", 13],
[1302702, "MANICORE", "AMAZONAS", "AM", 13],
[1302801, "MARAA", "AMAZONAS", "AM", 13],
[1302900, "MAUES", "AMAZONAS", "AM", 13],
[1303007, "NHAMUNDA", "AMAZONAS", "AM", 13],
[1303106, "NOVA OLINDA DO NORTE", "AMAZONAS", "AM", 13],
[1303205, "NOVO AIRAO", "AMAZONAS", "AM", 13],
[1303304, "NOVO ARIPUANA", "AMAZONAS", "AM", 13],
[1303403, "PARINTINS", "AMAZONAS", "AM", 13],
[1303502, "PAUINI", "AMAZONAS", "AM", 13],
[1303536, "PRESIDENTE FIGUEIREDO", "AMAZONAS", "AM", 13],
[1303569, "RIO PRETO DA EVA", "AMAZONAS", "AM", 13],
[1303601, "SANTA ISABEL DO RIO NEGRO", "AMAZONAS", "AM", 13],
[1303700, "SANTO ANTONIO DO ICA", "AMAZONAS", "AM", 13],
[1303809, "SAO GABRIEL DA CACHOEIRA", "AMAZONAS", "AM", 13],
[1303908, "SAO PAULO DE OLIVENCA", "AMAZONAS", "AM", 13],
[1303957, "SAO SEBASTIAO DO UATUMA", "AMAZONAS", "AM", 13],
[1304005, "SILVES", "AMAZONAS", "AM", 13],
[1304062, "TABATINGA", "AMAZONAS", "AM", 13],
[1304104, "TAPAUA", "AMAZONAS", "AM", 13],
[1304203, "TEFE", "AMAZONAS", "AM", 13],
[1304237, "TONANTINS", "AMAZONAS", "AM", 13],
[1304260, "UARINI", "AMAZONAS", "AM", 13],
[1304302, "URUCARA", "AMAZONAS", "AM", 13],
[1304401, "URUCURITUBA", "AMAZONAS", "AM", 13],
[1400027, "AMAJARI", "RORAIMA", "RR", 14],
[1400050, "ALTO ALEGRE", "RORAIMA", "RR", 14],
[1400100, "BOA VISTA", "RORAIMA", "RR", 14],
[1400159, "BONFIM", "RORAIMA", "RR", 14],
[1400175, "CANTA", "RORAIMA", "RR", 14],
[1400209, "CARACARAI", "RORAIMA", "RR", 14],
[1400233, "CAROEBE", "RORAIMA", "RR", 14],
[1400282, "IRACEMA", "RORAIMA", "RR", 14],
[1400308, "MUCAJAI", "RORAIMA", "RR", 14],
[1400407, "NORMANDIA", "RORAIMA", "RR", 14],
[1400456, "PACARAIMA", "RORAIMA", "RR", 14],
[1400472, "RORAINOPOLIS", "RORAIMA", "RR", 14],
[1400506, "SAO JOAO DA BALIZA", "RORAIMA", "RR", 14],
[1400605, "SAO LUIZ", "RORAIMA", "RR", 14],
[1400704, "UIRAMUTA", "RORAIMA", "RR", 14],
[1500107, "ABAETETUBA", "PARA", "PA", 15],
[1500131, "ABEL FIGUEIREDO", "PARA", "PA", 15],
[1500206, "ACARA", "PARA", "PA", 15],
[1500305, "AFUA", "PARA", "PA", 15],
[1500347, "AGUA AZUL DO NORTE", "PARA", "PA", 15],
[1500404, "ALENQUER", "PARA", "PA", 15],
[1500503, "ALMEIRIM", "PARA", "PA", 15],
[1500602, "ALTAMIRA", "PARA", "PA", 15],
[1500701, "ANAJAS", "PARA", "PA", 15],
[1500800, "ANANINDEUA", "PARA", "PA", 15],
[1500859, "ANAPU", "PARA", "PA", 15],
[1500909, "AUGUSTO CORREA", "PARA", "PA", 15],
[1500958, "AURORA DO PARA", "PARA", "PA", 15],
[1501006, "AVEIRO", "PARA", "PA", 15],
[1501105, "BAGRE", "PARA", "PA", 15],
[1501204, "BAIAO", "PARA", "PA", 15],
[1501253, "BANNACH", "PARA", "PA", 15],
[1501303, "BARCARENA", "PARA", "PA", 15],
[1501402, "BELEM", "PARA", "PA", 15],
[1501451, "BELTERRA", "PARA", "PA", 15],
[1501501, "BENEVIDES", "PARA", "PA", 15],
[1501576, "BOM JESUS DO TOCANTINS", "PARA", "PA", 15],
[1501600, "BONITO", "PARA", "PA", 15],
[1501709, "BRAGANCA", "PARA", "PA", 15],
[1501725, "BRASIL NOVO", "PARA", "PA", 15],
[1501758, "BREJO GRANDE DO ARAGUAIA", "PARA", "PA", 15],
[1501782, "BREU BRANCO", "PARA", "PA", 15],
[1501808, "BREVES", "PARA", "PA", 15],
[1501907, "BUJARU", "PARA", "PA", 15],
[1501956, "CACHOEIRA DO PIRIA", "PARA", "PA", 15],
[1502004, "CACHOEIRA DO ARARI", "PARA", "PA", 15],
[1502103, "CAMETA", "PARA", "PA", 15],
[1502152, "CANAA DOS CARAJAS", "PARA", "PA", 15],
[1502202, "CAPANEMA", "PARA", "PA", 15],
[1502301, "CAPITAO POCO", "PARA", "PA", 15],
[1502400, "CASTANHAL", "PARA", "PA", 15],
[1502509, "CHAVES", "PARA", "PA", 15],
[1502608, "COLARES", "PARA", "PA", 15],
[1502707, "CONCEICAO DO ARAGUAIA", "PARA", "PA", 15],
[1502756, "CONCORDIA DO PARA", "PARA", "PA", 15],
[1502764, "CUMARU DO NORTE", "PARA", "PA", 15],
[1502772, "CURIONOPOLIS", "PARA", "PA", 15],
[1502806, "CURRALINHO", "PARA", "PA", 15],
[1502855, "CURUA", "PARA", "PA", 15],
[1502905, "CURUCA", "PARA", "PA", 15],
[1502939, "DOM ELISEU", "PARA", "PA", 15],
[1502954, "ELDORADO DOS CARAJAS", "PARA", "PA", 15],
[1503002, "FARO", "PARA", "PA", 15],
[1503044, "FLORESTA DO ARAGUAIA", "PARA", "PA", 15],
[1503077, "GARRAFAO DO NORTE", "PARA", "PA", 15],
[1503093, "GOIANESIA DO PARA", "PARA", "PA", 15],
[1503101, "GURUPA", "PARA", "PA", 15],
[1503200, "IGARAPE-ACU", "PARA", "PA", 15],
[1503309, "IGARAPE-MIRI", "PARA", "PA", 15],
[1503408, "INHANGAPI", "PARA", "PA", 15],
[1503457, "IPIXUNA DO PARA", "PARA", "PA", 15],
[1503507, "IRITUIA", "PARA", "PA", 15],
[1503606, "ITAITUBA", "PARA", "PA", 15],
[1503705, "ITUPIRANGA", "PARA", "PA", 15],
[1503754, "JACAREACANGA", "PARA", "PA", 15],
[1503804, "JACUNDA", "PARA", "PA", 15],
[1503903, "JURUTI", "PARA", "PA", 15],
[1504000, "LIMOEIRO DO AJURU", "PARA", "PA", 15],
[1504059, "MAE DO RIO", "PARA", "PA", 15],
[1504109, "MAGALHAES BARATA", "PARA", "PA", 15],
[1504208, "MARABA", "PARA", "PA", 15],
[1504307, "MARACANA", "PARA", "PA", 15],
[1504406, "MARAPANIM", "PARA", "PA", 15],
[1504422, "MARITUBA", "PARA", "PA", 15],
[1504455, "MEDICILANDIA", "PARA", "PA", 15],
[1504505, "MELGACO", "PARA", "PA", 15],
[1504604, "MOCAJUBA", "PARA", "PA", 15],
[1504703, "MOJU", "PARA", "PA", 15],
[1504802, "MONTE ALEGRE", "PARA", "PA", 15],
[1504901, "MUANA", "PARA", "PA", 15],
[1504950, "NOVA ESPERANCA DO PIRIA", "PARA", "PA", 15],
[1504976, "NOVA IPIXUNA", "PARA", "PA", 15],
[1505007, "NOVA TIMBOTEUA", "PARA", "PA", 15],
[1505031, "NOVO PROGRESSO", "PARA", "PA", 15],
[1505064, "NOVO REPARTIMENTO", "PARA", "PA", 15],
[1505106, "OBIDOS", "PARA", "PA", 15],
[1505205, "OEIRAS DO PARA", "PARA", "PA", 15],
[1505304, "ORIXIMINA", "PARA", "PA", 15],
[1505403, "OUREM", "PARA", "PA", 15],
[1505437, "OURILANDIA DO NORTE", "PARA", "PA", 15],
[1505486, "PACAJA", "PARA", "PA", 15],
[1505494, "PALESTINA DO PARA", "PARA", "PA", 15],
[1505502, "PARAGOMINAS", "PARA", "PA", 15],
[1505536, "PARAUAPEBAS", "PARA", "PA", 15],
[1505551, "PAU D'ARCO", "PARA", "PA", 15],
[1505601, "PEIXE-BOI", "PARA", "PA", 15],
[1505635, "PICARRA", "PARA", "PA", 15],
[1505650, "PLACAS", "PARA", "PA", 15],
[1505700, "PONTA DE PEDRAS", "PARA", "PA", 15],
[1505809, "PORTEL", "PARA", "PA", 15],
[1505908, "PORTO DE MOZ", "PARA", "PA", 15],
[1506005, "PRAINHA", "PARA", "PA", 15],
[1506104, "PRIMAVERA", "PARA", "PA", 15],
[1506112, "QUATIPURU", "PARA", "PA", 15],
[1506138, "REDENCAO", "PARA", "PA", 15],
[1506161, "RIO MARIA", "PARA", "PA", 15],
[1506187, "RONDON DO PARA", "PARA", "PA", 15],
[1506195, "RUROPOLIS", "PARA", "PA", 15],
[1506203, "SALINOPOLIS", "PARA", "PA", 15],
[1506302, "SALVATERRA", "PARA", "PA", 15],
[1506351, "SANTA BARBARA DO PARA", "PARA", "PA", 15],
[1506401, "SANTA CRUZ DO ARARI", "PARA", "PA", 15],
[1506500, "SANTA ISABEL DO PARA", "PARA", "PA", 15],
[1506559, "SANTA LUZIA DO PARA", "PARA", "PA", 15],
[1506583, "SANTA MARIA DAS BARREIRAS", "PARA", "PA", 15],
[1506609, "SANTA MARIA DO PARA", "PARA", "PA", 15],
[1506708, "SANTANA DO ARAGUAIA", "PARA", "PA", 15],
[1506807, "SANTAREM", "PARA", "PA", 15],
[1506906, "SANTAREM NOVO", "PARA", "PA", 15],
[1507003, "SANTO ANTONIO DO TAUA", "PARA", "PA", 15],
[1507102, "SAO CAETANO DE ODIVELAS", "PARA", "PA", 15],
[1507151, "SAO DOMINGOS DO ARAGUAIA", "PARA", "PA", 15],
[1507201, "SAO DOMINGOS DO CAPIM", "PARA", "PA", 15],
[1507300, "SAO FELIX DO XINGU", "PARA", "PA", 15],
[1507409, "SAO FRANCISCO DO PARA", "PARA", "PA", 15],
[1507458, "SAO GERALDO DO ARAGUAIA", "PARA", "PA", 15],
[1507466, "SAO JOAO DA PONTA", "PARA", "PA", 15],
[1507474, "SAO JOAO DE PIRABAS", "PARA", "PA", 15],
[1507508, "SAO JOAO DO ARAGUAIA", "PARA", "PA", 15],
[1507607, "SAO MIGUEL DO GUAMA", "PARA", "PA", 15],
[1507706, "SAO SEBASTIAO DA BOA VIST", "PARA", "PA", 15],
[1507755, "SAPUCAIA", "PARA", "PA", 15],
[1507805, "SENADOR JOSE PORFIRIO", "PARA", "PA", 15],
[1507904, "SOURE", "PARA", "PA", 15],
[1507953, "TAILANDIA", "PARA", "PA", 15],
[1507961, "TERRA ALTA", "PARA", "PA", 15],
[1507979, "TERRA SANTA", "PARA", "PA", 15],
[1508001, "TOME-ACU", "PARA", "PA", 15],
[1508035, "TRACUATEUA", "PARA", "PA", 15],
[1508050, "TRAIRAO", "PARA", "PA", 15],
[1508084, "TUCUMA", "PARA", "PA", 15],
[1508100, "TUCURUI", "PARA", "PA", 15],
[1508126, "ULIANOPOLIS", "PARA", "PA", 15],
[1508159, "URUARA", "PARA", "PA", 15],
[1508209, "VIGIA", "PARA", "PA", 15],
[1508308, "VISEU", "PARA", "PA", 15],
[1508357, "VITORIA DO XINGU", "PARA", "PA", 15],
[1508407, "XINGUARA", "PARA", "PA", 15],
[1600055, "SERRA DO NAVIO", "AMAPA", "AP", 16],
[1600105, "AMAPA", "AMAPA", "AP", 16],
[1600154, "PEDRA BRANCA DO AMAPARI", "AMAPA", "AP", 16],
[1600204, "CALCOENE", "AMAPA", "AP", 16],
[1600212, "CUTIAS", "AMAPA", "AP", 16],
[1600238, "FERREIRA GOMES", "AMAPA", "AP", 16],
[1600253, "ITAUBAL", "AMAPA", "AP", 16],
[1600279, "LARANJAL DO JARI", "AMAPA", "AP", 16],
[1600303, "MACAPA", "AMAPA", "AP", 16],
[1600402, "MAZAGAO", "AMAPA", "AP", 16],
[1600501, "OIAPOQUE", "AMAPA", "AP", 16],
[1600535, "PORTO GRANDE", "AMAPA", "AP", 16],
[1600550, "PRACUUBA", "AMAPA", "AP", 16],
[1600600, "SANTANA", "AMAPA", "AP", 16],
[1600709, "TARTARUGALZINHO", "AMAPA", "AP", 16],
[1600808, "VITORIA DO JARI", "AMAPA", "AP", 16],
[1700251, "ABREULANDIA", "TOCANTINS", "TO", 17],
[1700301, "AGUIARNOPOLIS", "TOCANTINS", "TO", 17],
[1700350, "ALIANCA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1700400, "ALMAS", "TOCANTINS", "TO", 17],
[1700707, "ALVORADA", "TOCANTINS", "TO", 17],
[1701002, "ANANAS", "TOCANTINS", "TO", 17],
[1701051, "ANGICO", "TOCANTINS", "TO", 17],
[1701101, "APARECIDA DO RIO NEGRO", "TOCANTINS", "TO", 17],
[1701309, "ARAGOMINAS", "TOCANTINS", "TO", 17],
[1701903, "ARAGUACEMA", "TOCANTINS", "TO", 17],
[1702000, "ARAGUACU", "TOCANTINS", "TO", 17],
[1702109, "ARAGUAINA", "TOCANTINS", "TO", 17],
[1702158, "ARAGUANA", "TOCANTINS", "TO", 17],
[1702208, "ARAGUATINS", "TOCANTINS", "TO", 17],
[1702307, "ARAPOEMA", "TOCANTINS", "TO", 17],
[1702406, "ARRAIAS", "TOCANTINS", "TO", 17],
[1702554, "AUGUSTINOPOLIS", "TOCANTINS", "TO", 17],
[1702703, "AURORA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1702901, "AXIXA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1703008, "BABACULANDIA", "TOCANTINS", "TO", 17],
[1703057, "BANDEIRANTES DO TOCANTINS", "TOCANTINS", "TO", 17],
[1703073, "BARRA DO OURO", "TOCANTINS", "TO", 17],
[1703107, "BARROLANDIA", "TOCANTINS", "TO", 17],
[1703206, "BERNARDO SAYAO", "TOCANTINS", "TO", 17],
[1703305, "BOM JESUS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1703602, "BRASILANDIA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1703701, "BREJINHO DE NAZARE", "TOCANTINS", "TO", 17],
[1703800, "BURITI DO TOCANTINS", "TOCANTINS", "TO", 17],
[1703826, "CACHOEIRINHA", "TOCANTINS", "TO", 17],
[1703842, "CAMPOS LINDOS", "TOCANTINS", "TO", 17],
[1703867, "CARIRI DO TOCANTINS", "TOCANTINS", "TO", 17],
[1703883, "CARMOLANDIA", "TOCANTINS", "TO", 17],
[1703891, "CARRASCO BONITO", "TOCANTINS", "TO", 17],
[1703909, "CASEARA", "TOCANTINS", "TO", 17],
[1704105, "CENTENARIO", "TOCANTINS", "TO", 17],
[1704600, "CHAPADA DE AREIA", "TOCANTINS", "TO", 17],
[1705102, "CHAPADA DA NATIVIDADE", "TOCANTINS", "TO", 17],
[1705508, "COLINAS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1705557, "COMBINADO", "TOCANTINS", "TO", 17],
[1705607, "CONCEICAO DO TOCANTINS", "TOCANTINS", "TO", 17],
[1706001, "COUTO MAGALHAES", "TOCANTINS", "TO", 17],
[1706100, "CRISTALANDIA", "TOCANTINS", "TO", 17],
[1706258, "CRIXAS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1706506, "DARCINOPOLIS", "TOCANTINS", "TO", 17],
[1707009, "DIANOPOLIS", "TOCANTINS", "TO", 17],
[1707108, "DIVINOPOLIS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1707207, "DOIS IRMAOS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1707306, "DUERE", "TOCANTINS", "TO", 17],
[1707405, "ESPERANTINA", "TOCANTINS", "TO", 17],
[1707553, "FATIMA", "TOCANTINS", "TO", 17],
[1707652, "FIGUEIROPOLIS", "TOCANTINS", "TO", 17],
[1707702, "FILADELFIA", "TOCANTINS", "TO", 17],
[1708205, "FORMOSO DO ARAGUAIA", "TOCANTINS", "TO", 17],
[1708254, "FORTALEZA DO TABOCAO", "TOCANTINS", "TO", 17],
[1708304, "GOIANORTE", "TOCANTINS", "TO", 17],
[1709005, "GOIATINS", "TOCANTINS", "TO", 17],
[1709302, "GUARAI", "TOCANTINS", "TO", 17],
[1709500, "GURUPI", "TOCANTINS", "TO", 17],
[1709807, "IPUEIRAS", "TOCANTINS", "TO", 17],
[1710508, "ITACAJA", "TOCANTINS", "TO", 17],
[1710706, "ITAGUATINS", "TOCANTINS", "TO", 17],
[1710904, "ITAPIRATINS", "TOCANTINS", "TO", 17],
[1711100, "ITAPORA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1711506, "JAU DO TOCANTINS", "TOCANTINS", "TO", 17],
[1711803, "JUARINA", "TOCANTINS", "TO", 17],
[1711902, "LAGOA DA CONFUSAO", "TOCANTINS", "TO", 17],
[1711951, "LAGOA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1712009, "LAJEADO", "TOCANTINS", "TO", 17],
[1712157, "LAVANDEIRA", "TOCANTINS", "TO", 17],
[1712405, "LIZARDA", "TOCANTINS", "TO", 17],
[1712454, "LUZINOPOLIS", "TOCANTINS", "TO", 17],
[1712504, "MARIANOPOLIS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1712702, "MATEIROS", "TOCANTINS", "TO", 17],
[1712801, "MAURILANDIA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1713205, "MIRACEMA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1713304, "MIRANORTE", "TOCANTINS", "TO", 17],
[1713601, "MONTE DO CARMO", "TOCANTINS", "TO", 17],
[1713700, "MONTE SANTO DO TOCANTINS", "TOCANTINS", "TO", 17],
[1713809, "PALMEIRAS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1713957, "MURICILANDIA", "TOCANTINS", "TO", 17],
[1714203, "NATIVIDADE", "TOCANTINS", "TO", 17],
[1714302, "NAZARE", "TOCANTINS", "TO", 17],
[1714880, "NOVA OLINDA", "TOCANTINS", "TO", 17],
[1715002, "NOVA ROSALANDIA", "TOCANTINS", "TO", 17],
[1715101, "NOVO ACORDO", "TOCANTINS", "TO", 17],
[1715150, "NOVO ALEGRE", "TOCANTINS", "TO", 17],
[1715259, "NOVO JARDIM", "TOCANTINS", "TO", 17],
[1715507, "OLIVEIRA DE FATIMA", "TOCANTINS", "TO", 17],
[1715705, "PALMEIRANTE", "TOCANTINS", "TO", 17],
[1715754, "PALMEIROPOLIS", "TOCANTINS", "TO", 17],
[1716109, "PARAISO DO TOCANTINS", "TOCANTINS", "TO", 17],
[1716208, "PARANA", "TOCANTINS", "TO", 17],
[1716307, "PAU D'ARCO", "TOCANTINS", "TO", 17],
[1716505, "PEDRO AFONSO", "TOCANTINS", "TO", 17],
[1716604, "PEIXE", "TOCANTINS", "TO", 17],
[1716653, "PEQUIZEIRO", "TOCANTINS", "TO", 17],
[1716703, "COLMEIA", "TOCANTINS", "TO", 17],
[1717008, "PINDORAMA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1717206, "PIRAQUE", "TOCANTINS", "TO", 17],
[1717503, "PIUM", "TOCANTINS", "TO", 17],
[1717800, "PONTE ALTA DO BOM JESUS", "TOCANTINS", "TO", 17],
[1717909, "PONTE ALTA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1718006, "PORTO ALEGRE DO TOCANTINS", "TOCANTINS", "TO", 17],
[1718204, "PORTO NACIONAL", "TOCANTINS", "TO", 17],
[1718303, "PRAIA NORTE", "TOCANTINS", "TO", 17],
[1718402, "PRESIDENTE KENNEDY", "TOCANTINS", "TO", 17],
[1718451, "PUGMIL", "TOCANTINS", "TO", 17],
[1718501, "RECURSOLANDIA", "TOCANTINS", "TO", 17],
[1718550, "RIACHINHO", "TOCANTINS", "TO", 17],
[1718659, "RIO DA CONCEICAO", "TOCANTINS", "TO", 17],
[1718709, "RIO DOS BOIS", "TOCANTINS", "TO", 17],
[1718758, "RIO SONO", "TOCANTINS", "TO", 17],
[1718808, "SAMPAIO", "TOCANTINS", "TO", 17],
[1718840, "SANDOLANDIA", "TOCANTINS", "TO", 17],
[1718865, "SANTA FE DO ARAGUAIA", "TOCANTINS", "TO", 17],
[1718881, "SANTA MARIA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1718899, "SANTA RITA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1718907, "SANTA ROSA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1719004, "SANTA TEREZA DO TOCANTINS", "TOCANTINS", "TO", 17],
[1720002, "SANTA TEREZINHA DO TOCANT", "TOCANTINS", "TO", 17],
[1720101, "SAO BENTO DO TOCANTINS", "TOCANTINS", "TO", 17],
[1720150, "SAO FELIX DO TOCANTINS", "TOCANTINS", "TO", 17],
[1720200, "SAO MIGUEL DO TOCANTINS", "TOCANTINS", "TO", 17],
[1720259, "SAO SALVADOR DO TOCANTINS", "TOCANTINS", "TO", 17],
[1720309, "SAO SEBASTIAO DO TOCANTIN", "TOCANTINS", "TO", 17],
[1720499, "SAO VALERIO", "TOCANTINS", "TO", 17],
[1720655, "SILVANOPOLIS", "TOCANTINS", "TO", 17],
[1720804, "SITIO NOVO DO TOCANTINS", "TOCANTINS", "TO", 17],
[1720853, "SUCUPIRA", "TOCANTINS", "TO", 17],
[1720903, "TAGUATINGA", "TOCANTINS", "TO", 17],
[1720937, "TAIPAS DO TOCANTINS", "TOCANTINS", "TO", 17],
[1720978, "TALISMA", "TOCANTINS", "TO", 17],
[1721000, "PALMAS", "TOCANTINS", "TO", 17],
[1721109, "TOCANTINIA", "TOCANTINS", "TO", 17],
[1721208, "TOCANTINOPOLIS", "TOCANTINS", "TO", 17],
[1721257, "TUPIRAMA", "TOCANTINS", "TO", 17],
[1721307, "TUPIRATINS", "TOCANTINS", "TO", 17],
[1722081, "WANDERLANDIA", "TOCANTINS", "TO", 17],
[1722107, "XAMBIOA", "TOCANTINS", "TO", 17],
[2100055, "ACAILANDIA", "MARANHAO", "MA", 21],
[2100105, "AFONSO CUNHA", "MARANHAO", "MA", 21],
[2100154, "AGUA DOCE DO MARANHAO", "MARANHAO", "MA", 21],
[2100204, "ALCANTARA", "MARANHAO", "MA", 21],
[2100303, "ALDEIAS ALTAS", "MARANHAO", "MA", 21],
[2100402, "ALTAMIRA DO MARANHAO", "MARANHAO", "MA", 21],
[2100436, "ALTO ALEGRE DO MARANHAO", "MARANHAO", "MA", 21],
[2100477, "ALTO ALEGRE DO PINDARE", "MARANHAO", "MA", 21],
[2100501, "ALTO PARNAIBA", "MARANHAO", "MA", 21],
[2100550, "AMAPA DO MARANHAO", "MARANHAO", "MA", 21],
[2100600, "AMARANTE DO MARANHAO", "MARANHAO", "MA", 21],
[2100709, "ANAJATUBA", "MARANHAO", "MA", 21],
[2100808, "ANAPURUS", "MARANHAO", "MA", 21],
[2100832, "APICUM-ACU", "MARANHAO", "MA", 21],
[2100873, "ARAGUANA", "MARANHAO", "MA", 21],
[2100907, "ARAIOSES", "MARANHAO", "MA", 21],
[2100956, "ARAME", "MARANHAO", "MA", 21],
[2101004, "ARARI", "MARANHAO", "MA", 21],
[2101103, "AXIXA", "MARANHAO", "MA", 21],
[2101202, "BACABAL", "MARANHAO", "MA", 21],
[2101251, "BACABEIRA", "MARANHAO", "MA", 21],
[2101301, "BACURI", "MARANHAO", "MA", 21],
[2101350, "BACURITUBA", "MARANHAO", "MA", 21],
[2101400, "BALSAS", "MARANHAO", "MA", 21],
[2101509, "BARAO DE GRAJAU", "MARANHAO", "MA", 21],
[2101608, "BARRA DO CORDA", "MARANHAO", "MA", 21],
[2101707, "BARREIRINHAS", "MARANHAO", "MA", 21],
[2101731, "BELAGUA", "MARANHAO", "MA", 21],
[2101772, "BELA VISTA DO MARANHAO", "MARANHAO", "MA", 21],
[2101806, "BENEDITO LEITE", "MARANHAO", "MA", 21],
[2101905, "BEQUIMAO", "MARANHAO", "MA", 21],
[2101939, "BERNARDO DO MEARIM", "MARANHAO", "MA", 21],
[2101970, "BOA VISTA DO GURUPI", "MARANHAO", "MA", 21],
[2102002, "BOM JARDIM", "MARANHAO", "MA", 21],
[2102036, "BOM JESUS DAS SELVAS", "MARANHAO", "MA", 21],
[2102077, "BOM LUGAR", "MARANHAO", "MA", 21],
[2102101, "BREJO", "MARANHAO", "MA", 21],
[2102150, "BREJO DE AREIA", "MARANHAO", "MA", 21],
[2102200, "BURITI", "MARANHAO", "MA", 21],
[2102309, "BURITI BRAVO", "MARANHAO", "MA", 21],
[2102325, "BURITICUPU", "MARANHAO", "MA", 21],
[2102358, "BURITIRANA", "MARANHAO", "MA", 21],
[2102374, "CACHOEIRA GRANDE", "MARANHAO", "MA", 21],
[2102408, "CAJAPIO", "MARANHAO", "MA", 21],
[2102507, "CAJARI", "MARANHAO", "MA", 21],
[2102556, "CAMPESTRE DO MARANHAO", "MARANHAO", "MA", 21],
[2102606, "CANDIDO MENDES", "MARANHAO", "MA", 21],
[2102705, "CANTANHEDE", "MARANHAO", "MA", 21],
[2102754, "CAPINZAL DO NORTE", "MARANHAO", "MA", 21],
[2102804, "CAROLINA", "MARANHAO", "MA", 21],
[2102903, "CARUTAPERA", "MARANHAO", "MA", 21],
[2103000, "CAXIAS", "MARANHAO", "MA", 21],
[2103109, "CEDRAL", "MARANHAO", "MA", 21],
[2103125, "CENTRAL DO MARANHAO", "MARANHAO", "MA", 21],
[2103158, "CENTRO DO GUILHERME", "MARANHAO", "MA", 21],
[2103174, "CENTRO NOVO DO MARANHAO", "MARANHAO", "MA", 21],
[2103208, "CHAPADINHA", "MARANHAO", "MA", 21],
[2103257, "CIDELANDIA", "MARANHAO", "MA", 21],
[2103307, "CODO", "MARANHAO", "MA", 21],
[2103406, "COELHO NETO", "MARANHAO", "MA", 21],
[2103505, "COLINAS", "MARANHAO", "MA", 21],
[2103554, "CONCEICAO DO LAGO-ACU", "MARANHAO", "MA", 21],
[2103604, "COROATA", "MARANHAO", "MA", 21],
[2103703, "CURURUPU", "MARANHAO", "MA", 21],
[2103752, "DAVINOPOLIS", "MARANHAO", "MA", 21],
[2103802, "DOM PEDRO", "MARANHAO", "MA", 21],
[2103901, "DUQUE BACELAR", "MARANHAO", "MA", 21],
[2104008, "ESPERANTINOPOLIS", "MARANHAO", "MA", 21],
[2104057, "ESTREITO", "MARANHAO", "MA", 21],
[2104073, "FEIRA NOVA DO MARANHAO", "MARANHAO", "MA", 21],
[2104081, "FERNANDO FALCAO", "MARANHAO", "MA", 21],
[2104099, "FORMOSA DA SERRA NEGRA", "MARANHAO", "MA", 21],
[2104107, "FORTALEZA DOS NOGUEIRAS", "MARANHAO", "MA", 21],
[2104206, "FORTUNA", "MARANHAO", "MA", 21],
[2104305, "GODOFREDO VIANA", "MARANHAO", "MA", 21],
[2104404, "GONCALVES DIAS", "MARANHAO", "MA", 21],
[2104503, "GOVERNADOR ARCHER", "MARANHAO", "MA", 21],
[2104552, "GOVERNADOR EDISON LOBAO", "MARANHAO", "MA", 21],
[2104602, "GOVERNADOR EUGENIO BARROS", "MARANHAO", "MA", 21],
[2104628, "GOVERNADOR LUIZ ROCHA", "MARANHAO", "MA", 21],
[2104651, "GOVERNADOR NEWTON BELLO", "MARANHAO", "MA", 21],
[2104677, "GOVERNADOR NUNES FREIRE", "MARANHAO", "MA", 21],
[2104701, "GRACA ARANHA", "MARANHAO", "MA", 21],
[2104800, "GRAJAU", "MARANHAO", "MA", 21],
[2104909, "GUIMARAES", "MARANHAO", "MA", 21],
[2105005, "HUMBERTO DE CAMPOS", "MARANHAO", "MA", 21],
[2105104, "ICATU", "MARANHAO", "MA", 21],
[2105153, "IGARAPE DO MEIO", "MARANHAO", "MA", 21],
[2105203, "IGARAPE GRANDE", "MARANHAO", "MA", 21],
[2105302, "IMPERATRIZ", "MARANHAO", "MA", 21],
[2105351, "ITAIPAVA DO GRAJAU", "MARANHAO", "MA", 21],
[2105401, "ITAPECURU MIRIM", "MARANHAO", "MA", 21],
[2105427, "ITINGA DO MARANHAO", "MARANHAO", "MA", 21],
[2105450, "JATOBA", "MARANHAO", "MA", 21],
[2105476, "JENIPAPO DOS VIEIRAS", "MARANHAO", "MA", 21],
[2105500, "JOAO LISBOA", "MARANHAO", "MA", 21],
[2105609, "JOSELANDIA", "MARANHAO", "MA", 21],
[2105658, "JUNCO DO MARANHAO", "MARANHAO", "MA", 21],
[2105708, "LAGO DA PEDRA", "MARANHAO", "MA", 21],
[2105807, "LAGO DO JUNCO", "MARANHAO", "MA", 21],
[2105906, "LAGO VERDE", "MARANHAO", "MA", 21],
[2105922, "LAGOA DO MATO", "MARANHAO", "MA", 21],
[2105948, "LAGO DOS RODRIGUES", "MARANHAO", "MA", 21],
[2105963, "LAGOA GRANDE DO MARANHAO", "MARANHAO", "MA", 21],
[2105989, "LAJEADO NOVO", "MARANHAO", "MA", 21],
[2106003, "LIMA CAMPOS", "MARANHAO", "MA", 21],
[2106102, "LORETO", "MARANHAO", "MA", 21],
[2106201, "LUIS DOMINGUES", "MARANHAO", "MA", 21],
[2106300, "MAGALHAES DE ALMEIDA", "MARANHAO", "MA", 21],
[2106326, "MARACACUME", "MARANHAO", "MA", 21],
[2106359, "MARAJA DO SENA", "MARANHAO", "MA", 21],
[2106375, "MARANHAOZINHO", "MARANHAO", "MA", 21],
[2106409, "MATA ROMA", "MARANHAO", "MA", 21],
[2106508, "MATINHA", "MARANHAO", "MA", 21],
[2106607, "MATÕES", "MARANHAO", "MA", 21],
[2106631, "MATÕES DO NORTE", "MARANHAO", "MA", 21],
[2106672, "MILAGRES DO MARANHAO", "MARANHAO", "MA", 21],
[2106706, "MIRADOR", "MARANHAO", "MA", 21],
[2106755, "MIRANDA DO NORTE", "MARANHAO", "MA", 21],
[2106805, "MIRINZAL", "MARANHAO", "MA", 21],
[2106904, "MONCAO", "MARANHAO", "MA", 21],
[2107001, "MONTES ALTOS", "MARANHAO", "MA", 21],
[2107100, "MORROS", "MARANHAO", "MA", 21],
[2107209, "NINA RODRIGUES", "MARANHAO", "MA", 21],
[2107258, "NOVA COLINAS", "MARANHAO", "MA", 21],
[2107308, "NOVA IORQUE", "MARANHAO", "MA", 21],
[2107357, "NOVA OLINDA DO MARANHAO", "MARANHAO", "MA", 21],
[2107407, "OLHO D'AGUA DAS CUNHAS", "MARANHAO", "MA", 21],
[2107456, "OLINDA NOVA DO MARANHAO", "MARANHAO", "MA", 21],
[2107506, "PACO DO LUMIAR", "MARANHAO", "MA", 21],
[2107605, "PALMEIRANDIA", "MARANHAO", "MA", 21],
[2107704, "PARAIBANO", "MARANHAO", "MA", 21],
[2107803, "PARNARAMA", "MARANHAO", "MA", 21],
[2107902, "PASSAGEM FRANCA", "MARANHAO", "MA", 21],
[2108009, "PASTOS BONS", "MARANHAO", "MA", 21],
[2108058, "PAULINO NEVES", "MARANHAO", "MA", 21],
[2108108, "PAULO RAMOS", "MARANHAO", "MA", 21],
[2108207, "PEDREIRAS", "MARANHAO", "MA", 21],
[2108256, "PEDRO DO ROSARIO", "MARANHAO", "MA", 21],
[2108306, "PENALVA", "MARANHAO", "MA", 21],
[2108405, "PERI MIRIM", "MARANHAO", "MA", 21],
[2108454, "PERITORO", "MARANHAO", "MA", 21],
[2108504, "PINDARE-MIRIM", "MARANHAO", "MA", 21],
[2108603, "PINHEIRO", "MARANHAO", "MA", 21],
[2108702, "PIO XII", "MARANHAO", "MA", 21],
[2108801, "PIRAPEMAS", "MARANHAO", "MA", 21],
[2108900, "POCAO DE PEDRAS", "MARANHAO", "MA", 21],
[2109007, "PORTO FRANCO", "MARANHAO", "MA", 21],
[2109056, "PORTO RICO DO MARANHAO", "MARANHAO", "MA", 21],
[2109106, "PRESIDENTE DUTRA", "MARANHAO", "MA", 21],
[2109205, "PRESIDENTE JUSCELINO", "MARANHAO", "MA", 21],
[2109239, "PRESIDENTE MEDICI", "MARANHAO", "MA", 21],
[2109270, "PRESIDENTE SARNEY", "MARANHAO", "MA", 21],
[2109304, "PRESIDENTE VARGAS", "MARANHAO", "MA", 21],
[2109403, "PRIMEIRA CRUZ", "MARANHAO", "MA", 21],
[2109452, "RAPOSA", "MARANHAO", "MA", 21],
[2109502, "RIACHAO", "MARANHAO", "MA", 21],
[2109551, "RIBAMAR FIQUENE", "MARANHAO", "MA", 21],
[2109601, "ROSARIO", "MARANHAO", "MA", 21],
[2109700, "SAMBAIBA", "MARANHAO", "MA", 21],
[2109759, "SANTA FILOMENA DO MARANHA", "MARANHAO", "MA", 21],
[2109809, "SANTA HELENA", "MARANHAO", "MA", 21],
[2109908, "SANTA INES", "MARANHAO", "MA", 21],
[2110005, "SANTA LUZIA", "MARANHAO", "MA", 21],
[2110039, "SANTA LUZIA DO PARUA", "MARANHAO", "MA", 21],
[2110104, "SANTA QUITERIA DO MARANHA", "MARANHAO", "MA", 21],
[2110203, "SANTA RITA", "MARANHAO", "MA", 21],
[2110237, "SANTANA DO MARANHAO", "MARANHAO", "MA", 21],
[2110278, "SANTO AMARO DO MARANHAO", "MARANHAO", "MA", 21],
[2110302, "SANTO ANTONIO DOS LOPES", "MARANHAO", "MA", 21],
[2110401, "SAO BENEDITO DO RIO PRETO", "MARANHAO", "MA", 21],
[2110500, "SAO BENTO", "MARANHAO", "MA", 21],
[2110609, "SAO BERNARDO", "MARANHAO", "MA", 21],
[2110658, "SAO DOMINGOS DO AZEITAO", "MARANHAO", "MA", 21],
[2110708, "SAO DOMINGOS DO MARANHAO", "MARANHAO", "MA", 21],
[2110807, "SAO FELIX DE BALSAS", "MARANHAO", "MA", 21],
[2110856, "SAO FRANCISCO DO BREJAO", "MARANHAO", "MA", 21],
[2110906, "SAO FRANCISCO DO MARANHAO", "MARANHAO", "MA", 21],
[2111003, "SAO JOAO BATISTA", "MARANHAO", "MA", 21],
[2111029, "SAO JOAO DO CARU", "MARANHAO", "MA", 21],
[2111052, "SAO JOAO DO PARAISO", "MARANHAO", "MA", 21],
[2111078, "SAO JOAO DO SOTER", "MARANHAO", "MA", 21],
[2111102, "SAO JOAO DOS PATOS", "MARANHAO", "MA", 21],
[2111201, "SAO JOSE DE RIBAMAR", "MARANHAO", "MA", 21],
[2111250, "SAO JOSE DOS BASILIOS", "MARANHAO", "MA", 21],
[2111300, "SAO LUIS", "MARANHAO", "MA", 21],
[2111409, "SAO LUIS GONZAGA DO MARAN", "MARANHAO", "MA", 21],
[2111508, "SAO MATEUS DO MARANHAO", "MARANHAO", "MA", 21],
[2111532, "SAO PEDRO DA AGUA BRANCA", "MARANHAO", "MA", 21],
[2111573, "SAO PEDRO DOS CRENTES", "MARANHAO", "MA", 21],
[2111607, "SAO RAIMUNDO DAS MANGABEI", "MARANHAO", "MA", 21],
[2111631, "SAO RAIMUNDO DO DOCA BEZE", "MARANHAO", "MA", 21],
[2111672, "SAO ROBERTO", "MARANHAO", "MA", 21],
[2111706, "SAO VICENTE FERRER", "MARANHAO", "MA", 21],
[2111722, "SATUBINHA", "MARANHAO", "MA", 21],
[2111748, "SENADOR ALEXANDRE COSTA", "MARANHAO", "MA", 21],
[2111763, "SENADOR LA ROCQUE", "MARANHAO", "MA", 21],
[2111789, "SERRANO DO MARANHAO", "MARANHAO", "MA", 21],
[2111805, "SITIO NOVO", "MARANHAO", "MA", 21],
[2111904, "SUCUPIRA DO NORTE", "MARANHAO", "MA", 21],
[2111953, "SUCUPIRA DO RIACHAO", "MARANHAO", "MA", 21],
[2112001, "TASSO FRAGOSO", "MARANHAO", "MA", 21],
[2112100, "TIMBIRAS", "MARANHAO", "MA", 21],
[2112209, "TIMON", "MARANHAO", "MA", 21],
[2112233, "TRIZIDELA DO VALE", "MARANHAO", "MA", 21],
[2112274, "TUFILANDIA", "MARANHAO", "MA", 21],
[2112308, "TUNTUM", "MARANHAO", "MA", 21],
[2112407, "TURIACU", "MARANHAO", "MA", 21],
[2112456, "TURILANDIA", "MARANHAO", "MA", 21],
[2112506, "TUTOIA", "MARANHAO", "MA", 21],
[2112605, "URBANO SANTOS", "MARANHAO", "MA", 21],
[2112704, "VARGEM GRANDE", "MARANHAO", "MA", 21],
[2112803, "VIANA", "MARANHAO", "MA", 21],
[2112852, "VILA NOVA DOS MARTIRIOS", "MARANHAO", "MA", 21],
[2112902, "VITORIA DO MEARIM", "MARANHAO", "MA", 21],
[2113009, "VITORINO FREIRE", "MARANHAO", "MA", 21],
[2114007, "ZE DOCA", "MARANHAO", "MA", 21],
[2200053, "ACAUA", "PIAUI", "PI", 22],
[2200103, "AGRICOLANDIA", "PIAUI", "PI", 22],
[2200202, "AGUA BRANCA", "PIAUI", "PI", 22],
[2200251, "ALAGOINHA DO PIAUI", "PIAUI", "PI", 22],
[2200277, "ALEGRETE DO PIAUI", "PIAUI", "PI", 22],
[2200301, "ALTO LONGA", "PIAUI", "PI", 22],
[2200400, "ALTOS", "PIAUI", "PI", 22],
[2200459, "ALVORADA DO GURGUEIA", "PIAUI", "PI", 22],
[2200509, "AMARANTE", "PIAUI", "PI", 22],
[2200608, "ANGICAL DO PIAUI", "PIAUI", "PI", 22],
[2200707, "ANISIO DE ABREU", "PIAUI", "PI", 22],
[2200806, "ANTONIO ALMEIDA", "PIAUI", "PI", 22],
[2200905, "AROAZES", "PIAUI", "PI", 22],
[2200954, "AROEIRAS DO ITAIM", "PIAUI", "PI", 22],
[2201002, "ARRAIAL", "PIAUI", "PI", 22],
[2201051, "ASSUNCAO DO PIAUI", "PIAUI", "PI", 22],
[2201101, "AVELINO LOPES", "PIAUI", "PI", 22],
[2201150, "BAIXA GRANDE DO RIBEIRO", "PIAUI", "PI", 22],
[2201176, "BARRA D'ALCANTARA", "PIAUI", "PI", 22],
[2201200, "BARRAS", "PIAUI", "PI", 22],
[2201309, "BARREIRAS DO PIAUI", "PIAUI", "PI", 22],
[2201408, "BARRO DURO", "PIAUI", "PI", 22],
[2201507, "BATALHA", "PIAUI", "PI", 22],
[2201556, "BELA VISTA DO PIAUI", "PIAUI", "PI", 22],
[2201572, "BELEM DO PIAUI", "PIAUI", "PI", 22],
[2201606, "BENEDITINOS", "PIAUI", "PI", 22],
[2201705, "BERTOLINIA", "PIAUI", "PI", 22],
[2201739, "BETANIA DO PIAUI", "PIAUI", "PI", 22],
[2201770, "BOA HORA", "PIAUI", "PI", 22],
[2201804, "BOCAINA", "PIAUI", "PI", 22],
[2201903, "BOM JESUS", "PIAUI", "PI", 22],
[2201919, "BOM PRINCIPIO DO PIAUI", "PIAUI", "PI", 22],
[2201929, "BONFIM DO PIAUI", "PIAUI", "PI", 22],
[2201945, "BOQUEIRAO DO PIAUI", "PIAUI", "PI", 22],
[2201960, "BRASILEIRA", "PIAUI", "PI", 22],
[2201988, "BREJO DO PIAUI", "PIAUI", "PI", 22],
[2202000, "BURITI DOS LOPES", "PIAUI", "PI", 22],
[2202026, "BURITI DOS MONTES", "PIAUI", "PI", 22],
[2202059, "CABECEIRAS DO PIAUI", "PIAUI", "PI", 22],
[2202075, "CAJAZEIRAS DO PIAUI", "PIAUI", "PI", 22],
[2202083, "CAJUEIRO DA PRAIA", "PIAUI", "PI", 22],
[2202091, "CALDEIRAO GRANDE DO PIAUI", "PIAUI", "PI", 22],
[2202109, "CAMPINAS DO PIAUI", "PIAUI", "PI", 22],
[2202117, "CAMPO ALEGRE DO FIDALGO", "PIAUI", "PI", 22],
[2202133, "CAMPO GRANDE DO PIAUI", "PIAUI", "PI", 22],
[2202174, "CAMPO LARGO DO PIAUI", "PIAUI", "PI", 22],
[2202208, "CAMPO MAIOR", "PIAUI", "PI", 22],
[2202251, "CANAVIEIRA", "PIAUI", "PI", 22],
[2202307, "CANTO DO BURITI", "PIAUI", "PI", 22],
[2202406, "CAPITAO DE CAMPOS", "PIAUI", "PI", 22],
[2202455, "CAPITAO GERVASIO OLIVEIRA", "PIAUI", "PI", 22],
[2202505, "CARACOL", "PIAUI", "PI", 22],
[2202539, "CARAUBAS DO PIAUI", "PIAUI", "PI", 22],
[2202554, "CARIDADE DO PIAUI", "PIAUI", "PI", 22],
[2202604, "CASTELO DO PIAUI", "PIAUI", "PI", 22],
[2202653, "CAXINGO", "PIAUI", "PI", 22],
[2202703, "COCAL", "PIAUI", "PI", 22],
[2202711, "COCAL DE TELHA", "PIAUI", "PI", 22],
[2202729, "COCAL DOS ALVES", "PIAUI", "PI", 22],
[2202737, "COIVARAS", "PIAUI", "PI", 22],
[2202752, "COLONIA DO GURGUEIA", "PIAUI", "PI", 22],
[2202778, "COLONIA DO PIAUI", "PIAUI", "PI", 22],
[2202802, "CONCEICAO DO CANINDE", "PIAUI", "PI", 22],
[2202851, "CORONEL JOSE DIAS", "PIAUI", "PI", 22],
[2202901, "CORRENTE", "PIAUI", "PI", 22],
[2203008, "CRISTALANDIA DO PIAUI", "PIAUI", "PI", 22],
[2203107, "CRISTINO CASTRO", "PIAUI", "PI", 22],
[2203206, "CURIMATA", "PIAUI", "PI", 22],
[2203230, "CURRAIS", "PIAUI", "PI", 22],
[2203255, "CURRALINHOS", "PIAUI", "PI", 22],
[2203271, "CURRAL NOVO DO PIAUI", "PIAUI", "PI", 22],
[2203305, "DEMERVAL LOBAO", "PIAUI", "PI", 22],
[2203354, "DIRCEU ARCOVERDE", "PIAUI", "PI", 22],
[2203404, "DOM EXPEDITO LOPES", "PIAUI", "PI", 22],
[2203420, "DOMINGOS MOURAO", "PIAUI", "PI", 22],
[2203453, "DOM INOCENCIO", "PIAUI", "PI", 22],
[2203503, "ELESBAO VELOSO", "PIAUI", "PI", 22],
[2203602, "ELISEU MARTINS", "PIAUI", "PI", 22],
[2203701, "ESPERANTINA", "PIAUI", "PI", 22],
[2203750, "FARTURA DO PIAUI", "PIAUI", "PI", 22],
[2203800, "FLORES DO PIAUI", "PIAUI", "PI", 22],
[2203859, "FLORESTA DO PIAUI", "PIAUI", "PI", 22],
[2203909, "FLORIANO", "PIAUI", "PI", 22],
[2204006, "FRANCINOPOLIS", "PIAUI", "PI", 22],
[2204105, "FRANCISCO AYRES", "PIAUI", "PI", 22],
[2204154, "FRANCISCO MACEDO", "PIAUI", "PI", 22],
[2204204, "FRANCISCO SANTOS", "PIAUI", "PI", 22],
[2204303, "FRONTEIRAS", "PIAUI", "PI", 22],
[2204352, "GEMINIANO", "PIAUI", "PI", 22],
[2204402, "GILBUES", "PIAUI", "PI", 22],
[2204501, "GUADALUPE", "PIAUI", "PI", 22],
[2204550, "GUARIBAS", "PIAUI", "PI", 22],
[2204600, "HUGO NAPOLEAO", "PIAUI", "PI", 22],
[2204659, "ILHA GRANDE", "PIAUI", "PI", 22],
[2204709, "INHUMA", "PIAUI", "PI", 22],
[2204808, "IPIRANGA DO PIAUI", "PIAUI", "PI", 22],
[2204907, "ISAIAS COELHO", "PIAUI", "PI", 22],
[2205003, "ITAINOPOLIS", "PIAUI", "PI", 22],
[2205102, "ITAUEIRA", "PIAUI", "PI", 22],
[2205151, "JACOBINA DO PIAUI", "PIAUI", "PI", 22],
[2205201, "JAICOS", "PIAUI", "PI", 22],
[2205250, "JARDIM DO MULATO", "PIAUI", "PI", 22],
[2205276, "JATOBA DO PIAUI", "PIAUI", "PI", 22],
[2205300, "JERUMENHA", "PIAUI", "PI", 22],
[2205359, "JOAO COSTA", "PIAUI", "PI", 22],
[2205409, "JOAQUIM PIRES", "PIAUI", "PI", 22],
[2205458, "JOCA MARQUES", "PIAUI", "PI", 22],
[2205508, "JOSE DE FREITAS", "PIAUI", "PI", 22],
[2205516, "JUAZEIRO DO PIAUI", "PIAUI", "PI", 22],
[2205524, "JULIO BORGES", "PIAUI", "PI", 22],
[2205532, "JUREMA", "PIAUI", "PI", 22],
[2205540, "LAGOINHA DO PIAUI", "PIAUI", "PI", 22],
[2205557, "LAGOA ALEGRE", "PIAUI", "PI", 22],
[2205565, "LAGOA DO BARRO DO PIAUI", "PIAUI", "PI", 22],
[2205573, "LAGOA DE SAO FRANCISCO", "PIAUI", "PI", 22],
[2205581, "LAGOA DO PIAUI", "PIAUI", "PI", 22],
[2205599, "LAGOA DO SITIO", "PIAUI", "PI", 22],
[2205607, "LANDRI SALES", "PIAUI", "PI", 22],
[2205706, "LUIS CORREIA", "PIAUI", "PI", 22],
[2205805, "LUZILANDIA", "PIAUI", "PI", 22],
[2205854, "MADEIRO", "PIAUI", "PI", 22],
[2205904, "MANOEL EMIDIO", "PIAUI", "PI", 22],
[2205953, "MARCOLANDIA", "PIAUI", "PI", 22],
[2206001, "MARCOS PARENTE", "PIAUI", "PI", 22],
[2206050, "MASSAPE DO PIAUI", "PIAUI", "PI", 22],
[2206100, "MATIAS OLIMPIO", "PIAUI", "PI", 22],
[2206209, "MIGUEL ALVES", "PIAUI", "PI", 22],
[2206308, "MIGUEL LEAO", "PIAUI", "PI", 22],
[2206357, "MILTON BRANDAO", "PIAUI", "PI", 22],
[2206407, "MONSENHOR GIL", "PIAUI", "PI", 22],
[2206506, "MONSENHOR HIPOLITO", "PIAUI", "PI", 22],
[2206605, "MONTE ALEGRE DO PIAUI", "PIAUI", "PI", 22],
[2206654, "MORRO CABECA NO TEMPO", "PIAUI", "PI", 22],
[2206670, "MORRO DO CHAPEU DO PIAUI", "PIAUI", "PI", 22],
[2206696, "MURICI DOS PORTELAS", "PIAUI", "PI", 22],
[2206704, "NAZARE DO PIAUI", "PIAUI", "PI", 22],
[2206720, "NAZARIA", "PIAUI", "PI", 22],
[2206753, "NOSSA SENHORA DE NAZARE", "PIAUI", "PI", 22],
[2206803, "NOSSA SENHORA DOS REMEDIO", "PIAUI", "PI", 22],
[2206902, "NOVO ORIENTE DO PIAUI", "PIAUI", "PI", 22],
[2206951, "NOVO SANTO ANTONIO", "PIAUI", "PI", 22],
[2207009, "OEIRAS", "PIAUI", "PI", 22],
[2207108, "OLHO D'AGUA DO PIAUI", "PIAUI", "PI", 22],
[2207207, "PADRE MARCOS", "PIAUI", "PI", 22],
[2207306, "PAES LANDIM", "PIAUI", "PI", 22],
[2207355, "PAJEU DO PIAUI", "PIAUI", "PI", 22],
[2207405, "PALMEIRA DO PIAUI", "PIAUI", "PI", 22],
[2207504, "PALMEIRAIS", "PIAUI", "PI", 22],
[2207553, "PAQUETA", "PIAUI", "PI", 22],
[2207603, "PARNAGUA", "PIAUI", "PI", 22],
[2207702, "PARNAIBA", "PIAUI", "PI", 22],
[2207751, "PASSAGEM FRANCA DO PIAUI", "PIAUI", "PI", 22],
[2207777, "PATOS DO PIAUI", "PIAUI", "PI", 22],
[2207793, "PAU D'ARCO DO PIAUI", "PIAUI", "PI", 22],
[2207801, "PAULISTANA", "PIAUI", "PI", 22],
[2207850, "PAVUSSU", "PIAUI", "PI", 22],
[2207900, "PEDRO II", "PIAUI", "PI", 22],
[2207934, "PEDRO LAURENTINO", "PIAUI", "PI", 22],
[2207959, "NOVA SANTA RITA", "PIAUI", "PI", 22],
[2208007, "PICOS", "PIAUI", "PI", 22],
[2208106, "PIMENTEIRAS", "PIAUI", "PI", 22],
[2208205, "PIO IX", "PIAUI", "PI", 22],
[2208304, "PIRACURUCA", "PIAUI", "PI", 22],
[2208403, "PIRIPIRI", "PIAUI", "PI", 22],
[2208502, "PORTO", "PIAUI", "PI", 22],
[2208551, "PORTO ALEGRE DO PIAUI", "PIAUI", "PI", 22],
[2208601, "PRATA DO PIAUI", "PIAUI", "PI", 22],
[2208650, "QUEIMADA NOVA", "PIAUI", "PI", 22],
[2208700, "REDENCAO DO GURGUEIA", "PIAUI", "PI", 22],
[2208809, "REGENERACAO", "PIAUI", "PI", 22],
[2208858, "RIACHO FRIO", "PIAUI", "PI", 22],
[2208874, "RIBEIRA DO PIAUI", "PIAUI", "PI", 22],
[2208908, "RIBEIRO GONCALVES", "PIAUI", "PI", 22],
[2209005, "RIO GRANDE DO PIAUI", "PIAUI", "PI", 22],
[2209104, "SANTA CRUZ DO PIAUI", "PIAUI", "PI", 22],
[2209153, "SANTA CRUZ DOS MILAGRES", "PIAUI", "PI", 22],
[2209203, "SANTA FILOMENA", "PIAUI", "PI", 22],
[2209302, "SANTA LUZ", "PIAUI", "PI", 22],
[2209351, "SANTANA DO PIAUI", "PIAUI", "PI", 22],
[2209377, "SANTA ROSA DO PIAUI", "PIAUI", "PI", 22],
[2209401, "SANTO ANTONIO DE LISBOA", "PIAUI", "PI", 22],
[2209450, "SANTO ANTONIO DOS MILAGRE", "PIAUI", "PI", 22],
[2209500, "SANTO INACIO DO PIAUI", "PIAUI", "PI", 22],
[2209559, "SAO BRAZ DO PIAUI", "PIAUI", "PI", 22],
[2209609, "SAO FELIX DO PIAUI", "PIAUI", "PI", 22],
[2209658, "SAO FRANCISCO DE ASSIS DO", "PIAUI", "PI", 22],
[2209708, "SAO FRANCISCO DO PIAUI", "PIAUI", "PI", 22],
[2209757, "SAO GONCALO DO GURGUEIA", "PIAUI", "PI", 22],
[2209807, "SAO GONCALO DO PIAUI", "PIAUI", "PI", 22],
[2209856, "SAO JOAO DA CANABRAVA", "PIAUI", "PI", 22],
[2209872, "SAO JOAO DA FRONTEIRA", "PIAUI", "PI", 22],
[2209906, "SAO JOAO DA SERRA", "PIAUI", "PI", 22],
[2209955, "SAO JOAO DA VARJOTA", "PIAUI", "PI", 22],
[2209971, "SAO JOAO DO ARRAIAL", "PIAUI", "PI", 22],
[2210003, "SAO JOAO DO PIAUI", "PIAUI", "PI", 22],
[2210052, "SAO JOSE DO DIVINO", "PIAUI", "PI", 22],
[2210102, "SAO JOSE DO PEIXE", "PIAUI", "PI", 22],
[2210201, "SAO JOSE DO PIAUI", "PIAUI", "PI", 22],
[2210300, "SAO JULIAO", "PIAUI", "PI", 22],
[2210359, "SAO LOURENCO DO PIAUI", "PIAUI", "PI", 22],
[2210375, "SAO LUIS DO PIAUI", "PIAUI", "PI", 22],
[2210383, "SAO MIGUEL DA BAIXA GRAND", "PIAUI", "PI", 22],
[2210391, "SAO MIGUEL DO FIDALGO", "PIAUI", "PI", 22],
[2210409, "SAO MIGUEL DO TAPUIO", "PIAUI", "PI", 22],
[2210508, "SAO PEDRO DO PIAUI", "PIAUI", "PI", 22],
[2210607, "SAO RAIMUNDO NONATO", "PIAUI", "PI", 22],
[2210623, "SEBASTIAO BARROS", "PIAUI", "PI", 22],
[2210631, "SEBASTIAO LEAL", "PIAUI", "PI", 22],
[2210656, "SIGEFREDO PACHECO", "PIAUI", "PI", 22],
[2210706, "SIMÕES", "PIAUI", "PI", 22],
[2210805, "SIMPLICIO MENDES", "PIAUI", "PI", 22],
[2210904, "SOCORRO DO PIAUI", "PIAUI", "PI", 22],
[2210938, "SUSSUAPARA", "PIAUI", "PI", 22],
[2210953, "TAMBORIL DO PIAUI", "PIAUI", "PI", 22],
[2210979, "TANQUE DO PIAUI", "PIAUI", "PI", 22],
[2211001, "TERESINA", "PIAUI", "PI", 22],
[2211100, "UNIAO", "PIAUI", "PI", 22],
[2211209, "URUCUI", "PIAUI", "PI", 22],
[2211308, "VALENCA DO PIAUI", "PIAUI", "PI", 22],
[2211357, "VARZEA BRANCA", "PIAUI", "PI", 22],
[2211407, "VARZEA GRANDE", "PIAUI", "PI", 22],
[2211506, "VERA MENDES", "PIAUI", "PI", 22],
[2211605, "VILA NOVA DO PIAUI", "PIAUI", "PI", 22],
[2211704, "WALL FERRAZ", "PIAUI", "PI", 22],
[2300101, "ABAIARA", "CEARA", "CE", 23],
[2300150, "ACARAPE", "CEARA", "CE", 23],
[2300200, "ACARAU", "CEARA", "CE", 23],
[2300309, "ACOPIARA", "CEARA", "CE", 23],
[2300408, "AIUABA", "CEARA", "CE", 23],
[2300507, "ALCANTARAS", "CEARA", "CE", 23],
[2300606, "ALTANEIRA", "CEARA", "CE", 23],
[2300705, "ALTO SANTO", "CEARA", "CE", 23],
[2300754, "AMONTADA", "CEARA", "CE", 23],
[2300804, "ANTONINA DO NORTE", "CEARA", "CE", 23],
[2300903, "APUIARES", "CEARA", "CE", 23],
[2301000, "AQUIRAZ", "CEARA", "CE", 23],
[2301109, "ARACATI", "CEARA", "CE", 23],
[2301208, "ARACOIABA", "CEARA", "CE", 23],
[2301257, "ARARENDA", "CEARA", "CE", 23],
[2301307, "ARARIPE", "CEARA", "CE", 23],
[2301406, "ARATUBA", "CEARA", "CE", 23],
[2301505, "ARNEIROZ", "CEARA", "CE", 23],
[2301604, "ASSARE", "CEARA", "CE", 23],
[2301703, "AURORA", "CEARA", "CE", 23],
[2301802, "BAIXIO", "CEARA", "CE", 23],
[2301851, "BANABUIU", "CEARA", "CE", 23],
[2301901, "BARBALHA", "CEARA", "CE", 23],
[2301950, "BARREIRA", "CEARA", "CE", 23],
[2302008, "BARRO", "CEARA", "CE", 23],
[2302057, "BARROQUINHA", "CEARA", "CE", 23],
[2302107, "BATURITE", "CEARA", "CE", 23],
[2302206, "BEBERIBE", "CEARA", "CE", 23],
[2302305, "BELA CRUZ", "CEARA", "CE", 23],
[2302404, "BOA VIAGEM", "CEARA", "CE", 23],
[2302503, "BREJO SANTO", "CEARA", "CE", 23],
[2302602, "CAMOCIM", "CEARA", "CE", 23],
[2302701, "CAMPOS SALES", "CEARA", "CE", 23],
[2302800, "CANINDE", "CEARA", "CE", 23],
[2302909, "CAPISTRANO", "CEARA", "CE", 23],
[2303006, "CARIDADE", "CEARA", "CE", 23],
[2303105, "CARIRE", "CEARA", "CE", 23],
[2303303, "CARIUS", "CEARA", "CE", 23],
[2303402, "CARNAUBAL", "CEARA", "CE", 23],
[2303501, "CASCAVEL", "CEARA", "CE", 23],
[2303600, "CATARINA", "CEARA", "CE", 23],
[2303709, "CAUCAIA", "CEARA", "CE", 23],
[2303808, "CEDRO", "CEARA", "CE", 23],
[2303907, "CHAVAL", "CEARA", "CE", 23],
[2303931, "CHORO", "CEARA", "CE", 23],
[2303956, "CHOROZINHO", "CEARA", "CE", 23],
[2304004, "COREAU", "CEARA", "CE", 23],
[2304103, "CRATEUS", "CEARA", "CE", 23],
[2304202, "CRATO", "CEARA", "CE", 23],
[2304236, "CROATA", "CEARA", "CE", 23],
[2304251, "CRUZ", "CEARA", "CE", 23],
[2304269, "DEPUTADO IRAPUAN PINHEIRO", "CEARA", "CE", 23],
[2304277, "ERERE", "CEARA", "CE", 23],
[2304285, "EUSEBIO", "CEARA", "CE", 23],
[2304301, "FARIAS BRITO", "CEARA", "CE", 23],
[2304400, "FORTALEZA", "CEARA", "CE", 23],
[2304459, "FORTIM", "CEARA", "CE", 23],
[2304509, "FRECHEIRINHA", "CEARA", "CE", 23],
[2304608, "GENERAL SAMPAIO", "CEARA", "CE", 23],
[2304707, "GRANJA", "CEARA", "CE", 23],
[2304806, "GRANJEIRO", "CEARA", "CE", 23],
[2304905, "GROAIRAS", "CEARA", "CE", 23],
[2304954, "GUAIUBA", "CEARA", "CE", 23],
[2305001, "GUARACIABA DO NORTE", "CEARA", "CE", 23],
[2305100, "GUARAMIRANGA", "CEARA", "CE", 23],
[2305209, "HIDROLANDIA", "CEARA", "CE", 23],
[2305233, "HORIZONTE", "CEARA", "CE", 23],
[2305266, "IBARETAMA", "CEARA", "CE", 23],
[2305308, "IBIAPINA", "CEARA", "CE", 23],
[2305332, "IBICUITINGA", "CEARA", "CE", 23],
[2305357, "ICAPUI", "CEARA", "CE", 23],
[2305407, "ICO", "CEARA", "CE", 23],
[2305506, "IGUATU", "CEARA", "CE", 23],
[2305605, "INDEPENDENCIA", "CEARA", "CE", 23],
[2305654, "IPAPORANGA", "CEARA", "CE", 23],
[2305704, "IPAUMIRIM", "CEARA", "CE", 23],
[2305803, "IPU", "CEARA", "CE", 23],
[2305902, "IPUEIRAS", "CEARA", "CE", 23],
[2306009, "IRACEMA", "CEARA", "CE", 23],
[2306207, "ITAICABA", "CEARA", "CE", 23],
[2306256, "ITAITINGA", "CEARA", "CE", 23],
[2306306, "ITAPAGE", "CEARA", "CE", 23],
[2306405, "ITAPIPOCA", "CEARA", "CE", 23],
[2306504, "ITAPIUNA", "CEARA", "CE", 23],
[2306553, "ITAREMA", "CEARA", "CE", 23],
[2306603, "ITATIRA", "CEARA", "CE", 23],
[2306702, "JAGUARETAMA", "CEARA", "CE", 23],
[2306801, "JAGUARIBARA", "CEARA", "CE", 23],
[2306900, "JAGUARIBE", "CEARA", "CE", 23],
[2307007, "JAGUARUANA", "CEARA", "CE", 23],
[2307106, "JARDIM", "CEARA", "CE", 23],
[2307205, "JATI", "CEARA", "CE", 23],
[2307254, "JIJOCA DE JERICOACOARA", "CEARA", "CE", 23],
[2307304, "JUAZEIRO DO NORTE", "CEARA", "CE", 23],
[2307403, "JUCAS", "CEARA", "CE", 23],
[2307502, "LAVRAS DA MANGABEIRA", "CEARA", "CE", 23],
[2307601, "LIMOEIRO DO NORTE", "CEARA", "CE", 23],
[2307635, "MADALENA", "CEARA", "CE", 23],
[2307650, "MARACANAU", "CEARA", "CE", 23],
[2307700, "MARANGUAPE", "CEARA", "CE", 23],
[2307809, "MARCO", "CEARA", "CE", 23],
[2307908, "MARTINOPOLE", "CEARA", "CE", 23],
[2308005, "MASSAPE", "CEARA", "CE", 23],
[2308104, "MAURITI", "CEARA", "CE", 23],
[2308203, "MERUOCA", "CEARA", "CE", 23],
[2308302, "MILAGRES", "CEARA", "CE", 23],
[2308351, "MILHA", "CEARA", "CE", 23],
[2308377, "MIRAIMA", "CEARA", "CE", 23],
[2308401, "MISSAO VELHA", "CEARA", "CE", 23],
[2308500, "MOMBACA", "CEARA", "CE", 23],
[2308609, "MONSENHOR TABOSA", "CEARA", "CE", 23],
[2308708, "MORADA NOVA", "CEARA", "CE", 23],
[2308807, "MORAUJO", "CEARA", "CE", 23],
[2308906, "MORRINHOS", "CEARA", "CE", 23],
[2309003, "MUCAMBO", "CEARA", "CE", 23],
[2309102, "MULUNGU", "CEARA", "CE", 23],
[2309201, "NOVA OLINDA", "CEARA", "CE", 23],
[2309300, "NOVA RUSSAS", "CEARA", "CE", 23],
[2309409, "NOVO ORIENTE", "CEARA", "CE", 23],
[2309458, "OCARA", "CEARA", "CE", 23],
[2309508, "OROS", "CEARA", "CE", 23],
[2309607, "PACAJUS", "CEARA", "CE", 23],
[2309706, "PACATUBA", "CEARA", "CE", 23],
[2309904, "PACUJA", "CEARA", "CE", 23],
[2310001, "PALHANO", "CEARA", "CE", 23],
[2310100, "PALMACIA", "CEARA", "CE", 23],
[2310209, "PARACURU", "CEARA", "CE", 23],
[2310258, "PARAIPABA", "CEARA", "CE", 23],
[2310308, "PARAMBU", "CEARA", "CE", 23],
[2310407, "PARAMOTI", "CEARA", "CE", 23],
[2310506, "PEDRA BRANCA", "CEARA", "CE", 23],
[2310605, "PENAFORTE", "CEARA", "CE", 23],
[2310704, "PENTECOSTE", "CEARA", "CE", 23],
[2310803, "PEREIRO", "CEARA", "CE", 23],
[2310852, "PINDORETAMA", "CEARA", "CE", 23],
[2310902, "PIQUET CARNEIRO", "CEARA", "CE", 23],
[2310951, "PIRES FERREIRA", "CEARA", "CE", 23],
[2311009, "PORANGA", "CEARA", "CE", 23],
[2311108, "PORTEIRAS", "CEARA", "CE", 23],
[2311207, "POTENGI", "CEARA", "CE", 23],
[2311231, "POTIRETAMA", "CEARA", "CE", 23],
[2311264, "QUITERIANOPOLIS", "CEARA", "CE", 23],
[2311306, "QUIXADA", "CEARA", "CE", 23],
[2311355, "QUIXELO", "CEARA", "CE", 23],
[2311405, "QUIXERAMOBIM", "CEARA", "CE", 23],
[2311504, "QUIXERE", "CEARA", "CE", 23],
[2311603, "REDENCAO", "CEARA", "CE", 23],
[2311801, "RUSSAS", "CEARA", "CE", 23],
[2311900, "SABOEIRO", "CEARA", "CE", 23],
[2311959, "SALITRE", "CEARA", "CE", 23],
[2312007, "SANTANA DO ACARAU", "CEARA", "CE", 23],
[2312106, "SANTANA DO CARIRI", "CEARA", "CE", 23],
[2312205, "SANTA QUITERIA", "CEARA", "CE", 23],
[2312304, "SAO BENEDITO", "CEARA", "CE", 23],
[2312403, "SAO GONCALO DO AMARANTE", "CEARA", "CE", 23],
[2312502, "SAO JOAO DO JAGUARIBE", "CEARA", "CE", 23],
[2312601, "SAO LUIS DO CURU", "CEARA", "CE", 23],
[2312700, "SENADOR POMPEU", "CEARA", "CE", 23],
[2312809, "SENADOR SA", "CEARA", "CE", 23],
[2312908, "SOBRAL", "CEARA", "CE", 23],
[2313005, "SOLONOPOLE", "CEARA", "CE", 23],
[2313104, "TABULEIRO DO NORTE", "CEARA", "CE", 23],
[2313203, "TAMBORIL", "CEARA", "CE", 23],
[2313252, "TARRAFAS", "CEARA", "CE", 23],
[2313302, "TAUA", "CEARA", "CE", 23],
[2313351, "TEJUCUOCA", "CEARA", "CE", 23],
[2313401, "TIANGUA", "CEARA", "CE", 23],
[2313500, "TRAIRI", "CEARA", "CE", 23],
[2313559, "TURURU", "CEARA", "CE", 23],
[2313609, "UBAJARA", "CEARA", "CE", 23],
[2313708, "UMARI", "CEARA", "CE", 23],
[2313757, "UMIRIM", "CEARA", "CE", 23],
[2313807, "URUBURETAMA", "CEARA", "CE", 23],
[2313906, "URUOCA", "CEARA", "CE", 23],
[2313955, "VARJOTA", "CEARA", "CE", 23],
[2314003, "VARZEA ALEGRE", "CEARA", "CE", 23],
[2314102, "VICOSA DO CEARA", "CEARA", "CE", 23],
[2400109, "ACARI", "RIO GRANDE DO NORTE", "RN", 24],
[2400208, "ACU", "RIO GRANDE DO NORTE", "RN", 24],
[2400307, "AFONSO BEZERRA", "RIO GRANDE DO NORTE", "RN", 24],
[2400406, "AGUA NOVA", "RIO GRANDE DO NORTE", "RN", 24],
[2400505, "ALEXANDRIA", "RIO GRANDE DO NORTE", "RN", 24],
[2400604, "ALMINO AFONSO", "RIO GRANDE DO NORTE", "RN", 24],
[2400703, "ALTO DO RODRIGUES", "RIO GRANDE DO NORTE", "RN", 24],
[2400802, "ANGICOS", "RIO GRANDE DO NORTE", "RN", 24],
[2400901, "ANTONIO MARTINS", "RIO GRANDE DO NORTE", "RN", 24],
[2401008, "APODI", "RIO GRANDE DO NORTE", "RN", 24],
[2401107, "AREIA BRANCA", "RIO GRANDE DO NORTE", "RN", 24],
[2401206, "ARES", "RIO GRANDE DO NORTE", "RN", 24],
[2401305, "AUGUSTO SEVERO", "RIO GRANDE DO NORTE", "RN", 24],
[2401404, "BAIA FORMOSA", "RIO GRANDE DO NORTE", "RN", 24],
[2401453, "BARAUNA", "RIO GRANDE DO NORTE", "RN", 24],
[2401503, "BARCELONA", "RIO GRANDE DO NORTE", "RN", 24],
[2401602, "BENTO FERNANDES", "RIO GRANDE DO NORTE", "RN", 24],
[2401651, "BODO", "RIO GRANDE DO NORTE", "RN", 24],
[2401701, "BOM JESUS", "RIO GRANDE DO NORTE", "RN", 24],
[2401800, "BREJINHO", "RIO GRANDE DO NORTE", "RN", 24],
[2401859, "CAICARA DO NORTE", "RIO GRANDE DO NORTE", "RN", 24],
[2401909, "CAICARA DO RIO DO VENTO", "RIO GRANDE DO NORTE", "RN", 24],
[2402006, "CAICO", "RIO GRANDE DO NORTE", "RN", 24],
[2402105, "CAMPO REDONDO", "RIO GRANDE DO NORTE", "RN", 24],
[2402204, "CANGUARETAMA", "RIO GRANDE DO NORTE", "RN", 24],
[2402303, "CARAUBAS", "RIO GRANDE DO NORTE", "RN", 24],
[2402402, "CARNAUBA DOS DANTAS", "RIO GRANDE DO NORTE", "RN", 24],
[2402501, "CARNAUBAIS", "RIO GRANDE DO NORTE", "RN", 24],
[2402600, "CEARA-MIRIM", "RIO GRANDE DO NORTE", "RN", 24],
[2402709, "CERRO CORA", "RIO GRANDE DO NORTE", "RN", 24],
[2402808, "CORONEL EZEQUIEL", "RIO GRANDE DO NORTE", "RN", 24],
[2402907, "CORONEL JOAO PESSOA", "RIO GRANDE DO NORTE", "RN", 24],
[2403004, "CRUZETA", "RIO GRANDE DO NORTE", "RN", 24],
[2403103, "CURRAIS NOVOS", "RIO GRANDE DO NORTE", "RN", 24],
[2403202, "DOUTOR SEVERIANO", "RIO GRANDE DO NORTE", "RN", 24],
[2403251, "PARNAMIRIM", "RIO GRANDE DO NORTE", "RN", 24],
[2403301, "ENCANTO", "RIO GRANDE DO NORTE", "RN", 24],
[2403400, "EQUADOR", "RIO GRANDE DO NORTE", "RN", 24],
[2403509, "ESPIRITO SANTO", "RIO GRANDE DO NORTE", "RN", 24],
[2403608, "EXTREMOZ", "RIO GRANDE DO NORTE", "RN", 24],
[2403707, "FELIPE GUERRA", "RIO GRANDE DO NORTE", "RN", 24],
[2403756, "FERNANDO PEDROZA", "RIO GRANDE DO NORTE", "RN", 24],
[2403806, "FLORANIA", "RIO GRANDE DO NORTE", "RN", 24],
[2403905, "FRANCISCO DANTAS", "RIO GRANDE DO NORTE", "RN", 24],
[2404002, "FRUTUOSO GOMES", "RIO GRANDE DO NORTE", "RN", 24],
[2404101, "GALINHOS", "RIO GRANDE DO NORTE", "RN", 24],
[2404200, "GOIANINHA", "RIO GRANDE DO NORTE", "RN", 24],
[2404309, "GOVERNADOR DIX-SEPT ROSAD", "RIO GRANDE DO NORTE", "RN", 24],
[2404408, "GROSSOS", "RIO GRANDE DO NORTE", "RN", 24],
[2404507, "GUAMARE", "RIO GRANDE DO NORTE", "RN", 24],
[2404606, "IELMO MARINHO", "RIO GRANDE DO NORTE", "RN", 24],
[2404705, "IPANGUACU", "RIO GRANDE DO NORTE", "RN", 24],
[2404804, "IPUEIRA", "RIO GRANDE DO NORTE", "RN", 24],
[2404853, "ITAJA", "RIO GRANDE DO NORTE", "RN", 24],
[2404903, "ITAU", "RIO GRANDE DO NORTE", "RN", 24],
[2405009, "JACANA", "RIO GRANDE DO NORTE", "RN", 24],
[2405108, "JANDAIRA", "RIO GRANDE DO NORTE", "RN", 24],
[2405207, "JANDUIS", "RIO GRANDE DO NORTE", "RN", 24],
[2405306, "JANUARIO CICCO", "RIO GRANDE DO NORTE", "RN", 24],
[2405405, "JAPI", "RIO GRANDE DO NORTE", "RN", 24],
[2405504, "JARDIM DE ANGICOS", "RIO GRANDE DO NORTE", "RN", 24],
[2405603, "JARDIM DE PIRANHAS", "RIO GRANDE DO NORTE", "RN", 24],
[2405702, "JARDIM DO SERIDO", "RIO GRANDE DO NORTE", "RN", 24],
[2405801, "JOAO CAMARA", "RIO GRANDE DO NORTE", "RN", 24],
[2405900, "JOAO DIAS", "RIO GRANDE DO NORTE", "RN", 24],
[2406007, "JOSE DA PENHA", "RIO GRANDE DO NORTE", "RN", 24],
[2406106, "JUCURUTU", "RIO GRANDE DO NORTE", "RN", 24],
[2406155, "JUNDIA", "RIO GRANDE DO NORTE", "RN", 24],
[2406205, "LAGOA D'ANTA", "RIO GRANDE DO NORTE", "RN", 24],
[2406304, "LAGOA DE PEDRAS", "RIO GRANDE DO NORTE", "RN", 24],
[2406403, "LAGOA DE VELHOS", "RIO GRANDE DO NORTE", "RN", 24],
[2406502, "LAGOA NOVA", "RIO GRANDE DO NORTE", "RN", 24],
[2406601, "LAGOA SALGADA", "RIO GRANDE DO NORTE", "RN", 24],
[2406700, "LAJES", "RIO GRANDE DO NORTE", "RN", 24],
[2406809, "LAJES PINTADAS", "RIO GRANDE DO NORTE", "RN", 24],
[2406908, "LUCRECIA", "RIO GRANDE DO NORTE", "RN", 24],
[2407005, "LUIS GOMES", "RIO GRANDE DO NORTE", "RN", 24],
[2407104, "MACAIBA", "RIO GRANDE DO NORTE", "RN", 24],
[2407203, "MACAU", "RIO GRANDE DO NORTE", "RN", 24],
[2407252, "MAJOR SALES", "RIO GRANDE DO NORTE", "RN", 24],
[2407302, "MARCELINO VIEIRA", "RIO GRANDE DO NORTE", "RN", 24],
[2407401, "MARTINS", "RIO GRANDE DO NORTE", "RN", 24],
[2407500, "MAXARANGUAPE", "RIO GRANDE DO NORTE", "RN", 24],
[2407609, "MESSIAS TARGINO", "RIO GRANDE DO NORTE", "RN", 24],
[2407708, "MONTANHAS", "RIO GRANDE DO NORTE", "RN", 24],
[2407807, "MONTE ALEGRE", "RIO GRANDE DO NORTE", "RN", 24],
[2407906, "MONTE DAS GAMELEIRAS", "RIO GRANDE DO NORTE", "RN", 24],
[2408003, "MOSSORO", "RIO GRANDE DO NORTE", "RN", 24],
[2408102, "NATAL", "RIO GRANDE DO NORTE", "RN", 24],
[2408201, "NISIA FLORESTA", "RIO GRANDE DO NORTE", "RN", 24],
[2408300, "NOVA CRUZ", "RIO GRANDE DO NORTE", "RN", 24],
[2408409, "OLHO-D'AGUA DO BORGES", "RIO GRANDE DO NORTE", "RN", 24],
[2408508, "OURO BRANCO", "RIO GRANDE DO NORTE", "RN", 24],
[2408607, "PARANA", "RIO GRANDE DO NORTE", "RN", 24],
[2408706, "PARAU", "RIO GRANDE DO NORTE", "RN", 24],
[2408805, "PARAZINHO", "RIO GRANDE DO NORTE", "RN", 24],
[2408904, "PARELHAS", "RIO GRANDE DO NORTE", "RN", 24],
[2408953, "RIO DO FOGO", "RIO GRANDE DO NORTE", "RN", 24],
[2409100, "PASSA E FICA", "RIO GRANDE DO NORTE", "RN", 24],
[2409209, "PASSAGEM", "RIO GRANDE DO NORTE", "RN", 24],
[2409308, "PATU", "RIO GRANDE DO NORTE", "RN", 24],
[2409332, "SANTA MARIA", "RIO GRANDE DO NORTE", "RN", 24],
[2409407, "PAU DOS FERROS", "RIO GRANDE DO NORTE", "RN", 24],
[2409506, "PEDRA GRANDE", "RIO GRANDE DO NORTE", "RN", 24],
[2409605, "PEDRA PRETA", "RIO GRANDE DO NORTE", "RN", 24],
[2409704, "PEDRO AVELINO", "RIO GRANDE DO NORTE", "RN", 24],
[2409803, "PEDRO VELHO", "RIO GRANDE DO NORTE", "RN", 24],
[2409902, "PENDENCIAS", "RIO GRANDE DO NORTE", "RN", 24],
[2410009, "PILÕES", "RIO GRANDE DO NORTE", "RN", 24],
[2410108, "POCO BRANCO", "RIO GRANDE DO NORTE", "RN", 24],
[2410207, "PORTALEGRE", "RIO GRANDE DO NORTE", "RN", 24],
[2410256, "PORTO DO MANGUE", "RIO GRANDE DO NORTE", "RN", 24],
[2410306, "PRESIDENTE JUSCELINO", "RIO GRANDE DO NORTE", "RN", 24],
[2410405, "PUREZA", "RIO GRANDE DO NORTE", "RN", 24],
[2410504, "RAFAEL FERNANDES", "RIO GRANDE DO NORTE", "RN", 24],
[2410603, "RAFAEL GODEIRO", "RIO GRANDE DO NORTE", "RN", 24],
[2410702, "RIACHO DA CRUZ", "RIO GRANDE DO NORTE", "RN", 24],
[2410801, "RIACHO DE SANTANA", "RIO GRANDE DO NORTE", "RN", 24],
[2410900, "RIACHUELO", "RIO GRANDE DO NORTE", "RN", 24],
[2411007, "RODOLFO FERNANDES", "RIO GRANDE DO NORTE", "RN", 24],
[2411056, "TIBAU", "RIO GRANDE DO NORTE", "RN", 24],
[2411106, "RUY BARBOSA", "RIO GRANDE DO NORTE", "RN", 24],
[2411205, "SANTA CRUZ", "RIO GRANDE DO NORTE", "RN", 24],
[2411403, "SANTANA DO MATOS", "RIO GRANDE DO NORTE", "RN", 24],
[2411429, "SANTANA DO SERIDO", "RIO GRANDE DO NORTE", "RN", 24],
[2411502, "SANTO ANTONIO", "RIO GRANDE DO NORTE", "RN", 24],
[2411601, "SAO BENTO DO NORTE", "RIO GRANDE DO NORTE", "RN", 24],
[2411700, "SAO BENTO DO TRAIRI", "RIO GRANDE DO NORTE", "RN", 24],
[2411809, "SAO FERNANDO", "RIO GRANDE DO NORTE", "RN", 24],
[2411908, "SAO FRANCISCO DO OESTE", "RIO GRANDE DO NORTE", "RN", 24],
[2412005, "SAO GONCALO DO AMARANTE", "RIO GRANDE DO NORTE", "RN", 24],
[2412104, "SAO JOAO DO SABUGI", "RIO GRANDE DO NORTE", "RN", 24],
[2412203, "SAO JOSE DE MIPIBU", "RIO GRANDE DO NORTE", "RN", 24],
[2412302, "SAO JOSE DO CAMPESTRE", "RIO GRANDE DO NORTE", "RN", 24],
[2412401, "SAO JOSE DO SERIDO", "RIO GRANDE DO NORTE", "RN", 24],
[2412500, "SAO MIGUEL", "RIO GRANDE DO NORTE", "RN", 24],
[2412559, "SAO MIGUEL DO GOSTOSO", "RIO GRANDE DO NORTE", "RN", 24],
[2412609, "SAO PAULO DO POTENGI", "RIO GRANDE DO NORTE", "RN", 24],
[2412708, "SAO PEDRO", "RIO GRANDE DO NORTE", "RN", 24],
[2412807, "SAO RAFAEL", "RIO GRANDE DO NORTE", "RN", 24],
[2412906, "SAO TOME", "RIO GRANDE DO NORTE", "RN", 24],
[2413003, "SAO VICENTE", "RIO GRANDE DO NORTE", "RN", 24],
[2413102, "SENADOR ELOI DE SOUZA", "RIO GRANDE DO NORTE", "RN", 24],
[2413201, "SENADOR GEORGINO AVELINO", "RIO GRANDE DO NORTE", "RN", 24],
[2413300, "SERRA DE SAO BENTO", "RIO GRANDE DO NORTE", "RN", 24],
[2413359, "SERRA DO MEL", "RIO GRANDE DO NORTE", "RN", 24],
[2413409, "SERRA NEGRA DO NORTE", "RIO GRANDE DO NORTE", "RN", 24],
[2413508, "SERRINHA", "RIO GRANDE DO NORTE", "RN", 24],
[2413557, "SERRINHA DOS PINTOS", "RIO GRANDE DO NORTE", "RN", 24],
[2413607, "SEVERIANO MELO", "RIO GRANDE DO NORTE", "RN", 24],
[2413706, "SITIO NOVO", "RIO GRANDE DO NORTE", "RN", 24],
[2413805, "TABOLEIRO GRANDE", "RIO GRANDE DO NORTE", "RN", 24],
[2413904, "TAIPU", "RIO GRANDE DO NORTE", "RN", 24],
[2414001, "TANGARA", "RIO GRANDE DO NORTE", "RN", 24],
[2414100, "TENENTE ANANIAS", "RIO GRANDE DO NORTE", "RN", 24],
[2414159, "TENENTE LAURENTINO CRUZ", "RIO GRANDE DO NORTE", "RN", 24],
[2414209, "TIBAU DO SUL", "RIO GRANDE DO NORTE", "RN", 24],
[2414308, "TIMBAUBA DOS BATISTAS", "RIO GRANDE DO NORTE", "RN", 24],
[2414407, "TOUROS", "RIO GRANDE DO NORTE", "RN", 24],
[2414456, "TRIUNFO POTIGUAR", "RIO GRANDE DO NORTE", "RN", 24],
[2414506, "UMARIZAL", "RIO GRANDE DO NORTE", "RN", 24],
[2414605, "UPANEMA", "RIO GRANDE DO NORTE", "RN", 24],
[2414704, "VARZEA", "RIO GRANDE DO NORTE", "RN", 24],
[2414753, "VENHA-VER", "RIO GRANDE DO NORTE", "RN", 24],
[2414803, "VERA CRUZ", "RIO GRANDE DO NORTE", "RN", 24],
[2414902, "VICOSA", "RIO GRANDE DO NORTE", "RN", 24],
[2415008, "VILA FLOR", "RIO GRANDE DO NORTE", "RN", 24],
[2500106, "AGUA BRANCA", "PARAIBA", "PB", 25],
[2500205, "AGUIAR", "PARAIBA", "PB", 25],
[2500304, "ALAGOA GRANDE", "PARAIBA", "PB", 25],
[2500403, "ALAGOA NOVA", "PARAIBA", "PB", 25],
[2500502, "ALAGOINHA", "PARAIBA", "PB", 25],
[2500536, "ALCANTIL", "PARAIBA", "PB", 25],
[2500577, "ALGODAO DE JANDAIRA", "PARAIBA", "PB", 25],
[2500601, "ALHANDRA", "PARAIBA", "PB", 25],
[2500700, "SAO JOAO DO RIO DO PEIXE", "PARAIBA", "PB", 25],
[2500734, "AMPARO", "PARAIBA", "PB", 25],
[2500775, "APARECIDA", "PARAIBA", "PB", 25],
[2500809, "ARACAGI", "PARAIBA", "PB", 25],
[2500908, "ARARA", "PARAIBA", "PB", 25],
[2501005, "ARARUNA", "PARAIBA", "PB", 25],
[2501104, "AREIA", "PARAIBA", "PB", 25],
[2501153, "AREIA DE BARAUNAS", "PARAIBA", "PB", 25],
[2501203, "AREIAL", "PARAIBA", "PB", 25],
[2501302, "AROEIRAS", "PARAIBA", "PB", 25],
[2501351, "ASSUNCAO", "PARAIBA", "PB", 25],
[2501401, "BAIA DA TRAICAO", "PARAIBA", "PB", 25],
[2501500, "BANANEIRAS", "PARAIBA", "PB", 25],
[2501534, "BARAUNA", "PARAIBA", "PB", 25],
[2501575, "BARRA DE SANTANA", "PARAIBA", "PB", 25],
[2501609, "BARRA DE SANTA ROSA", "PARAIBA", "PB", 25],
[2501708, "BARRA DE SAO MIGUEL", "PARAIBA", "PB", 25],
[2501807, "BAYEUX", "PARAIBA", "PB", 25],
[2501906, "BELEM", "PARAIBA", "PB", 25],
[2502003, "BELEM DO BREJO DO CRUZ", "PARAIBA", "PB", 25],
[2502052, "BERNARDINO BATISTA", "PARAIBA", "PB", 25],
[2502102, "BOA VENTURA", "PARAIBA", "PB", 25],
[2502151, "BOA VISTA", "PARAIBA", "PB", 25],
[2502201, "BOM JESUS", "PARAIBA", "PB", 25],
[2502300, "BOM SUCESSO", "PARAIBA", "PB", 25],
[2502409, "BONITO DE SANTA FE", "PARAIBA", "PB", 25],
[2502508, "BOQUEIRAO", "PARAIBA", "PB", 25],
[2502607, "IGARACY", "PARAIBA", "PB", 25],
[2502706, "BORBOREMA", "PARAIBA", "PB", 25],
[2502805, "BREJO DO CRUZ", "PARAIBA", "PB", 25],
[2502904, "BREJO DOS SANTOS", "PARAIBA", "PB", 25],
[2503001, "CAAPORA", "PARAIBA", "PB", 25],
[2503100, "CABACEIRAS", "PARAIBA", "PB", 25],
[2503209, "CABEDELO", "PARAIBA", "PB", 25],
[2503308, "CACHOEIRA DOS INDIOS", "PARAIBA", "PB", 25],
[2503407, "CACIMBA DE AREIA", "PARAIBA", "PB", 25],
[2503506, "CACIMBA DE DENTRO", "PARAIBA", "PB", 25],
[2503555, "CACIMBAS", "PARAIBA", "PB", 25],
[2503605, "CAICARA", "PARAIBA", "PB", 25],
[2503704, "CAJAZEIRAS", "PARAIBA", "PB", 25],
[2503753, "CAJAZEIRINHAS", "PARAIBA", "PB", 25],
[2503803, "CALDAS BRANDAO", "PARAIBA", "PB", 25],
[2503902, "CAMALAU", "PARAIBA", "PB", 25],
[2504009, "CAMPINA GRANDE", "PARAIBA", "PB", 25],
[2504033, "CAPIM", "PARAIBA", "PB", 25],
[2504074, "CARAUBAS", "PARAIBA", "PB", 25],
[2504108, "CARRAPATEIRA", "PARAIBA", "PB", 25],
[2504157, "CASSERENGUE", "PARAIBA", "PB", 25],
[2504207, "CATINGUEIRA", "PARAIBA", "PB", 25],
[2504306, "CATOLE DO ROCHA", "PARAIBA", "PB", 25],
[2504355, "CATURITE", "PARAIBA", "PB", 25],
[2504405, "CONCEICAO", "PARAIBA", "PB", 25],
[2504504, "CONDADO", "PARAIBA", "PB", 25],
[2504603, "CONDE", "PARAIBA", "PB", 25],
[2504702, "CONGO", "PARAIBA", "PB", 25],
[2504801, "COREMAS", "PARAIBA", "PB", 25],
[2504850, "COXIXOLA", "PARAIBA", "PB", 25],
[2504900, "CRUZ DO ESPIRITO SANTO", "PARAIBA", "PB", 25],
[2505006, "CUBATI", "PARAIBA", "PB", 25],
[2505105, "CUITE", "PARAIBA", "PB", 25],
[2505204, "CUITEGI", "PARAIBA", "PB", 25],
[2505238, "CUITE DE MAMANGUAPE", "PARAIBA", "PB", 25],
[2505279, "CURRAL DE CIMA", "PARAIBA", "PB", 25],
[2505303, "CURRAL VELHO", "PARAIBA", "PB", 25],
[2505352, "DAMIAO", "PARAIBA", "PB", 25],
[2505402, "DESTERRO", "PARAIBA", "PB", 25],
[2505501, "VISTA SERRANA", "PARAIBA", "PB", 25],
[2505600, "DIAMANTE", "PARAIBA", "PB", 25],
[2505709, "DONA INES", "PARAIBA", "PB", 25],
[2505808, "DUAS ESTRADAS", "PARAIBA", "PB", 25],
[2505907, "EMAS", "PARAIBA", "PB", 25],
[2506004, "ESPERANCA", "PARAIBA", "PB", 25],
[2506103, "FAGUNDES", "PARAIBA", "PB", 25],
[2506202, "FREI MARTINHO", "PARAIBA", "PB", 25],
[2506251, "GADO BRAVO", "PARAIBA", "PB", 25],
[2506301, "GUARABIRA", "PARAIBA", "PB", 25],
[2506400, "GURINHEM", "PARAIBA", "PB", 25],
[2506509, "GURJAO", "PARAIBA", "PB", 25],
[2506608, "IBIARA", "PARAIBA", "PB", 25],
[2506707, "IMACULADA", "PARAIBA", "PB", 25],
[2506806, "INGA", "PARAIBA", "PB", 25],
[2506905, "ITABAIANA", "PARAIBA", "PB", 25],
[2507002, "ITAPORANGA", "PARAIBA", "PB", 25],
[2507101, "ITAPOROROCA", "PARAIBA", "PB", 25],
[2507200, "ITATUBA", "PARAIBA", "PB", 25],
[2507309, "JACARAU", "PARAIBA", "PB", 25],
[2507408, "JERICO", "PARAIBA", "PB", 25],
[2507507, "JOAO PESSOA", "PARAIBA", "PB", 25],
[2507606, "JUAREZ TAVORA", "PARAIBA", "PB", 25],
[2507705, "JUAZEIRINHO", "PARAIBA", "PB", 25],
[2507804, "JUNCO DO SERIDO", "PARAIBA", "PB", 25],
[2507903, "JURIPIRANGA", "PARAIBA", "PB", 25],
[2508000, "JURU", "PARAIBA", "PB", 25],
[2508109, "LAGOA", "PARAIBA", "PB", 25],
[2508208, "LAGOA DE DENTRO", "PARAIBA", "PB", 25],
[2508307, "LAGOA SECA", "PARAIBA", "PB", 25],
[2508406, "LASTRO", "PARAIBA", "PB", 25],
[2508505, "LIVRAMENTO", "PARAIBA", "PB", 25],
[2508554, "LOGRADOURO", "PARAIBA", "PB", 25],
[2508604, "LUCENA", "PARAIBA", "PB", 25],
[2508703, "MAE D'AGUA", "PARAIBA", "PB", 25],
[2508802, "MALTA", "PARAIBA", "PB", 25],
[2508901, "MAMANGUAPE", "PARAIBA", "PB", 25],
[2509008, "MANAIRA", "PARAIBA", "PB", 25],
[2509057, "MARCACAO", "PARAIBA", "PB", 25],
[2509107, "MARI", "PARAIBA", "PB", 25],
[2509156, "MARIZOPOLIS", "PARAIBA", "PB", 25],
[2509206, "MASSARANDUBA", "PARAIBA", "PB", 25],
[2509305, "MATARACA", "PARAIBA", "PB", 25],
[2509339, "MATINHAS", "PARAIBA", "PB", 25],
[2509370, "MATO GROSSO", "PARAIBA", "PB", 25],
[2509396, "MATUREIA", "PARAIBA", "PB", 25],
[2509404, "MOGEIRO", "PARAIBA", "PB", 25],
[2509503, "MONTADAS", "PARAIBA", "PB", 25],
[2509602, "MONTE HOREBE", "PARAIBA", "PB", 25],
[2509701, "MONTEIRO", "PARAIBA", "PB", 25],
[2509800, "MULUNGU", "PARAIBA", "PB", 25],
[2509909, "NATUBA", "PARAIBA", "PB", 25],
[2510006, "NAZAREZINHO", "PARAIBA", "PB", 25],
[2510105, "NOVA FLORESTA", "PARAIBA", "PB", 25],
[2510204, "NOVA OLINDA", "PARAIBA", "PB", 25],
[2510303, "NOVA PALMEIRA", "PARAIBA", "PB", 25],
[2510402, "OLHO D'AGUA", "PARAIBA", "PB", 25],
[2510501, "OLIVEDOS", "PARAIBA", "PB", 25],
[2510600, "OURO VELHO", "PARAIBA", "PB", 25],
[2510659, "PARARI", "PARAIBA", "PB", 25],
[2510709, "PASSAGEM", "PARAIBA", "PB", 25],
[2510808, "PATOS", "PARAIBA", "PB", 25],
[2510907, "PAULISTA", "PARAIBA", "PB", 25],
[2511004, "PEDRA BRANCA", "PARAIBA", "PB", 25],
[2511103, "PEDRA LAVRADA", "PARAIBA", "PB", 25],
[2511202, "PEDRAS DE FOGO", "PARAIBA", "PB", 25],
[2511301, "PIANCO", "PARAIBA", "PB", 25],
[2511400, "PICUI", "PARAIBA", "PB", 25],
[2511509, "PILAR", "PARAIBA", "PB", 25],
[2511608, "PILÕES", "PARAIBA", "PB", 25],
[2511707, "PILÕEZINHOS", "PARAIBA", "PB", 25],
[2511806, "PIRPIRITUBA", "PARAIBA", "PB", 25],
[2511905, "PITIMBU", "PARAIBA", "PB", 25],
[2512002, "POCINHOS", "PARAIBA", "PB", 25],
[2512036, "POCO DANTAS", "PARAIBA", "PB", 25],
[2512077, "POCO DE JOSE DE MOURA", "PARAIBA", "PB", 25],
[2512101, "POMBAL", "PARAIBA", "PB", 25],
[2512200, "PRATA", "PARAIBA", "PB", 25],
[2512309, "PRINCESA ISABEL", "PARAIBA", "PB", 25],
[2512408, "PUXINANA", "PARAIBA", "PB", 25],
[2512507, "QUEIMADAS", "PARAIBA", "PB", 25],
[2512606, "QUIXABA", "PARAIBA", "PB", 25],
[2512705, "REMIGIO", "PARAIBA", "PB", 25],
[2512721, "PEDRO REGIS", "PARAIBA", "PB", 25],
[2512747, "RIACHAO", "PARAIBA", "PB", 25],
[2512754, "RIACHAO DO BACAMARTE", "PARAIBA", "PB", 25],
[2512762, "RIACHAO DO POCO", "PARAIBA", "PB", 25],
[2512788, "RIACHO DE SANTO ANTONIO", "PARAIBA", "PB", 25],
[2512804, "RIACHO DOS CAVALOS", "PARAIBA", "PB", 25],
[2512903, "RIO TINTO", "PARAIBA", "PB", 25],
[2513000, "SALGADINHO", "PARAIBA", "PB", 25],
[2513109, "SALGADO DE SAO FELIX", "PARAIBA", "PB", 25],
[2513158, "SANTA CECILIA", "PARAIBA", "PB", 25],
[2513208, "SANTA CRUZ", "PARAIBA", "PB", 25],
[2513307, "SANTA HELENA", "PARAIBA", "PB", 25],
[2513356, "SANTA INES", "PARAIBA", "PB", 25],
[2513406, "SANTA LUZIA", "PARAIBA", "PB", 25],
[2513505, "SANTANA DE MANGUEIRA", "PARAIBA", "PB", 25],
[2513604, "SANTANA DOS GARROTES", "PARAIBA", "PB", 25],
[2513653, "SANTAREM", "PARAIBA", "PB", 25],
[2513703, "SANTA RITA", "PARAIBA", "PB", 25],
[2513802, "SANTA TERESINHA", "PARAIBA", "PB", 25],
[2513851, "SANTO ANDRE", "PARAIBA", "PB", 25],
[2513901, "SAO BENTO", "PARAIBA", "PB", 25],
[2513927, "SAO BENTINHO", "PARAIBA", "PB", 25],
[2513943, "SAO DOMINGOS DO CARIRI", "PARAIBA", "PB", 25],
[2513968, "SAO DOMINGOS", "PARAIBA", "PB", 25],
[2513984, "SAO FRANCISCO", "PARAIBA", "PB", 25],
[2514008, "SAO JOAO DO CARIRI", "PARAIBA", "PB", 25],
[2514107, "SAO JOAO DO TIGRE", "PARAIBA", "PB", 25],
[2514206, "SAO JOSE DA LAGOA TAPADA", "PARAIBA", "PB", 25],
[2514305, "SAO JOSE DE CAIANA", "PARAIBA", "PB", 25],
[2514404, "SAO JOSE DE ESPINHARAS", "PARAIBA", "PB", 25],
[2514453, "SAO JOSE DOS RAMOS", "PARAIBA", "PB", 25],
[2514503, "SAO JOSE DE PIRANHAS", "PARAIBA", "PB", 25],
[2514552, "SAO JOSE DE PRINCESA", "PARAIBA", "PB", 25],
[2514602, "SAO JOSE DO BONFIM", "PARAIBA", "PB", 25],
[2514651, "SAO JOSE DO BREJO DO CRUZ", "PARAIBA", "PB", 25],
[2514701, "SAO JOSE DO SABUGI", "PARAIBA", "PB", 25],
[2514800, "SAO JOSE DOS CORDEIROS", "PARAIBA", "PB", 25],
[2514909, "SAO MAMEDE", "PARAIBA", "PB", 25],
[2515005, "SAO MIGUEL DE TAIPU", "PARAIBA", "PB", 25],
[2515104, "SAO SEBASTIAO DE LAGOA DE", "PARAIBA", "PB", 25],
[2515203, "SAO SEBASTIAO DO UMBUZEIR", "PARAIBA", "PB", 25],
[2515302, "SAPE", "PARAIBA", "PB", 25],
[2515401, "SERIDO", "PARAIBA", "PB", 25],
[2515500, "SERRA BRANCA", "PARAIBA", "PB", 25],
[2515609, "SERRA DA RAIZ", "PARAIBA", "PB", 25],
[2515708, "SERRA GRANDE", "PARAIBA", "PB", 25],
[2515807, "SERRA REDONDA", "PARAIBA", "PB", 25],
[2515906, "SERRARIA", "PARAIBA", "PB", 25],
[2515930, "SERTAOZINHO", "PARAIBA", "PB", 25],
[2515971, "SOBRADO", "PARAIBA", "PB", 25],
[2516003, "SOLANEA", "PARAIBA", "PB", 25],
[2516102, "SOLEDADE", "PARAIBA", "PB", 25],
[2516151, "SOSSEGO", "PARAIBA", "PB", 25],
[2516201, "SOUSA", "PARAIBA", "PB", 25],
[2516300, "SUME", "PARAIBA", "PB", 25],
[2516409, "CAMPO DE SANTANA", "PARAIBA", "PB", 25],
[2516508, "TAPEROA", "PARAIBA", "PB", 25],
[2516607, "TAVARES", "PARAIBA", "PB", 25],
[2516706, "TEIXEIRA", "PARAIBA", "PB", 25],
[2516755, "TENORIO", "PARAIBA", "PB", 25],
[2516805, "TRIUNFO", "PARAIBA", "PB", 25],
[2516904, "UIRAUNA", "PARAIBA", "PB", 25],
[2517001, "UMBUZEIRO", "PARAIBA", "PB", 25],
[2517100, "VARZEA", "PARAIBA", "PB", 25],
[2517209, "VIEIROPOLIS", "PARAIBA", "PB", 25],
[2517407, "ZABELE", "PARAIBA", "PB", 25],
[2600054, "ABREU E LIMA", "PERNAMBUCO", "PE", 26],
[2600104, "AFOGADOS DA INGAZEIRA", "PERNAMBUCO", "PE", 26],
[2600203, "AFRANIO", "PERNAMBUCO", "PE", 26],
[2600302, "AGRESTINA", "PERNAMBUCO", "PE", 26],
[2600401, "AGUA PRETA", "PERNAMBUCO", "PE", 26],
[2600500, "AGUAS BELAS", "PERNAMBUCO", "PE", 26],
[2600609, "ALAGOINHA", "PERNAMBUCO", "PE", 26],
[2600708, "ALIANCA", "PERNAMBUCO", "PE", 26],
[2600807, "ALTINHO", "PERNAMBUCO", "PE", 26],
[2600906, "AMARAJI", "PERNAMBUCO", "PE", 26],
[2601003, "ANGELIM", "PERNAMBUCO", "PE", 26],
[2601052, "ARACOIABA", "PERNAMBUCO", "PE", 26],
[2601102, "ARARIPINA", "PERNAMBUCO", "PE", 26],
[2601201, "ARCOVERDE", "PERNAMBUCO", "PE", 26],
[2601300, "BARRA DE GUABIRABA", "PERNAMBUCO", "PE", 26],
[2601409, "BARREIROS", "PERNAMBUCO", "PE", 26],
[2601508, "BELEM DE MARIA", "PERNAMBUCO", "PE", 26],
[2601607, "BELEM DE SAO FRANCISCO", "PERNAMBUCO", "PE", 26],
[2601706, "BELO JARDIM", "PERNAMBUCO", "PE", 26],
[2601805, "BETANIA", "PERNAMBUCO", "PE", 26],
[2601904, "BEZERROS", "PERNAMBUCO", "PE", 26],
[2602001, "BODOCO", "PERNAMBUCO", "PE", 26],
[2602100, "BOM CONSELHO", "PERNAMBUCO", "PE", 26],
[2602209, "BOM JARDIM", "PERNAMBUCO", "PE", 26],
[2602308, "BONITO", "PERNAMBUCO", "PE", 26],
[2602407, "BREJAO", "PERNAMBUCO", "PE", 26],
[2602506, "BREJINHO", "PERNAMBUCO", "PE", 26],
[2602605, "BREJO DA MADRE DE DEUS", "PERNAMBUCO", "PE", 26],
[2602704, "BUENOS AIRES", "PERNAMBUCO", "PE", 26],
[2602803, "BUIQUE", "PERNAMBUCO", "PE", 26],
[2602902, "CABO DE SANTO AGOSTINHO", "PERNAMBUCO", "PE", 26],
[2603009, "CABROBO", "PERNAMBUCO", "PE", 26],
[2603108, "CACHOEIRINHA", "PERNAMBUCO", "PE", 26],
[2603207, "CAETES", "PERNAMBUCO", "PE", 26],
[2603306, "CALCADO", "PERNAMBUCO", "PE", 26],
[2603405, "CALUMBI", "PERNAMBUCO", "PE", 26],
[2603454, "CAMARAGIBE", "PERNAMBUCO", "PE", 26],
[2603504, "CAMOCIM DE SAO FELIX", "PERNAMBUCO", "PE", 26],
[2603603, "CAMUTANGA", "PERNAMBUCO", "PE", 26],
[2603702, "CANHOTINHO", "PERNAMBUCO", "PE", 26],
[2603801, "CAPOEIRAS", "PERNAMBUCO", "PE", 26],
[2603900, "CARNAIBA", "PERNAMBUCO", "PE", 26],
[2603926, "CARNAUBEIRA DA PENHA", "PERNAMBUCO", "PE", 26],
[2604007, "CARPINA", "PERNAMBUCO", "PE", 26],
[2604106, "CARUARU", "PERNAMBUCO", "PE", 26],
[2604155, "CASINHAS", "PERNAMBUCO", "PE", 26],
[2604205, "CATENDE", "PERNAMBUCO", "PE", 26],
[2604304, "CEDRO", "PERNAMBUCO", "PE", 26],
[2604403, "CHA DE ALEGRIA", "PERNAMBUCO", "PE", 26],
[2604502, "CHA GRANDE", "PERNAMBUCO", "PE", 26],
[2604601, "CONDADO", "PERNAMBUCO", "PE", 26],
[2604700, "CORRENTES", "PERNAMBUCO", "PE", 26],
[2604809, "CORTES", "PERNAMBUCO", "PE", 26],
[2604908, "CUMARU", "PERNAMBUCO", "PE", 26],
[2605004, "CUPIRA", "PERNAMBUCO", "PE", 26],
[2605103, "CUSTODIA", "PERNAMBUCO", "PE", 26],
[2605152, "DORMENTES", "PERNAMBUCO", "PE", 26],
[2605202, "ESCADA", "PERNAMBUCO", "PE", 26],
[2605301, "EXU", "PERNAMBUCO", "PE", 26],
[2605400, "FEIRA NOVA", "PERNAMBUCO", "PE", 26],
[2605459, "FERNANDO DE NORONHA", "PERNAMBUCO", "PE", 26],
[2605509, "FERREIROS", "PERNAMBUCO", "PE", 26],
[2605608, "FLORES", "PERNAMBUCO", "PE", 26],
[2605707, "FLORESTA", "PERNAMBUCO", "PE", 26],
[2605806, "FREI MIGUELINHO", "PERNAMBUCO", "PE", 26],
[2606002, "GARANHUNS", "PERNAMBUCO", "PE", 26],
[2606101, "GLORIA DO GOITA", "PERNAMBUCO", "PE", 26],
[2606200, "GOIANA", "PERNAMBUCO", "PE", 26],
[2606309, "GRANITO", "PERNAMBUCO", "PE", 26],
[2606408, "GRAVATA", "PERNAMBUCO", "PE", 26],
[2606507, "IATI", "PERNAMBUCO", "PE", 26],
[2606606, "IBIMIRIM", "PERNAMBUCO", "PE", 26],
[2606705, "IBIRAJUBA", "PERNAMBUCO", "PE", 26],
[2606804, "IGARASSU", "PERNAMBUCO", "PE", 26],
[2606903, "IGUARACI", "PERNAMBUCO", "PE", 26],
[2607000, "INAJA", "PERNAMBUCO", "PE", 26],
[2607109, "INGAZEIRA", "PERNAMBUCO", "PE", 26],
[2607208, "IPOJUCA", "PERNAMBUCO", "PE", 26],
[2607307, "IPUBI", "PERNAMBUCO", "PE", 26],
[2607406, "ITACURUBA", "PERNAMBUCO", "PE", 26],
[2607505, "ITAIBA", "PERNAMBUCO", "PE", 26],
[2607604, "ILHA DE ITAMARACA", "PERNAMBUCO", "PE", 26],
[2607653, "ITAMBE", "PERNAMBUCO", "PE", 26],
[2607703, "ITAPETIM", "PERNAMBUCO", "PE", 26],
[2607752, "ITAPISSUMA", "PERNAMBUCO", "PE", 26],
[2607802, "ITAQUITINGA", "PERNAMBUCO", "PE", 26],
[2607901, "JABOATAO DOS GUARARAPES", "PERNAMBUCO", "PE", 26],
[2607950, "JAQUEIRA", "PERNAMBUCO", "PE", 26],
[2608008, "JATAUBA", "PERNAMBUCO", "PE", 26],
[2608057, "JATOBA", "PERNAMBUCO", "PE", 26],
[2608107, "JOAO ALFREDO", "PERNAMBUCO", "PE", 26],
[2608206, "JOAQUIM NABUCO", "PERNAMBUCO", "PE", 26],
[2608255, "JUCATI", "PERNAMBUCO", "PE", 26],
[2608305, "JUPI", "PERNAMBUCO", "PE", 26],
[2608404, "JUREMA", "PERNAMBUCO", "PE", 26],
[2608453, "LAGOA DO CARRO", "PERNAMBUCO", "PE", 26],
[2608503, "LAGOA DO ITAENGA", "PERNAMBUCO", "PE", 26],
[2608602, "LAGOA DO OURO", "PERNAMBUCO", "PE", 26],
[2608701, "LAGOA DOS GATOS", "PERNAMBUCO", "PE", 26],
[2608750, "LAGOA GRANDE", "PERNAMBUCO", "PE", 26],
[2608800, "LAJEDO", "PERNAMBUCO", "PE", 26],
[2608909, "LIMOEIRO", "PERNAMBUCO", "PE", 26],
[2609006, "MACAPARANA", "PERNAMBUCO", "PE", 26],
[2609105, "MACHADOS", "PERNAMBUCO", "PE", 26],
[2609154, "MANARI", "PERNAMBUCO", "PE", 26],
[2609204, "MARAIAL", "PERNAMBUCO", "PE", 26],
[2609303, "MIRANDIBA", "PERNAMBUCO", "PE", 26],
[2609402, "MORENO", "PERNAMBUCO", "PE", 26],
[2609501, "NAZARE DA MATA", "PERNAMBUCO", "PE", 26],
[2609600, "OLINDA", "PERNAMBUCO", "PE", 26],
[2609709, "OROBO", "PERNAMBUCO", "PE", 26],
[2609808, "OROCO", "PERNAMBUCO", "PE", 26],
[2609907, "OURICURI", "PERNAMBUCO", "PE", 26],
[2610004, "PALMARES", "PERNAMBUCO", "PE", 26],
[2610103, "PALMEIRINA", "PERNAMBUCO", "PE", 26],
[2610202, "PANELAS", "PERNAMBUCO", "PE", 26],
[2610301, "PARANATAMA", "PERNAMBUCO", "PE", 26],
[2610400, "PARNAMIRIM", "PERNAMBUCO", "PE", 26],
[2610509, "PASSIRA", "PERNAMBUCO", "PE", 26],
[2610608, "PAUDALHO", "PERNAMBUCO", "PE", 26],
[2610707, "PAULISTA", "PERNAMBUCO", "PE", 26],
[2610806, "PEDRA", "PERNAMBUCO", "PE", 26],
[2610905, "PESQUEIRA", "PERNAMBUCO", "PE", 26],
[2611002, "PETROLANDIA", "PERNAMBUCO", "PE", 26],
[2611101, "PETROLINA", "PERNAMBUCO", "PE", 26],
[2611200, "POCAO", "PERNAMBUCO", "PE", 26],
[2611309, "POMBOS", "PERNAMBUCO", "PE", 26],
[2611408, "PRIMAVERA", "PERNAMBUCO", "PE", 26],
[2611507, "QUIPAPA", "PERNAMBUCO", "PE", 26],
[2611533, "QUIXABA", "PERNAMBUCO", "PE", 26],
[2611606, "RECIFE", "PERNAMBUCO", "PE", 26],
[2611705, "RIACHO DAS ALMAS", "PERNAMBUCO", "PE", 26],
[2611804, "RIBEIRAO", "PERNAMBUCO", "PE", 26],
[2611903, "RIO FORMOSO", "PERNAMBUCO", "PE", 26],
[2612000, "SAIRE", "PERNAMBUCO", "PE", 26],
[2612109, "SALGADINHO", "PERNAMBUCO", "PE", 26],
[2612208, "SALGUEIRO", "PERNAMBUCO", "PE", 26],
[2612307, "SALOA", "PERNAMBUCO", "PE", 26],
[2612406, "SANHARO", "PERNAMBUCO", "PE", 26],
[2612455, "SANTA CRUZ", "PERNAMBUCO", "PE", 26],
[2612471, "SANTA CRUZ DA BAIXA VERDE", "PERNAMBUCO", "PE", 26],
[2612505, "SANTA CRUZ DO CAPIBARIBE", "PERNAMBUCO", "PE", 26],
[2612554, "SANTA FILOMENA", "PERNAMBUCO", "PE", 26],
[2612604, "SANTA MARIA DA BOA VISTA", "PERNAMBUCO", "PE", 26],
[2612703, "SANTA MARIA DO CAMBUCA", "PERNAMBUCO", "PE", 26],
[2612802, "SANTA TEREZINHA", "PERNAMBUCO", "PE", 26],
[2612901, "SAO BENEDITO DO SUL", "PERNAMBUCO", "PE", 26],
[2613008, "SAO BENTO DO UNA", "PERNAMBUCO", "PE", 26],
[2613107, "SAO CAITANO", "PERNAMBUCO", "PE", 26],
[2613206, "SAO JOAO", "PERNAMBUCO", "PE", 26],
[2613305, "SAO JOAQUIM DO MONTE", "PERNAMBUCO", "PE", 26],
[2613404, "SAO JOSE DA COROA GRANDE", "PERNAMBUCO", "PE", 26],
[2613503, "SAO JOSE DO BELMONTE", "PERNAMBUCO", "PE", 26],
[2613602, "SAO JOSE DO EGITO", "PERNAMBUCO", "PE", 26],
[2613701, "SAO LOURENCO DA MATA", "PERNAMBUCO", "PE", 26],
[2613800, "SAO VICENTE FERRER", "PERNAMBUCO", "PE", 26],
[2613909, "SERRA TALHADA", "PERNAMBUCO", "PE", 26],
[2614006, "SERRITA", "PERNAMBUCO", "PE", 26],
[2614105, "SERTANIA", "PERNAMBUCO", "PE", 26],
[2614204, "SIRINHAEM", "PERNAMBUCO", "PE", 26],
[2614303, "MOREILANDIA", "PERNAMBUCO", "PE", 26],
[2614402, "SOLIDAO", "PERNAMBUCO", "PE", 26],
[2614501, "SURUBIM", "PERNAMBUCO", "PE", 26],
[2614600, "TABIRA", "PERNAMBUCO", "PE", 26],
[2614709, "TACAIMBO", "PERNAMBUCO", "PE", 26],
[2614808, "TACARATU", "PERNAMBUCO", "PE", 26],
[2614857, "TAMANDARE", "PERNAMBUCO", "PE", 26],
[2615003, "TAQUARITINGA DO NORTE", "PERNAMBUCO", "PE", 26],
[2615102, "TEREZINHA", "PERNAMBUCO", "PE", 26],
[2615201, "TERRA NOVA", "PERNAMBUCO", "PE", 26],
[2615300, "TIMBAUBA", "PERNAMBUCO", "PE", 26],
[2615409, "TORITAMA", "PERNAMBUCO", "PE", 26],
[2615508, "TRACUNHAEM", "PERNAMBUCO", "PE", 26],
[2615607, "TRINDADE", "PERNAMBUCO", "PE", 26],
[2615706, "TRIUNFO", "PERNAMBUCO", "PE", 26],
[2615805, "TUPANATINGA", "PERNAMBUCO", "PE", 26],
[2615904, "TUPARETAMA", "PERNAMBUCO", "PE", 26],
[2616001, "VENTUROSA", "PERNAMBUCO", "PE", 26],
[2616100, "VERDEJANTE", "PERNAMBUCO", "PE", 26],
[2616183, "VERTENTE DO LERIO", "PERNAMBUCO", "PE", 26],
[2616209, "VERTENTES", "PERNAMBUCO", "PE", 26],
[2616308, "VICENCIA", "PERNAMBUCO", "PE", 26],
[2616407, "VITORIA DE SANTO ANTAO", "PERNAMBUCO", "PE", 26],
[2616506, "XEXEU", "PERNAMBUCO", "PE", 26],
[2700102, "AGUA BRANCA", "ALAGOAS", "AL", 27],
[2700201, "ANADIA", "ALAGOAS", "AL", 27],
[2700300, "ARAPIRACA", "ALAGOAS", "AL", 27],
[2700409, "ATALAIA", "ALAGOAS", "AL", 27],
[2700508, "BARRA DE SANTO ANTONIO", "ALAGOAS", "AL", 27],
[2700607, "BARRA DE SAO MIGUEL", "ALAGOAS", "AL", 27],
[2700706, "BATALHA", "ALAGOAS", "AL", 27],
[2700805, "BELEM", "ALAGOAS", "AL", 27],
[2700904, "BELO MONTE", "ALAGOAS", "AL", 27],
[2701001, "BOCA DA MATA", "ALAGOAS", "AL", 27],
[2701100, "BRANQUINHA", "ALAGOAS", "AL", 27],
[2701209, "CACIMBINHAS", "ALAGOAS", "AL", 27],
[2701308, "CAJUEIRO", "ALAGOAS", "AL", 27],
[2701357, "CAMPESTRE", "ALAGOAS", "AL", 27],
[2701407, "CAMPO ALEGRE", "ALAGOAS", "AL", 27],
[2701506, "CAMPO GRANDE", "ALAGOAS", "AL", 27],
[2701605, "CANAPI", "ALAGOAS", "AL", 27],
[2701704, "CAPELA", "ALAGOAS", "AL", 27],
[2701803, "CARNEIROS", "ALAGOAS", "AL", 27],
[2701902, "CHA PRETA", "ALAGOAS", "AL", 27],
[2702009, "COITE DO NOIA", "ALAGOAS", "AL", 27],
[2702108, "COLONIA LEOPOLDINA", "ALAGOAS", "AL", 27],
[2702207, "COQUEIRO SECO", "ALAGOAS", "AL", 27],
[2702306, "CORURIPE", "ALAGOAS", "AL", 27],
[2702355, "CRAIBAS", "ALAGOAS", "AL", 27],
[2702405, "DELMIRO GOUVEIA", "ALAGOAS", "AL", 27],
[2702504, "DOIS RIACHOS", "ALAGOAS", "AL", 27],
[2702553, "ESTRELA DE ALAGOAS", "ALAGOAS", "AL", 27],
[2702603, "FEIRA GRANDE", "ALAGOAS", "AL", 27],
[2702702, "FELIZ DESERTO", "ALAGOAS", "AL", 27],
[2702801, "FLEXEIRAS", "ALAGOAS", "AL", 27],
[2702900, "GIRAU DO PONCIANO", "ALAGOAS", "AL", 27],
[2703007, "IBATEGUARA", "ALAGOAS", "AL", 27],
[2703106, "IGACI", "ALAGOAS", "AL", 27],
[2703205, "IGREJA NOVA", "ALAGOAS", "AL", 27],
[2703304, "INHAPI", "ALAGOAS", "AL", 27],
[2703403, "JACARE DOS HOMENS", "ALAGOAS", "AL", 27],
[2703502, "JACUIPE", "ALAGOAS", "AL", 27],
[2703601, "JAPARATINGA", "ALAGOAS", "AL", 27],
[2703700, "JARAMATAIA", "ALAGOAS", "AL", 27],
[2703759, "JEQUIA DA PRAIA", "ALAGOAS", "AL", 27],
[2703809, "JOAQUIM GOMES", "ALAGOAS", "AL", 27],
[2703908, "JUNDIA", "ALAGOAS", "AL", 27],
[2704005, "JUNQUEIRO", "ALAGOAS", "AL", 27],
[2704104, "LAGOA DA CANOA", "ALAGOAS", "AL", 27],
[2704203, "LIMOEIRO DE ANADIA", "ALAGOAS", "AL", 27],
[2704302, "MACEIO", "ALAGOAS", "AL", 27],
[2704401, "MAJOR ISIDORO", "ALAGOAS", "AL", 27],
[2704500, "MARAGOGI", "ALAGOAS", "AL", 27],
[2704609, "MARAVILHA", "ALAGOAS", "AL", 27],
[2704708, "MARECHAL DEODORO", "ALAGOAS", "AL", 27],
[2704807, "MARIBONDO", "ALAGOAS", "AL", 27],
[2704906, "MAR VERMELHO", "ALAGOAS", "AL", 27],
[2705002, "MATA GRANDE", "ALAGOAS", "AL", 27],
[2705101, "MATRIZ DE CAMARAGIBE", "ALAGOAS", "AL", 27],
[2705200, "MESSIAS", "ALAGOAS", "AL", 27],
[2705309, "MINADOR DO NEGRAO", "ALAGOAS", "AL", 27],
[2705408, "MONTEIROPOLIS", "ALAGOAS", "AL", 27],
[2705507, "MURICI", "ALAGOAS", "AL", 27],
[2705606, "NOVO LINO", "ALAGOAS", "AL", 27],
[2705705, "OLHO D'AGUA DAS FLORES", "ALAGOAS", "AL", 27],
[2705804, "OLHO D'AGUA DO CASADO", "ALAGOAS", "AL", 27],
[2705903, "OLHO D'AGUA GRANDE", "ALAGOAS", "AL", 27],
[2706000, "OLIVENCA", "ALAGOAS", "AL", 27],
[2706109, "OURO BRANCO", "ALAGOAS", "AL", 27],
[2706208, "PALESTINA", "ALAGOAS", "AL", 27],
[2706307, "PALMEIRA DOS INDIOS", "ALAGOAS", "AL", 27],
[2706406, "PAO DE ACUCAR", "ALAGOAS", "AL", 27],
[2706422, "PARICONHA", "ALAGOAS", "AL", 27],
[2706448, "PARIPUEIRA", "ALAGOAS", "AL", 27],
[2706505, "PASSO DE CAMARAGIBE", "ALAGOAS", "AL", 27],
[2706604, "PAULO JACINTO", "ALAGOAS", "AL", 27],
[2706703, "PENEDO", "ALAGOAS", "AL", 27],
[2706802, "PIACABUCU", "ALAGOAS", "AL", 27],
[2706901, "PILAR", "ALAGOAS", "AL", 27],
[2707008, "PINDOBA", "ALAGOAS", "AL", 27],
[2707107, "PIRANHAS", "ALAGOAS", "AL", 27],
[2707206, "POCO DAS TRINCHEIRAS", "ALAGOAS", "AL", 27],
[2707305, "PORTO CALVO", "ALAGOAS", "AL", 27],
[2707404, "PORTO DE PEDRAS", "ALAGOAS", "AL", 27],
[2707503, "PORTO REAL DO COLEGIO", "ALAGOAS", "AL", 27],
[2707602, "QUEBRANGULO", "ALAGOAS", "AL", 27],
[2707701, "RIO LARGO", "ALAGOAS", "AL", 27],
[2707800, "ROTEIRO", "ALAGOAS", "AL", 27],
[2707909, "SANTA LUZIA DO NORTE", "ALAGOAS", "AL", 27],
[2708006, "SANTANA DO IPANEMA", "ALAGOAS", "AL", 27],
[2708105, "SANTANA DO MUNDAU", "ALAGOAS", "AL", 27],
[2708204, "SAO BRAS", "ALAGOAS", "AL", 27],
[2708303, "SAO JOSE DA LAJE", "ALAGOAS", "AL", 27],
[2708402, "SAO JOSE DA TAPERA", "ALAGOAS", "AL", 27],
[2708501, "SAO LUIS DO QUITUNDE", "ALAGOAS", "AL", 27],
[2708600, "SAO MIGUEL DOS CAMPOS", "ALAGOAS", "AL", 27],
[2708709, "SAO MIGUEL DOS MILAGRES", "ALAGOAS", "AL", 27],
[2708808, "SAO SEBASTIAO", "ALAGOAS", "AL", 27],
[2708907, "SATUBA", "ALAGOAS", "AL", 27],
[2708956, "SENADOR RUI PALMEIRA", "ALAGOAS", "AL", 27],
[2709004, "TANQUE D'ARCA", "ALAGOAS", "AL", 27],
[2709103, "TAQUARANA", "ALAGOAS", "AL", 27],
[2709152, "TEOTONIO VILELA", "ALAGOAS", "AL", 27],
[2709202, "TRAIPU", "ALAGOAS", "AL", 27],
[2709301, "UNIAO DOS PALMARES", "ALAGOAS", "AL", 27],
[2709400, "VICOSA", "ALAGOAS", "AL", 27],
[2800100, "AMPARO DE SAO FRANCISCO", "SERGIPE", "SE", 28],
[2800209, "AQUIDABA", "SERGIPE", "SE", 28],
[2800308, "ARACAJU", "SERGIPE", "SE", 28],
[2800407, "ARAUA", "SERGIPE", "SE", 28],
[2800506, "AREIA BRANCA", "SERGIPE", "SE", 28],
[2800605, "BARRA DOS COQUEIROS", "SERGIPE", "SE", 28],
[2800670, "BOQUIM", "SERGIPE", "SE", 28],
[2800704, "BREJO GRANDE", "SERGIPE", "SE", 28],
[2801009, "CAMPO DO BRITO", "SERGIPE", "SE", 28],
[2801108, "CANHOBA", "SERGIPE", "SE", 28],
[2801207, "CANINDE DE SAO FRANCISCO", "SERGIPE", "SE", 28],
[2801306, "CAPELA", "SERGIPE", "SE", 28],
[2801405, "CARIRA", "SERGIPE", "SE", 28],
[2801504, "CARMOPOLIS", "SERGIPE", "SE", 28],
[2801603, "CEDRO DE SAO JOAO", "SERGIPE", "SE", 28],
[2801702, "CRISTINAPOLIS", "SERGIPE", "SE", 28],
[2801900, "CUMBE", "SERGIPE", "SE", 28],
[2802007, "DIVINA PASTORA", "SERGIPE", "SE", 28],
[2802106, "ESTANCIA", "SERGIPE", "SE", 28],
[2802205, "FEIRA NOVA", "SERGIPE", "SE", 28],
[2802304, "FREI PAULO", "SERGIPE", "SE", 28],
[2802403, "GARARU", "SERGIPE", "SE", 28],
[2802502, "GENERAL MAYNARD", "SERGIPE", "SE", 28],
[2802601, "GRACHO CARDOSO", "SERGIPE", "SE", 28],
[2802700, "ILHA DAS FLORES", "SERGIPE", "SE", 28],
[2802809, "INDIAROBA", "SERGIPE", "SE", 28],
[2802908, "ITABAIANA", "SERGIPE", "SE", 28],
[2803005, "ITABAIANINHA", "SERGIPE", "SE", 28],
[2803104, "ITABI", "SERGIPE", "SE", 28],
[2803203, "ITAPORANGA D'AJUDA", "SERGIPE", "SE", 28],
[2803302, "JAPARATUBA", "SERGIPE", "SE", 28],
[2803401, "JAPOATA", "SERGIPE", "SE", 28],
[2803500, "LAGARTO", "SERGIPE", "SE", 28],
[2803609, "LARANJEIRAS", "SERGIPE", "SE", 28],
[2803708, "MACAMBIRA", "SERGIPE", "SE", 28],
[2803807, "MALHADA DOS BOIS", "SERGIPE", "SE", 28],
[2803906, "MALHADOR", "SERGIPE", "SE", 28],
[2804003, "MARUIM", "SERGIPE", "SE", 28],
[2804102, "MOITA BONITA", "SERGIPE", "SE", 28],
[2804201, "MONTE ALEGRE DE SERGIPE", "SERGIPE", "SE", 28],
[2804300, "MURIBECA", "SERGIPE", "SE", 28],
[2804409, "NEOPOLIS", "SERGIPE", "SE", 28],
[2804458, "NOSSA SENHORA APARECIDA", "SERGIPE", "SE", 28],
[2804508, "NOSSA SENHORA DA GLORIA", "SERGIPE", "SE", 28],
[2804607, "NOSSA SENHORA DAS DORES", "SERGIPE", "SE", 28],
[2804706, "NOSSA SENHORA DE LOURDES", "SERGIPE", "SE", 28],
[2804805, "NOSSA SENHORA DO SOCORRO", "SERGIPE", "SE", 28],
[2804904, "PACATUBA", "SERGIPE", "SE", 28],
[2805000, "PEDRA MOLE", "SERGIPE", "SE", 28],
[2805109, "PEDRINHAS", "SERGIPE", "SE", 28],
[2805208, "PINHAO", "SERGIPE", "SE", 28],
[2805307, "PIRAMBU", "SERGIPE", "SE", 28],
[2805406, "POCO REDONDO", "SERGIPE", "SE", 28],
[2805505, "POCO VERDE", "SERGIPE", "SE", 28],
[2805604, "PORTO DA FOLHA", "SERGIPE", "SE", 28],
[2805703, "PROPRIA", "SERGIPE", "SE", 28],
[2805802, "RIACHAO DO DANTAS", "SERGIPE", "SE", 28],
[2805901, "RIACHUELO", "SERGIPE", "SE", 28],
[2806008, "RIBEIROPOLIS", "SERGIPE", "SE", 28],
[2806107, "ROSARIO DO CATETE", "SERGIPE", "SE", 28],
[2806206, "SALGADO", "SERGIPE", "SE", 28],
[2806305, "SANTA LUZIA DO ITANHY", "SERGIPE", "SE", 28],
[2806404, "SANTANA DO SAO FRANCISCO", "SERGIPE", "SE", 28],
[2806503, "SANTA ROSA DE LIMA", "SERGIPE", "SE", 28],
[2806602, "SANTO AMARO DAS BROTAS", "SERGIPE", "SE", 28],
[2806701, "SAO CRISTOVAO", "SERGIPE", "SE", 28],
[2806800, "SAO DOMINGOS", "SERGIPE", "SE", 28],
[2806909, "SAO FRANCISCO", "SERGIPE", "SE", 28],
[2807006, "SAO MIGUEL DO ALEIXO", "SERGIPE", "SE", 28],
[2807105, "SIMAO DIAS", "SERGIPE", "SE", 28],
[2807204, "SIRIRI", "SERGIPE", "SE", 28],
[2807303, "TELHA", "SERGIPE", "SE", 28],
[2807402, "TOBIAS BARRETO", "SERGIPE", "SE", 28],
[2807501, "TOMAR DO GERU", "SERGIPE", "SE", 28],
[2807600, "UMBAUBA", "SERGIPE", "SE", 28],
[2900108, "ABAIRA", "BAHIA", "BA", 0],
[2900207, "ABARE", "BAHIA", "BA", 0],
[2900306, "ACAJUTIBA", "BAHIA", "BA", 0],
[2900355, "ADUSTINA", "BAHIA", "BA", 0],
[2900405, "AGUA FRIA", "BAHIA", "BA", 0],
[2900504, "ERICO CARDOSO", "BAHIA", "BA", 0],
[2900603, "AIQUARA", "BAHIA", "BA", 0],
[2900702, "ALAGOINHAS", "BAHIA", "BA", 0],
[2900801, "ALCOBACA", "BAHIA", "BA", 0],
[2900900, "ALMADINA", "BAHIA", "BA", 0],
[2901007, "AMARGOSA", "BAHIA", "BA", 0],
[2901106, "AMELIA RODRIGUES", "BAHIA", "BA", 0],
[2901155, "AMERICA DOURADA", "BAHIA", "BA", 0],
[2901205, "ANAGE", "BAHIA", "BA", 0],
[2901304, "ANDARAI", "BAHIA", "BA", 0],
[2901353, "ANDORINHA", "BAHIA", "BA", 0],
[2901502, "ANGUERA", "BAHIA", "BA", 0],
[2901601, "ANTAS", "BAHIA", "BA", 0],
[2901700, "ANTONIO CARDOSO", "BAHIA", "BA", 0],
[2901809, "ANTONIO GONCALVES", "BAHIA", "BA", 0],
[2901908, "APORA", "BAHIA", "BA", 0],
[2901957, "APUAREMA", "BAHIA", "BA", 0],
[2902005, "ARACATU", "BAHIA", "BA", 0],
[2902054, "ARACAS", "BAHIA", "BA", 0],
[2902104, "ARACI", "BAHIA", "BA", 0],
[2902203, "ARAMARI", "BAHIA", "BA", 0],
[2902252, "ARATACA", "BAHIA", "BA", 0],
[2902302, "ARATUIPE", "BAHIA", "BA", 0],
[2902401, "AURELINO LEAL", "BAHIA", "BA", 0],
[2902500, "BAIANOPOLIS", "BAHIA", "BA", 0],
[2902609, "BAIXA GRANDE", "BAHIA", "BA", 0],
[2902658, "BANZAE", "BAHIA", "BA", 0],
[2902708, "BARRA", "BAHIA", "BA", 0],
[2902807, "BARRA DA ESTIVA", "BAHIA", "BA", 0],
[2902906, "BARRA DO CHOCA", "BAHIA", "BA", 0],
[2903003, "BARRA DO MENDES", "BAHIA", "BA", 0],
[2903102, "BARRA DO ROCHA", "BAHIA", "BA", 0],
[2903201, "BARREIRAS", "BAHIA", "BA", 0],
[2903235, "BARRO ALTO", "BAHIA", "BA", 0],
[2903276, "BARROCAS", "BAHIA", "BA", 0],
[2903300, "BARRO PRETO", "BAHIA", "BA", 0],
[2903409, "BELMONTE", "BAHIA", "BA", 0],
[2903508, "BELO CAMPO", "BAHIA", "BA", 0],
[2903607, "BIRITINGA", "BAHIA", "BA", 0],
[2903706, "BOA NOVA", "BAHIA", "BA", 0],
[2903805, "BOA VISTA DO TUPIM", "BAHIA", "BA", 0],
[2903904, "BOM JESUS DA LAPA", "BAHIA", "BA", 0],
[2903953, "BOM JESUS DA SERRA", "BAHIA", "BA", 0],
[2904001, "BONINAL", "BAHIA", "BA", 0],
[2904050, "BONITO", "BAHIA", "BA", 0],
[2904100, "BOQUIRA", "BAHIA", "BA", 0],
[2904209, "BOTUPORA", "BAHIA", "BA", 0],
[2904308, "BREJÕES", "BAHIA", "BA", 0],
[2904407, "BREJOLANDIA", "BAHIA", "BA", 0],
[2904506, "BROTAS DE MACAUBAS", "BAHIA", "BA", 0],
[2904605, "BRUMADO", "BAHIA", "BA", 0],
[2904704, "BUERAREMA", "BAHIA", "BA", 0],
[2904753, "BURITIRAMA", "BAHIA", "BA", 0],
[2904803, "CAATIBA", "BAHIA", "BA", 0],
[2904852, "CABACEIRAS DO PARAGUACU", "BAHIA", "BA", 0],
[2904902, "CACHOEIRA", "BAHIA", "BA", 0],
[2905008, "CACULE", "BAHIA", "BA", 0],
[2905107, "CAEM", "BAHIA", "BA", 0],
[2905156, "CAETANOS", "BAHIA", "BA", 0],
[2905206, "CAETITE", "BAHIA", "BA", 0],
[2905305, "CAFARNAUM", "BAHIA", "BA", 0],
[2905404, "CAIRU", "BAHIA", "BA", 0],
[2905503, "CALDEIRAO GRANDE", "BAHIA", "BA", 0],
[2905602, "CAMACAN", "BAHIA", "BA", 0],
[2905701, "CAMACARI", "BAHIA", "BA", 0],
[2905800, "CAMAMU", "BAHIA", "BA", 0],
[2905909, "CAMPO ALEGRE DE LOURDES", "BAHIA", "BA", 0],
[2906105, "CANAPOLIS", "BAHIA", "BA", 0],
[2906204, "CANARANA", "BAHIA", "BA", 0],
[2906303, "CANAVIEIRAS", "BAHIA", "BA", 0],
[2906402, "CANDEAL", "BAHIA", "BA", 0],
[2906501, "CANDEIAS", "BAHIA", "BA", 0],
[2906600, "CANDIBA", "BAHIA", "BA", 0],
[2906709, "CANDIDO SALES", "BAHIA", "BA", 0],
[2906808, "CANSANCAO", "BAHIA", "BA", 0],
[2906824, "CANUDOS", "BAHIA", "BA", 0],
[2906857, "CAPELA DO ALTO ALEGRE", "BAHIA", "BA", 0],
[2906873, "CAPIM GROSSO", "BAHIA", "BA", 0],
[2906899, "CARAIBAS", "BAHIA", "BA", 0],
[2906907, "CARAVELAS", "BAHIA", "BA", 0],
[2907004, "CARDEAL DA SILVA", "BAHIA", "BA", 0],
[2907103, "CARINHANHA", "BAHIA", "BA", 0],
[2907202, "CASA NOVA", "BAHIA", "BA", 0],
[2907301, "CASTRO ALVES", "BAHIA", "BA", 0],
[2907400, "CATOLANDIA", "BAHIA", "BA", 0],
[2907509, "CATU", "BAHIA", "BA", 0],
[2907558, "CATURAMA", "BAHIA", "BA", 0],
[2907608, "CENTRAL", "BAHIA", "BA", 0],
[2907707, "CHORROCHO", "BAHIA", "BA", 0],
[2907806, "CICERO DANTAS", "BAHIA", "BA", 0],
[2907905, "CIPO", "BAHIA", "BA", 0],
[2908002, "COARACI", "BAHIA", "BA", 0],
[2908101, "COCOS", "BAHIA", "BA", 0],
[2908200, "CONCEICAO DA FEIRA", "BAHIA", "BA", 0],
[2908309, "CONCEICAO DO ALMEIDA", "BAHIA", "BA", 0],
[2908408, "CONCEICAO DO COITE", "BAHIA", "BA", 0],
[2908507, "CONCEICAO DO JACUIPE", "BAHIA", "BA", 0],
[2908606, "CONDE", "BAHIA", "BA", 0],
[2908705, "CONDEUBA", "BAHIA", "BA", 0],
[2908804, "CONTENDAS DO SINCORA", "BAHIA", "BA", 0],
[2908903, "CORACAO DE MARIA", "BAHIA", "BA", 0],
[2909000, "CORDEIROS", "BAHIA", "BA", 0],
[2909109, "CORIBE", "BAHIA", "BA", 0],
[2909208, "CORONEL JOAO SA", "BAHIA", "BA", 0],
[2909307, "CORRENTINA", "BAHIA", "BA", 0],
[2909406, "COTEGIPE", "BAHIA", "BA", 0],
[2909505, "CRAVOLANDIA", "BAHIA", "BA", 0],
[2909703, "CRISTOPOLIS", "BAHIA", "BA", 0],
[2909802, "CRUZ DAS ALMAS", "BAHIA", "BA", 0],
[2909901, "CURACA", "BAHIA", "BA", 0],
[2910008, "DARIO MEIRA", "BAHIA", "BA", 0],
[2910057, "DIAS D'AVILA", "BAHIA", "BA", 0],
[2910107, "DOM BASILIO", "BAHIA", "BA", 0],
[2910206, "DOM MACEDO COSTA", "BAHIA", "BA", 0],
[2910305, "ELISIO MEDRADO", "BAHIA", "BA", 0],
[2910404, "ENCRUZILHADA", "BAHIA", "BA", 0],
[2910503, "ENTRE RIOS", "BAHIA", "BA", 0],
[2910602, "ESPLANADA", "BAHIA", "BA", 0],
[2910701, "EUCLIDES DA CUNHA", "BAHIA", "BA", 0],
[2910727, "EUNAPOLIS", "BAHIA", "BA", 0],
[2910750, "FATIMA", "BAHIA", "BA", 0],
[2910776, "FEIRA DA MATA", "BAHIA", "BA", 0],
[2910800, "FEIRA DE SANTANA", "BAHIA", "BA", 0],
[2910859, "FILADELFIA", "BAHIA", "BA", 0],
[2910909, "FIRMINO ALVES", "BAHIA", "BA", 0],
[2911006, "FLORESTA AZUL", "BAHIA", "BA", 0],
[2911105, "FORMOSA DO RIO PRETO", "BAHIA", "BA", 0],
[2911204, "GANDU", "BAHIA", "BA", 0],
[2911253, "GAVIAO", "BAHIA", "BA", 0],
[2911303, "GENTIO DO OURO", "BAHIA", "BA", 0],
[2911402, "GLORIA", "BAHIA", "BA", 0],
[2911501, "GONGOGI", "BAHIA", "BA", 0],
[2911600, "GOVERNADOR MANGABEIRA", "BAHIA", "BA", 0],
[2911659, "GUAJERU", "BAHIA", "BA", 0],
[2911709, "GUANAMBI", "BAHIA", "BA", 0],
[2911857, "HELIOPOLIS", "BAHIA", "BA", 0],
[2911907, "IACU", "BAHIA", "BA", 0],
[2912004, "IBIASSUCE", "BAHIA", "BA", 0],
[2912103, "IBICARAI", "BAHIA", "BA", 0],
[2912202, "IBICOARA", "BAHIA", "BA", 0],
[2912301, "IBICUI", "BAHIA", "BA", 0],
[2912400, "IBIPEBA", "BAHIA", "BA", 0],
[2912509, "IBIPITANGA", "BAHIA", "BA", 0],
[2912608, "IBIQUERA", "BAHIA", "BA", 0],
[2912707, "IBIRAPITANGA", "BAHIA", "BA", 0],
[2912806, "IBIRAPUA", "BAHIA", "BA", 0],
[2912905, "IBIRATAIA", "BAHIA", "BA", 0],
[2913002, "IBITIARA", "BAHIA", "BA", 0],
[2913101, "IBITITA", "BAHIA", "BA", 0],
[2913200, "IBOTIRAMA", "BAHIA", "BA", 0],
[2913309, "ICHU", "BAHIA", "BA", 0],
[2913408, "IGAPORA", "BAHIA", "BA", 0],
[2913457, "IGRAPIUNA", "BAHIA", "BA", 0],
[2913507, "IGUAI", "BAHIA", "BA", 0],
[2913606, "ILHEUS", "BAHIA", "BA", 0],
[2913705, "INHAMBUPE", "BAHIA", "BA", 0],
[2913804, "IPECAETA", "BAHIA", "BA", 0],
[2913903, "IPIAU", "BAHIA", "BA", 0],
[2914000, "IPIRA", "BAHIA", "BA", 0],
[2914109, "IPUPIARA", "BAHIA", "BA", 0],
[2914208, "IRAJUBA", "BAHIA", "BA", 0],
[2914307, "IRAMAIA", "BAHIA", "BA", 0],
[2914406, "IRAQUARA", "BAHIA", "BA", 0],
[2914505, "IRARA", "BAHIA", "BA", 0],
[2914604, "IRECE", "BAHIA", "BA", 0],
[2914653, "ITABELA", "BAHIA", "BA", 0],
[2914703, "ITABERABA", "BAHIA", "BA", 0],
[2914802, "ITABUNA", "BAHIA", "BA", 0],
[2914901, "ITACARE", "BAHIA", "BA", 0],
[2915007, "ITAETE", "BAHIA", "BA", 0],
[2915106, "ITAGI", "BAHIA", "BA", 0],
[2915205, "ITAGIBA", "BAHIA", "BA", 0],
[2915304, "ITAGIMIRIM", "BAHIA", "BA", 0],
[2915353, "ITAGUACU DA BAHIA", "BAHIA", "BA", 0],
[2915403, "ITAJU DO COLONIA", "BAHIA", "BA", 0],
[2915502, "ITAJUIPE", "BAHIA", "BA", 0],
[2915601, "ITAMARAJU", "BAHIA", "BA", 0],
[2915700, "ITAMARI", "BAHIA", "BA", 0],
[2915809, "ITAMBE", "BAHIA", "BA", 0],
[2915908, "ITANAGRA", "BAHIA", "BA", 0],
[2916005, "ITANHEM", "BAHIA", "BA", 0],
[2916104, "ITAPARICA", "BAHIA", "BA", 0],
[2916203, "ITAPE", "BAHIA", "BA", 0],
[2916302, "ITAPEBI", "BAHIA", "BA", 0],
[2916401, "ITAPETINGA", "BAHIA", "BA", 0],
[2916500, "ITAPICURU", "BAHIA", "BA", 0],
[2916609, "ITAPITANGA", "BAHIA", "BA", 0],
[2916708, "ITAQUARA", "BAHIA", "BA", 0],
[2916807, "ITARANTIM", "BAHIA", "BA", 0],
[2916856, "ITATIM", "BAHIA", "BA", 0],
[2916906, "ITIRUCU", "BAHIA", "BA", 0],
[2917003, "ITIUBA", "BAHIA", "BA", 0],
[2917102, "ITORORO", "BAHIA", "BA", 0],
[2917201, "ITUACU", "BAHIA", "BA", 0],
[2917300, "ITUBERA", "BAHIA", "BA", 0],
[2917334, "IUIU", "BAHIA", "BA", 0],
[2917359, "JABORANDI", "BAHIA", "BA", 0],
[2917409, "JACARACI", "BAHIA", "BA", 0],
[2917508, "JACOBINA", "BAHIA", "BA", 0],
[2917607, "JAGUAQUARA", "BAHIA", "BA", 0],
[2917706, "JAGUARARI", "BAHIA", "BA", 0],
[2917805, "JAGUARIPE", "BAHIA", "BA", 0],
[2917904, "JANDAIRA", "BAHIA", "BA", 0],
[2918001, "JEQUIE", "BAHIA", "BA", 0],
[2918100, "JEREMOABO", "BAHIA", "BA", 0],
[2918209, "JIQUIRICA", "BAHIA", "BA", 0],
[2918308, "JITAUNA", "BAHIA", "BA", 0],
[2918357, "JOAO DOURADO", "BAHIA", "BA", 0],
[2918407, "JUAZEIRO", "BAHIA", "BA", 0],
[2918456, "JUCURUCU", "BAHIA", "BA", 0],
[2918506, "JUSSARA", "BAHIA", "BA", 0],
[2918555, "JUSSARI", "BAHIA", "BA", 0],
[2918605, "JUSSIAPE", "BAHIA", "BA", 0],
[2918704, "LAFAIETE COUTINHO", "BAHIA", "BA", 0],
[2918753, "LAGOA REAL", "BAHIA", "BA", 0],
[2918803, "LAJE", "BAHIA", "BA", 0],
[2918902, "LAJEDAO", "BAHIA", "BA", 0],
[2919009, "LAJEDINHO", "BAHIA", "BA", 0],
[2919058, "LAJEDO DO TABOCAL", "BAHIA", "BA", 0],
[2919108, "LAMARAO", "BAHIA", "BA", 0],
[2919157, "LAPAO", "BAHIA", "BA", 0],
[2919207, "LAURO DE FREITAS", "BAHIA", "BA", 0],
[2919306, "LENCOIS", "BAHIA", "BA", 0],
[2919405, "LICINIO DE ALMEIDA", "BAHIA", "BA", 0],
[2919504, "LIVRAMENTO DE NOSSA SENHO", "BAHIA", "BA", 0],
[2919553, "LUIS EDUARDO MAGALHAES", "BAHIA", "BA", 0],
[2919603, "MACAJUBA", "BAHIA", "BA", 0],
[2919702, "MACARANI", "BAHIA", "BA", 0],
[2919801, "MACAUBAS", "BAHIA", "BA", 0],
[2919900, "MACURURE", "BAHIA", "BA", 0],
[2919926, "MADRE DE DEUS", "BAHIA", "BA", 0],
[2919959, "MAETINGA", "BAHIA", "BA", 0],
[2920007, "MAIQUINIQUE", "BAHIA", "BA", 0],
[2920106, "MAIRI", "BAHIA", "BA", 0],
[2920205, "MALHADA", "BAHIA", "BA", 0],
[2920304, "MALHADA DE PEDRAS", "BAHIA", "BA", 0],
[2920403, "MANOEL VITORINO", "BAHIA", "BA", 0],
[2920452, "MANSIDAO", "BAHIA", "BA", 0],
[2920502, "MARACAS", "BAHIA", "BA", 0],
[2920601, "MARAGOGIPE", "BAHIA", "BA", 0],
[2920700, "MARAU", "BAHIA", "BA", 0],
[2920809, "MARCIONILIO SOUZA", "BAHIA", "BA", 0],
[2920908, "MASCOTE", "BAHIA", "BA", 0],
[2921005, "MATA DE SAO JOAO", "BAHIA", "BA", 0],
[2921054, "MATINA", "BAHIA", "BA", 0],
[2921104, "MEDEIROS NETO", "BAHIA", "BA", 0],
[2921203, "MIGUEL CALMON", "BAHIA", "BA", 0],
[2921302, "MILAGRES", "BAHIA", "BA", 0],
[2921401, "MIRANGABA", "BAHIA", "BA", 0],
[2921450, "MIRANTE", "BAHIA", "BA", 0],
[2921500, "MONTE SANTO", "BAHIA", "BA", 0],
[2921609, "MORPARA", "BAHIA", "BA", 0],
[2921708, "MORRO DO CHAPEU", "BAHIA", "BA", 0],
[2921807, "MORTUGABA", "BAHIA", "BA", 0],
[2921906, "MUCUGE", "BAHIA", "BA", 0],
[2922003, "MUCURI", "BAHIA", "BA", 0],
[2922052, "MULUNGU DO MORRO", "BAHIA", "BA", 0],
[2922102, "MUNDO NOVO", "BAHIA", "BA", 0],
[2922201, "MUNIZ FERREIRA", "BAHIA", "BA", 0],
[2922250, "MUQUEM DE SAO FRANCISCO", "BAHIA", "BA", 0],
[2922300, "MURITIBA", "BAHIA", "BA", 0],
[2922409, "MUTUIPE", "BAHIA", "BA", 0],
[2922508, "NAZARE", "BAHIA", "BA", 0],
[2922607, "NILO PECANHA", "BAHIA", "BA", 0],
[2922656, "NORDESTINA", "BAHIA", "BA", 0],
[2922706, "NOVA CANAA", "BAHIA", "BA", 0],
[2922730, "NOVA FATIMA", "BAHIA", "BA", 0],
[2922755, "NOVA IBIA", "BAHIA", "BA", 0],
[2922805, "NOVA ITARANA", "BAHIA", "BA", 0],
[2922854, "NOVA REDENCAO", "BAHIA", "BA", 0],
[2922904, "NOVA SOURE", "BAHIA", "BA", 0],
[2923001, "NOVA VICOSA", "BAHIA", "BA", 0],
[2923035, "NOVO HORIZONTE", "BAHIA", "BA", 0],
[2923050, "NOVO TRIUNFO", "BAHIA", "BA", 0],
[2923100, "OLINDINA", "BAHIA", "BA", 0],
[2923209, "OLIVEIRA DOS BREJINHOS", "BAHIA", "BA", 0],
[2923308, "OURICANGAS", "BAHIA", "BA", 0],
[2923357, "OUROLANDIA", "BAHIA", "BA", 0],
[2923407, "PALMAS DE MONTE ALTO", "BAHIA", "BA", 0],
[2923506, "PALMEIRAS", "BAHIA", "BA", 0],
[2923605, "PARAMIRIM", "BAHIA", "BA", 0],
[2923704, "PARATINGA", "BAHIA", "BA", 0],
[2923803, "PARIPIRANGA", "BAHIA", "BA", 0],
[2923902, "PAU BRASIL", "BAHIA", "BA", 0],
[2924009, "PAULO AFONSO", "BAHIA", "BA", 0],
[2924058, "PE DE SERRA", "BAHIA", "BA", 0],
[2924108, "PEDRAO", "BAHIA", "BA", 0],
[2924207, "PEDRO ALEXANDRE", "BAHIA", "BA", 0],
[2924306, "PIATA", "BAHIA", "BA", 0],
[2924405, "PILAO ARCADO", "BAHIA", "BA", 0],
[2924504, "PINDAI", "BAHIA", "BA", 0],
[2924603, "PINDOBACU", "BAHIA", "BA", 0],
[2924652, "PINTADAS", "BAHIA", "BA", 0],
[2924678, "PIRAI DO NORTE", "BAHIA", "BA", 0],
[2924702, "PIRIPA", "BAHIA", "BA", 0],
[2924801, "PIRITIBA", "BAHIA", "BA", 0],
[2924900, "PLANALTINO", "BAHIA", "BA", 0],
[2925006, "PLANALTO", "BAHIA", "BA", 0],
[2925105, "POCÕES", "BAHIA", "BA", 0],
[2925204, "POJUCA", "BAHIA", "BA", 0],
[2925253, "PONTO NOVO", "BAHIA", "BA", 0],
[2925303, "PORTO SEGURO", "BAHIA", "BA", 0],
[2925402, "POTIRAGUA", "BAHIA", "BA", 0],
[2925501, "PRADO", "BAHIA", "BA", 0],
[2925600, "PRESIDENTE DUTRA", "BAHIA", "BA", 0],
[2925709, "PRESIDENTE JANIO QUADROS", "BAHIA", "BA", 0],
[2925758, "PRESIDENTE TANCREDO NEVES", "BAHIA", "BA", 0],
[2925808, "QUEIMADAS", "BAHIA", "BA", 0],
[2925907, "QUIJINGUE", "BAHIA", "BA", 0],
[2925931, "QUIXABEIRA", "BAHIA", "BA", 0],
[2925956, "RAFAEL JAMBEIRO", "BAHIA", "BA", 0],
[2926004, "REMANSO", "BAHIA", "BA", 0],
[2926103, "RETIROLANDIA", "BAHIA", "BA", 0],
[2926202, "RIACHAO DAS NEVES", "BAHIA", "BA", 0],
[2926301, "RIACHAO DO JACUIPE", "BAHIA", "BA", 0],
[2926400, "RIACHO DE SANTANA", "BAHIA", "BA", 0],
[2926509, "RIBEIRA DO AMPARO", "BAHIA", "BA", 0],
[2926608, "RIBEIRA DO POMBAL", "BAHIA", "BA", 0],
[2926657, "RIBEIRAO DO LARGO", "BAHIA", "BA", 0],
[2926707, "RIO DE CONTAS", "BAHIA", "BA", 0],
[2926806, "RIO DO ANTONIO", "BAHIA", "BA", 0],
[2926905, "RIO DO PIRES", "BAHIA", "BA", 0],
[2927002, "RIO REAL", "BAHIA", "BA", 0],
[2927101, "RODELAS", "BAHIA", "BA", 0],
[2927200, "RUY BARBOSA", "BAHIA", "BA", 0],
[2927309, "SALINAS DA MARGARIDA", "BAHIA", "BA", 0],
[2927408, "SALVADOR", "BAHIA", "BA", 0],
[2927507, "SANTA BARBARA", "BAHIA", "BA", 0],
[2927606, "SANTA BRIGIDA", "BAHIA", "BA", 0],
[2927705, "SANTA CRUZ CABRALIA", "BAHIA", "BA", 0],
[2927804, "SANTA CRUZ DA VITORIA", "BAHIA", "BA", 0],
[2927903, "SANTA INES", "BAHIA", "BA", 0],
[2928000, "SANTALUZ", "BAHIA", "BA", 0],
[2928059, "SANTA LUZIA", "BAHIA", "BA", 0],
[2928109, "SANTA MARIA DA VITORIA", "BAHIA", "BA", 0],
[2928208, "SANTANA", "BAHIA", "BA", 0],
[2928307, "SANTANOPOLIS", "BAHIA", "BA", 0],
[2928406, "SANTA RITA DE CASSIA", "BAHIA", "BA", 0],
[2928505, "SANTA TERESINHA", "BAHIA", "BA", 0],
[2928604, "SANTO AMARO", "BAHIA", "BA", 0],
[2928703, "SANTO ANTONIO DE JESUS", "BAHIA", "BA", 0],
[2928802, "SANTO ESTEVAO", "BAHIA", "BA", 0],
[2928901, "SAO DESIDERIO", "BAHIA", "BA", 0],
[2928950, "SAO DOMINGOS", "BAHIA", "BA", 0],
[2929008, "SAO FELIX", "BAHIA", "BA", 0],
[2929057, "SAO FELIX DO CORIBE", "BAHIA", "BA", 0],
[2929107, "SAO FELIPE", "BAHIA", "BA", 0],
[2929206, "SAO FRANCISCO DO CONDE", "BAHIA", "BA", 0],
[2929255, "SAO GABRIEL", "BAHIA", "BA", 0],
[2929305, "SAO GONCALO DOS CAMPOS", "BAHIA", "BA", 0],
[2929354, "SAO JOSE DA VITORIA", "BAHIA", "BA", 0],
[2929370, "SAO JOSE DO JACUIPE", "BAHIA", "BA", 0],
[2929404, "SAO MIGUEL DAS MATAS", "BAHIA", "BA", 0],
[2929503, "SAO SEBASTIAO DO PASSE", "BAHIA", "BA", 0],
[2929602, "SAPEACU", "BAHIA", "BA", 0],
[2929701, "SATIRO DIAS", "BAHIA", "BA", 0],
[2929750, "SAUBARA", "BAHIA", "BA", 0],
[2929800, "SAUDE", "BAHIA", "BA", 0],
[2929909, "SEABRA", "BAHIA", "BA", 0],
[2930006, "SEBASTIAO LARANJEIRAS", "BAHIA", "BA", 0],
[2930105, "SENHOR DO BONFIM", "BAHIA", "BA", 0],
[2930154, "SERRA DO RAMALHO", "BAHIA", "BA", 0],
[2930204, "SENTO SE", "BAHIA", "BA", 0],
[2930303, "SERRA DOURADA", "BAHIA", "BA", 0],
[2930402, "SERRA PRETA", "BAHIA", "BA", 0],
[2930501, "SERRINHA", "BAHIA", "BA", 0],
[2930600, "SERROLANDIA", "BAHIA", "BA", 0],
[2930709, "SIMÕES FILHO", "BAHIA", "BA", 0],
[2930758, "SITIO DO MATO", "BAHIA", "BA", 0],
[2930766, "SITIO DO QUINTO", "BAHIA", "BA", 0],
[2930774, "SOBRADINHO", "BAHIA", "BA", 0],
[2930808, "SOUTO SOARES", "BAHIA", "BA", 0],
[2930907, "TABOCAS DO BREJO VELHO", "BAHIA", "BA", 0],
[2931004, "TANHACU", "BAHIA", "BA", 0],
[2931053, "TANQUE NOVO", "BAHIA", "BA", 0],
[2931103, "TANQUINHO", "BAHIA", "BA", 0],
[2931202, "TAPEROA", "BAHIA", "BA", 0],
[2931301, "TAPIRAMUTA", "BAHIA", "BA", 0],
[2931350, "TEIXEIRA DE FREITAS", "BAHIA", "BA", 0],
[2931400, "TEODORO SAMPAIO", "BAHIA", "BA", 0],
[2931509, "TEOFILANDIA", "BAHIA", "BA", 0],
[2931608, "TEOLANDIA", "BAHIA", "BA", 0],
[2931707, "TERRA NOVA", "BAHIA", "BA", 0],
[2931806, "TREMEDAL", "BAHIA", "BA", 0],
[2931905, "TUCANO", "BAHIA", "BA", 0],
[2932002, "UAUA", "BAHIA", "BA", 0],
[2932101, "UBAIRA", "BAHIA", "BA", 0],
[2932200, "UBAITABA", "BAHIA", "BA", 0],
[2932309, "UBATA", "BAHIA", "BA", 0],
[2932408, "UIBAI", "BAHIA", "BA", 0],
[2932457, "UMBURANAS", "BAHIA", "BA", 0],
[2932507, "UNA", "BAHIA", "BA", 0],
[2932606, "URANDI", "BAHIA", "BA", 0],
[2932705, "URUCUCA", "BAHIA", "BA", 0],
[2932804, "UTINGA", "BAHIA", "BA", 0],
[2932903, "VALENCA", "BAHIA", "BA", 0],
[2933000, "VALENTE", "BAHIA", "BA", 0],
[2933059, "VARZEA DA ROCA", "BAHIA", "BA", 0],
[2933109, "VARZEA DO POCO", "BAHIA", "BA", 0],
[2933158, "VARZEA NOVA", "BAHIA", "BA", 0],
[2933174, "VARZEDO", "BAHIA", "BA", 0],
[2933208, "VERA CRUZ", "BAHIA", "BA", 0],
[2933257, "VEREDA", "BAHIA", "BA", 0],
[2933307, "VITORIA DA CONQUISTA", "BAHIA", "BA", 0],
[2933406, "WAGNER", "BAHIA", "BA", 0],
[2933455, "WANDERLEY", "BAHIA", "BA", 0],
[2933505, "WENCESLAU GUIMARAES", "BAHIA", "BA", 0],
[2933604, "XIQUE-XIQUE", "BAHIA", "BA", 0],
[3100104, "ABADIA DOS DOURADOS", "MINAS GERAIS", "MG", 31],
[3100203, "ABAETE", "MINAS GERAIS", "MG", 31],
[3100302, "ABRE CAMPO", "MINAS GERAIS", "MG", 31],
[3100401, "ACAIACA", "MINAS GERAIS", "MG", 31],
[3100500, "ACUCENA", "MINAS GERAIS", "MG", 31],
[3100609, "AGUA BOA", "MINAS GERAIS", "MG", 31],
[3100708, "AGUA COMPRIDA", "MINAS GERAIS", "MG", 31],
[3100807, "AGUANIL", "MINAS GERAIS", "MG", 31],
[3100906, "AGUAS FORMOSAS", "MINAS GERAIS", "MG", 31],
[3101003, "AGUAS VERMELHAS", "MINAS GERAIS", "MG", 31],
[3101102, "AIMORES", "MINAS GERAIS", "MG", 31],
[3101201, "AIURUOCA", "MINAS GERAIS", "MG", 31],
[3101300, "ALAGOA", "MINAS GERAIS", "MG", 31],
[3101409, "ALBERTINA", "MINAS GERAIS", "MG", 31],
[3101508, "ALEM PARAIBA", "MINAS GERAIS", "MG", 31],
[3101607, "ALFENAS", "MINAS GERAIS", "MG", 31],
[3101631, "ALFREDO VASCONCELOS", "MINAS GERAIS", "MG", 31],
[3101706, "ALMENARA", "MINAS GERAIS", "MG", 31],
[3101805, "ALPERCATA", "MINAS GERAIS", "MG", 31],
[3101904, "ALPINOPOLIS", "MINAS GERAIS", "MG", 31],
[3102001, "ALTEROSA", "MINAS GERAIS", "MG", 31],
[3102050, "ALTO CAPARAO", "MINAS GERAIS", "MG", 31],
[3102100, "ALTO RIO DOCE", "MINAS GERAIS", "MG", 31],
[3102209, "ALVARENGA", "MINAS GERAIS", "MG", 31],
[3102308, "ALVINOPOLIS", "MINAS GERAIS", "MG", 31],
[3102407, "ALVORADA DE MINAS", "MINAS GERAIS", "MG", 31],
[3102506, "AMPARO DO SERRA", "MINAS GERAIS", "MG", 31],
[3102605, "ANDRADAS", "MINAS GERAIS", "MG", 31],
[3102704, "CACHOEIRA DE PAJEU", "MINAS GERAIS", "MG", 31],
[3102803, "ANDRELANDIA", "MINAS GERAIS", "MG", 31],
[3102852, "ANGELANDIA", "MINAS GERAIS", "MG", 31],
[3102902, "ANTONIO CARLOS", "MINAS GERAIS", "MG", 31],
[3103009, "ANTONIO DIAS", "MINAS GERAIS", "MG", 31],
[3103108, "ANTONIO PRADO DE MINAS", "MINAS GERAIS", "MG", 31],
[3103207, "ARACAI", "MINAS GERAIS", "MG", 31],
[3103306, "ARACITABA", "MINAS GERAIS", "MG", 31],
[3103504, "ARAGUARI", "MINAS GERAIS", "MG", 31],
[3103603, "ARANTINA", "MINAS GERAIS", "MG", 31],
[3103702, "ARAPONGA", "MINAS GERAIS", "MG", 31],
[3103751, "ARAPORA", "MINAS GERAIS", "MG", 31],
[3103801, "ARAPUA", "MINAS GERAIS", "MG", 31],
[3103900, "ARAUJOS", "MINAS GERAIS", "MG", 31],
[3104007, "ARAXA", "MINAS GERAIS", "MG", 31],
[3104106, "ARCEBURGO", "MINAS GERAIS", "MG", 31],
[3104205, "ARCOS", "MINAS GERAIS", "MG", 31],
[3104304, "AREADO", "MINAS GERAIS", "MG", 31],
[3104403, "ARGIRITA", "MINAS GERAIS", "MG", 31],
[3104452, "ARICANDUVA", "MINAS GERAIS", "MG", 31],
[3104502, "ARINOS", "MINAS GERAIS", "MG", 31],
[3104601, "ASTOLFO DUTRA", "MINAS GERAIS", "MG", 31],
[3104700, "ATALEIA", "MINAS GERAIS", "MG", 31],
[3104809, "AUGUSTO DE LIMA", "MINAS GERAIS", "MG", 31],
[3104908, "BAEPENDI", "MINAS GERAIS", "MG", 31],
[3105004, "BALDIM", "MINAS GERAIS", "MG", 31],
[3105103, "BAMBUI", "MINAS GERAIS", "MG", 31],
[3105202, "BANDEIRA", "MINAS GERAIS", "MG", 31],
[3105301, "BANDEIRA DO SUL", "MINAS GERAIS", "MG", 31],
[3105400, "BARAO DE COCAIS", "MINAS GERAIS", "MG", 31],
[3105509, "BARAO DE MONTE ALTO", "MINAS GERAIS", "MG", 31],
[3105608, "BARBACENA", "MINAS GERAIS", "MG", 31],
[3105707, "BARRA LONGA", "MINAS GERAIS", "MG", 31],
[3105905, "BARROSO", "MINAS GERAIS", "MG", 31],
[3106002, "BELA VISTA DE MINAS", "MINAS GERAIS", "MG", 31],
[3106101, "BELMIRO BRAGA", "MINAS GERAIS", "MG", 31],
[3106200, "BELO HORIZONTE", "MINAS GERAIS", "MG", 31],
[3106309, "BELO ORIENTE", "MINAS GERAIS", "MG", 31],
[3106408, "BELO VALE", "MINAS GERAIS", "MG", 31],
[3106507, "BERILO", "MINAS GERAIS", "MG", 31],
[3106606, "BERTOPOLIS", "MINAS GERAIS", "MG", 31],
[3106655, "BERIZAL", "MINAS GERAIS", "MG", 31],
[3106705, "BETIM", "MINAS GERAIS", "MG", 31],
[3106804, "BIAS FORTES", "MINAS GERAIS", "MG", 31],
[3106903, "BICAS", "MINAS GERAIS", "MG", 31],
[3107000, "BIQUINHAS", "MINAS GERAIS", "MG", 31],
[3107109, "BOA ESPERANCA", "MINAS GERAIS", "MG", 31],
[3107208, "BOCAINA DE MINAS", "MINAS GERAIS", "MG", 31],
[3107307, "BOCAIUVA", "MINAS GERAIS", "MG", 31],
[3107406, "BOM DESPACHO", "MINAS GERAIS", "MG", 31],
[3107505, "BOM JARDIM DE MINAS", "MINAS GERAIS", "MG", 31],
[3107604, "BOM JESUS DA PENHA", "MINAS GERAIS", "MG", 31],
[3107703, "BOM JESUS DO AMPARO", "MINAS GERAIS", "MG", 31],
[3107802, "BOM JESUS DO GALHO", "MINAS GERAIS", "MG", 31],
[3107901, "BOM REPOUSO", "MINAS GERAIS", "MG", 31],
[3108008, "BOM SUCESSO", "MINAS GERAIS", "MG", 31],
[3108107, "BONFIM", "MINAS GERAIS", "MG", 31],
[3108206, "BONFINOPOLIS DE MINAS", "MINAS GERAIS", "MG", 31],
[3108255, "BONITO DE MINAS", "MINAS GERAIS", "MG", 31],
[3108305, "BORDA DA MATA", "MINAS GERAIS", "MG", 31],
[3108404, "BOTELHOS", "MINAS GERAIS", "MG", 31],
[3108503, "BOTUMIRIM", "MINAS GERAIS", "MG", 31],
[3108552, "BRASILANDIA DE MINAS", "MINAS GERAIS", "MG", 31],
[3108602, "BRASILIA DE MINAS", "MINAS GERAIS", "MG", 31],
[3108701, "BRAS PIRES", "MINAS GERAIS", "MG", 31],
[3108800, "BRAUNAS", "MINAS GERAIS", "MG", 31],
[3108909, "BRASOPOLIS", "MINAS GERAIS", "MG", 31],
[3109006, "BRUMADINHO", "MINAS GERAIS", "MG", 31],
[3109105, "BUENO BRANDAO", "MINAS GERAIS", "MG", 31],
[3109204, "BUENOPOLIS", "MINAS GERAIS", "MG", 31],
[3109253, "BUGRE", "MINAS GERAIS", "MG", 31],
[3109303, "BURITIS", "MINAS GERAIS", "MG", 31],
[3109402, "BURITIZEIRO", "MINAS GERAIS", "MG", 31],
[3109451, "CABECEIRA GRANDE", "MINAS GERAIS", "MG", 31],
[3109501, "CABO VERDE", "MINAS GERAIS", "MG", 31],
[3109600, "CACHOEIRA DA PRATA", "MINAS GERAIS", "MG", 31],
[3109709, "CACHOEIRA DE MINAS", "MINAS GERAIS", "MG", 31],
[3109808, "CACHOEIRA DOURADA", "MINAS GERAIS", "MG", 31],
[3109907, "CAETANOPOLIS", "MINAS GERAIS", "MG", 31],
[3110004, "CAETE", "MINAS GERAIS", "MG", 31],
[3110103, "CAIANA", "MINAS GERAIS", "MG", 31],
[3110202, "CAJURI", "MINAS GERAIS", "MG", 31],
[3110301, "CALDAS", "MINAS GERAIS", "MG", 31],
[3110400, "CAMACHO", "MINAS GERAIS", "MG", 31],
[3110509, "CAMANDUCAIA", "MINAS GERAIS", "MG", 31],
[3110608, "CAMBUI", "MINAS GERAIS", "MG", 31],
[3110707, "CAMBUQUIRA", "MINAS GERAIS", "MG", 31],
[3110806, "CAMPANARIO", "MINAS GERAIS", "MG", 31],
[3110905, "CAMPANHA", "MINAS GERAIS", "MG", 31],
[3111002, "CAMPESTRE", "MINAS GERAIS", "MG", 31],
[3111101, "CAMPINA VERDE", "MINAS GERAIS", "MG", 31],
[3111150, "CAMPO AZUL", "MINAS GERAIS", "MG", 31],
[3111200, "CAMPO BELO", "MINAS GERAIS", "MG", 31],
[3111309, "CAMPO DO MEIO", "MINAS GERAIS", "MG", 31],
[3111408, "CAMPO FLORIDO", "MINAS GERAIS", "MG", 31],
[3111507, "CAMPOS ALTOS", "MINAS GERAIS", "MG", 31],
[3111606, "CAMPOS GERAIS", "MINAS GERAIS", "MG", 31],
[3111705, "CANAA", "MINAS GERAIS", "MG", 31],
[3111804, "CANAPOLIS", "MINAS GERAIS", "MG", 31],
[3111903, "CANA VERDE", "MINAS GERAIS", "MG", 31],
[3112000, "CANDEIAS", "MINAS GERAIS", "MG", 31],
[3112059, "CANTAGALO", "MINAS GERAIS", "MG", 31],
[3112109, "CAPARAO", "MINAS GERAIS", "MG", 31],
[3112208, "CAPELA NOVA", "MINAS GERAIS", "MG", 31],
[3112307, "CAPELINHA", "MINAS GERAIS", "MG", 31],
[3112406, "CAPETINGA", "MINAS GERAIS", "MG", 31],
[3112505, "CAPIM BRANCO", "MINAS GERAIS", "MG", 31],
[3112604, "CAPINOPOLIS", "MINAS GERAIS", "MG", 31],
[3112653, "CAPITAO ANDRADE", "MINAS GERAIS", "MG", 31],
[3112703, "CAPITAO ENEAS", "MINAS GERAIS", "MG", 31],
[3112802, "CAPITOLIO", "MINAS GERAIS", "MG", 31],
[3112901, "CAPUTIRA", "MINAS GERAIS", "MG", 31],
[3113008, "CARAI", "MINAS GERAIS", "MG", 31],
[3113107, "CARANAIBA", "MINAS GERAIS", "MG", 31],
[3113206, "CARANDAI", "MINAS GERAIS", "MG", 31],
[3113305, "CARANGOLA", "MINAS GERAIS", "MG", 31],
[3113404, "CARATINGA", "MINAS GERAIS", "MG", 31],
[3113503, "CARBONITA", "MINAS GERAIS", "MG", 31],
[3113602, "CAREACU", "MINAS GERAIS", "MG", 31],
[3113701, "CARLOS CHAGAS", "MINAS GERAIS", "MG", 31],
[3113800, "CARMESIA", "MINAS GERAIS", "MG", 31],
[3113909, "CARMO DA CACHOEIRA", "MINAS GERAIS", "MG", 31],
[3114006, "CARMO DA MATA", "MINAS GERAIS", "MG", 31],
[3114105, "CARMO DE MINAS", "MINAS GERAIS", "MG", 31],
[3114204, "CARMO DO CAJURU", "MINAS GERAIS", "MG", 31],
[3114303, "CARMO DO PARANAIBA", "MINAS GERAIS", "MG", 31],
[3114402, "CARMO DO RIO CLARO", "MINAS GERAIS", "MG", 31],
[3114501, "CARMOPOLIS DE MINAS", "MINAS GERAIS", "MG", 31],
[3114550, "CARNEIRINHO", "MINAS GERAIS", "MG", 31],
[3114600, "CARRANCAS", "MINAS GERAIS", "MG", 31],
[3114709, "CARVALHOPOLIS", "MINAS GERAIS", "MG", 31],
[3114808, "CARVALHOS", "MINAS GERAIS", "MG", 31],
[3114907, "CASA GRANDE", "MINAS GERAIS", "MG", 31],
[3115003, "CASCALHO RICO", "MINAS GERAIS", "MG", 31],
[3115102, "CASSIA", "MINAS GERAIS", "MG", 31],
[3115201, "CONCEICAO DA BARRA DE MIN", "MINAS GERAIS", "MG", 31],
[3115300, "CATAGUASES", "MINAS GERAIS", "MG", 31],
[3115359, "CATAS ALTAS", "MINAS GERAIS", "MG", 31],
[3115409, "CATAS ALTAS DA NORUEGA", "MINAS GERAIS", "MG", 31],
[3115474, "CATUTI", "MINAS GERAIS", "MG", 31],
[3115508, "CAXAMBU", "MINAS GERAIS", "MG", 31],
[3115607, "CEDRO DO ABAETE", "MINAS GERAIS", "MG", 31],
[3115706, "CENTRAL DE MINAS", "MINAS GERAIS", "MG", 31],
[3115805, "CENTRALINA", "MINAS GERAIS", "MG", 31],
[3115904, "CHACARA", "MINAS GERAIS", "MG", 31],
[3116001, "CHALE", "MINAS GERAIS", "MG", 31],
[3116100, "CHAPADA DO NORTE", "MINAS GERAIS", "MG", 31],
[3116159, "CHAPADA GAUCHA", "MINAS GERAIS", "MG", 31],
[3116209, "CHIADOR", "MINAS GERAIS", "MG", 31],
[3116308, "CIPOTANEA", "MINAS GERAIS", "MG", 31],
[3116407, "CLARAVAL", "MINAS GERAIS", "MG", 31],
[3116506, "CLARO DOS POCÕES", "MINAS GERAIS", "MG", 31],
[3116605, "CLAUDIO", "MINAS GERAIS", "MG", 31],
[3116704, "COIMBRA", "MINAS GERAIS", "MG", 31],
[3116803, "COLUNA", "MINAS GERAIS", "MG", 31],
[3116902, "COMENDADOR GOMES", "MINAS GERAIS", "MG", 31],
[3117009, "COMERCINHO", "MINAS GERAIS", "MG", 31],
[3117108, "CONCEICAO DA APARECIDA", "MINAS GERAIS", "MG", 31],
[3117207, "CONCEICAO DAS PEDRAS", "MINAS GERAIS", "MG", 31],
[3117306, "CONCEICAO DAS ALAGOAS", "MINAS GERAIS", "MG", 31],
[3117405, "CONCEICAO DE IPANEMA", "MINAS GERAIS", "MG", 31],
[3117504, "CONCEICAO DO MATO DENTRO", "MINAS GERAIS", "MG", 31],
[3117603, "CONCEICAO DO PARA", "MINAS GERAIS", "MG", 31],
[3117702, "CONCEICAO DO RIO VERDE", "MINAS GERAIS", "MG", 31],
[3117801, "CONCEICAO DOS OUROS", "MINAS GERAIS", "MG", 31],
[3117836, "CONEGO MARINHO", "MINAS GERAIS", "MG", 31],
[3117876, "CONFINS", "MINAS GERAIS", "MG", 31],
[3118007, "CONGONHAS", "MINAS GERAIS", "MG", 31],
[3118106, "CONGONHAS DO NORTE", "MINAS GERAIS", "MG", 31],
[3118205, "CONQUISTA", "MINAS GERAIS", "MG", 31],
[3118304, "CONSELHEIRO LAFAIETE", "MINAS GERAIS", "MG", 31],
[3118403, "CONSELHEIRO PENA", "MINAS GERAIS", "MG", 31],
[3118502, "CONSOLACAO", "MINAS GERAIS", "MG", 31],
[3118601, "CONTAGEM", "MINAS GERAIS", "MG", 31],
[3118700, "COQUEIRAL", "MINAS GERAIS", "MG", 31],
[3118809, "CORACAO DE JESUS", "MINAS GERAIS", "MG", 31],
[3118908, "CORDISBURGO", "MINAS GERAIS", "MG", 31],
[3119005, "CORDISLANDIA", "MINAS GERAIS", "MG", 31],
[3119104, "CORINTO", "MINAS GERAIS", "MG", 31],
[3119203, "COROACI", "MINAS GERAIS", "MG", 31],
[3119302, "COROMANDEL", "MINAS GERAIS", "MG", 31],
[3119401, "CORONEL FABRICIANO", "MINAS GERAIS", "MG", 31],
[3119500, "CORONEL MURTA", "MINAS GERAIS", "MG", 31],
[3119609, "CORONEL PACHECO", "MINAS GERAIS", "MG", 31],
[3119708, "CORONEL XAVIER CHAVES", "MINAS GERAIS", "MG", 31],
[3119807, "CORREGO DANTA", "MINAS GERAIS", "MG", 31],
[3119906, "CORREGO DO BOM JESUS", "MINAS GERAIS", "MG", 31],
[3119955, "CORREGO FUNDO", "MINAS GERAIS", "MG", 31],
[3120003, "CORREGO NOVO", "MINAS GERAIS", "MG", 31],
[3120102, "COUTO DE MAGALHAES DE MIN", "MINAS GERAIS", "MG", 31],
[3120151, "CRISOLITA", "MINAS GERAIS", "MG", 31],
[3120201, "CRISTAIS", "MINAS GERAIS", "MG", 31],
[3120300, "CRISTALIA", "MINAS GERAIS", "MG", 31],
[3120409, "CRISTIANO OTONI", "MINAS GERAIS", "MG", 31],
[3120508, "CRISTINA", "MINAS GERAIS", "MG", 31],
[3120607, "CRUCILANDIA", "MINAS GERAIS", "MG", 31],
[3120706, "CRUZEIRO DA FORTALEZA", "MINAS GERAIS", "MG", 31],
[3120805, "CRUZILIA", "MINAS GERAIS", "MG", 31],
[3120839, "CUPARAQUE", "MINAS GERAIS", "MG", 31],
[3120870, "CURRAL DE DENTRO", "MINAS GERAIS", "MG", 31],
[3120904, "CURVELO", "MINAS GERAIS", "MG", 31],
[3121001, "DATAS", "MINAS GERAIS", "MG", 31],
[3121100, "DELFIM MOREIRA", "MINAS GERAIS", "MG", 31],
[3121209, "DELFINOPOLIS", "MINAS GERAIS", "MG", 31],
[3121258, "DELTA", "MINAS GERAIS", "MG", 31],
[3121308, "DESCOBERTO", "MINAS GERAIS", "MG", 31],
[3121407, "DESTERRO DE ENTRE RIOS", "MINAS GERAIS", "MG", 31],
[3121506, "DESTERRO DO MELO", "MINAS GERAIS", "MG", 31],
[3121605, "DIAMANTINA", "MINAS GERAIS", "MG", 31],
[3121704, "DIOGO DE VASCONCELOS", "MINAS GERAIS", "MG", 31],
[3121803, "DIONISIO", "MINAS GERAIS", "MG", 31],
[3121902, "DIVINESIA", "MINAS GERAIS", "MG", 31],
[3122009, "DIVINO", "MINAS GERAIS", "MG", 31],
[3122108, "DIVINO DAS LARANJEIRAS", "MINAS GERAIS", "MG", 31],
[3122207, "DIVINOLANDIA DE MINAS", "MINAS GERAIS", "MG", 31],
[3122306, "DIVINOPOLIS", "MINAS GERAIS", "MG", 31],
[3122355, "DIVISA ALEGRE", "MINAS GERAIS", "MG", 31],
[3122405, "DIVISA NOVA", "MINAS GERAIS", "MG", 31],
[3122454, "DIVISOPOLIS", "MINAS GERAIS", "MG", 31],
[3122470, "DOM BOSCO", "MINAS GERAIS", "MG", 31],
[3122504, "DOM CAVATI", "MINAS GERAIS", "MG", 31],
[3122603, "DOM JOAQUIM", "MINAS GERAIS", "MG", 31],
[3122702, "DOM SILVERIO", "MINAS GERAIS", "MG", 31],
[3122801, "DOM VICOSO", "MINAS GERAIS", "MG", 31],
[3122900, "DONA EUSEBIA", "MINAS GERAIS", "MG", 31],
[3123007, "DORES DE CAMPOS", "MINAS GERAIS", "MG", 31],
[3123106, "DORES DE GUANHAES", "MINAS GERAIS", "MG", 31],
[3123205, "DORES DO INDAIA", "MINAS GERAIS", "MG", 31],
[3123304, "DORES DO TURVO", "MINAS GERAIS", "MG", 31],
[3123403, "DORESOPOLIS", "MINAS GERAIS", "MG", 31],
[3123502, "DOURADOQUARA", "MINAS GERAIS", "MG", 31],
[3123528, "DURANDE", "MINAS GERAIS", "MG", 31],
[3123601, "ELOI MENDES", "MINAS GERAIS", "MG", 31],
[3123700, "ENGENHEIRO CALDAS", "MINAS GERAIS", "MG", 31],
[3123809, "ENGENHEIRO NAVARRO", "MINAS GERAIS", "MG", 31],
[3123858, "ENTRE FOLHAS", "MINAS GERAIS", "MG", 31],
[3123908, "ENTRE RIOS DE MINAS", "MINAS GERAIS", "MG", 31],
[3124005, "ERVALIA", "MINAS GERAIS", "MG", 31],
[3124104, "ESMERALDAS", "MINAS GERAIS", "MG", 31],
[3124203, "ESPERA FELIZ", "MINAS GERAIS", "MG", 31],
[3124302, "ESPINOSA", "MINAS GERAIS", "MG", 31],
[3124401, "ESPIRITO SANTO DO DOURADO", "MINAS GERAIS", "MG", 31],
[3124500, "ESTIVA", "MINAS GERAIS", "MG", 31],
[3124609, "ESTRELA DALVA", "MINAS GERAIS", "MG", 31],
[3124708, "ESTRELA DO INDAIA", "MINAS GERAIS", "MG", 31],
[3124807, "ESTRELA DO SUL", "MINAS GERAIS", "MG", 31],
[3124906, "EUGENOPOLIS", "MINAS GERAIS", "MG", 31],
[3125002, "EWBANK DA CAMARA", "MINAS GERAIS", "MG", 31],
[3125101, "EXTREMA", "MINAS GERAIS", "MG", 31],
[3125200, "FAMA", "MINAS GERAIS", "MG", 31],
[3125309, "FARIA LEMOS", "MINAS GERAIS", "MG", 31],
[3125408, "FELICIO DOS SANTOS", "MINAS GERAIS", "MG", 31],
[3125507, "SAO GONCALO DO RIO PRETO", "MINAS GERAIS", "MG", 31],
[3125606, "FELISBURGO", "MINAS GERAIS", "MG", 31],
[3125705, "FELIXLANDIA", "MINAS GERAIS", "MG", 31],
[3125804, "FERNANDES TOURINHO", "MINAS GERAIS", "MG", 31],
[3125903, "FERROS", "MINAS GERAIS", "MG", 31],
[3125952, "FERVEDOURO", "MINAS GERAIS", "MG", 31],
[3126000, "FLORESTAL", "MINAS GERAIS", "MG", 31],
[3126109, "FORMIGA", "MINAS GERAIS", "MG", 31],
[3126208, "FORMOSO", "MINAS GERAIS", "MG", 31],
[3126307, "FORTALEZA DE MINAS", "MINAS GERAIS", "MG", 31],
[3126406, "FORTUNA DE MINAS", "MINAS GERAIS", "MG", 31],
[3126505, "FRANCISCO BADARO", "MINAS GERAIS", "MG", 31],
[3126604, "FRANCISCO DUMONT", "MINAS GERAIS", "MG", 31],
[3126703, "FRANCISCO SA", "MINAS GERAIS", "MG", 31],
[3126752, "FRANCISCOPOLIS", "MINAS GERAIS", "MG", 31],
[3126802, "FREI GASPAR", "MINAS GERAIS", "MG", 31],
[3126901, "FREI INOCENCIO", "MINAS GERAIS", "MG", 31],
[3126950, "FREI LAGONEGRO", "MINAS GERAIS", "MG", 31],
[3127008, "FRONTEIRA", "MINAS GERAIS", "MG", 31],
[3127057, "FRONTEIRA DOS VALES", "MINAS GERAIS", "MG", 31],
[3127073, "FRUTA DE LEITE", "MINAS GERAIS", "MG", 31],
[3127107, "FRUTAL", "MINAS GERAIS", "MG", 31],
[3127206, "FUNILANDIA", "MINAS GERAIS", "MG", 31],
[3127305, "GALILEIA", "MINAS GERAIS", "MG", 31],
[3127339, "GAMELEIRAS", "MINAS GERAIS", "MG", 31],
[3127354, "GLAUCILANDIA", "MINAS GERAIS", "MG", 31],
[3127370, "GOIABEIRA", "MINAS GERAIS", "MG", 31],
[3127388, "GOIANA", "MINAS GERAIS", "MG", 31],
[3127404, "GONCALVES", "MINAS GERAIS", "MG", 31],
[3127503, "GONZAGA", "MINAS GERAIS", "MG", 31],
[3127602, "GOUVEIA", "MINAS GERAIS", "MG", 31],
[3127701, "GOVERNADOR VALADARES", "MINAS GERAIS", "MG", 31],
[3127800, "GRAO MOGOL", "MINAS GERAIS", "MG", 31],
[3127909, "GRUPIARA", "MINAS GERAIS", "MG", 31],
[3128006, "GUANHAES", "MINAS GERAIS", "MG", 31],
[3128105, "GUAPE", "MINAS GERAIS", "MG", 31],
[3128253, "GUARACIAMA", "MINAS GERAIS", "MG", 31],
[3128303, "GUARANESIA", "MINAS GERAIS", "MG", 31],
[3128402, "GUARANI", "MINAS GERAIS", "MG", 31],
[3128501, "GUARARA", "MINAS GERAIS", "MG", 31],
[3128600, "GUARDA-MOR", "MINAS GERAIS", "MG", 31],
[3128709, "GUAXUPE", "MINAS GERAIS", "MG", 31],
[3128808, "GUIDOVAL", "MINAS GERAIS", "MG", 31],
[3128907, "GUIMARANIA", "MINAS GERAIS", "MG", 31],
[3129004, "GUIRICEMA", "MINAS GERAIS", "MG", 31],
[3129103, "GURINHATA", "MINAS GERAIS", "MG", 31],
[3129202, "HELIODORA", "MINAS GERAIS", "MG", 31],
[3129301, "IAPU", "MINAS GERAIS", "MG", 31],
[3129400, "IBERTIOGA", "MINAS GERAIS", "MG", 31],
[3129608, "IBIAI", "MINAS GERAIS", "MG", 31],
[3129657, "IBIRACATU", "MINAS GERAIS", "MG", 31],
[3129707, "IBIRACI", "MINAS GERAIS", "MG", 31],
[3129806, "IBIRITE", "MINAS GERAIS", "MG", 31],
[3129905, "IBITIURA DE MINAS", "MINAS GERAIS", "MG", 31],
[3130002, "IBITURUNA", "MINAS GERAIS", "MG", 31],
[3130051, "ICARAI DE MINAS", "MINAS GERAIS", "MG", 31],
[3130101, "IGARAPE", "MINAS GERAIS", "MG", 31],
[3130200, "IGARATINGA", "MINAS GERAIS", "MG", 31],
[3130309, "IGUATAMA", "MINAS GERAIS", "MG", 31],
[3130408, "IJACI", "MINAS GERAIS", "MG", 31],
[3130507, "ILICINEA", "MINAS GERAIS", "MG", 31],
[3130556, "IMBE DE MINAS", "MINAS GERAIS", "MG", 31],
[3130606, "INCONFIDENTES", "MINAS GERAIS", "MG", 31],
[3130655, "INDAIABIRA", "MINAS GERAIS", "MG", 31],
[3130705, "INDIANOPOLIS", "MINAS GERAIS", "MG", 31],
[3130804, "INGAI", "MINAS GERAIS", "MG", 31],
[3130903, "INHAPIM", "MINAS GERAIS", "MG", 31],
[3131000, "INHAUMA", "MINAS GERAIS", "MG", 31],
[3131109, "INIMUTABA", "MINAS GERAIS", "MG", 31],
[3131158, "IPABA", "MINAS GERAIS", "MG", 31],
[3131208, "IPANEMA", "MINAS GERAIS", "MG", 31],
[3131307, "IPATINGA", "MINAS GERAIS", "MG", 31],
[3131406, "IPIACU", "MINAS GERAIS", "MG", 31],
[3131505, "IPUIUNA", "MINAS GERAIS", "MG", 31],
[3131604, "IRAI DE MINAS", "MINAS GERAIS", "MG", 31],
[3131703, "ITABIRA", "MINAS GERAIS", "MG", 31],
[3131802, "ITABIRINHA", "MINAS GERAIS", "MG", 31],
[3131901, "ITABIRITO", "MINAS GERAIS", "MG", 31],
[3132008, "ITACAMBIRA", "MINAS GERAIS", "MG", 31],
[3132107, "ITACARAMBI", "MINAS GERAIS", "MG", 31],
[3132206, "ITAGUARA", "MINAS GERAIS", "MG", 31],
[3132305, "ITAIPE", "MINAS GERAIS", "MG", 31],
[3132404, "ITAJUBA", "MINAS GERAIS", "MG", 31],
[3132503, "ITAMARANDIBA", "MINAS GERAIS", "MG", 31],
[3132602, "ITAMARATI DE MINAS", "MINAS GERAIS", "MG", 31],
[3132701, "ITAMBACURI", "MINAS GERAIS", "MG", 31],
[3132800, "ITAMBE DO MATO DENTRO", "MINAS GERAIS", "MG", 31],
[3132909, "ITAMOGI", "MINAS GERAIS", "MG", 31],
[3133006, "ITAMONTE", "MINAS GERAIS", "MG", 31],
[3133105, "ITANHANDU", "MINAS GERAIS", "MG", 31],
[3133204, "ITANHOMI", "MINAS GERAIS", "MG", 31],
[3133303, "ITAOBIM", "MINAS GERAIS", "MG", 31],
[3133402, "ITAPAGIPE", "MINAS GERAIS", "MG", 31],
[3133501, "ITAPECERICA", "MINAS GERAIS", "MG", 31],
[3133600, "ITAPEVA", "MINAS GERAIS", "MG", 31],
[3133709, "ITATIAIUCU", "MINAS GERAIS", "MG", 31],
[3133758, "ITAU DE MINAS", "MINAS GERAIS", "MG", 31],
[3133808, "ITAUNA", "MINAS GERAIS", "MG", 31],
[3133907, "ITAVERAVA", "MINAS GERAIS", "MG", 31],
[3134004, "ITINGA", "MINAS GERAIS", "MG", 31],
[3134103, "ITUETA", "MINAS GERAIS", "MG", 31],
[3134202, "ITUIUTABA", "MINAS GERAIS", "MG", 31],
[3134301, "ITUMIRIM", "MINAS GERAIS", "MG", 31],
[3134400, "ITURAMA", "MINAS GERAIS", "MG", 31],
[3134509, "ITUTINGA", "MINAS GERAIS", "MG", 31],
[3134608, "JABOTICATUBAS", "MINAS GERAIS", "MG", 31],
[3134707, "JACINTO", "MINAS GERAIS", "MG", 31],
[3134806, "JACUI", "MINAS GERAIS", "MG", 31],
[3134905, "JACUTINGA", "MINAS GERAIS", "MG", 31],
[3135001, "JAGUARACU", "MINAS GERAIS", "MG", 31],
[3135050, "JAIBA", "MINAS GERAIS", "MG", 31],
[3135076, "JAMPRUCA", "MINAS GERAIS", "MG", 31],
[3135100, "JANAUBA", "MINAS GERAIS", "MG", 31],
[3135209, "JANUARIA", "MINAS GERAIS", "MG", 31],
[3135308, "JAPARAIBA", "MINAS GERAIS", "MG", 31],
[3135357, "JAPONVAR", "MINAS GERAIS", "MG", 31],
[3135407, "JECEABA", "MINAS GERAIS", "MG", 31],
[3135456, "JENIPAPO DE MINAS", "MINAS GERAIS", "MG", 31],
[3135506, "JEQUERI", "MINAS GERAIS", "MG", 31],
[3135605, "JEQUITAI", "MINAS GERAIS", "MG", 31],
[3135704, "JEQUITIBA", "MINAS GERAIS", "MG", 31],
[3135803, "JEQUITINHONHA", "MINAS GERAIS", "MG", 31],
[3135902, "JESUANIA", "MINAS GERAIS", "MG", 31],
[3136009, "JOAIMA", "MINAS GERAIS", "MG", 31],
[3136108, "JOANESIA", "MINAS GERAIS", "MG", 31],
[3136207, "JOAO MONLEVADE", "MINAS GERAIS", "MG", 31],
[3136306, "JOAO PINHEIRO", "MINAS GERAIS", "MG", 31],
[3136405, "JOAQUIM FELICIO", "MINAS GERAIS", "MG", 31],
[3136504, "JORDANIA", "MINAS GERAIS", "MG", 31],
[3136520, "JOSE GONCALVES DE MINAS", "MINAS GERAIS", "MG", 31],
[3136553, "JOSE RAYDAN", "MINAS GERAIS", "MG", 31],
[3136579, "JOSENOPOLIS", "MINAS GERAIS", "MG", 31],
[3136603, "NOVA UNIAO", "MINAS GERAIS", "MG", 31],
[3136652, "JUATUBA", "MINAS GERAIS", "MG", 31],
[3136702, "JUIZ DE FORA", "MINAS GERAIS", "MG", 31],
[3136801, "JURAMENTO", "MINAS GERAIS", "MG", 31],
[3136900, "JURUAIA", "MINAS GERAIS", "MG", 31],
[3136959, "JUVENILIA", "MINAS GERAIS", "MG", 31],
[3137007, "LADAINHA", "MINAS GERAIS", "MG", 31],
[3137106, "LAGAMAR", "MINAS GERAIS", "MG", 31],
[3137205, "LAGOA DA PRATA", "MINAS GERAIS", "MG", 31],
[3137304, "LAGOA DOS PATOS", "MINAS GERAIS", "MG", 31],
[3137403, "LAGOA DOURADA", "MINAS GERAIS", "MG", 31],
[3137502, "LAGOA FORMOSA", "MINAS GERAIS", "MG", 31],
[3137536, "LAGOA GRANDE", "MINAS GERAIS", "MG", 31],
[3137601, "LAGOA SANTA", "MINAS GERAIS", "MG", 31],
[3137700, "LAJINHA", "MINAS GERAIS", "MG", 31],
[3137809, "LAMBARI", "MINAS GERAIS", "MG", 31],
[3137908, "LAMIM", "MINAS GERAIS", "MG", 31],
[3138005, "LARANJAL", "MINAS GERAIS", "MG", 31],
[3138104, "LASSANCE", "MINAS GERAIS", "MG", 31],
[3138203, "LAVRAS", "MINAS GERAIS", "MG", 31],
[3138302, "LEANDRO FERREIRA", "MINAS GERAIS", "MG", 31],
[3138351, "LEME DO PRADO", "MINAS GERAIS", "MG", 31],
[3138401, "LEOPOLDINA", "MINAS GERAIS", "MG", 31],
[3138500, "LIBERDADE", "MINAS GERAIS", "MG", 31],
[3138609, "LIMA DUARTE", "MINAS GERAIS", "MG", 31],
[3138625, "LIMEIRA DO OESTE", "MINAS GERAIS", "MG", 31],
[3138658, "LONTRA", "MINAS GERAIS", "MG", 31],
[3138674, "LUISBURGO", "MINAS GERAIS", "MG", 31],
[3138708, "LUMINARIAS", "MINAS GERAIS", "MG", 31],
[3138807, "LUZ", "MINAS GERAIS", "MG", 31],
[3138906, "MACHACALIS", "MINAS GERAIS", "MG", 31],
[3139003, "MACHADO", "MINAS GERAIS", "MG", 31],
[3139102, "MADRE DE DEUS DE MINAS", "MINAS GERAIS", "MG", 31],
[3139201, "MALACACHETA", "MINAS GERAIS", "MG", 31],
[3139250, "MAMONAS", "MINAS GERAIS", "MG", 31],
[3139300, "MANGA", "MINAS GERAIS", "MG", 31],
[3139409, "MANHUACU", "MINAS GERAIS", "MG", 31],
[3139508, "MANHUMIRIM", "MINAS GERAIS", "MG", 31],
[3139607, "MANTENA", "MINAS GERAIS", "MG", 31],
[3139706, "MARAVILHAS", "MINAS GERAIS", "MG", 31],
[3139805, "MAR DE ESPANHA", "MINAS GERAIS", "MG", 31],
[3139904, "MARIA DA FE", "MINAS GERAIS", "MG", 31],
[3140001, "MARIANA", "MINAS GERAIS", "MG", 31],
[3140100, "MARILAC", "MINAS GERAIS", "MG", 31],
[3140159, "MARIO CAMPOS", "MINAS GERAIS", "MG", 31],
[3140209, "MARIPA DE MINAS", "MINAS GERAIS", "MG", 31],
[3140308, "MARLIERIA", "MINAS GERAIS", "MG", 31],
[3140407, "MARMELOPOLIS", "MINAS GERAIS", "MG", 31],
[3140506, "MARTINHO CAMPOS", "MINAS GERAIS", "MG", 31],
[3140530, "MARTINS SOARES", "MINAS GERAIS", "MG", 31],
[3140555, "MATA VERDE", "MINAS GERAIS", "MG", 31],
[3140605, "MATERLANDIA", "MINAS GERAIS", "MG", 31],
[3140704, "MATEUS LEME", "MINAS GERAIS", "MG", 31],
[3140803, "MATIAS BARBOSA", "MINAS GERAIS", "MG", 31],
[3140852, "MATIAS CARDOSO", "MINAS GERAIS", "MG", 31],
[3140902, "MATIPO", "MINAS GERAIS", "MG", 31],
[3141009, "MATO VERDE", "MINAS GERAIS", "MG", 31],
[3141108, "MATOZINHOS", "MINAS GERAIS", "MG", 31],
[3141207, "MATUTINA", "MINAS GERAIS", "MG", 31],
[3141306, "MEDEIROS", "MINAS GERAIS", "MG", 31],
[3141405, "MEDINA", "MINAS GERAIS", "MG", 31],
[3141504, "MENDES PIMENTEL", "MINAS GERAIS", "MG", 31],
[3141603, "MERCES", "MINAS GERAIS", "MG", 31],
[3141702, "MESQUITA", "MINAS GERAIS", "MG", 31],
[3141801, "MINAS NOVAS", "MINAS GERAIS", "MG", 31],
[3141900, "MINDURI", "MINAS GERAIS", "MG", 31],
[3142007, "MIRABELA", "MINAS GERAIS", "MG", 31],
[3142106, "MIRADOURO", "MINAS GERAIS", "MG", 31],
[3142205, "MIRAI", "MINAS GERAIS", "MG", 31],
[3142254, "MIRAVANIA", "MINAS GERAIS", "MG", 31],
[3142403, "MOEMA", "MINAS GERAIS", "MG", 31],
[3142502, "MONJOLOS", "MINAS GERAIS", "MG", 31],
[3142601, "MONSENHOR PAULO", "MINAS GERAIS", "MG", 31],
[3142700, "MONTALVANIA", "MINAS GERAIS", "MG", 31],
[3142809, "MONTE ALEGRE DE MINAS", "MINAS GERAIS", "MG", 31],
[3142908, "MONTE AZUL", "MINAS GERAIS", "MG", 31],
[3143005, "MONTE BELO", "MINAS GERAIS", "MG", 31],
[3143104, "MONTE CARMELO", "MINAS GERAIS", "MG", 31],
[3143153, "MONTE FORMOSO", "MINAS GERAIS", "MG", 31],
[3143203, "MONTE SANTO DE MINAS", "MINAS GERAIS", "MG", 31],
[3143302, "MONTES CLAROS", "MINAS GERAIS", "MG", 31],
[3143401, "MONTE SIAO", "MINAS GERAIS", "MG", 31],
[3143450, "MONTEZUMA", "MINAS GERAIS", "MG", 31],
[3143500, "MORADA NOVA DE MINAS", "MINAS GERAIS", "MG", 31],
[3143609, "MORRO DA GARCA", "MINAS GERAIS", "MG", 31],
[3143708, "MORRO DO PILAR", "MINAS GERAIS", "MG", 31],
[3143807, "MUNHOZ", "MINAS GERAIS", "MG", 31],
[3143906, "MURIAE", "MINAS GERAIS", "MG", 31],
[3144003, "MUTUM", "MINAS GERAIS", "MG", 31],
[3144102, "MUZAMBINHO", "MINAS GERAIS", "MG", 31],
[3144201, "NACIP RAYDAN", "MINAS GERAIS", "MG", 31],
[3144300, "NANUQUE", "MINAS GERAIS", "MG", 31],
[3144359, "NAQUE", "MINAS GERAIS", "MG", 31],
[3144375, "NATALANDIA", "MINAS GERAIS", "MG", 31],
[3144409, "NATERCIA", "MINAS GERAIS", "MG", 31],
[3144508, "NAZARENO", "MINAS GERAIS", "MG", 31],
[3144607, "NEPOMUCENO", "MINAS GERAIS", "MG", 31],
[3144656, "NINHEIRA", "MINAS GERAIS", "MG", 31],
[3144672, "NOVA BELEM", "MINAS GERAIS", "MG", 31],
[3144706, "NOVA ERA", "MINAS GERAIS", "MG", 31],
[3144805, "NOVA LIMA", "MINAS GERAIS", "MG", 31],
[3144904, "NOVA MODICA", "MINAS GERAIS", "MG", 31],
[3145000, "NOVA PONTE", "MINAS GERAIS", "MG", 31],
[3145059, "NOVA PORTEIRINHA", "MINAS GERAIS", "MG", 31],
[3145109, "NOVA RESENDE", "MINAS GERAIS", "MG", 31],
[3145208, "NOVA SERRANA", "MINAS GERAIS", "MG", 31],
[3145307, "NOVO CRUZEIRO", "MINAS GERAIS", "MG", 31],
[3145356, "NOVO ORIENTE DE MINAS", "MINAS GERAIS", "MG", 31],
[3145372, "NOVORIZONTE", "MINAS GERAIS", "MG", 31],
[3145406, "OLARIA", "MINAS GERAIS", "MG", 31],
[3145455, "OLHOS-D'AGUA", "MINAS GERAIS", "MG", 31],
[3145505, "OLIMPIO NORONHA", "MINAS GERAIS", "MG", 31],
[3145604, "OLIVEIRA", "MINAS GERAIS", "MG", 31],
[3145703, "OLIVEIRA FORTES", "MINAS GERAIS", "MG", 31],
[3145802, "ONCA DE PITANGUI", "MINAS GERAIS", "MG", 31],
[3145851, "ORATORIOS", "MINAS GERAIS", "MG", 31],
[3145877, "ORIZANIA", "MINAS GERAIS", "MG", 31],
[3145901, "OURO BRANCO", "MINAS GERAIS", "MG", 31],
[3146008, "OURO FINO", "MINAS GERAIS", "MG", 31],
[3146107, "OURO PRETO", "MINAS GERAIS", "MG", 31],
[3146206, "OURO VERDE DE MINAS", "MINAS GERAIS", "MG", 31],
[3146255, "PADRE CARVALHO", "MINAS GERAIS", "MG", 31],
[3146305, "PADRE PARAISO", "MINAS GERAIS", "MG", 31],
[3146404, "PAINEIRAS", "MINAS GERAIS", "MG", 31],
[3146503, "PAINS", "MINAS GERAIS", "MG", 31],
[3146552, "PAI PEDRO", "MINAS GERAIS", "MG", 31],
[3146602, "PAIVA", "MINAS GERAIS", "MG", 31],
[3146701, "PALMA", "MINAS GERAIS", "MG", 31],
[3146750, "PALMOPOLIS", "MINAS GERAIS", "MG", 31],
[3146909, "PAPAGAIOS", "MINAS GERAIS", "MG", 31],
[3147006, "PARACATU", "MINAS GERAIS", "MG", 31],
[3147105, "PARA DE MINAS", "MINAS GERAIS", "MG", 31],
[3147204, "PARAGUACU", "MINAS GERAIS", "MG", 31],
[3147303, "PARAISOPOLIS", "MINAS GERAIS", "MG", 31],
[3147402, "PARAOPEBA", "MINAS GERAIS", "MG", 31],
[3147501, "PASSABEM", "MINAS GERAIS", "MG", 31],
[3147600, "PASSA QUATRO", "MINAS GERAIS", "MG", 31],
[3147709, "PASSA TEMPO", "MINAS GERAIS", "MG", 31],
[3147808, "PASSA-VINTE", "MINAS GERAIS", "MG", 31],
[3147907, "PASSOS", "MINAS GERAIS", "MG", 31],
[3147956, "PATIS", "MINAS GERAIS", "MG", 31],
[3148004, "PATOS DE MINAS", "MINAS GERAIS", "MG", 31],
[3148103, "PATROCINIO", "MINAS GERAIS", "MG", 31],
[3148202, "PATROCINIO DO MURIAE", "MINAS GERAIS", "MG", 31],
[3148301, "PAULA CANDIDO", "MINAS GERAIS", "MG", 31],
[3148400, "PAULISTAS", "MINAS GERAIS", "MG", 31],
[3148509, "PAVAO", "MINAS GERAIS", "MG", 31],
[3148608, "PECANHA", "MINAS GERAIS", "MG", 31],
[3148707, "PEDRA AZUL", "MINAS GERAIS", "MG", 31],
[3148756, "PEDRA BONITA", "MINAS GERAIS", "MG", 31],
[3148806, "PEDRA DO ANTA", "MINAS GERAIS", "MG", 31],
[3148905, "PEDRA DO INDAIA", "MINAS GERAIS", "MG", 31],
[3149002, "PEDRA DOURADA", "MINAS GERAIS", "MG", 31],
[3149101, "PEDRALVA", "MINAS GERAIS", "MG", 31],
[3149150, "PEDRAS DE MARIA DA CRUZ", "MINAS GERAIS", "MG", 31],
[3149200, "PEDRINOPOLIS", "MINAS GERAIS", "MG", 31],
[3149309, "PEDRO LEOPOLDO", "MINAS GERAIS", "MG", 31],
[3149408, "PEDRO TEIXEIRA", "MINAS GERAIS", "MG", 31],
[3149507, "PEQUERI", "MINAS GERAIS", "MG", 31],
[3149606, "PEQUI", "MINAS GERAIS", "MG", 31],
[3149705, "PERDIGAO", "MINAS GERAIS", "MG", 31],
[3149804, "PERDIZES", "MINAS GERAIS", "MG", 31],
[3149903, "PERDÕES", "MINAS GERAIS", "MG", 31],
[3149952, "PERIQUITO", "MINAS GERAIS", "MG", 31],
[3150000, "PESCADOR", "MINAS GERAIS", "MG", 31],
[3150109, "PIAU", "MINAS GERAIS", "MG", 31],
[3150158, "PIEDADE DE CARATINGA", "MINAS GERAIS", "MG", 31],
[3150208, "PIEDADE DE PONTE NOVA", "MINAS GERAIS", "MG", 31],
[3150307, "PIEDADE DO RIO GRANDE", "MINAS GERAIS", "MG", 31],
[3150406, "PIEDADE DOS GERAIS", "MINAS GERAIS", "MG", 31],
[3150505, "PIMENTA", "MINAS GERAIS", "MG", 31],
[3150539, "PINGO-D'AGUA", "MINAS GERAIS", "MG", 31],
[3150570, "PINTOPOLIS", "MINAS GERAIS", "MG", 31],
[3150604, "PIRACEMA", "MINAS GERAIS", "MG", 31],
[3150703, "PIRAJUBA", "MINAS GERAIS", "MG", 31],
[3150802, "PIRANGA", "MINAS GERAIS", "MG", 31],
[3150901, "PIRANGUCU", "MINAS GERAIS", "MG", 31],
[3151008, "PIRANGUINHO", "MINAS GERAIS", "MG", 31],
[3151107, "PIRAPETINGA", "MINAS GERAIS", "MG", 31],
[3151206, "PIRAPORA", "MINAS GERAIS", "MG", 31],
[3151305, "PIRAUBA", "MINAS GERAIS", "MG", 31],
[3151404, "PITANGUI", "MINAS GERAIS", "MG", 31],
[3151503, "PIUMHI", "MINAS GERAIS", "MG", 31],
[3151602, "PLANURA", "MINAS GERAIS", "MG", 31],
[3151701, "POCO FUNDO", "MINAS GERAIS", "MG", 31],
[3151800, "POCOS DE CALDAS", "MINAS GERAIS", "MG", 31],
[3151909, "POCRANE", "MINAS GERAIS", "MG", 31],
[3152006, "POMPEU", "MINAS GERAIS", "MG", 31],
[3152105, "PONTE NOVA", "MINAS GERAIS", "MG", 31],
[3152131, "PONTO CHIQUE", "MINAS GERAIS", "MG", 31],
[3152170, "PONTO DOS VOLANTES", "MINAS GERAIS", "MG", 31],
[3152204, "PORTEIRINHA", "MINAS GERAIS", "MG", 31],
[3152303, "PORTO FIRME", "MINAS GERAIS", "MG", 31],
[3152402, "POTE", "MINAS GERAIS", "MG", 31],
[3152501, "POUSO ALEGRE", "MINAS GERAIS", "MG", 31],
[3152600, "POUSO ALTO", "MINAS GERAIS", "MG", 31],
[3152709, "PRADOS", "MINAS GERAIS", "MG", 31],
[3152808, "PRATA", "MINAS GERAIS", "MG", 31],
[3152907, "PRATAPOLIS", "MINAS GERAIS", "MG", 31],
[3153004, "PRATINHA", "MINAS GERAIS", "MG", 31],
[3153103, "PRESIDENTE BERNARDES", "MINAS GERAIS", "MG", 31],
[3153202, "PRESIDENTE JUSCELINO", "MINAS GERAIS", "MG", 31],
[3153301, "PRESIDENTE KUBITSCHEK", "MINAS GERAIS", "MG", 31],
[3153400, "PRESIDENTE OLEGARIO", "MINAS GERAIS", "MG", 31],
[3153509, "ALTO JEQUITIBA", "MINAS GERAIS", "MG", 31],
[3153608, "PRUDENTE DE MORAIS", "MINAS GERAIS", "MG", 31],
[3153707, "QUARTEL GERAL", "MINAS GERAIS", "MG", 31],
[3153806, "QUELUZITO", "MINAS GERAIS", "MG", 31],
[3153905, "RAPOSOS", "MINAS GERAIS", "MG", 31],
[3154002, "RAUL SOARES", "MINAS GERAIS", "MG", 31],
[3154101, "RECREIO", "MINAS GERAIS", "MG", 31],
[3154150, "REDUTO", "MINAS GERAIS", "MG", 31],
[3154200, "RESENDE COSTA", "MINAS GERAIS", "MG", 31],
[3154309, "RESPLENDOR", "MINAS GERAIS", "MG", 31],
[3154408, "RESSAQUINHA", "MINAS GERAIS", "MG", 31],
[3154457, "RIACHINHO", "MINAS GERAIS", "MG", 31],
[3154507, "RIACHO DOS MACHADOS", "MINAS GERAIS", "MG", 31],
[3154606, "RIBEIRAO DAS NEVES", "MINAS GERAIS", "MG", 31],
[3154705, "RIBEIRAO VERMELHO", "MINAS GERAIS", "MG", 31],
[3154804, "RIO ACIMA", "MINAS GERAIS", "MG", 31],
[3154903, "RIO CASCA", "MINAS GERAIS", "MG", 31],
[3155009, "RIO DOCE", "MINAS GERAIS", "MG", 31],
[3155108, "RIO DO PRADO", "MINAS GERAIS", "MG", 31],
[3155207, "RIO ESPERA", "MINAS GERAIS", "MG", 31],
[3155306, "RIO MANSO", "MINAS GERAIS", "MG", 31],
[3155405, "RIO NOVO", "MINAS GERAIS", "MG", 31],
[3155504, "RIO PARANAIBA", "MINAS GERAIS", "MG", 31],
[3155603, "RIO PARDO DE MINAS", "MINAS GERAIS", "MG", 31],
[3155702, "RIO PIRACICABA", "MINAS GERAIS", "MG", 31],
[3155801, "RIO POMBA", "MINAS GERAIS", "MG", 31],
[3155900, "RIO PRETO", "MINAS GERAIS", "MG", 31],
[3156007, "RIO VERMELHO", "MINAS GERAIS", "MG", 31],
[3156106, "RITAPOLIS", "MINAS GERAIS", "MG", 31],
[3156205, "ROCHEDO DE MINAS", "MINAS GERAIS", "MG", 31],
[3156304, "RODEIRO", "MINAS GERAIS", "MG", 31],
[3156403, "ROMARIA", "MINAS GERAIS", "MG", 31],
[3156452, "ROSARIO DA LIMEIRA", "MINAS GERAIS", "MG", 31],
[3156502, "RUBELITA", "MINAS GERAIS", "MG", 31],
[3156601, "RUBIM", "MINAS GERAIS", "MG", 31],
[3156700, "SABARA", "MINAS GERAIS", "MG", 31],
[3156809, "SABINOPOLIS", "MINAS GERAIS", "MG", 31],
[3156908, "SACRAMENTO", "MINAS GERAIS", "MG", 31],
[3157005, "SALINAS", "MINAS GERAIS", "MG", 31],
[3157104, "SALTO DA DIVISA", "MINAS GERAIS", "MG", 31],
[3157203, "SANTA BARBARA", "MINAS GERAIS", "MG", 31],
[3157252, "SANTA BARBARA DO LESTE", "MINAS GERAIS", "MG", 31],
[3157278, "SANTA BARBARA DO MONTE VE", "MINAS GERAIS", "MG", 31],
[3157302, "SANTA BARBARA DO TUGURIO", "MINAS GERAIS", "MG", 31],
[3157336, "SANTA CRUZ DE MINAS", "MINAS GERAIS", "MG", 31],
[3157377, "SANTA CRUZ DE SALINAS", "MINAS GERAIS", "MG", 31],
[3157401, "SANTA CRUZ DO ESCALVADO", "MINAS GERAIS", "MG", 31],
[3157500, "SANTA EFIGENIA DE MINAS", "MINAS GERAIS", "MG", 31],
[3157609, "SANTA FE DE MINAS", "MINAS GERAIS", "MG", 31],
[3157658, "SANTA HELENA DE MINAS", "MINAS GERAIS", "MG", 31],
[3157708, "SANTA JULIANA", "MINAS GERAIS", "MG", 31],
[3157807, "SANTA LUZIA", "MINAS GERAIS", "MG", 31],
[3157906, "SANTA MARGARIDA", "MINAS GERAIS", "MG", 31],
[3158003, "SANTA MARIA DE ITABIRA", "MINAS GERAIS", "MG", 31],
[3158102, "SANTA MARIA DO SALTO", "MINAS GERAIS", "MG", 31],
[3158201, "SANTA MARIA DO SUACUI", "MINAS GERAIS", "MG", 31],
[3158300, "SANTANA DA VARGEM", "MINAS GERAIS", "MG", 31],
[3158409, "SANTANA DE CATAGUASES", "MINAS GERAIS", "MG", 31],
[3158508, "SANTANA DE PIRAPAMA", "MINAS GERAIS", "MG", 31],
[3158607, "SANTANA DO DESERTO", "MINAS GERAIS", "MG", 31],
[3158706, "SANTANA DO GARAMBEU", "MINAS GERAIS", "MG", 31],
[3158805, "SANTANA DO JACARE", "MINAS GERAIS", "MG", 31],
[3158904, "SANTANA DO MANHUACU", "MINAS GERAIS", "MG", 31],
[3158953, "SANTANA DO PARAISO", "MINAS GERAIS", "MG", 31],
[3159001, "SANTANA DO RIACHO", "MINAS GERAIS", "MG", 31],
[3159100, "SANTANA DOS MONTES", "MINAS GERAIS", "MG", 31],
[3159209, "SANTA RITA DE CALDAS", "MINAS GERAIS", "MG", 31],
[3159308, "SANTA RITA DE JACUTINGA", "MINAS GERAIS", "MG", 31],
[3159357, "SANTA RITA DE MINAS", "MINAS GERAIS", "MG", 31],
[3159407, "SANTA RITA DE IBITIPOCA", "MINAS GERAIS", "MG", 31],
[3159506, "SANTA RITA DO ITUETO", "MINAS GERAIS", "MG", 31],
[3159605, "SANTA RITA DO SAPUCAI", "MINAS GERAIS", "MG", 31],
[3159704, "SANTA ROSA DA SERRA", "MINAS GERAIS", "MG", 31],
[3159803, "SANTA VITORIA", "MINAS GERAIS", "MG", 31],
[3159902, "SANTO ANTONIO DO AMPARO", "MINAS GERAIS", "MG", 31],
[3160009, "SANTO ANTONIO DO AVENTURE", "MINAS GERAIS", "MG", 31],
[3160108, "SANTO ANTONIO DO GRAMA", "MINAS GERAIS", "MG", 31],
[3160207, "SANTO ANTONIO DO ITAMBE", "MINAS GERAIS", "MG", 31],
[3160306, "SANTO ANTONIO DO JACINTO", "MINAS GERAIS", "MG", 31],
[3160405, "SANTO ANTONIO DO MONTE", "MINAS GERAIS", "MG", 31],
[3160454, "SANTO ANTONIO DO RETIRO", "MINAS GERAIS", "MG", 31],
[3160504, "SANTO ANTONIO DO RIO ABAI", "MINAS GERAIS", "MG", 31],
[3160603, "SANTO HIPOLITO", "MINAS GERAIS", "MG", 31],
[3160702, "SANTOS DUMONT", "MINAS GERAIS", "MG", 31],
[3160801, "SAO BENTO ABADE", "MINAS GERAIS", "MG", 31],
[3160900, "SAO BRAS DO SUACUI", "MINAS GERAIS", "MG", 31],
[3160959, "SAO DOMINGOS DAS DORES", "MINAS GERAIS", "MG", 31],
[3161007, "SAO DOMINGOS DO PRATA", "MINAS GERAIS", "MG", 31],
[3161056, "SAO FELIX DE MINAS", "MINAS GERAIS", "MG", 31],
[3161106, "SAO FRANCISCO", "MINAS GERAIS", "MG", 31],
[3161205, "SAO FRANCISCO DE PAULA", "MINAS GERAIS", "MG", 31],
[3161304, "SAO FRANCISCO DE SALES", "MINAS GERAIS", "MG", 31],
[3161403, "SAO FRANCISCO DO GLORIA", "MINAS GERAIS", "MG", 31],
[3161502, "SAO GERALDO", "MINAS GERAIS", "MG", 31],
[3161601, "SAO GERALDO DA PIEDADE", "MINAS GERAIS", "MG", 31],
[3161650, "SAO GERALDO DO BAIXIO", "MINAS GERAIS", "MG", 31],
[3161700, "SAO GONCALO DO ABAETE", "MINAS GERAIS", "MG", 31],
[3161809, "SAO GONCALO DO PARA", "MINAS GERAIS", "MG", 31],
[3161908, "SAO GONCALO DO RIO ABAIXO", "MINAS GERAIS", "MG", 31],
[3162005, "SAO GONCALO DO SAPUCAI", "MINAS GERAIS", "MG", 31],
[3162104, "SAO GOTARDO", "MINAS GERAIS", "MG", 31],
[3162203, "SAO JOAO BATISTA DO GLORI", "MINAS GERAIS", "MG", 31],
[3162252, "SAO JOAO DA LAGOA", "MINAS GERAIS", "MG", 31],
[3162302, "SAO JOAO DA MATA", "MINAS GERAIS", "MG", 31],
[3162401, "SAO JOAO DA PONTE", "MINAS GERAIS", "MG", 31],
[3162450, "SAO JOAO DAS MISSÕES", "MINAS GERAIS", "MG", 31],
[3162500, "SAO JOAO DEL REI", "MINAS GERAIS", "MG", 31],
[3162559, "SAO JOAO DO MANHUACU", "MINAS GERAIS", "MG", 31],
[3162575, "SAO JOAO DO MANTENINHA", "MINAS GERAIS", "MG", 31],
[3162609, "SAO JOAO DO ORIENTE", "MINAS GERAIS", "MG", 31],
[3162658, "SAO JOAO DO PACUI", "MINAS GERAIS", "MG", 31],
[3162708, "SAO JOAO DO PARAISO", "MINAS GERAIS", "MG", 31],
[3162807, "SAO JOAO EVANGELISTA", "MINAS GERAIS", "MG", 31],
[3162906, "SAO JOAO NEPOMUCENO", "MINAS GERAIS", "MG", 31],
[3162922, "SAO JOAQUIM DE BICAS", "MINAS GERAIS", "MG", 31],
[3162948, "SAO JOSE DA BARRA", "MINAS GERAIS", "MG", 31],
[3162955, "SAO JOSE DA LAPA", "MINAS GERAIS", "MG", 31],
[3163003, "SAO JOSE DA SAFIRA", "MINAS GERAIS", "MG", 31],
[3163102, "SAO JOSE DA VARGINHA", "MINAS GERAIS", "MG", 31],
[3163201, "SAO JOSE DO ALEGRE", "MINAS GERAIS", "MG", 31],
[3163300, "SAO JOSE DO DIVINO", "MINAS GERAIS", "MG", 31],
[3163409, "SAO JOSE DO GOIABAL", "MINAS GERAIS", "MG", 31],
[3163508, "SAO JOSE DO JACURI", "MINAS GERAIS", "MG", 31],
[3163607, "SAO JOSE DO MANTIMENTO", "MINAS GERAIS", "MG", 31],
[3163706, "SAO LOURENCO", "MINAS GERAIS", "MG", 31],
[3163805, "SAO MIGUEL DO ANTA", "MINAS GERAIS", "MG", 31],
[3163904, "SAO PEDRO DA UNIAO", "MINAS GERAIS", "MG", 31],
[3164001, "SAO PEDRO DOS FERROS", "MINAS GERAIS", "MG", 31],
[3164100, "SAO PEDRO DO SUACUI", "MINAS GERAIS", "MG", 31],
[3164209, "SAO ROMAO", "MINAS GERAIS", "MG", 31],
[3164308, "SAO ROQUE DE MINAS", "MINAS GERAIS", "MG", 31],
[3164407, "SAO SEBASTIAO DA BELA VIS", "MINAS GERAIS", "MG", 31],
[3164431, "SAO SEBASTIAO DA VARGEM A", "MINAS GERAIS", "MG", 31],
[3164472, "SAO SEBASTIAO DO ANTA", "MINAS GERAIS", "MG", 31],
[3164506, "SAO SEBASTIAO DO MARANHAO", "MINAS GERAIS", "MG", 31],
[3164605, "SAO SEBASTIAO DO OESTE", "MINAS GERAIS", "MG", 31],
[3164704, "SAO SEBASTIAO DO PARAISO", "MINAS GERAIS", "MG", 31],
[3164803, "SAO SEBASTIAO DO RIO PRET", "MINAS GERAIS", "MG", 31],
[3164902, "SAO SEBASTIAO DO RIO VERD", "MINAS GERAIS", "MG", 31],
[3165008, "SAO TIAGO", "MINAS GERAIS", "MG", 31],
[3165107, "SAO TOMAS DE AQUINO", "MINAS GERAIS", "MG", 31],
[3165206, "SAO THOME DAS LETRAS", "MINAS GERAIS", "MG", 31],
[3165305, "SAO VICENTE DE MINAS", "MINAS GERAIS", "MG", 31],
[3165404, "SAPUCAI-MIRIM", "MINAS GERAIS", "MG", 31],
[3165503, "SARDOA", "MINAS GERAIS", "MG", 31],
[3165537, "SARZEDO", "MINAS GERAIS", "MG", 31],
[3165552, "SETUBINHA", "MINAS GERAIS", "MG", 31],
[3165560, "SEM-PEIXE", "MINAS GERAIS", "MG", 31],
[3165578, "SENADOR AMARAL", "MINAS GERAIS", "MG", 31],
[1100023, "ARIQUEMES", "RONDONIA", "RO", 11],
[1100031, "CABIXI", "RONDONIA", "RO", 11],
[1100049, "CACOAL", "RONDONIA", "RO", 11],
[1100056, "CEREJEIRAS", "RONDONIA", "RO", 11],
[1100064, "COLORADO DO OESTE", "RONDONIA", "RO", 11],
[1100072, "CORUMBIARA", "RONDONIA", "RO", 11],
[1100080, "COSTA MARQUES", "RONDONIA", "RO", 11],
[1100098, "ESPIGAO D'OESTE", "RONDONIA", "RO", 11],
[1100106, "GUAJARA-MIRIM", "RONDONIA", "RO", 11],
[1100114, "JARU", "RONDONIA", "RO", 11],
[1100122, "JI-PARANA", "RONDONIA", "RO", 11],
[1100130, "MACHADINHO D'OESTE", "RONDONIA", "RO", 11],
[1100148, "NOVA BRASILANDIA D'OESTE", "RONDONIA", "RO", 11],
[1100155, "OURO PRETO DO OESTE", "RONDONIA", "RO", 11],
[1100189, "PIMENTA BUENO", "RONDONIA", "RO", 11],
[1100205, "PORTO VELHO", "RONDONIA", "RO", 11],
[1100254, "PRESIDENTE MEDICI", "RONDONIA", "RO", 11],
[1100262, "RIO CRESPO", "RONDONIA", "RO", 11],
[1100288, "ROLIM DE MOURA", "RONDONIA", "RO", 11],
[1100296, "SANTA LUZIA D'OESTE", "RONDONIA", "RO", 11],
[1100304, "VILHENA", "RONDONIA", "RO", 11],
[1100320, "SAO MIGUEL DO GUAPORE", "RONDONIA", "RO", 11],
[1100338, "NOVA MAMORE", "RONDONIA", "RO", 11],
[1100346, "ALVORADA D'OESTE", "RONDONIA", "RO", 11],
[1100379, "ALTO ALEGRE DOS PARECIS", "RONDONIA", "RO", 11],
[1100403, "ALTO PARAISO", "RONDONIA", "RO", 11],
[1100452, "BURITIS", "RONDONIA", "RO", 11],
[1100502, "NOVO HORIZONTE DO OESTE", "RONDONIA", "RO", 11],
[1100601, "CACAULANDIA", "RONDONIA", "RO", 11],
[1100700, "CAMPO NOVO DE RONDONIA", "RONDONIA", "RO", 11],
[1100809, "CANDEIAS DO JAMARI", "RONDONIA", "RO", 11],
[1100908, "CASTANHEIRAS", "RONDONIA", "RO", 11],
[1100924, "CHUPINGUAIA", "RONDONIA", "RO", 11],
[1100940, "CUJUBIM", "RONDONIA", "RO", 11],
[1101005, "GOVERNADOR JORGE TEIXEIRA", "RONDONIA", "RO", 11],
[1101104, "ITAPUA DO OESTE", "RONDONIA", "RO", 11],
[1101203, "MINISTRO ANDREAZZA", "RONDONIA", "RO", 11],
[1101302, "MIRANTE DA SERRA", "RONDONIA", "RO", 11],
[1101401, "MONTE NEGRO", "RONDONIA", "RO", 11],
[1101435, "NOVA UNIAO", "RONDONIA", "RO", 11],
[1101450, "PARECIS", "RONDONIA", "RO", 11],
[1101468, "PIMENTEIRAS DO OESTE", "RONDONIA", "RO", 11],
[1101476, "PRIMAVERA DE RONDONIA", "RONDONIA", "RO", 11],
[1101484, "SAO FELIPE D'OESTE", "RONDONIA", "RO", 11],
[1101492, "SAO FRANCISCO DO GUAPORE", "RONDONIA", "RO", 11],
[1101500, "SERINGUEIRAS", "RONDONIA", "RO", 11],
[3165602, "SENADOR CORTES", "MINAS GERAIS", "MG", 31],
[3165701, "SENADOR FIRMINO", "MINAS GERAIS", "MG", 31],
[3165800, "SENADOR JOSE BENTO", "MINAS GERAIS", "MG", 31],
[3165909, "SENADOR MODESTINO GONCALV", "MINAS GERAIS", "MG", 31],
[3166006, "SENHORA DE OLIVEIRA", "MINAS GERAIS", "MG", 31],
[3166105, "SENHORA DO PORTO", "MINAS GERAIS", "MG", 31],
[3166204, "SENHORA DOS REMEDIOS", "MINAS GERAIS", "MG", 31],
[3166303, "SERICITA", "MINAS GERAIS", "MG", 31],
[3166402, "SERITINGA", "MINAS GERAIS", "MG", 31],
[3166501, "SERRA AZUL DE MINAS", "MINAS GERAIS", "MG", 31],
[3166600, "SERRA DA SAUDADE", "MINAS GERAIS", "MG", 31],
[3166709, "SERRA DOS AIMORES", "MINAS GERAIS", "MG", 31],
[3166808, "SERRA DO SALITRE", "MINAS GERAIS", "MG", 31],
[3166907, "SERRANIA", "MINAS GERAIS", "MG", 31],
[3166956, "SERRANOPOLIS DE MINAS", "MINAS GERAIS", "MG", 31],
[3167004, "SERRANOS", "MINAS GERAIS", "MG", 31],
[3167103, "SERRO", "MINAS GERAIS", "MG", 31],
[3167202, "SETE LAGOAS", "MINAS GERAIS", "MG", 31],
[3167301, "SILVEIRANIA", "MINAS GERAIS", "MG", 31],
[3167400, "SILVIANOPOLIS", "MINAS GERAIS", "MG", 31],
[3167509, "SIMAO PEREIRA", "MINAS GERAIS", "MG", 31],
[3167608, "SIMONESIA", "MINAS GERAIS", "MG", 31],
[3167707, "SOBRALIA", "MINAS GERAIS", "MG", 31],
[3167806, "SOLEDADE DE MINAS", "MINAS GERAIS", "MG", 31],
[3167905, "TABULEIRO", "MINAS GERAIS", "MG", 31],
[3168002, "TAIOBEIRAS", "MINAS GERAIS", "MG", 31],
[3168051, "TAPARUBA", "MINAS GERAIS", "MG", 31],
[3168101, "TAPIRA", "MINAS GERAIS", "MG", 31],
[3168200, "TAPIRAI", "MINAS GERAIS", "MG", 31],
[3168309, "TAQUARACU DE MINAS", "MINAS GERAIS", "MG", 31],
[3168408, "TARUMIRIM", "MINAS GERAIS", "MG", 31],
[3168507, "TEIXEIRAS", "MINAS GERAIS", "MG", 31],
[3168606, "TEOFILO OTONI", "MINAS GERAIS", "MG", 31],
[3168705, "TIMOTEO", "MINAS GERAIS", "MG", 31],
[3168804, "TIRADENTES", "MINAS GERAIS", "MG", 31],
[3168903, "TIROS", "MINAS GERAIS", "MG", 31],
[3169000, "TOCANTINS", "MINAS GERAIS", "MG", 31],
[3169059, "TOCOS DO MOJI", "MINAS GERAIS", "MG", 31],
[3169109, "TOLEDO", "MINAS GERAIS", "MG", 31],
[3169208, "TOMBOS", "MINAS GERAIS", "MG", 31],
[3169307, "TRES CORACÕES", "MINAS GERAIS", "MG", 31],
[3169356, "TRES MARIAS", "MINAS GERAIS", "MG", 31],
[3169406, "TRES PONTAS", "MINAS GERAIS", "MG", 31],
[3169505, "TUMIRITINGA", "MINAS GERAIS", "MG", 31],
[3169604, "TUPACIGUARA", "MINAS GERAIS", "MG", 31],
[3169703, "TURMALINA", "MINAS GERAIS", "MG", 31],
[3169802, "TURVOLANDIA", "MINAS GERAIS", "MG", 31],
[3169901, "UBA", "MINAS GERAIS", "MG", 31],
[3170008, "UBAI", "MINAS GERAIS", "MG", 31],
[3170057, "UBAPORANGA", "MINAS GERAIS", "MG", 31],
[3170107, "UBERABA", "MINAS GERAIS", "MG", 31],
[3170305, "UMBURATIBA", "MINAS GERAIS", "MG", 31],
[3170404, "UNAI", "MINAS GERAIS", "MG", 31],
[3170438, "UNIAO DE MINAS", "MINAS GERAIS", "MG", 31],
[3170479, "URUANA DE MINAS", "MINAS GERAIS", "MG", 31],
[3170503, "URUCANIA", "MINAS GERAIS", "MG", 31],
[3170529, "URUCUIA", "MINAS GERAIS", "MG", 31],
[3170578, "VARGEM ALEGRE", "MINAS GERAIS", "MG", 31],
[3170602, "VARGEM BONITA", "MINAS GERAIS", "MG", 31],
[3170651, "VARGEM GRANDE DO RIO PARD", "MINAS GERAIS", "MG", 31],
[3170701, "VARGINHA", "MINAS GERAIS", "MG", 31],
[3170750, "VARJAO DE MINAS", "MINAS GERAIS", "MG", 31],
[3170800, "VARZEA DA PALMA", "MINAS GERAIS", "MG", 31],
[3170909, "VARZELANDIA", "MINAS GERAIS", "MG", 31],
[3171006, "VAZANTE", "MINAS GERAIS", "MG", 31],
[3171030, "VERDELANDIA", "MINAS GERAIS", "MG", 31],
[3171105, "VERISSIMO", "MINAS GERAIS", "MG", 31],
[3171154, "VERMELHO NOVO", "MINAS GERAIS", "MG", 31],
[3171204, "VESPASIANO", "MINAS GERAIS", "MG", 31],
[3171303, "VICOSA", "MINAS GERAIS", "MG", 31],
[3171402, "VIEIRAS", "MINAS GERAIS", "MG", 31],
[3171501, "MATHIAS LOBATO", "MINAS GERAIS", "MG", 31],
[3171600, "VIRGEM DA LAPA", "MINAS GERAIS", "MG", 31],
[3171709, "VIRGINIA", "MINAS GERAIS", "MG", 31],
[3171808, "VIRGINOPOLIS", "MINAS GERAIS", "MG", 31],
[3171907, "VIRGOLANDIA", "MINAS GERAIS", "MG", 31],
[3172004, "VISCONDE DO RIO BRANCO", "MINAS GERAIS", "MG", 31],
[3172103, "VOLTA GRANDE", "MINAS GERAIS", "MG", 31],
[3172202, "WENCESLAU BRAZ", "MINAS GERAIS", "MG", 31],
[3200102, "AFONSO CLAUDIO", "ESPIRITO SANTO", "ES", 32],
[3200136, "AGUIA BRANCA", "ESPIRITO SANTO", "ES", 32],
[3200169, "AGUA DOCE DO NORTE", "ESPIRITO SANTO", "ES", 32],
[3200201, "ALEGRE", "ESPIRITO SANTO", "ES", 32],
[3200300, "ALFREDO CHAVES", "ESPIRITO SANTO", "ES", 32],
[3200359, "ALTO RIO NOVO", "ESPIRITO SANTO", "ES", 32],
[3200409, "ANCHIETA", "ESPIRITO SANTO", "ES", 32],
[3200508, "APIACA", "ESPIRITO SANTO", "ES", 32],
[3200607, "ARACRUZ", "ESPIRITO SANTO", "ES", 32],
[3200706, "ATILIO VIVACQUA", "ESPIRITO SANTO", "ES", 32],
[3200805, "BAIXO GUANDU", "ESPIRITO SANTO", "ES", 32],
[3200904, "BARRA DE SAO FRANCISCO", "ESPIRITO SANTO", "ES", 32],
[3201001, "BOA ESPERANCA", "ESPIRITO SANTO", "ES", 32],
[3201100, "BOM JESUS DO NORTE", "ESPIRITO SANTO", "ES", 32],
[3201159, "BREJETUBA", "ESPIRITO SANTO", "ES", 32],
[3201209, "CACHOEIRO DE ITAPEMIRIM", "ESPIRITO SANTO", "ES", 32],
[3201308, "CARIACICA", "ESPIRITO SANTO", "ES", 32],
[3201407, "CASTELO", "ESPIRITO SANTO", "ES", 32],
[3201506, "COLATINA", "ESPIRITO SANTO", "ES", 32],
[3201605, "CONCEICAO DA BARRA", "ESPIRITO SANTO", "ES", 32],
[3201704, "CONCEICAO DO CASTELO", "ESPIRITO SANTO", "ES", 32],
[3201803, "DIVINO DE SAO LOURENCO", "ESPIRITO SANTO", "ES", 32],
[3201902, "DOMINGOS MARTINS", "ESPIRITO SANTO", "ES", 32],
[3202009, "DORES DO RIO PRETO", "ESPIRITO SANTO", "ES", 32],
[3202108, "ECOPORANGA", "ESPIRITO SANTO", "ES", 32],
[3202207, "FUNDAO", "ESPIRITO SANTO", "ES", 32],
[3202256, "GOVERNADOR LINDENBERG", "ESPIRITO SANTO", "ES", 32],
[3202306, "GUACUI", "ESPIRITO SANTO", "ES", 32],
[3202405, "GUARAPARI", "ESPIRITO SANTO", "ES", 32],
[3202454, "IBATIBA", "ESPIRITO SANTO", "ES", 32],
[3202504, "IBIRACU", "ESPIRITO SANTO", "ES", 32],
[3202553, "IBITIRAMA", "ESPIRITO SANTO", "ES", 32],
[3202603, "ICONHA", "ESPIRITO SANTO", "ES", 32],
[3202652, "IRUPI", "ESPIRITO SANTO", "ES", 32],
[3202702, "ITAGUACU", "ESPIRITO SANTO", "ES", 32],
[3202801, "ITAPEMIRIM", "ESPIRITO SANTO", "ES", 32],
[3202900, "ITARANA", "ESPIRITO SANTO", "ES", 32],
[3203007, "IUNA", "ESPIRITO SANTO", "ES", 32],
[3203056, "JAGUARE", "ESPIRITO SANTO", "ES", 32],
[3203106, "JERONIMO MONTEIRO", "ESPIRITO SANTO", "ES", 32],
[3203130, "JOAO NEIVA", "ESPIRITO SANTO", "ES", 32],
[3203163, "LARANJA DA TERRA", "ESPIRITO SANTO", "ES", 32],
[3203205, "LINHARES", "ESPIRITO SANTO", "ES", 32],
[3203304, "MANTENOPOLIS", "ESPIRITO SANTO", "ES", 32],
[3203320, "MARATAIZES", "ESPIRITO SANTO", "ES", 32],
[3203346, "MARECHAL FLORIANO", "ESPIRITO SANTO", "ES", 32],
[3203353, "MARILANDIA", "ESPIRITO SANTO", "ES", 32],
[3203403, "MIMOSO DO SUL", "ESPIRITO SANTO", "ES", 32],
[3203502, "MONTANHA", "ESPIRITO SANTO", "ES", 32],
[3203601, "MUCURICI", "ESPIRITO SANTO", "ES", 32],
[3203700, "MUNIZ FREIRE", "ESPIRITO SANTO", "ES", 32],
[3203809, "MUQUI", "ESPIRITO SANTO", "ES", 32],
[3203908, "NOVA VENECIA", "ESPIRITO SANTO", "ES", 32],
[3204005, "PANCAS", "ESPIRITO SANTO", "ES", 32],
[3204054, "PEDRO CANARIO", "ESPIRITO SANTO", "ES", 32],
[3204104, "PINHEIROS", "ESPIRITO SANTO", "ES", 32],
[3204203, "PIUMA", "ESPIRITO SANTO", "ES", 32],
[3204252, "PONTO BELO", "ESPIRITO SANTO", "ES", 32],
[3204302, "PRESIDENTE KENNEDY", "ESPIRITO SANTO", "ES", 32],
[3204351, "RIO BANANAL", "ESPIRITO SANTO", "ES", 32],
[3204401, "RIO NOVO DO SUL", "ESPIRITO SANTO", "ES", 32],
[3204500, "SANTA LEOPOLDINA", "ESPIRITO SANTO", "ES", 32],
[3204559, "SANTA MARIA DE JETIBA", "ESPIRITO SANTO", "ES", 32],
[3204609, "SANTA TERESA", "ESPIRITO SANTO", "ES", 32],
[3204658, "SAO DOMINGOS DO NORTE", "ESPIRITO SANTO", "ES", 32],
[3204708, "SAO GABRIEL DA PALHA", "ESPIRITO SANTO", "ES", 32],
[3204807, "SAO JOSE DO CALCADO", "ESPIRITO SANTO", "ES", 32],
[3204906, "SAO MATEUS", "ESPIRITO SANTO", "ES", 32],
[3204955, "SAO ROQUE DO CANAA", "ESPIRITO SANTO", "ES", 32],
[3205002, "SERRA", "ESPIRITO SANTO", "ES", 32],
[3205010, "SOORETAMA", "ESPIRITO SANTO", "ES", 32],
[3205036, "VARGEM ALTA", "ESPIRITO SANTO", "ES", 32],
[3205069, "VENDA NOVA DO IMIGRANTE", "ESPIRITO SANTO", "ES", 32],
[3205101, "VIANA", "ESPIRITO SANTO", "ES", 32],
[3205150, "VILA PAVAO", "ESPIRITO SANTO", "ES", 32],
[3205176, "VILA VALERIO", "ESPIRITO SANTO", "ES", 32],
[3205200, "VILA VELHA", "ESPIRITO SANTO", "ES", 32],
[3205309, "VITORIA", "ESPIRITO SANTO", "ES", 32],
[3300100, "ANGRA DOS REIS", "RIO DE JANEIRO", "RJ", 33],
[3300159, "APERIBE", "RIO DE JANEIRO", "RJ", 33],
[3300209, "ARARUAMA", "RIO DE JANEIRO", "RJ", 33],
[3300225, "AREAL", "RIO DE JANEIRO", "RJ", 33],
[3300233, "ARMACAO DOS BUZIOS", "RIO DE JANEIRO", "RJ", 33],
[3300258, "ARRAIAL DO CABO", "RIO DE JANEIRO", "RJ", 33],
[3300308, "BARRA DO PIRAI", "RIO DE JANEIRO", "RJ", 33],
[3300407, "BARRA MANSA", "RIO DE JANEIRO", "RJ", 33],
[3300456, "BELFORD ROXO", "RIO DE JANEIRO", "RJ", 33],
[3300506, "BOM JARDIM", "RIO DE JANEIRO", "RJ", 33],
[3300605, "BOM JESUS DO ITABAPOANA", "RIO DE JANEIRO", "RJ", 33],
[3300704, "CABO FRIO", "RIO DE JANEIRO", "RJ", 33],
[3300803, "CACHOEIRAS DE MACACU", "RIO DE JANEIRO", "RJ", 33],
[3300902, "CAMBUCI", "RIO DE JANEIRO", "RJ", 33],
[3300936, "CARAPEBUS", "RIO DE JANEIRO", "RJ", 33],
[3300951, "COMENDADOR LEVY GASPARIAN", "RIO DE JANEIRO", "RJ", 33],
[3301009, "CAMPOS DOS GOYTACAZES", "RIO DE JANEIRO", "RJ", 33],
[3301108, "CANTAGALO", "RIO DE JANEIRO", "RJ", 33],
[3301157, "CARDOSO MOREIRA", "RIO DE JANEIRO", "RJ", 33],
[3301207, "CARMO", "RIO DE JANEIRO", "RJ", 33],
[3301306, "CASIMIRO DE ABREU", "RIO DE JANEIRO", "RJ", 33],
[3301405, "CONCEICAO DE MACABU", "RIO DE JANEIRO", "RJ", 33],
[3301504, "CORDEIRO", "RIO DE JANEIRO", "RJ", 33],
[3301603, "DUAS BARRAS", "RIO DE JANEIRO", "RJ", 33],
[3301702, "DUQUE DE CAXIAS", "RIO DE JANEIRO", "RJ", 33],
[3301801, "ENGENHEIRO PAULO DE FRONT", "RIO DE JANEIRO", "RJ", 33],
[3301850, "GUAPIMIRIM", "RIO DE JANEIRO", "RJ", 33],
[3301876, "IGUABA GRANDE", "RIO DE JANEIRO", "RJ", 33],
[3301900, "ITABORAI", "RIO DE JANEIRO", "RJ", 33],
[3302007, "ITAGUAI", "RIO DE JANEIRO", "RJ", 33],
[3302056, "ITALVA", "RIO DE JANEIRO", "RJ", 33],
[3302106, "ITAOCARA", "RIO DE JANEIRO", "RJ", 33],
[3302205, "ITAPERUNA", "RIO DE JANEIRO", "RJ", 33],
[3302254, "ITATIAIA", "RIO DE JANEIRO", "RJ", 33],
[3302270, "JAPERI", "RIO DE JANEIRO", "RJ", 33],
[3302304, "LAJE DO MURIAE", "RIO DE JANEIRO", "RJ", 33],
[3302403, "MACAE", "RIO DE JANEIRO", "RJ", 33],
[3302452, "MACUCO", "RIO DE JANEIRO", "RJ", 33],
[3302502, "MAGE", "RIO DE JANEIRO", "RJ", 33],
[3302601, "MANGARATIBA", "RIO DE JANEIRO", "RJ", 33],
[3302700, "MARICA", "RIO DE JANEIRO", "RJ", 33],
[3302809, "MENDES", "RIO DE JANEIRO", "RJ", 33],
[3302858, "MESQUITA", "RIO DE JANEIRO", "RJ", 33],
[3302908, "MIGUEL PEREIRA", "RIO DE JANEIRO", "RJ", 33],
[3303005, "MIRACEMA", "RIO DE JANEIRO", "RJ", 33],
[3303104, "NATIVIDADE", "RIO DE JANEIRO", "RJ", 33],
[3303203, "NILOPOLIS", "RIO DE JANEIRO", "RJ", 33],
[3303302, "NITEROI", "RIO DE JANEIRO", "RJ", 33],
[3303401, "NOVA FRIBURGO", "RIO DE JANEIRO", "RJ", 33],
[3303500, "NOVA IGUACU", "RIO DE JANEIRO", "RJ", 33],
[3303609, "PARACAMBI", "RIO DE JANEIRO", "RJ", 33],
[3303708, "PARAIBA DO SUL", "RIO DE JANEIRO", "RJ", 33],
[3303807, "PARATY", "RIO DE JANEIRO", "RJ", 33],
[3303856, "PATY DO ALFERES", "RIO DE JANEIRO", "RJ", 33],
[3303906, "PETROPOLIS", "RIO DE JANEIRO", "RJ", 33],
[3303955, "PINHEIRAL", "RIO DE JANEIRO", "RJ", 33],
[3304003, "PIRAI", "RIO DE JANEIRO", "RJ", 33],
[3304102, "PORCIUNCULA", "RIO DE JANEIRO", "RJ", 33],
[3304110, "PORTO REAL", "RIO DE JANEIRO", "RJ", 33],
[3304128, "QUATIS", "RIO DE JANEIRO", "RJ", 33],
[3304144, "QUEIMADOS", "RIO DE JANEIRO", "RJ", 33],
[3304151, "QUISSAMA", "RIO DE JANEIRO", "RJ", 33],
[3304201, "RESENDE", "RIO DE JANEIRO", "RJ", 33],
[3304300, "RIO BONITO", "RIO DE JANEIRO", "RJ", 33],
[3304409, "RIO CLARO", "RIO DE JANEIRO", "RJ", 33],
[3304508, "RIO DAS FLORES", "RIO DE JANEIRO", "RJ", 33],
[3304524, "RIO DAS OSTRAS", "RIO DE JANEIRO", "RJ", 33],
[3304557, "RIO DE JANEIRO", "RIO DE JANEIRO", "RJ", 33],
[3304607, "SANTA MARIA MADALENA", "RIO DE JANEIRO", "RJ", 33],
[3304706, "SANTO ANTONIO DE PADUA", "RIO DE JANEIRO", "RJ", 33],
[3304755, "SAO FRANCISCO DE ITABAPOA", "RIO DE JANEIRO", "RJ", 33],
[3304805, "SAO FIDELIS", "RIO DE JANEIRO", "RJ", 33],
[3304904, "SAO GONCALO", "RIO DE JANEIRO", "RJ", 33],
[3305000, "SAO JOAO DA BARRA", "RIO DE JANEIRO", "RJ", 33],
[3305109, "SAO JOAO DE MERITI", "RIO DE JANEIRO", "RJ", 33],
[3305133, "SAO JOSE DE UBA", "RIO DE JANEIRO", "RJ", 33],
[3305158, "SAO JOSE DO VALE DO RIO P", "RIO DE JANEIRO", "RJ", 33],
[3305208, "SAO PEDRO DA ALDEIA", "RIO DE JANEIRO", "RJ", 33],
[3305307, "SAO SEBASTIAO DO ALTO", "RIO DE JANEIRO", "RJ", 33],
[3305406, "SAPUCAIA", "RIO DE JANEIRO", "RJ", 33],
[3305505, "SAQUAREMA", "RIO DE JANEIRO", "RJ", 33],
[3305554, "SEROPEDICA", "RIO DE JANEIRO", "RJ", 33],
[3305604, "SILVA JARDIM", "RIO DE JANEIRO", "RJ", 33],
[3305703, "SUMIDOURO", "RIO DE JANEIRO", "RJ", 33],
[3305752, "TANGUA", "RIO DE JANEIRO", "RJ", 33],
[3305802, "TERESOPOLIS", "RIO DE JANEIRO", "RJ", 33],
[3305901, "TRAJANO DE MORAES", "RIO DE JANEIRO", "RJ", 33],
[3306008, "TRES RIOS", "RIO DE JANEIRO", "RJ", 33],
[3306107, "VALENCA", "RIO DE JANEIRO", "RJ", 33],
[3306156, "VARRE-SAI", "RIO DE JANEIRO", "RJ", 33],
[3306206, "VASSOURAS", "RIO DE JANEIRO", "RJ", 33],
[3306305, "VOLTA REDONDA", "RIO DE JANEIRO", "RJ", 33],
[3500105, "ADAMANTINA", "SAO PAULO", "SP", 35],
[3500204, "ADOLFO", "SAO PAULO", "SP", 35],
[3500303, "AGUAI", "SAO PAULO", "SP", 35],
[3500402, "AGUAS DA PRATA", "SAO PAULO", "SP", 35],
[3500501, "AGUAS DE LINDOIA", "SAO PAULO", "SP", 35],
[3500550, "AGUAS DE SANTA BARBARA", "SAO PAULO", "SP", 35],
[3500600, "AGUAS DE SAO PEDRO", "SAO PAULO", "SP", 35],
[3500709, "AGUDOS", "SAO PAULO", "SP", 35],
[3500758, "ALAMBARI", "SAO PAULO", "SP", 35],
[3500808, "ALFREDO MARCONDES", "SAO PAULO", "SP", 35],
[3500907, "ALTAIR", "SAO PAULO", "SP", 35],
[3501004, "ALTINOPOLIS", "SAO PAULO", "SP", 35],
[3501103, "ALTO ALEGRE", "SAO PAULO", "SP", 35],
[3501152, "ALUMINIO", "SAO PAULO", "SP", 35],
[3501202, "ALVARES FLORENCE", "SAO PAULO", "SP", 35],
[3501301, "ALVARES MACHADO", "SAO PAULO", "SP", 35],
[3501400, "ALVARO DE CARVALHO", "SAO PAULO", "SP", 35],
[3501509, "ALVINLANDIA", "SAO PAULO", "SP", 35],
[3501608, "AMERICANA", "SAO PAULO", "SP", 35],
[3501707, "AMERICO BRASILIENSE", "SAO PAULO", "SP", 35],
[3501806, "AMERICO DE CAMPOS", "SAO PAULO", "SP", 35],
[3501905, "AMPARO", "SAO PAULO", "SP", 35],
[3502002, "ANALANDIA", "SAO PAULO", "SP", 35],
[3502101, "ANDRADINA", "SAO PAULO", "SP", 35],
[3502200, "ANGATUBA", "SAO PAULO", "SP", 35],
[3502309, "ANHEMBI", "SAO PAULO", "SP", 35],
[3502408, "ANHUMAS", "SAO PAULO", "SP", 35],
[3502507, "APARECIDA", "SAO PAULO", "SP", 35],
[3502606, "APARECIDA D'OESTE", "SAO PAULO", "SP", 35],
[3502705, "APIAI", "SAO PAULO", "SP", 35],
[3502754, "ARACARIGUAMA", "SAO PAULO", "SP", 35],
[3502804, "ARACATUBA", "SAO PAULO", "SP", 35],
[3502903, "ARACOIABA DA SERRA", "SAO PAULO", "SP", 35],
[3503000, "ARAMINA", "SAO PAULO", "SP", 35],
[3503109, "ARANDU", "SAO PAULO", "SP", 35],
[3503158, "ARAPEI", "SAO PAULO", "SP", 35],
[3503208, "ARARAQUARA", "SAO PAULO", "SP", 35],
[3503307, "ARARAS", "SAO PAULO", "SP", 35],
[3503356, "ARCO-IRIS", "SAO PAULO", "SP", 35],
[3503406, "AREALVA", "SAO PAULO", "SP", 35],
[3503604, "AREIOPOLIS", "SAO PAULO", "SP", 35],
[3503703, "ARIRANHA", "SAO PAULO", "SP", 35],
[3503802, "ARTUR NOGUEIRA", "SAO PAULO", "SP", 35],
[3503901, "ARUJA", "SAO PAULO", "SP", 35],
[3503950, "ASPASIA", "SAO PAULO", "SP", 35],
[3504008, "ASSIS", "SAO PAULO", "SP", 35],
[3504107, "ATIBAIA", "SAO PAULO", "SP", 35],
[3504206, "AURIFLAMA", "SAO PAULO", "SP", 35],
[3504305, "AVAI", "SAO PAULO", "SP", 35],
[3504404, "AVANHANDAVA", "SAO PAULO", "SP", 35],
[3504503, "AVARE", "SAO PAULO", "SP", 35],
[3504602, "BADY BASSITT", "SAO PAULO", "SP", 35],
[3504701, "BALBINOS", "SAO PAULO", "SP", 35],
[3504800, "BALSAMO", "SAO PAULO", "SP", 35],
[3504909, "BANANAL", "SAO PAULO", "SP", 35],
[3505005, "BARAO DE ANTONINA", "SAO PAULO", "SP", 35],
[3505104, "BARBOSA", "SAO PAULO", "SP", 35],
[3505203, "BARIRI", "SAO PAULO", "SP", 35],
[3505302, "BARRA BONITA", "SAO PAULO", "SP", 35],
[3505351, "BARRA DO CHAPEU", "SAO PAULO", "SP", 35],
[3505401, "BARRA DO TURVO", "SAO PAULO", "SP", 35],
[3505500, "BARRETOS", "SAO PAULO", "SP", 35],
[3505609, "BARRINHA", "SAO PAULO", "SP", 35],
[3505708, "BARUERI", "SAO PAULO", "SP", 35],
[3505807, "BASTOS", "SAO PAULO", "SP", 35],
[3505906, "BATATAIS", "SAO PAULO", "SP", 35],
[3506003, "BAURU", "SAO PAULO", "SP", 35],
[3506102, "BEBEDOURO", "SAO PAULO", "SP", 35],
[3506201, "BENTO DE ABREU", "SAO PAULO", "SP", 35],
[3506300, "BERNARDINO DE CAMPOS", "SAO PAULO", "SP", 35],
[3506359, "BERTIOGA", "SAO PAULO", "SP", 35],
[3506409, "BILAC", "SAO PAULO", "SP", 35],
[3506508, "BIRIGUI", "SAO PAULO", "SP", 35],
[3506607, "BIRITIBA-MIRIM", "SAO PAULO", "SP", 35],
[3506706, "BOA ESPERANCA DO SUL", "SAO PAULO", "SP", 35],
[3506805, "BOCAINA", "SAO PAULO", "SP", 35],
[3506904, "BOFETE", "SAO PAULO", "SP", 35],
[3507001, "BOITUVA", "SAO PAULO", "SP", 35],
[3507100, "BOM JESUS DOS PERDÕES", "SAO PAULO", "SP", 35],
[3507159, "BOM SUCESSO DE ITARARE", "SAO PAULO", "SP", 35],
[3507209, "BORA", "SAO PAULO", "SP", 35],
[3507308, "BORACEIA", "SAO PAULO", "SP", 35],
[3507407, "BORBOREMA", "SAO PAULO", "SP", 35],
[3507456, "BOREBI", "SAO PAULO", "SP", 35],
[3507506, "BOTUCATU", "SAO PAULO", "SP", 35],
[3507605, "BRAGANCA PAULISTA", "SAO PAULO", "SP", 35],
[3507704, "BRAUNA", "SAO PAULO", "SP", 35],
[3507753, "BREJO ALEGRE", "SAO PAULO", "SP", 35],
[3507803, "BRODOWSKI", "SAO PAULO", "SP", 35],
[3507902, "BROTAS", "SAO PAULO", "SP", 35],
[3508009, "BURI", "SAO PAULO", "SP", 35],
[3508108, "BURITAMA", "SAO PAULO", "SP", 35],
[3508207, "BURITIZAL", "SAO PAULO", "SP", 35],
[3508306, "CABRALIA PAULISTA", "SAO PAULO", "SP", 35],
[3508405, "CABREUVA", "SAO PAULO", "SP", 35],
[3508504, "CACAPAVA", "SAO PAULO", "SP", 35],
[3508603, "CACHOEIRA PAULISTA", "SAO PAULO", "SP", 35],
[3508702, "CACONDE", "SAO PAULO", "SP", 35],
[3508801, "CAFELANDIA", "SAO PAULO", "SP", 35],
[3508900, "CAIABU", "SAO PAULO", "SP", 35],
[3509007, "CAIEIRAS", "SAO PAULO", "SP", 35],
[3509106, "CAIUA", "SAO PAULO", "SP", 35],
[3509205, "CAJAMAR", "SAO PAULO", "SP", 35],
[3509254, "CAJATI", "SAO PAULO", "SP", 35],
[3509304, "CAJOBI", "SAO PAULO", "SP", 35],
[3509403, "CAJURU", "SAO PAULO", "SP", 35],
[3509452, "CAMPINA DO MONTE ALEGRE", "SAO PAULO", "SP", 35],
[3509502, "CAMPINAS", "SAO PAULO", "SP", 35],
[3509601, "CAMPO LIMPO PAULISTA", "SAO PAULO", "SP", 35],
[3509700, "CAMPOS DO JORDAO", "SAO PAULO", "SP", 35],
[3509809, "CAMPOS NOVOS PAULISTA", "SAO PAULO", "SP", 35],
[3509908, "CANANEIA", "SAO PAULO", "SP", 35],
[3509957, "CANAS", "SAO PAULO", "SP", 35],
[3510005, "CANDIDO MOTA", "SAO PAULO", "SP", 35],
[3510104, "CANDIDO RODRIGUES", "SAO PAULO", "SP", 35],
[3510153, "CANITAR", "SAO PAULO", "SP", 35],
[3510203, "CAPAO BONITO", "SAO PAULO", "SP", 35],
[3510302, "CAPELA DO ALTO", "SAO PAULO", "SP", 35],
[3510401, "CAPIVARI", "SAO PAULO", "SP", 35],
[3510500, "CARAGUATATUBA", "SAO PAULO", "SP", 35],
[3510609, "CARAPICUIBA", "SAO PAULO", "SP", 35],
[3510708, "CARDOSO", "SAO PAULO", "SP", 35],
[3510807, "CASA BRANCA", "SAO PAULO", "SP", 35],
[3510906, "CASSIA DOS COQUEIROS", "SAO PAULO", "SP", 35],
[3511003, "CASTILHO", "SAO PAULO", "SP", 35],
[3511102, "CATANDUVA", "SAO PAULO", "SP", 35],
[3511201, "CATIGUA", "SAO PAULO", "SP", 35],
[3511300, "CEDRAL", "SAO PAULO", "SP", 35],
[3511409, "CERQUEIRA CESAR", "SAO PAULO", "SP", 35],
[3511508, "CERQUILHO", "SAO PAULO", "SP", 35],
[3511607, "CESARIO LANGE", "SAO PAULO", "SP", 35],
[3511706, "CHARQUEADA", "SAO PAULO", "SP", 35],
[3511904, "CLEMENTINA", "SAO PAULO", "SP", 35],
[3512001, "COLINA", "SAO PAULO", "SP", 35],
[3512100, "COLOMBIA", "SAO PAULO", "SP", 35],
[3512209, "CONCHAL", "SAO PAULO", "SP", 35],
[3512308, "CONCHAS", "SAO PAULO", "SP", 35],
[3512407, "CORDEIROPOLIS", "SAO PAULO", "SP", 35],
[3512506, "COROADOS", "SAO PAULO", "SP", 35],
[3512605, "CORONEL MACEDO", "SAO PAULO", "SP", 35],
[3512704, "CORUMBATAI", "SAO PAULO", "SP", 35],
[3512803, "COSMOPOLIS", "SAO PAULO", "SP", 35],
[3512902, "COSMORAMA", "SAO PAULO", "SP", 35],
[3513009, "COTIA", "SAO PAULO", "SP", 35],
[3513108, "CRAVINHOS", "SAO PAULO", "SP", 35],
[3513207, "CRISTAIS PAULISTA", "SAO PAULO", "SP", 35],
[3513306, "CRUZALIA", "SAO PAULO", "SP", 35],
[3513405, "CRUZEIRO", "SAO PAULO", "SP", 35],
[3513504, "CUBATAO", "SAO PAULO", "SP", 35],
[3513603, "CUNHA", "SAO PAULO", "SP", 35],
[3513702, "DESCALVADO", "SAO PAULO", "SP", 35],
[3513801, "DIADEMA", "SAO PAULO", "SP", 35],
[3513850, "DIRCE REIS", "SAO PAULO", "SP", 35],
[3513900, "DIVINOLANDIA", "SAO PAULO", "SP", 35],
[3514007, "DOBRADA", "SAO PAULO", "SP", 35],
[3514106, "DOIS CORREGOS", "SAO PAULO", "SP", 35],
[3514205, "DOLCINOPOLIS", "SAO PAULO", "SP", 35],
[3514304, "DOURADO", "SAO PAULO", "SP", 35],
[3514403, "DRACENA", "SAO PAULO", "SP", 35],
[3514502, "DUARTINA", "SAO PAULO", "SP", 35],
[3514601, "DUMONT", "SAO PAULO", "SP", 35],
[3514700, "ECHAPORA", "SAO PAULO", "SP", 35],
[3514809, "ELDORADO", "SAO PAULO", "SP", 35],
[3514908, "ELIAS FAUSTO", "SAO PAULO", "SP", 35],
[3514924, "ELISIARIO", "SAO PAULO", "SP", 35],
[3514957, "EMBAUBA", "SAO PAULO", "SP", 35],
[3515004, "EMBU", "SAO PAULO", "SP", 35],
[3515103, "EMBU-GUACU", "SAO PAULO", "SP", 35],
[3515129, "EMILIANOPOLIS", "SAO PAULO", "SP", 35],
[3515152, "ENGENHEIRO COELHO", "SAO PAULO", "SP", 35],
[3515186, "ESPIRITO SANTO DO PINHAL", "SAO PAULO", "SP", 35],
[3515194, "ESPIRITO SANTO DO TURVO", "SAO PAULO", "SP", 35],
[3515202, "ESTRELA D'OESTE", "SAO PAULO", "SP", 35],
[3515301, "ESTRELA DO NORTE", "SAO PAULO", "SP", 35],
[3515350, "EUCLIDES DA CUNHA PAULIST", "SAO PAULO", "SP", 35],
[3515400, "FARTURA", "SAO PAULO", "SP", 35],
[3515509, "FERNANDOPOLIS", "SAO PAULO", "SP", 35],
[3515608, "FERNANDO PRESTES", "SAO PAULO", "SP", 35],
[3515657, "FERNAO", "SAO PAULO", "SP", 35],
[3515707, "FERRAZ DE VASCONCELOS", "SAO PAULO", "SP", 35],
[3515806, "FLORA RICA", "SAO PAULO", "SP", 35],
[3515905, "FLOREAL", "SAO PAULO", "SP", 35],
[3516002, "FLORIDA PAULISTA", "SAO PAULO", "SP", 35],
[3516101, "FLORINIA", "SAO PAULO", "SP", 35],
[3516200, "FRANCA", "SAO PAULO", "SP", 35],
[3516309, "FRANCISCO MORATO", "SAO PAULO", "SP", 35],
[3516408, "FRANCO DA ROCHA", "SAO PAULO", "SP", 35],
[3516507, "GABRIEL MONTEIRO", "SAO PAULO", "SP", 35],
[3516606, "GALIA", "SAO PAULO", "SP", 35],
[3516705, "GARCA", "SAO PAULO", "SP", 35],
[3516804, "GASTAO VIDIGAL", "SAO PAULO", "SP", 35],
[3516853, "GAVIAO PEIXOTO", "SAO PAULO", "SP", 35],
[3516903, "GENERAL SALGADO", "SAO PAULO", "SP", 35],
[3517000, "GETULINA", "SAO PAULO", "SP", 35],
[3517109, "GLICERIO", "SAO PAULO", "SP", 35],
[3517208, "GUAICARA", "SAO PAULO", "SP", 35],
[3517307, "GUAIMBE", "SAO PAULO", "SP", 35],
[3517406, "GUAIRA", "SAO PAULO", "SP", 35],
[3517505, "GUAPIACU", "SAO PAULO", "SP", 35],
[3517604, "GUAPIARA", "SAO PAULO", "SP", 35],
[3517703, "GUARA", "SAO PAULO", "SP", 35],
[3517802, "GUARACAI", "SAO PAULO", "SP", 35],
[3517901, "GUARACI", "SAO PAULO", "SP", 35],
[3518008, "GUARANI D'OESTE", "SAO PAULO", "SP", 35],
[3518107, "GUARANTA", "SAO PAULO", "SP", 35],
[3518206, "GUARARAPES", "SAO PAULO", "SP", 35],
[3518305, "GUARAREMA", "SAO PAULO", "SP", 35],
[3518404, "GUARATINGUETA", "SAO PAULO", "SP", 35],
[3518503, "GUAREI", "SAO PAULO", "SP", 35],
[3518602, "GUARIBA", "SAO PAULO", "SP", 35],
[3518701, "GUARUJA", "SAO PAULO", "SP", 35],
[3518800, "GUARULHOS", "SAO PAULO", "SP", 35],
[3518859, "GUATAPARA", "SAO PAULO", "SP", 35],
[3518909, "GUZOLANDIA", "SAO PAULO", "SP", 35],
[3519006, "HERCULANDIA", "SAO PAULO", "SP", 35],
[3519055, "HOLAMBRA", "SAO PAULO", "SP", 35],
[3519071, "HORTOLANDIA", "SAO PAULO", "SP", 35],
[3519105, "IACANGA", "SAO PAULO", "SP", 35],
[3519204, "IACRI", "SAO PAULO", "SP", 35],
[3519253, "IARAS", "SAO PAULO", "SP", 35],
[3519303, "IBATE", "SAO PAULO", "SP", 35],
[3519402, "IBIRA", "SAO PAULO", "SP", 35],
[3519501, "IBIRAREMA", "SAO PAULO", "SP", 35],
[3519600, "IBITINGA", "SAO PAULO", "SP", 35],
[3519709, "IBIUNA", "SAO PAULO", "SP", 35],
[3519808, "ICEM", "SAO PAULO", "SP", 35],
[3519907, "IEPE", "SAO PAULO", "SP", 35],
[3520004, "IGARACU DO TIETE", "SAO PAULO", "SP", 35],
[3520103, "IGARAPAVA", "SAO PAULO", "SP", 35],
[3520202, "IGARATA", "SAO PAULO", "SP", 35],
[3520301, "IGUAPE", "SAO PAULO", "SP", 35],
[3520400, "ILHABELA", "SAO PAULO", "SP", 35],
[3520426, "ILHA COMPRIDA", "SAO PAULO", "SP", 35],
[3520442, "ILHA SOLTEIRA", "SAO PAULO", "SP", 35],
[3520509, "INDAIATUBA", "SAO PAULO", "SP", 35],
[3520608, "INDIANA", "SAO PAULO", "SP", 35],
[3520707, "INDIAPORA", "SAO PAULO", "SP", 35],
[3520806, "INUBIA PAULISTA", "SAO PAULO", "SP", 35],
[3520905, "IPAUSSU", "SAO PAULO", "SP", 35],
[3521002, "IPERO", "SAO PAULO", "SP", 35],
[3521101, "IPEUNA", "SAO PAULO", "SP", 35],
[3521150, "IPIGUA", "SAO PAULO", "SP", 35],
[3521200, "IPORANGA", "SAO PAULO", "SP", 35],
[3521309, "IPUA", "SAO PAULO", "SP", 35],
[3521408, "IRACEMAPOLIS", "SAO PAULO", "SP", 35],
[3521507, "IRAPUA", "SAO PAULO", "SP", 35],
[3521606, "IRAPURU", "SAO PAULO", "SP", 35],
[3521705, "ITABERA", "SAO PAULO", "SP", 35],
[3521804, "ITAI", "SAO PAULO", "SP", 35],
[3521903, "ITAJOBI", "SAO PAULO", "SP", 35],
[3522000, "ITAJU", "SAO PAULO", "SP", 35],
[3522109, "ITANHAEM", "SAO PAULO", "SP", 35],
[3522158, "ITAOCA", "SAO PAULO", "SP", 35],
[3522208, "ITAPECERICA DA SERRA", "SAO PAULO", "SP", 35],
[3522307, "ITAPETININGA", "SAO PAULO", "SP", 35],
[3522406, "ITAPEVA", "SAO PAULO", "SP", 35],
[3522505, "ITAPEVI", "SAO PAULO", "SP", 35],
[3522604, "ITAPIRA", "SAO PAULO", "SP", 35],
[3522653, "ITAPIRAPUA PAULISTA", "SAO PAULO", "SP", 35],
[3522703, "ITAPOLIS", "SAO PAULO", "SP", 35],
[3522802, "ITAPORANGA", "SAO PAULO", "SP", 35],
[3522901, "ITAPUI", "SAO PAULO", "SP", 35],
[3523008, "ITAPURA", "SAO PAULO", "SP", 35],
[3523107, "ITAQUAQUECETUBA", "SAO PAULO", "SP", 35],
[3523206, "ITARARE", "SAO PAULO", "SP", 35],
[3523305, "ITARIRI", "SAO PAULO", "SP", 35],
[3523404, "ITATIBA", "SAO PAULO", "SP", 35],
[3523503, "ITATINGA", "SAO PAULO", "SP", 35],
[3523602, "ITIRAPINA", "SAO PAULO", "SP", 35],
[3523701, "ITIRAPUA", "SAO PAULO", "SP", 35],
[3523800, "ITOBI", "SAO PAULO", "SP", 35],
[3523909, "ITU", "SAO PAULO", "SP", 35],
[3524006, "ITUPEVA", "SAO PAULO", "SP", 35],
[3524105, "ITUVERAVA", "SAO PAULO", "SP", 35],
[3524204, "JABORANDI", "SAO PAULO", "SP", 35],
[3524303, "JABOTICABAL", "SAO PAULO", "SP", 35],
[3524402, "JACAREI", "SAO PAULO", "SP", 35],
[3524501, "JACI", "SAO PAULO", "SP", 35],
[3524600, "JACUPIRANGA", "SAO PAULO", "SP", 35],
[3524709, "JAGUARIUNA", "SAO PAULO", "SP", 35],
[3524808, "JALES", "SAO PAULO", "SP", 35],
[3524907, "JAMBEIRO", "SAO PAULO", "SP", 35],
[3525003, "JANDIRA", "SAO PAULO", "SP", 35],
[3525102, "JARDINOPOLIS", "SAO PAULO", "SP", 35],
[3525201, "JARINU", "SAO PAULO", "SP", 35],
[3525300, "JAU", "SAO PAULO", "SP", 35],
[3525409, "JERIQUARA", "SAO PAULO", "SP", 35],
[3525508, "JOANOPOLIS", "SAO PAULO", "SP", 35],
[3525607, "JOAO RAMALHO", "SAO PAULO", "SP", 35],
[3525706, "JOSE BONIFACIO", "SAO PAULO", "SP", 35],
[3525805, "JULIO MESQUITA", "SAO PAULO", "SP", 35],
[3525854, "JUMIRIM", "SAO PAULO", "SP", 35],
[3525904, "JUNDIAI", "SAO PAULO", "SP", 35],
[3526001, "JUNQUEIROPOLIS", "SAO PAULO", "SP", 35],
[3526100, "JUQUIA", "SAO PAULO", "SP", 35],
[3526209, "JUQUITIBA", "SAO PAULO", "SP", 35],
[3526308, "LAGOINHA", "SAO PAULO", "SP", 35],
[3526407, "LARANJAL PAULISTA", "SAO PAULO", "SP", 35],
[3526506, "LAVINIA", "SAO PAULO", "SP", 35],
[3526605, "LAVRINHAS", "SAO PAULO", "SP", 35],
[3526704, "LEME", "SAO PAULO", "SP", 35],
[3526803, "LENCOIS PAULISTA", "SAO PAULO", "SP", 35],
[3526902, "LIMEIRA", "SAO PAULO", "SP", 35],
[3527009, "LINDOIA", "SAO PAULO", "SP", 35],
[3527108, "LINS", "SAO PAULO", "SP", 35],
[3527207, "LORENA", "SAO PAULO", "SP", 35],
[3527256, "LOURDES", "SAO PAULO", "SP", 35],
[3527306, "LOUVEIRA", "SAO PAULO", "SP", 35],
[3527405, "LUCELIA", "SAO PAULO", "SP", 35],
[3527504, "LUCIANOPOLIS", "SAO PAULO", "SP", 35],
[3527603, "LUIS ANTONIO", "SAO PAULO", "SP", 35],
[3527702, "LUIZIANIA", "SAO PAULO", "SP", 35],
[3527801, "LUPERCIO", "SAO PAULO", "SP", 35],
[3527900, "LUTECIA", "SAO PAULO", "SP", 35],
[3528007, "MACATUBA", "SAO PAULO", "SP", 35],
[3528106, "MACAUBAL", "SAO PAULO", "SP", 35],
[3528205, "MACEDONIA", "SAO PAULO", "SP", 35],
[3528304, "MAGDA", "SAO PAULO", "SP", 35],
[3528403, "MAIRINQUE", "SAO PAULO", "SP", 35],
[3528502, "MAIRIPORA", "SAO PAULO", "SP", 35],
[3528601, "MANDURI", "SAO PAULO", "SP", 35],
[3528700, "MARABA PAULISTA", "SAO PAULO", "SP", 35],
[3528809, "MARACAI", "SAO PAULO", "SP", 35],
[3528858, "MARAPOAMA", "SAO PAULO", "SP", 35],
[3528908, "MARIAPOLIS", "SAO PAULO", "SP", 35],
[3529005, "MARILIA", "SAO PAULO", "SP", 35],
[3529203, "MARTINOPOLIS", "SAO PAULO", "SP", 35],
[3529302, "MATAO", "SAO PAULO", "SP", 35],
[3529401, "MAUA", "SAO PAULO", "SP", 35],
[3529500, "MENDONCA", "SAO PAULO", "SP", 35],
[3529609, "MERIDIANO", "SAO PAULO", "SP", 35],
[3529658, "MESOPOLIS", "SAO PAULO", "SP", 35],
[3529708, "MIGUELOPOLIS", "SAO PAULO", "SP", 35],
[3529807, "MINEIROS DO TIETE", "SAO PAULO", "SP", 35],
[3529906, "MIRACATU", "SAO PAULO", "SP", 35],
[3530003, "MIRA ESTRELA", "SAO PAULO", "SP", 35],
[3530102, "MIRANDOPOLIS", "SAO PAULO", "SP", 35],
[3530201, "MIRANTE DO PARANAPANEMA", "SAO PAULO", "SP", 35],
[3530300, "MIRASSOL", "SAO PAULO", "SP", 35],
[3530409, "MIRASSOLANDIA", "SAO PAULO", "SP", 35],
[3530508, "MOCOCA", "SAO PAULO", "SP", 35],
[3530607, "MOGI DAS CRUZES", "SAO PAULO", "SP", 35],
[3530706, "MOGI GUACU", "SAO PAULO", "SP", 35],
[3530805, "MOJI MIRIM", "SAO PAULO", "SP", 35],
[3530904, "MOMBUCA", "SAO PAULO", "SP", 35],
[3531001, "MONCÕES", "SAO PAULO", "SP", 35],
[3531100, "MONGAGUA", "SAO PAULO", "SP", 35],
[3531209, "MONTE ALEGRE DO SUL", "SAO PAULO", "SP", 35],
[3531308, "MONTE ALTO", "SAO PAULO", "SP", 35],
[3531407, "MONTE APRAZIVEL", "SAO PAULO", "SP", 35],
[3531506, "MONTE AZUL PAULISTA", "SAO PAULO", "SP", 35],
[3531605, "MONTE CASTELO", "SAO PAULO", "SP", 35],
[3531704, "MONTEIRO LOBATO", "SAO PAULO", "SP", 35],
[3531803, "MONTE MOR", "SAO PAULO", "SP", 35],
[3531902, "MORRO AGUDO", "SAO PAULO", "SP", 35],
[3532009, "MORUNGABA", "SAO PAULO", "SP", 35],
[3532058, "MOTUCA", "SAO PAULO", "SP", 35],
[3532108, "MURUTINGA DO SUL", "SAO PAULO", "SP", 35],
[3532157, "NANTES", "SAO PAULO", "SP", 35],
[3532207, "NARANDIBA", "SAO PAULO", "SP", 35],
[3532306, "NATIVIDADE DA SERRA", "SAO PAULO", "SP", 35],
[3532504, "NEVES PAULISTA", "SAO PAULO", "SP", 35],
[3532603, "NHANDEARA", "SAO PAULO", "SP", 35],
[3532702, "NIPOA", "SAO PAULO", "SP", 35],
[3532801, "NOVA ALIANCA", "SAO PAULO", "SP", 35],
[3532827, "NOVA CAMPINA", "SAO PAULO", "SP", 35],
[3532843, "NOVA CANAA PAULISTA", "SAO PAULO", "SP", 35],
[3532868, "NOVA CASTILHO", "SAO PAULO", "SP", 35],
[3532900, "NOVA EUROPA", "SAO PAULO", "SP", 35],
[3533007, "NOVA GRANADA", "SAO PAULO", "SP", 35],
[3533106, "NOVA GUATAPORANGA", "SAO PAULO", "SP", 35],
[3533205, "NOVA INDEPENDENCIA", "SAO PAULO", "SP", 35],
[3533254, "NOVAIS", "SAO PAULO", "SP", 35],
[3533304, "NOVA LUZITANIA", "SAO PAULO", "SP", 35],
[3533403, "NOVA ODESSA", "SAO PAULO", "SP", 35],
[3533502, "NOVO HORIZONTE", "SAO PAULO", "SP", 35],
[3533601, "NUPORANGA", "SAO PAULO", "SP", 35],
[3533700, "OCAUCU", "SAO PAULO", "SP", 35],
[3533809, "OLEO", "SAO PAULO", "SP", 35],
[3533908, "OLIMPIA", "SAO PAULO", "SP", 35],
[3534005, "ONDA VERDE", "SAO PAULO", "SP", 35],
[3534104, "ORIENTE", "SAO PAULO", "SP", 35],
[3534203, "ORINDIUVA", "SAO PAULO", "SP", 35],
[3534302, "ORLANDIA", "SAO PAULO", "SP", 35],
[3534401, "OSASCO", "SAO PAULO", "SP", 35],
[3534500, "OSCAR BRESSANE", "SAO PAULO", "SP", 35],
[3534609, "OSVALDO CRUZ", "SAO PAULO", "SP", 35],
[3534708, "OURINHOS", "SAO PAULO", "SP", 35],
[3534757, "OUROESTE", "SAO PAULO", "SP", 35],
[3534807, "OURO VERDE", "SAO PAULO", "SP", 35],
[3534906, "PACAEMBU", "SAO PAULO", "SP", 35],
[3535002, "PALESTINA", "SAO PAULO", "SP", 35],
[3535101, "PALMARES PAULISTA", "SAO PAULO", "SP", 35],
[3535200, "PALMEIRA D'OESTE", "SAO PAULO", "SP", 35],
[3535309, "PALMITAL", "SAO PAULO", "SP", 35],
[3535408, "PANORAMA", "SAO PAULO", "SP", 35],
[3535507, "PARAGUACU PAULISTA", "SAO PAULO", "SP", 35],
[3535606, "PARAIBUNA", "SAO PAULO", "SP", 35],
[3535705, "PARAISO", "SAO PAULO", "SP", 35],
[3535804, "PARANAPANEMA", "SAO PAULO", "SP", 35],
[3535903, "PARANAPUA", "SAO PAULO", "SP", 35],
[3536000, "PARAPUA", "SAO PAULO", "SP", 35],
[3536109, "PARDINHO", "SAO PAULO", "SP", 35],
[3536208, "PARIQUERA-ACU", "SAO PAULO", "SP", 35],
[3536257, "PARISI", "SAO PAULO", "SP", 35],
[3536307, "PATROCINIO PAULISTA", "SAO PAULO", "SP", 35],
[3536406, "PAULICEIA", "SAO PAULO", "SP", 35],
[3536505, "PAULINIA", "SAO PAULO", "SP", 35],
[3536570, "PAULISTANIA", "SAO PAULO", "SP", 35],
[3536604, "PAULO DE FARIA", "SAO PAULO", "SP", 35],
[3536703, "PEDERNEIRAS", "SAO PAULO", "SP", 35],
[3536802, "PEDRA BELA", "SAO PAULO", "SP", 35],
[3536901, "PEDRANOPOLIS", "SAO PAULO", "SP", 35],
[3537008, "PEDREGULHO", "SAO PAULO", "SP", 35],
[3537107, "PEDREIRA", "SAO PAULO", "SP", 35],
[3537156, "PEDRINHAS PAULISTA", "SAO PAULO", "SP", 35],
[3537206, "PEDRO DE TOLEDO", "SAO PAULO", "SP", 35],
[3537305, "PENAPOLIS", "SAO PAULO", "SP", 35],
[3537404, "PEREIRA BARRETO", "SAO PAULO", "SP", 35],
[3537503, "PEREIRAS", "SAO PAULO", "SP", 35],
[3537602, "PERUIBE", "SAO PAULO", "SP", 35],
[3537701, "PIACATU", "SAO PAULO", "SP", 35],
[3537800, "PIEDADE", "SAO PAULO", "SP", 35],
[3537909, "PILAR DO SUL", "SAO PAULO", "SP", 35],
[3538006, "PINDAMONHANGABA", "SAO PAULO", "SP", 35],
[3538105, "PINDORAMA", "SAO PAULO", "SP", 35],
[3538204, "PINHALZINHO", "SAO PAULO", "SP", 35],
[3538303, "PIQUEROBI", "SAO PAULO", "SP", 35],
[3538501, "PIQUETE", "SAO PAULO", "SP", 35],
[3538600, "PIRACAIA", "SAO PAULO", "SP", 35],
[3538709, "PIRACICABA", "SAO PAULO", "SP", 35],
[3538808, "PIRAJU", "SAO PAULO", "SP", 35],
[3538907, "PIRAJUI", "SAO PAULO", "SP", 35],
[3539004, "PIRANGI", "SAO PAULO", "SP", 35],
[3539103, "PIRAPORA DO BOM JESUS", "SAO PAULO", "SP", 35],
[3539202, "PIRAPOZINHO", "SAO PAULO", "SP", 35],
[3539301, "PIRASSUNUNGA", "SAO PAULO", "SP", 35],
[3539400, "PIRATININGA", "SAO PAULO", "SP", 35],
[3539509, "PITANGUEIRAS", "SAO PAULO", "SP", 35],
[3539608, "PLANALTO", "SAO PAULO", "SP", 35],
[3539707, "PLATINA", "SAO PAULO", "SP", 35],
[3539806, "POA", "SAO PAULO", "SP", 35],
[3539905, "POLONI", "SAO PAULO", "SP", 35],
[3540002, "POMPEIA", "SAO PAULO", "SP", 35],
[3540101, "PONGAI", "SAO PAULO", "SP", 35],
[3540200, "PONTAL", "SAO PAULO", "SP", 35],
[3540259, "PONTALINDA", "SAO PAULO", "SP", 35],
[3540309, "PONTES GESTAL", "SAO PAULO", "SP", 35],
[3540408, "POPULINA", "SAO PAULO", "SP", 35],
[3540507, "PORANGABA", "SAO PAULO", "SP", 35],
[3540606, "PORTO FELIZ", "SAO PAULO", "SP", 35],
[3540705, "PORTO FERREIRA", "SAO PAULO", "SP", 35],
[3540754, "POTIM", "SAO PAULO", "SP", 35],
[3540804, "POTIRENDABA", "SAO PAULO", "SP", 35],
[3540853, "PRACINHA", "SAO PAULO", "SP", 35],
[3540903, "PRADOPOLIS", "SAO PAULO", "SP", 35],
[3541000, "PRAIA GRANDE", "SAO PAULO", "SP", 35],
[3541059, "PRATANIA", "SAO PAULO", "SP", 35],
[3541109, "PRESIDENTE ALVES", "SAO PAULO", "SP", 35],
[3541208, "PRESIDENTE BERNARDES", "SAO PAULO", "SP", 35],
[3541307, "PRESIDENTE EPITACIO", "SAO PAULO", "SP", 35],
[3541406, "PRESIDENTE PRUDENTE", "SAO PAULO", "SP", 35],
[3541505, "PRESIDENTE VENCESLAU", "SAO PAULO", "SP", 35],
[3541604, "PROMISSAO", "SAO PAULO", "SP", 35],
[3541653, "QUADRA", "SAO PAULO", "SP", 35],
[3541703, "QUATA", "SAO PAULO", "SP", 35],
[3541802, "QUEIROZ", "SAO PAULO", "SP", 35],
[3541901, "QUELUZ", "SAO PAULO", "SP", 35],
[3542008, "QUINTANA", "SAO PAULO", "SP", 35],
[3542107, "RAFARD", "SAO PAULO", "SP", 35],
[3542206, "RANCHARIA", "SAO PAULO", "SP", 35],
[3542305, "REDENCAO DA SERRA", "SAO PAULO", "SP", 35],
[3542404, "REGENTE FEIJO", "SAO PAULO", "SP", 35],
[3542503, "REGINOPOLIS", "SAO PAULO", "SP", 35],
[3542602, "REGISTRO", "SAO PAULO", "SP", 35],
[3542701, "RESTINGA", "SAO PAULO", "SP", 35],
[3542800, "RIBEIRA", "SAO PAULO", "SP", 35],
[3542909, "RIBEIRAO BONITO", "SAO PAULO", "SP", 35],
[3543006, "RIBEIRAO BRANCO", "SAO PAULO", "SP", 35],
[3543105, "RIBEIRAO CORRENTE", "SAO PAULO", "SP", 35],
[3543204, "RIBEIRAO DO SUL", "SAO PAULO", "SP", 35],
[3543238, "RIBEIRAO DOS INDIOS", "SAO PAULO", "SP", 35],
[3543253, "RIBEIRAO GRANDE", "SAO PAULO", "SP", 35],
[3543303, "RIBEIRAO PIRES", "SAO PAULO", "SP", 35],
[3543402, "RIBEIRAO PRETO", "SAO PAULO", "SP", 35],
[3543501, "RIVERSUL", "SAO PAULO", "SP", 35],
[3543600, "RIFAINA", "SAO PAULO", "SP", 35],
[3543709, "RINCAO", "SAO PAULO", "SP", 35],
[3543808, "RINOPOLIS", "SAO PAULO", "SP", 35],
[3543907, "RIO CLARO", "SAO PAULO", "SP", 35],
[3544004, "RIO DAS PEDRAS", "SAO PAULO", "SP", 35],
[3544103, "RIO GRANDE DA SERRA", "SAO PAULO", "SP", 35],
[3544202, "RIOLANDIA", "SAO PAULO", "SP", 35],
[3544251, "ROSANA", "SAO PAULO", "SP", 35],
[3544301, "ROSEIRA", "SAO PAULO", "SP", 35],
[3544400, "RUBIACEA", "SAO PAULO", "SP", 35],
[3544509, "RUBINEIA", "SAO PAULO", "SP", 35],
[3544608, "SABINO", "SAO PAULO", "SP", 35],
[3544707, "SAGRES", "SAO PAULO", "SP", 35],
[3544806, "SALES", "SAO PAULO", "SP", 35],
[3544905, "SALES OLIVEIRA", "SAO PAULO", "SP", 35],
[3545001, "SALESOPOLIS", "SAO PAULO", "SP", 35],
[3545100, "SALMOURAO", "SAO PAULO", "SP", 35],
[3545159, "SALTINHO", "SAO PAULO", "SP", 35],
[3545209, "SALTO", "SAO PAULO", "SP", 35],
[3545308, "SALTO DE PIRAPORA", "SAO PAULO", "SP", 35],
[3545407, "SALTO GRANDE", "SAO PAULO", "SP", 35],
[3545506, "SANDOVALINA", "SAO PAULO", "SP", 35],
[3545605, "SANTA ADELIA", "SAO PAULO", "SP", 35],
[3545704, "SANTA ALBERTINA", "SAO PAULO", "SP", 35],
[3545803, "SANTA BARBARA D'OESTE", "SAO PAULO", "SP", 35],
[3546009, "SANTA BRANCA", "SAO PAULO", "SP", 35],
[3546108, "SANTA CLARA D'OESTE", "SAO PAULO", "SP", 35],
[3546207, "SANTA CRUZ DA CONCEICAO", "SAO PAULO", "SP", 35],
[3546256, "SANTA CRUZ DA ESPERANCA", "SAO PAULO", "SP", 35],
[3546306, "SANTA CRUZ DAS PALMEIRAS", "SAO PAULO", "SP", 35],
[3546405, "SANTA CRUZ DO RIO PARDO", "SAO PAULO", "SP", 35],
[3546504, "SANTA ERNESTINA", "SAO PAULO", "SP", 35],
[3546603, "SANTA FE DO SUL", "SAO PAULO", "SP", 35],
[3546702, "SANTA GERTRUDES", "SAO PAULO", "SP", 35],
[3546801, "SANTA ISABEL", "SAO PAULO", "SP", 35],
[3546900, "SANTA LUCIA", "SAO PAULO", "SP", 35],
[3547007, "SANTA MARIA DA SERRA", "SAO PAULO", "SP", 35],
[3547106, "SANTA MERCEDES", "SAO PAULO", "SP", 35],
[3547205, "SANTANA DA PONTE PENSA", "SAO PAULO", "SP", 35],
[3547304, "SANTANA DE PARNAIBA", "SAO PAULO", "SP", 35],
[3547403, "SANTA RITA D'OESTE", "SAO PAULO", "SP", 35],
[3547502, "SANTA RITA DO PASSA QUATR", "SAO PAULO", "SP", 35],
[3547601, "SANTA ROSA DE VITERBO", "SAO PAULO", "SP", 35],
[3547650, "SANTA SALETE", "SAO PAULO", "SP", 35],
[3547700, "SANTO ANASTACIO", "SAO PAULO", "SP", 35],
[3547809, "SANTO ANDRE", "SAO PAULO", "SP", 35],
[3547908, "SANTO ANTONIO DA ALEGRIA", "SAO PAULO", "SP", 35],
[3548005, "SANTO ANTONIO DE POSSE", "SAO PAULO", "SP", 35],
[3548054, "SANTO ANTONIO DO ARACANGU", "SAO PAULO", "SP", 35],
[3548104, "SANTO ANTONIO DO JARDIM", "SAO PAULO", "SP", 35],
[3548203, "SANTO ANTONIO DO PINHAL", "SAO PAULO", "SP", 35],
[3548302, "SANTO EXPEDITO", "SAO PAULO", "SP", 35],
[3548401, "SANTOPOLIS DO AGUAPEI", "SAO PAULO", "SP", 35],
[3548500, "SANTOS", "SAO PAULO", "SP", 35],
[3548609, "SAO BENTO DO SAPUCAI", "SAO PAULO", "SP", 35],
[3548708, "SAO BERNARDO DO CAMPO", "SAO PAULO", "SP", 35],
[3548807, "SAO CAETANO DO SUL", "SAO PAULO", "SP", 35],
[3548906, "SAO CARLOS", "SAO PAULO", "SP", 35],
[3549003, "SAO FRANCISCO", "SAO PAULO", "SP", 35],
[3549102, "SAO JOAO DA BOA VISTA", "SAO PAULO", "SP", 35],
[3549201, "SAO JOAO DAS DUAS PONTES", "SAO PAULO", "SP", 35],
[3549250, "SAO JOAO DE IRACEMA", "SAO PAULO", "SP", 35],
[3549300, "SAO JOAO DO PAU D'ALHO", "SAO PAULO", "SP", 35],
[3549409, "SAO JOAQUIM DA BARRA", "SAO PAULO", "SP", 35],
[3549508, "SAO JOSE DA BELA VISTA", "SAO PAULO", "SP", 35],
[3549607, "SAO JOSE DO BARREIRO", "SAO PAULO", "SP", 35],
[3549706, "SAO JOSE DO RIO PARDO", "SAO PAULO", "SP", 35],
[3549805, "SAO JOSE DO RIO PRETO", "SAO PAULO", "SP", 35],
[3549904, "SAO JOSE DOS CAMPOS", "SAO PAULO", "SP", 35],
[3549953, "SAO LOURENCO DA SERRA", "SAO PAULO", "SP", 35],
[3550001, "SAO LUIS DO PARAITINGA", "SAO PAULO", "SP", 35],
[3550100, "SAO MANUEL", "SAO PAULO", "SP", 35],
[3550209, "SAO MIGUEL ARCANJO", "SAO PAULO", "SP", 35],
[3550308, "SAO PAULO", "SAO PAULO", "SP", 35],
[3550407, "SAO PEDRO", "SAO PAULO", "SP", 35],
[3550506, "SAO PEDRO DO TURVO", "SAO PAULO", "SP", 35],
[3550605, "SAO ROQUE", "SAO PAULO", "SP", 35],
[3550704, "SAO SEBASTIAO", "SAO PAULO", "SP", 35],
[3550803, "SAO SEBASTIAO DA GRAMA", "SAO PAULO", "SP", 35],
[3550902, "SAO SIMAO", "SAO PAULO", "SP", 35],
[3551009, "SAO VICENTE", "SAO PAULO", "SP", 35],
[3551108, "SARAPUI", "SAO PAULO", "SP", 35],
[3551207, "SARUTAIA", "SAO PAULO", "SP", 35],
[3551306, "SEBASTIANOPOLIS DO SUL", "SAO PAULO", "SP", 35],
[3551405, "SERRA AZUL", "SAO PAULO", "SP", 35],
[3551504, "SERRANA", "SAO PAULO", "SP", 35],
[3551603, "SERRA NEGRA", "SAO PAULO", "SP", 35],
[3551702, "SERTAOZINHO", "SAO PAULO", "SP", 35],
[3551801, "SETE BARRAS", "SAO PAULO", "SP", 35],
[3551900, "SEVERINIA", "SAO PAULO", "SP", 35],
[3552007, "SILVEIRAS", "SAO PAULO", "SP", 35],
[3552106, "SOCORRO", "SAO PAULO", "SP", 35],
[3552205, "SOROCABA", "SAO PAULO", "SP", 35],
[3552304, "SUD MENNUCCI", "SAO PAULO", "SP", 35],
[3552403, "SUMARE", "SAO PAULO", "SP", 35],
[3552502, "SUZANO", "SAO PAULO", "SP", 35],
[3552551, "SUZANAPOLIS", "SAO PAULO", "SP", 35],
[3552601, "TABAPUA", "SAO PAULO", "SP", 35],
[3552700, "TABATINGA", "SAO PAULO", "SP", 35],
[3552809, "TABOAO DA SERRA", "SAO PAULO", "SP", 35],
[3552908, "TACIBA", "SAO PAULO", "SP", 35],
[3553005, "TAGUAI", "SAO PAULO", "SP", 35],
[3553104, "TAIACU", "SAO PAULO", "SP", 35],
[3553203, "TAIUVA", "SAO PAULO", "SP", 35],
[3553302, "TAMBAU", "SAO PAULO", "SP", 35],
[3553401, "TANABI", "SAO PAULO", "SP", 35],
[3553500, "TAPIRAI", "SAO PAULO", "SP", 35],
[3553609, "TAPIRATIBA", "SAO PAULO", "SP", 35],
[3553658, "TAQUARAL", "SAO PAULO", "SP", 35],
[3553708, "TAQUARITINGA", "SAO PAULO", "SP", 35],
[3553807, "TAQUARITUBA", "SAO PAULO", "SP", 35],
[3553856, "TAQUARIVAI", "SAO PAULO", "SP", 35],
[3553906, "TARABAI", "SAO PAULO", "SP", 35],
[3553955, "TARUMA", "SAO PAULO", "SP", 35],
[3554003, "TATUI", "SAO PAULO", "SP", 35],
[3554102, "TAUBATE", "SAO PAULO", "SP", 35],
[3554201, "TEJUPA", "SAO PAULO", "SP", 35],
[3554300, "TEODORO SAMPAIO", "SAO PAULO", "SP", 35],
[3554409, "TERRA ROXA", "SAO PAULO", "SP", 35],
[3554508, "TIETE", "SAO PAULO", "SP", 35],
[3554607, "TIMBURI", "SAO PAULO", "SP", 35],
[3554656, "TORRE DE PEDRA", "SAO PAULO", "SP", 35],
[3554706, "TORRINHA", "SAO PAULO", "SP", 35],
[3554755, "TRABIJU", "SAO PAULO", "SP", 35],
[3554805, "TREMEMBE", "SAO PAULO", "SP", 35],
[3554904, "TRES FRONTEIRAS", "SAO PAULO", "SP", 35],
[3554953, "TUIUTI", "SAO PAULO", "SP", 35],
[3555000, "TUPA", "SAO PAULO", "SP", 35],
[3555109, "TUPI PAULISTA", "SAO PAULO", "SP", 35],
[3555208, "TURIUBA", "SAO PAULO", "SP", 35],
[3555307, "TURMALINA", "SAO PAULO", "SP", 35],
[3555356, "UBARANA", "SAO PAULO", "SP", 35],
[3555406, "UBATUBA", "SAO PAULO", "SP", 35],
[3555505, "UBIRAJARA", "SAO PAULO", "SP", 35],
[3555604, "UCHOA", "SAO PAULO", "SP", 35],
[3555703, "UNIAO PAULISTA", "SAO PAULO", "SP", 35],
[3555802, "URANIA", "SAO PAULO", "SP", 35],
[3555901, "URU", "SAO PAULO", "SP", 35],
[3556008, "URUPES", "SAO PAULO", "SP", 35],
[3556107, "VALENTIM GENTIL", "SAO PAULO", "SP", 35],
[3556206, "VALINHOS", "SAO PAULO", "SP", 35],
[3556305, "VALPARAISO", "SAO PAULO", "SP", 35],
[3556354, "VARGEM", "SAO PAULO", "SP", 35],
[3556404, "VARGEM GRANDE DO SUL", "SAO PAULO", "SP", 35],
[3556453, "VARGEM GRANDE PAULISTA", "SAO PAULO", "SP", 35],
[3556503, "VARZEA PAULISTA", "SAO PAULO", "SP", 35],
[3556602, "VERA CRUZ", "SAO PAULO", "SP", 35],
[3556701, "VINHEDO", "SAO PAULO", "SP", 35],
[3556800, "VIRADOURO", "SAO PAULO", "SP", 35],
[3556909, "VISTA ALEGRE DO ALTO", "SAO PAULO", "SP", 35],
[3556958, "VITORIA BRASIL", "SAO PAULO", "SP", 35],
[3557006, "VOTORANTIM", "SAO PAULO", "SP", 35],
[3557105, "VOTUPORANGA", "SAO PAULO", "SP", 35],
[3557154, "ZACARIAS", "SAO PAULO", "SP", 35],
[3557204, "CHAVANTES", "SAO PAULO", "SP", 35],
[3557303, "ESTIVA GERBI", "SAO PAULO", "SP", 35],
[4100103, "ABATIA", "PARANA", "PR", 41],
[4100202, "ADRIANOPOLIS", "PARANA", "PR", 41],
[4100301, "AGUDOS DO SUL", "PARANA", "PR", 41],
[4100400, "ALMIRANTE TAMANDARE", "PARANA", "PR", 41],
[4100459, "ALTAMIRA DO PARANA", "PARANA", "PR", 41],
[4100509, "ALTONIA", "PARANA", "PR", 41],
[4100608, "ALTO PARANA", "PARANA", "PR", 41],
[4100707, "ALTO PIQUIRI", "PARANA", "PR", 41],
[4100806, "ALVORADA DO SUL", "PARANA", "PR", 41],
[4100905, "AMAPORA", "PARANA", "PR", 41],
[4101002, "AMPERE", "PARANA", "PR", 41],
[4101051, "ANAHY", "PARANA", "PR", 41],
[4101101, "ANDIRA", "PARANA", "PR", 41],
[4101150, "ANGULO", "PARANA", "PR", 41],
[4101200, "ANTONINA", "PARANA", "PR", 41],
[4101309, "ANTONIO OLINTO", "PARANA", "PR", 41],
[4101408, "APUCARANA", "PARANA", "PR", 41],
[4101507, "ARAPONGAS", "PARANA", "PR", 41],
[4101606, "ARAPOTI", "PARANA", "PR", 41],
[4101655, "ARAPUA", "PARANA", "PR", 41],
[4101705, "ARARUNA", "PARANA", "PR", 41],
[4101804, "ARAUCARIA", "PARANA", "PR", 41],
[4101853, "ARIRANHA DO IVAI", "PARANA", "PR", 41],
[4101903, "ASSAI", "PARANA", "PR", 41],
[4102000, "ASSIS CHATEAUBRIAND", "PARANA", "PR", 41],
[4102109, "ASTORGA", "PARANA", "PR", 41],
[4102208, "ATALAIA", "PARANA", "PR", 41],
[4102307, "BALSA NOVA", "PARANA", "PR", 41],
[4102406, "BANDEIRANTES", "PARANA", "PR", 41],
[4102505, "BARBOSA FERRAZ", "PARANA", "PR", 41],
[4102604, "BARRACAO", "PARANA", "PR", 41],
[4102703, "BARRA DO JACARE", "PARANA", "PR", 41],
[4102752, "BELA VISTA DA CAROBA", "PARANA", "PR", 41],
[4102802, "BELA VISTA DO PARAISO", "PARANA", "PR", 41],
[4102901, "BITURUNA", "PARANA", "PR", 41],
[4103008, "BOA ESPERANCA", "PARANA", "PR", 41],
[4103024, "BOA ESPERANCA DO IGUACU", "PARANA", "PR", 41],
[4103040, "BOA VENTURA DE SAO ROQUE", "PARANA", "PR", 41],
[4103057, "BOA VISTA DA APARECIDA", "PARANA", "PR", 41],
[4103107, "BOCAIUVA DO SUL", "PARANA", "PR", 41],
[4103156, "BOM JESUS DO SUL", "PARANA", "PR", 41],
[4103206, "BOM SUCESSO", "PARANA", "PR", 41],
[4103222, "BOM SUCESSO DO SUL", "PARANA", "PR", 41],
[4103305, "BORRAZOPOLIS", "PARANA", "PR", 41],
[4103354, "BRAGANEY", "PARANA", "PR", 41],
[4103370, "BRASILANDIA DO SUL", "PARANA", "PR", 41],
[4103404, "CAFEARA", "PARANA", "PR", 41],
[4103453, "CAFELANDIA", "PARANA", "PR", 41],
[4103479, "CAFEZAL DO SUL", "PARANA", "PR", 41],
[4103503, "CALIFORNIA", "PARANA", "PR", 41],
[4103602, "CAMBARA", "PARANA", "PR", 41],
[4103701, "CAMBE", "PARANA", "PR", 41],
[4103800, "CAMBIRA", "PARANA", "PR", 41],
[4103909, "CAMPINA DA LAGOA", "PARANA", "PR", 41],
[4103958, "CAMPINA DO SIMAO", "PARANA", "PR", 41],
[4104006, "CAMPINA GRANDE DO SUL", "PARANA", "PR", 41],
[4104055, "CAMPO BONITO", "PARANA", "PR", 41],
[4104105, "CAMPO DO TENENTE", "PARANA", "PR", 41],
[4104204, "CAMPO LARGO", "PARANA", "PR", 41],
[4104253, "CAMPO MAGRO", "PARANA", "PR", 41],
[4104303, "CAMPO MOURAO", "PARANA", "PR", 41],
[4104402, "CANDIDO DE ABREU", "PARANA", "PR", 41],
[4104428, "CANDOI", "PARANA", "PR", 41],
[4104451, "CANTAGALO", "PARANA", "PR", 41],
[4104501, "CAPANEMA", "PARANA", "PR", 41],
[4104600, "CAPITAO LEONIDAS MARQUES", "PARANA", "PR", 41],
[4104659, "CARAMBEI", "PARANA", "PR", 41],
[4104709, "CARLOPOLIS", "PARANA", "PR", 41],
[4104808, "CASCAVEL", "PARANA", "PR", 41],
[4104907, "CASTRO", "PARANA", "PR", 41],
[4105003, "CATANDUVAS", "PARANA", "PR", 41],
[4105102, "CENTENARIO DO SUL", "PARANA", "PR", 41],
[4105201, "CERRO AZUL", "PARANA", "PR", 41],
[4105300, "CEU AZUL", "PARANA", "PR", 41],
[4105409, "CHOPINZINHO", "PARANA", "PR", 41],
[4105508, "CIANORTE", "PARANA", "PR", 41],
[4105607, "CIDADE GAUCHA", "PARANA", "PR", 41],
[4105706, "CLEVELANDIA", "PARANA", "PR", 41],
[4105805, "COLOMBO", "PARANA", "PR", 41],
[4105904, "COLORADO", "PARANA", "PR", 41],
[4106001, "CONGONHINHAS", "PARANA", "PR", 41],
[4106100, "CONSELHEIRO MAIRINCK", "PARANA", "PR", 41],
[4106209, "CONTENDA", "PARANA", "PR", 41],
[4106308, "CORBELIA", "PARANA", "PR", 41],
[4106407, "CORNELIO PROCOPIO", "PARANA", "PR", 41],
[4106456, "CORONEL DOMINGOS SOARES", "PARANA", "PR", 41],
[4106506, "CORONEL VIVIDA", "PARANA", "PR", 41],
[4106555, "CORUMBATAI DO SUL", "PARANA", "PR", 41],
[4106571, "CRUZEIRO DO IGUACU", "PARANA", "PR", 41],
[4106605, "CRUZEIRO DO OESTE", "PARANA", "PR", 41],
[4106704, "CRUZEIRO DO SUL", "PARANA", "PR", 41],
[4106803, "CRUZ MACHADO", "PARANA", "PR", 41],
[4106852, "CRUZMALTINA", "PARANA", "PR", 41],
[4107009, "CURIUVA", "PARANA", "PR", 41],
[4107108, "DIAMANTE DO NORTE", "PARANA", "PR", 41],
[4107124, "DIAMANTE DO SUL", "PARANA", "PR", 41],
[4107157, "DIAMANTE D'OESTE", "PARANA", "PR", 41],
[4107207, "DOIS VIZINHOS", "PARANA", "PR", 41],
[4107256, "DOURADINA", "PARANA", "PR", 41],
[4107306, "DOUTOR CAMARGO", "PARANA", "PR", 41],
[4107405, "ENEAS MARQUES", "PARANA", "PR", 41],
[4107504, "ENGENHEIRO BELTRAO", "PARANA", "PR", 41],
[4107520, "ESPERANCA NOVA", "PARANA", "PR", 41],
[4107538, "ENTRE RIOS DO OESTE", "PARANA", "PR", 41],
[4107546, "ESPIGAO ALTO DO IGUACU", "PARANA", "PR", 41],
[4107553, "FAROL", "PARANA", "PR", 41],
[4107603, "FAXINAL", "PARANA", "PR", 41],
[4107652, "FAZENDA RIO GRANDE", "PARANA", "PR", 41],
[4107702, "FENIX", "PARANA", "PR", 41],
[4107736, "FERNANDES PINHEIRO", "PARANA", "PR", 41],
[4107751, "FIGUEIRA", "PARANA", "PR", 41],
[4107801, "FLORAI", "PARANA", "PR", 41],
[4107850, "FLOR DA SERRA DO SUL", "PARANA", "PR", 41],
[4107900, "FLORESTA", "PARANA", "PR", 41],
[4108007, "FLORESTOPOLIS", "PARANA", "PR", 41],
[4108106, "FLORIDA", "PARANA", "PR", 41],
[4108205, "FORMOSA DO OESTE", "PARANA", "PR", 41],
[4108304, "FOZ DO IGUACU", "PARANA", "PR", 41],
[4108320, "FRANCISCO ALVES", "PARANA", "PR", 41],
[4108403, "FRANCISCO BELTRAO", "PARANA", "PR", 41],
[4108452, "FOZ DO JORDAO", "PARANA", "PR", 41],
[4108502, "GENERAL CARNEIRO", "PARANA", "PR", 41],
[4108551, "GODOY MOREIRA", "PARANA", "PR", 41],
[4108601, "GOIOERE", "PARANA", "PR", 41],
[4108650, "GOIOXIM", "PARANA", "PR", 41],
[4108700, "GRANDES RIOS", "PARANA", "PR", 41],
[4108809, "GUAIRA", "PARANA", "PR", 41],
[4108908, "GUAIRACA", "PARANA", "PR", 41],
[4108957, "GUAMIRANGA", "PARANA", "PR", 41],
[4109005, "GUAPIRAMA", "PARANA", "PR", 41],
[4109104, "GUAPOREMA", "PARANA", "PR", 41],
[4109203, "GUARACI", "PARANA", "PR", 41],
[4109302, "GUARANIACU", "PARANA", "PR", 41],
[4109401, "GUARAPUAVA", "PARANA", "PR", 41],
[4109500, "GUARAQUECABA", "PARANA", "PR", 41],
[4109609, "GUARATUBA", "PARANA", "PR", 41],
[4109658, "HONORIO SERPA", "PARANA", "PR", 41],
[4109708, "IBAITI", "PARANA", "PR", 41],
[4109757, "IBEMA", "PARANA", "PR", 41],
[4109807, "IBIPORA", "PARANA", "PR", 41],
[4109906, "ICARAIMA", "PARANA", "PR", 41],
[4110003, "IGUARACU", "PARANA", "PR", 41],
[4110052, "IGUATU", "PARANA", "PR", 41],
[4110078, "IMBAU", "PARANA", "PR", 41],
[4110102, "IMBITUVA", "PARANA", "PR", 41],
[4110201, "INACIO MARTINS", "PARANA", "PR", 41],
[4110300, "INAJA", "PARANA", "PR", 41],
[4110409, "INDIANOPOLIS", "PARANA", "PR", 41],
[4110508, "IPIRANGA", "PARANA", "PR", 41],
[4110607, "IPORA", "PARANA", "PR", 41],
[4110656, "IRACEMA DO OESTE", "PARANA", "PR", 41],
[4110706, "IRATI", "PARANA", "PR", 41],
[4110805, "IRETAMA", "PARANA", "PR", 41],
[4110904, "ITAGUAJE", "PARANA", "PR", 41],
[4110953, "ITAIPULANDIA", "PARANA", "PR", 41],
[4111001, "ITAMBARACA", "PARANA", "PR", 41],
[4111100, "ITAMBE", "PARANA", "PR", 41],
[4111209, "ITAPEJARA D'OESTE", "PARANA", "PR", 41],
[4111258, "ITAPERUCU", "PARANA", "PR", 41],
[4111308, "ITAUNA DO SUL", "PARANA", "PR", 41],
[4111407, "IVAI", "PARANA", "PR", 41],
[4111506, "IVAIPORA", "PARANA", "PR", 41],
[4111555, "IVATE", "PARANA", "PR", 41],
[4111605, "IVATUBA", "PARANA", "PR", 41],
[4111704, "JABOTI", "PARANA", "PR", 41],
[4111803, "JACAREZINHO", "PARANA", "PR", 41],
[4111902, "JAGUAPITA", "PARANA", "PR", 41],
[4112009, "JAGUARIAIVA", "PARANA", "PR", 41],
[4112108, "JANDAIA DO SUL", "PARANA", "PR", 41],
[4112207, "JANIOPOLIS", "PARANA", "PR", 41],
[4112306, "JAPIRA", "PARANA", "PR", 41],
[4112405, "JAPURA", "PARANA", "PR", 41],
[4112504, "JARDIM ALEGRE", "PARANA", "PR", 41],
[4112603, "JARDIM OLINDA", "PARANA", "PR", 41],
[4112702, "JATAIZINHO", "PARANA", "PR", 41],
[4112751, "JESUITAS", "PARANA", "PR", 41],
[4112801, "JOAQUIM TAVORA", "PARANA", "PR", 41],
[4112900, "JUNDIAI DO SUL", "PARANA", "PR", 41],
[4112959, "JURANDA", "PARANA", "PR", 41],
[4113007, "JUSSARA", "PARANA", "PR", 41],
[4113106, "KALORE", "PARANA", "PR", 41],
[4113205, "LAPA", "PARANA", "PR", 41],
[4113254, "LARANJAL", "PARANA", "PR", 41],
[4113304, "LARANJEIRAS DO SUL", "PARANA", "PR", 41],
[4113403, "LEOPOLIS", "PARANA", "PR", 41],
[4113429, "LIDIANOPOLIS", "PARANA", "PR", 41],
[4113452, "LINDOESTE", "PARANA", "PR", 41],
[4113502, "LOANDA", "PARANA", "PR", 41],
[4113601, "LOBATO", "PARANA", "PR", 41],
[4113700, "LONDRINA", "PARANA", "PR", 41],
[4113734, "LUIZIANA", "PARANA", "PR", 41],
[4113759, "LUNARDELLI", "PARANA", "PR", 41],
[4113809, "LUPIONOPOLIS", "PARANA", "PR", 41],
[4113908, "MALLET", "PARANA", "PR", 41],
[4114005, "MAMBORE", "PARANA", "PR", 41],
[4114104, "MANDAGUACU", "PARANA", "PR", 41],
[4114203, "MANDAGUARI", "PARANA", "PR", 41],
[4114302, "MANDIRITUBA", "PARANA", "PR", 41],
[4114351, "MANFRINOPOLIS", "PARANA", "PR", 41],
[4114401, "MANGUEIRINHA", "PARANA", "PR", 41],
[4114500, "MANOEL RIBAS", "PARANA", "PR", 41],
[4114609, "MARECHAL CANDIDO RONDON", "PARANA", "PR", 41],
[4114708, "MARIA HELENA", "PARANA", "PR", 41],
[4114807, "MARIALVA", "PARANA", "PR", 41],
[4114906, "MARILANDIA DO SUL", "PARANA", "PR", 41],
[4115002, "MARILENA", "PARANA", "PR", 41],
[4115101, "MARILUZ", "PARANA", "PR", 41],
[4115200, "MARINGA", "PARANA", "PR", 41],
[4115309, "MARIOPOLIS", "PARANA", "PR", 41],
[4115358, "MARIPA", "PARANA", "PR", 41],
[4115408, "MARMELEIRO", "PARANA", "PR", 41],
[4115457, "MARQUINHO", "PARANA", "PR", 41],
[4115507, "MARUMBI", "PARANA", "PR", 41],
[4115606, "MATELANDIA", "PARANA", "PR", 41],
[4115705, "MATINHOS", "PARANA", "PR", 41],
[4115739, "MATO RICO", "PARANA", "PR", 41],
[4115754, "MAUA DA SERRA", "PARANA", "PR", 41],
[4115804, "MEDIANEIRA", "PARANA", "PR", 41],
[4115853, "MERCEDES", "PARANA", "PR", 41],
[4115903, "MIRADOR", "PARANA", "PR", 41],
[4116000, "MIRASELVA", "PARANA", "PR", 41],
[4116059, "MISSAL", "PARANA", "PR", 41],
[4116109, "MOREIRA SALES", "PARANA", "PR", 41],
[4116208, "MORRETES", "PARANA", "PR", 41],
[4116307, "MUNHOZ DE MELO", "PARANA", "PR", 41],
[4116406, "NOSSA SENHORA DAS GRACAS", "PARANA", "PR", 41],
[4116505, "NOVA ALIANCA DO IVAI", "PARANA", "PR", 41],
[4116604, "NOVA AMERICA DA COLINA", "PARANA", "PR", 41],
[4116703, "NOVA AURORA", "PARANA", "PR", 41],
[4116802, "NOVA CANTU", "PARANA", "PR", 41],
[4116901, "NOVA ESPERANCA", "PARANA", "PR", 41],
[4116950, "NOVA ESPERANCA DO SUDOEST", "PARANA", "PR", 41],
[4117008, "NOVA FATIMA", "PARANA", "PR", 41],
[4117057, "NOVA LARANJEIRAS", "PARANA", "PR", 41],
[4117107, "NOVA LONDRINA", "PARANA", "PR", 41],
[4117206, "NOVA OLIMPIA", "PARANA", "PR", 41],
[4117214, "NOVA SANTA BARBARA", "PARANA", "PR", 41],
[4117222, "NOVA SANTA ROSA", "PARANA", "PR", 41],
[4117255, "NOVA PRATA DO IGUACU", "PARANA", "PR", 41],
[4117271, "NOVA TEBAS", "PARANA", "PR", 41],
[4117297, "NOVO ITACOLOMI", "PARANA", "PR", 41],
[4117305, "ORTIGUEIRA", "PARANA", "PR", 41],
[4117404, "OURIZONA", "PARANA", "PR", 41],
[4117453, "OURO VERDE DO OESTE", "PARANA", "PR", 41],
[4117503, "PAICANDU", "PARANA", "PR", 41],
[4117602, "PALMAS", "PARANA", "PR", 41],
[4117701, "PALMEIRA", "PARANA", "PR", 41],
[4117800, "PALMITAL", "PARANA", "PR", 41],
[4117909, "PALOTINA", "PARANA", "PR", 41],
[4118006, "PARAISO DO NORTE", "PARANA", "PR", 41],
[4118105, "PARANACITY", "PARANA", "PR", 41],
[4118204, "PARANAGUA", "PARANA", "PR", 41],
[4118303, "PARANAPOEMA", "PARANA", "PR", 41],
[4118402, "PARANAVAI", "PARANA", "PR", 41],
[4118451, "PATO BRAGADO", "PARANA", "PR", 41],
[4118501, "PATO BRANCO", "PARANA", "PR", 41],
[4118600, "PAULA FREITAS", "PARANA", "PR", 41],
[4118709, "PAULO FRONTIN", "PARANA", "PR", 41],
[4118808, "PEABIRU", "PARANA", "PR", 41],
[4118857, "PEROBAL", "PARANA", "PR", 41],
[4118907, "PEROLA", "PARANA", "PR", 41],
[4119004, "PEROLA D'OESTE", "PARANA", "PR", 41],
[4119103, "PIEN", "PARANA", "PR", 41],
[4119152, "PINHAIS", "PARANA", "PR", 41],
[4119202, "PINHALAO", "PARANA", "PR", 41],
[4119251, "PINHAL DE SAO BENTO", "PARANA", "PR", 41],
[4119301, "PINHAO", "PARANA", "PR", 41],
[4119400, "PIRAI DO SUL", "PARANA", "PR", 41],
[4119509, "PIRAQUARA", "PARANA", "PR", 41],
[4119608, "PITANGA", "PARANA", "PR", 41],
[4119657, "PITANGUEIRAS", "PARANA", "PR", 41],
[4119707, "PLANALTINA DO PARANA", "PARANA", "PR", 41],
[4119806, "PLANALTO", "PARANA", "PR", 41],
[4119905, "PONTA GROSSA", "PARANA", "PR", 41],
[4119954, "PONTAL DO PARANA", "PARANA", "PR", 41],
[4120002, "PORECATU", "PARANA", "PR", 41],
[4120101, "PORTO AMAZONAS", "PARANA", "PR", 41],
[4120150, "PORTO BARREIRO", "PARANA", "PR", 41],
[4120200, "PORTO RICO", "PARANA", "PR", 41],
[4120309, "PORTO VITORIA", "PARANA", "PR", 41],
[4120333, "PRADO FERREIRA", "PARANA", "PR", 41],
[4120358, "PRANCHITA", "PARANA", "PR", 41],
[4120408, "PRESIDENTE CASTELO BRANCO", "PARANA", "PR", 41],
[4120507, "PRIMEIRO DE MAIO", "PARANA", "PR", 41],
[4120606, "PRUDENTOPOLIS", "PARANA", "PR", 41],
[4120655, "QUARTO CENTENARIO", "PARANA", "PR", 41],
[4120705, "QUATIGUA", "PARANA", "PR", 41],
[4120804, "QUATRO BARRAS", "PARANA", "PR", 41],
[4120853, "QUATRO PONTES", "PARANA", "PR", 41],
[4120903, "QUEDAS DO IGUACU", "PARANA", "PR", 41],
[4121000, "QUERENCIA DO NORTE", "PARANA", "PR", 41],
[4121109, "QUINTA DO SOL", "PARANA", "PR", 41],
[4121208, "QUITANDINHA", "PARANA", "PR", 41],
[4121257, "RAMILANDIA", "PARANA", "PR", 41],
[4121307, "RANCHO ALEGRE", "PARANA", "PR", 41],
[4121356, "RANCHO ALEGRE D'OESTE", "PARANA", "PR", 41],
[4121406, "REALEZA", "PARANA", "PR", 41],
[4121505, "REBOUCAS", "PARANA", "PR", 41],
[4121604, "RENASCENCA", "PARANA", "PR", 41],
[4121703, "RESERVA", "PARANA", "PR", 41],
[4121752, "RESERVA DO IGUACU", "PARANA", "PR", 41],
[4121802, "RIBEIRAO CLARO", "PARANA", "PR", 41],
[4121901, "RIBEIRAO DO PINHAL", "PARANA", "PR", 41],
[4122008, "RIO AZUL", "PARANA", "PR", 41],
[4122107, "RIO BOM", "PARANA", "PR", 41],
[4122156, "RIO BONITO DO IGUACU", "PARANA", "PR", 41],
[4122172, "RIO BRANCO DO IVAI", "PARANA", "PR", 41],
[4122206, "RIO BRANCO DO SUL", "PARANA", "PR", 41],
[4122305, "RIO NEGRO", "PARANA", "PR", 41],
[4122404, "ROLANDIA", "PARANA", "PR", 41],
[4122503, "RONCADOR", "PARANA", "PR", 41],
[4122602, "RONDON", "PARANA", "PR", 41],
[4122651, "ROSARIO DO IVAI", "PARANA", "PR", 41],
[4122701, "SABAUDIA", "PARANA", "PR", 41],
[4122800, "SALGADO FILHO", "PARANA", "PR", 41],
[4122909, "SALTO DO ITARARE", "PARANA", "PR", 41],
[4123006, "SALTO DO LONTRA", "PARANA", "PR", 41],
[4123105, "SANTA AMELIA", "PARANA", "PR", 41],
[4123204, "SANTA CECILIA DO PAVAO", "PARANA", "PR", 41],
[4123303, "SANTA CRUZ DE MONTE CASTE", "PARANA", "PR", 41],
[4123402, "SANTA FE", "PARANA", "PR", 41],
[4123501, "SANTA HELENA", "PARANA", "PR", 41],
[4123600, "SANTA INES", "PARANA", "PR", 41],
[4123709, "SANTA ISABEL DO IVAI", "PARANA", "PR", 41],
[4123808, "SANTA IZABEL DO OESTE", "PARANA", "PR", 41],
[4123824, "SANTA LUCIA", "PARANA", "PR", 41],
[4123857, "SANTA MARIA DO OESTE", "PARANA", "PR", 41],
[4123907, "SANTA MARIANA", "PARANA", "PR", 41],
[4123956, "SANTA MONICA", "PARANA", "PR", 41],
[4124004, "SANTANA DO ITARARE", "PARANA", "PR", 41],
[4124020, "SANTA TEREZA DO OESTE", "PARANA", "PR", 41],
[4124053, "SANTA TEREZINHA DE ITAIPU", "PARANA", "PR", 41],
[4124103, "SANTO ANTONIO DA PLATINA", "PARANA", "PR", 41],
[4124202, "SANTO ANTONIO DO CAIUA", "PARANA", "PR", 41],
[4124301, "SANTO ANTONIO DO PARAISO", "PARANA", "PR", 41],
[4124400, "SANTO ANTONIO DO SUDOESTE", "PARANA", "PR", 41],
[4124509, "SANTO INACIO", "PARANA", "PR", 41],
[4124608, "SAO CARLOS DO IVAI", "PARANA", "PR", 41],
[4124707, "SAO JERONIMO DA SERRA", "PARANA", "PR", 41],
[4124806, "SAO JOAO", "PARANA", "PR", 41],
[4124905, "SAO JOAO DO CAIUA", "PARANA", "PR", 41],
[4125001, "SAO JOAO DO IVAI", "PARANA", "PR", 41],
[4125100, "SAO JOAO DO TRIUNFO", "PARANA", "PR", 41],
[4125209, "SAO JORGE D'OESTE", "PARANA", "PR", 41],
[4125308, "SAO JORGE DO IVAI", "PARANA", "PR", 41],
[4125357, "SAO JORGE DO PATROCINIO", "PARANA", "PR", 41],
[4125407, "SAO JOSE DA BOA VISTA", "PARANA", "PR", 41],
[4125456, "SAO JOSE DAS PALMEIRAS", "PARANA", "PR", 41],
[4125506, "SAO JOSE DOS PINHAIS", "PARANA", "PR", 41],
[4125555, "SAO MANOEL DO PARANA", "PARANA", "PR", 41],
[4125605, "SAO MATEUS DO SUL", "PARANA", "PR", 41],
[4125704, "SAO MIGUEL DO IGUACU", "PARANA", "PR", 41],
[4125753, "SAO PEDRO DO IGUACU", "PARANA", "PR", 41],
[4125803, "SAO PEDRO DO IVAI", "PARANA", "PR", 41],
[4125902, "SAO PEDRO DO PARANA", "PARANA", "PR", 41],
[4126009, "SAO SEBASTIAO DA AMOREIRA", "PARANA", "PR", 41],
[4126108, "SAO TOME", "PARANA", "PR", 41],
[4126207, "SAPOPEMA", "PARANA", "PR", 41],
[4126256, "SARANDI", "PARANA", "PR", 41],
[4126272, "SAUDADE DO IGUACU", "PARANA", "PR", 41],
[4126306, "SENGES", "PARANA", "PR", 41],
[4126355, "SERRANOPOLIS DO IGUACU", "PARANA", "PR", 41],
[4126405, "SERTANEJA", "PARANA", "PR", 41],
[4126504, "SERTANOPOLIS", "PARANA", "PR", 41],
[4126603, "SIQUEIRA CAMPOS", "PARANA", "PR", 41],
[4126652, "SULINA", "PARANA", "PR", 41],
[4126678, "TAMARANA", "PARANA", "PR", 41],
[4126702, "TAMBOARA", "PARANA", "PR", 41],
[4126801, "TAPEJARA", "PARANA", "PR", 41],
[4126900, "TAPIRA", "PARANA", "PR", 41],
[4127007, "TEIXEIRA SOARES", "PARANA", "PR", 41],
[4127106, "TELEMACO BORBA", "PARANA", "PR", 41],
[4127205, "TERRA BOA", "PARANA", "PR", 41],
[4127304, "TERRA RICA", "PARANA", "PR", 41],
[4127403, "TERRA ROXA", "PARANA", "PR", 41],
[4127502, "TIBAGI", "PARANA", "PR", 41],
[4127601, "TIJUCAS DO SUL", "PARANA", "PR", 41],
[4127700, "TOLEDO", "PARANA", "PR", 41],
[4127809, "TOMAZINA", "PARANA", "PR", 41],
[4127858, "TRES BARRAS DO PARANA", "PARANA", "PR", 41],
[4127882, "TUNAS DO PARANA", "PARANA", "PR", 41],
[4127908, "TUNEIRAS DO OESTE", "PARANA", "PR", 41],
[4127957, "TUPASSI", "PARANA", "PR", 41],
[4127965, "TURVO", "PARANA", "PR", 41],
[4128005, "UBIRATA", "PARANA", "PR", 41],
[4128104, "UMUARAMA", "PARANA", "PR", 41],
[4128203, "UNIAO DA VITORIA", "PARANA", "PR", 41],
[4128302, "UNIFLOR", "PARANA", "PR", 41],
[4128401, "URAI", "PARANA", "PR", 41],
[4128500, "WENCESLAU BRAZ", "PARANA", "PR", 41],
[4128534, "VENTANIA", "PARANA", "PR", 41],
[4128559, "VERA CRUZ DO OESTE", "PARANA", "PR", 41],
[4128609, "VERE", "PARANA", "PR", 41],
[4128625, "ALTO PARAISO", "PARANA", "PR", 41],
[4128633, "DOUTOR ULYSSES", "PARANA", "PR", 41],
[4128658, "VIRMOND", "PARANA", "PR", 41],
[4128708, "VITORINO", "PARANA", "PR", 41],
[4128807, "XAMBRE", "PARANA", "PR", 41],
[4200051, "ABDON BATISTA", "SANTA CATARINA", "SC", 42],
[4200101, "ABELARDO LUZ", "SANTA CATARINA", "SC", 42],
[4200200, "AGROLANDIA", "SANTA CATARINA", "SC", 42],
[4200309, "AGRONOMICA", "SANTA CATARINA", "SC", 42],
[4200408, "AGUA DOCE", "SANTA CATARINA", "SC", 42],
[4200507, "AGUAS DE CHAPECO", "SANTA CATARINA", "SC", 42],
[4200556, "AGUAS FRIAS", "SANTA CATARINA", "SC", 42],
[4200606, "AGUAS MORNAS", "SANTA CATARINA", "SC", 42],
[4200705, "ALFREDO WAGNER", "SANTA CATARINA", "SC", 42],
[4200754, "ALTO BELA VISTA", "SANTA CATARINA", "SC", 42],
[4200804, "ANCHIETA", "SANTA CATARINA", "SC", 42],
[4200903, "ANGELINA", "SANTA CATARINA", "SC", 42],
[4201000, "ANITA GARIBALDI", "SANTA CATARINA", "SC", 42],
[4201109, "ANITAPOLIS", "SANTA CATARINA", "SC", 42],
[4201208, "ANTONIO CARLOS", "SANTA CATARINA", "SC", 42],
[4201257, "APIUNA", "SANTA CATARINA", "SC", 42],
[4201273, "ARABUTA", "SANTA CATARINA", "SC", 42],
[4201307, "ARAQUARI", "SANTA CATARINA", "SC", 42],
[4201406, "ARARANGUA", "SANTA CATARINA", "SC", 42],
[4201505, "ARMAZEM", "SANTA CATARINA", "SC", 42],
[4201604, "ARROIO TRINTA", "SANTA CATARINA", "SC", 42],
[4201653, "ARVOREDO", "SANTA CATARINA", "SC", 42],
[4201703, "ASCURRA", "SANTA CATARINA", "SC", 42],
[4201802, "ATALANTA", "SANTA CATARINA", "SC", 42],
[4201901, "AURORA", "SANTA CATARINA", "SC", 42],
[4201950, "BALNEARIO ARROIO DO SILVA", "SANTA CATARINA", "SC", 42],
[4202008, "BALNEARIO CAMBORIU", "SANTA CATARINA", "SC", 42],
[4202057, "BALNEARIO BARRA DO SUL", "SANTA CATARINA", "SC", 42],
[4202073, "BALNEARIO GAIVOTA", "SANTA CATARINA", "SC", 42],
[4202081, "BANDEIRANTE", "SANTA CATARINA", "SC", 42],
[4202099, "BARRA BONITA", "SANTA CATARINA", "SC", 42],
[4202107, "BARRA VELHA", "SANTA CATARINA", "SC", 42],
[4202131, "BELA VISTA DO TOLDO", "SANTA CATARINA", "SC", 42],
[4202156, "BELMONTE", "SANTA CATARINA", "SC", 42],
[4202206, "BENEDITO NOVO", "SANTA CATARINA", "SC", 42],
[4202305, "BIGUACU", "SANTA CATARINA", "SC", 42],
[4202404, "BLUMENAU", "SANTA CATARINA", "SC", 42],
[4202438, "BOCAINA DO SUL", "SANTA CATARINA", "SC", 42],
[4202453, "BOMBINHAS", "SANTA CATARINA", "SC", 42],
[4202503, "BOM JARDIM DA SERRA", "SANTA CATARINA", "SC", 42],
[4202537, "BOM JESUS", "SANTA CATARINA", "SC", 42],
[4202578, "BOM JESUS DO OESTE", "SANTA CATARINA", "SC", 42],
[4202602, "BOM RETIRO", "SANTA CATARINA", "SC", 42],
[4202701, "BOTUVERA", "SANTA CATARINA", "SC", 42],
[4202800, "BRACO DO NORTE", "SANTA CATARINA", "SC", 42],
[4202859, "BRACO DO TROMBUDO", "SANTA CATARINA", "SC", 42],
[4202875, "BRUNOPOLIS", "SANTA CATARINA", "SC", 42],
[4202909, "BRUSQUE", "SANTA CATARINA", "SC", 42],
[4203006, "CACADOR", "SANTA CATARINA", "SC", 42],
[4203105, "CAIBI", "SANTA CATARINA", "SC", 42],
[4203154, "CALMON", "SANTA CATARINA", "SC", 42],
[4203204, "CAMBORIU", "SANTA CATARINA", "SC", 42],
[4203253, "CAPAO ALTO", "SANTA CATARINA", "SC", 42],
[4203303, "CAMPO ALEGRE", "SANTA CATARINA", "SC", 42],
[4203402, "CAMPO BELO DO SUL", "SANTA CATARINA", "SC", 42],
[4203501, "CAMPO ERE", "SANTA CATARINA", "SC", 42],
[4203600, "CAMPOS NOVOS", "SANTA CATARINA", "SC", 42],
[4203709, "CANELINHA", "SANTA CATARINA", "SC", 42],
[4203808, "CANOINHAS", "SANTA CATARINA", "SC", 42],
[4203907, "CAPINZAL", "SANTA CATARINA", "SC", 42],
[4203956, "CAPIVARI DE BAIXO", "SANTA CATARINA", "SC", 42],
[4204004, "CATANDUVAS", "SANTA CATARINA", "SC", 42],
[4204103, "CAXAMBU DO SUL", "SANTA CATARINA", "SC", 42],
[4204152, "CELSO RAMOS", "SANTA CATARINA", "SC", 42],
[4204178, "CERRO NEGRO", "SANTA CATARINA", "SC", 42],
[4204194, "CHAPADAO DO LAGEADO", "SANTA CATARINA", "SC", 42],
[4204202, "CHAPECO", "SANTA CATARINA", "SC", 42],
[4204251, "COCAL DO SUL", "SANTA CATARINA", "SC", 42],
[4204301, "CONCORDIA", "SANTA CATARINA", "SC", 42],
[4204350, "CORDILHEIRA ALTA", "SANTA CATARINA", "SC", 42],
[4204400, "CORONEL FREITAS", "SANTA CATARINA", "SC", 42],
[4204459, "CORONEL MARTINS", "SANTA CATARINA", "SC", 42],
[4204509, "CORUPA", "SANTA CATARINA", "SC", 42],
[4204558, "CORREIA PINTO", "SANTA CATARINA", "SC", 42],
[4204608, "CRICIUMA", "SANTA CATARINA", "SC", 42],
[4204707, "CUNHA PORA", "SANTA CATARINA", "SC", 42],
[4204756, "CUNHATAI", "SANTA CATARINA", "SC", 42],
[4204806, "CURITIBANOS", "SANTA CATARINA", "SC", 42],
[4204905, "DESCANSO", "SANTA CATARINA", "SC", 42],
[4205001, "DIONISIO CERQUEIRA", "SANTA CATARINA", "SC", 42],
[4205100, "DONA EMMA", "SANTA CATARINA", "SC", 42],
[4205159, "DOUTOR PEDRINHO", "SANTA CATARINA", "SC", 42],
[4205175, "ENTRE RIOS", "SANTA CATARINA", "SC", 42],
[4205191, "ERMO", "SANTA CATARINA", "SC", 42],
[4205209, "ERVAL VELHO", "SANTA CATARINA", "SC", 42],
[4205308, "FAXINAL DOS GUEDES", "SANTA CATARINA", "SC", 42],
[4205357, "FLOR DO SERTAO", "SANTA CATARINA", "SC", 42],
[4205407, "FLORIANOPOLIS", "SANTA CATARINA", "SC", 42],
[4205431, "FORMOSA DO SUL", "SANTA CATARINA", "SC", 42],
[4205456, "FORQUILHINHA", "SANTA CATARINA", "SC", 42],
[4205506, "FRAIBURGO", "SANTA CATARINA", "SC", 42],
[4205555, "FREI ROGERIO", "SANTA CATARINA", "SC", 42],
[4205605, "GALVAO", "SANTA CATARINA", "SC", 42],
[4205704, "GAROPABA", "SANTA CATARINA", "SC", 42],
[4205803, "GARUVA", "SANTA CATARINA", "SC", 42],
[4205902, "GASPAR", "SANTA CATARINA", "SC", 42],
[4206009, "GOVERNADOR CELSO RAMOS", "SANTA CATARINA", "SC", 42],
[4206108, "GRAO PARA", "SANTA CATARINA", "SC", 42],
[4206207, "GRAVATAL", "SANTA CATARINA", "SC", 42],
[4206306, "GUABIRUBA", "SANTA CATARINA", "SC", 42],
[4206405, "GUARACIABA", "SANTA CATARINA", "SC", 42],
[4206504, "GUARAMIRIM", "SANTA CATARINA", "SC", 42],
[4206603, "GUARUJA DO SUL", "SANTA CATARINA", "SC", 42],
[4206652, "GUATAMBU", "SANTA CATARINA", "SC", 42],
[4206702, "HERVAL D'OESTE", "SANTA CATARINA", "SC", 42],
[4206751, "IBIAM", "SANTA CATARINA", "SC", 42],
[4206801, "IBICARE", "SANTA CATARINA", "SC", 42],
[4206900, "IBIRAMA", "SANTA CATARINA", "SC", 42],
[4207007, "ICARA", "SANTA CATARINA", "SC", 42],
[4207106, "ILHOTA", "SANTA CATARINA", "SC", 42],
[4207205, "IMARUI", "SANTA CATARINA", "SC", 42],
[4207304, "IMBITUBA", "SANTA CATARINA", "SC", 42],
[4207403, "IMBUIA", "SANTA CATARINA", "SC", 42],
[4207502, "INDAIAL", "SANTA CATARINA", "SC", 42],
[4207577, "IOMERE", "SANTA CATARINA", "SC", 42],
[4207601, "IPIRA", "SANTA CATARINA", "SC", 42],
[4207650, "IPORA DO OESTE", "SANTA CATARINA", "SC", 42],
[4207684, "IPUACU", "SANTA CATARINA", "SC", 42],
[4207700, "IPUMIRIM", "SANTA CATARINA", "SC", 42],
[4207759, "IRACEMINHA", "SANTA CATARINA", "SC", 42],
[4207809, "IRANI", "SANTA CATARINA", "SC", 42],
[4207858, "IRATI", "SANTA CATARINA", "SC", 42],
[4207908, "IRINEOPOLIS", "SANTA CATARINA", "SC", 42],
[4208005, "ITA", "SANTA CATARINA", "SC", 42],
[4208104, "ITAIOPOLIS", "SANTA CATARINA", "SC", 42],
[4208203, "ITAJAI", "SANTA CATARINA", "SC", 42],
[4208302, "ITAPEMA", "SANTA CATARINA", "SC", 42],
[4208401, "ITAPIRANGA", "SANTA CATARINA", "SC", 42],
[4208450, "ITAPOA", "SANTA CATARINA", "SC", 42],
[4208500, "ITUPORANGA", "SANTA CATARINA", "SC", 42],
[4208609, "JABORA", "SANTA CATARINA", "SC", 42],
[4208708, "JACINTO MACHADO", "SANTA CATARINA", "SC", 42],
[4208807, "JAGUARUNA", "SANTA CATARINA", "SC", 42],
[4208906, "JARAGUA DO SUL", "SANTA CATARINA", "SC", 42],
[4208955, "JARDINOPOLIS", "SANTA CATARINA", "SC", 42],
[4209003, "JOACABA", "SANTA CATARINA", "SC", 42],
[4209102, "JOINVILLE", "SANTA CATARINA", "SC", 42],
[4209151, "JOSE BOITEUX", "SANTA CATARINA", "SC", 42],
[4209177, "JUPIA", "SANTA CATARINA", "SC", 42],
[4209201, "LACERDOPOLIS", "SANTA CATARINA", "SC", 42],
[4209300, "LAGES", "SANTA CATARINA", "SC", 42],
[4209409, "LAGUNA", "SANTA CATARINA", "SC", 42],
[4209458, "LAJEADO GRANDE", "SANTA CATARINA", "SC", 42],
[4209508, "LAURENTINO", "SANTA CATARINA", "SC", 42],
[4209607, "LAURO MULLER", "SANTA CATARINA", "SC", 42],
[4209706, "LEBON REGIS", "SANTA CATARINA", "SC", 42],
[4209805, "LEOBERTO LEAL", "SANTA CATARINA", "SC", 42],
[4209854, "LINDOIA DO SUL", "SANTA CATARINA", "SC", 42],
[4209904, "LONTRAS", "SANTA CATARINA", "SC", 42],
[4210001, "LUIZ ALVES", "SANTA CATARINA", "SC", 42],
[4210035, "LUZERNA", "SANTA CATARINA", "SC", 42],
[4210050, "MACIEIRA", "SANTA CATARINA", "SC", 42],
[4210100, "MAFRA", "SANTA CATARINA", "SC", 42],
[4210209, "MAJOR GERCINO", "SANTA CATARINA", "SC", 42],
[4210308, "MAJOR VIEIRA", "SANTA CATARINA", "SC", 42],
[4210407, "MARACAJA", "SANTA CATARINA", "SC", 42],
[4210506, "MARAVILHA", "SANTA CATARINA", "SC", 42],
[4210555, "MAREMA", "SANTA CATARINA", "SC", 42],
[4210605, "MASSARANDUBA", "SANTA CATARINA", "SC", 42],
[4210704, "MATOS COSTA", "SANTA CATARINA", "SC", 42],
[4210803, "MELEIRO", "SANTA CATARINA", "SC", 42],
[4210852, "MIRIM DOCE", "SANTA CATARINA", "SC", 42],
[4210902, "MODELO", "SANTA CATARINA", "SC", 42],
[4211009, "MONDAI", "SANTA CATARINA", "SC", 42],
[4211058, "MONTE CARLO", "SANTA CATARINA", "SC", 42],
[4211108, "MONTE CASTELO", "SANTA CATARINA", "SC", 42],
[4211207, "MORRO DA FUMACA", "SANTA CATARINA", "SC", 42],
[4211256, "MORRO GRANDE", "SANTA CATARINA", "SC", 42],
[4211306, "NAVEGANTES", "SANTA CATARINA", "SC", 42],
[4211405, "NOVA ERECHIM", "SANTA CATARINA", "SC", 42],
[4211454, "NOVA ITABERABA", "SANTA CATARINA", "SC", 42],
[4211504, "NOVA TRENTO", "SANTA CATARINA", "SC", 42],
[4211603, "NOVA VENEZA", "SANTA CATARINA", "SC", 42],
[4211652, "NOVO HORIZONTE", "SANTA CATARINA", "SC", 42],
[4211702, "ORLEANS", "SANTA CATARINA", "SC", 42],
[4211751, "OTACILIO COSTA", "SANTA CATARINA", "SC", 42],
[4211801, "OURO", "SANTA CATARINA", "SC", 42],
[4211850, "OURO VERDE", "SANTA CATARINA", "SC", 42],
[4211876, "PAIAL", "SANTA CATARINA", "SC", 42],
[4211892, "PAINEL", "SANTA CATARINA", "SC", 42],
[4211900, "PALHOCA", "SANTA CATARINA", "SC", 42],
[4212007, "PALMA SOLA", "SANTA CATARINA", "SC", 42],
[4212056, "PALMEIRA", "SANTA CATARINA", "SC", 42],
[4212106, "PALMITOS", "SANTA CATARINA", "SC", 42],
[4212205, "PAPANDUVA", "SANTA CATARINA", "SC", 42],
[4212239, "PARAISO", "SANTA CATARINA", "SC", 42],
[4212254, "PASSO DE TORRES", "SANTA CATARINA", "SC", 42],
[4212270, "PASSOS MAIA", "SANTA CATARINA", "SC", 42],
[4212304, "PAULO LOPES", "SANTA CATARINA", "SC", 42],
[4212403, "PEDRAS GRANDES", "SANTA CATARINA", "SC", 42],
[4212502, "PENHA", "SANTA CATARINA", "SC", 42],
[4212601, "PERITIBA", "SANTA CATARINA", "SC", 42],
[4212700, "PETROLANDIA", "SANTA CATARINA", "SC", 42],
[4212809, "BALNEARIO PICARRAS", "SANTA CATARINA", "SC", 42],
[4212908, "PINHALZINHO", "SANTA CATARINA", "SC", 42],
[4213005, "PINHEIRO PRETO", "SANTA CATARINA", "SC", 42],
[4213104, "PIRATUBA", "SANTA CATARINA", "SC", 42],
[4213153, "PLANALTO ALEGRE", "SANTA CATARINA", "SC", 42],
[4213203, "POMERODE", "SANTA CATARINA", "SC", 42],
[4213302, "PONTE ALTA", "SANTA CATARINA", "SC", 42],
[4213351, "PONTE ALTA DO NORTE", "SANTA CATARINA", "SC", 42],
[4213401, "PONTE SERRADA", "SANTA CATARINA", "SC", 42],
[4213500, "PORTO BELO", "SANTA CATARINA", "SC", 42],
[4213609, "PORTO UNIAO", "SANTA CATARINA", "SC", 42],
[4213708, "POUSO REDONDO", "SANTA CATARINA", "SC", 42],
[4213807, "PRAIA GRANDE", "SANTA CATARINA", "SC", 42],
[4213906, "PRESIDENTE CASTELLO BRANC", "SANTA CATARINA", "SC", 42],
[4214003, "PRESIDENTE GETULIO", "SANTA CATARINA", "SC", 42],
[4214102, "PRESIDENTE NEREU", "SANTA CATARINA", "SC", 42],
[4214151, "PRINCESA", "SANTA CATARINA", "SC", 42],
[4214201, "QUILOMBO", "SANTA CATARINA", "SC", 42],
[4214300, "RANCHO QUEIMADO", "SANTA CATARINA", "SC", 42],
[4214409, "RIO DAS ANTAS", "SANTA CATARINA", "SC", 42],
[4214508, "RIO DO CAMPO", "SANTA CATARINA", "SC", 42],
[4214607, "RIO DO OESTE", "SANTA CATARINA", "SC", 42],
[4214706, "RIO DOS CEDROS", "SANTA CATARINA", "SC", 42],
[4214805, "RIO DO SUL", "SANTA CATARINA", "SC", 42],
[4214904, "RIO FORTUNA", "SANTA CATARINA", "SC", 42],
[4215000, "RIO NEGRINHO", "SANTA CATARINA", "SC", 42],
[4215059, "RIO RUFINO", "SANTA CATARINA", "SC", 42],
[4215075, "RIQUEZA", "SANTA CATARINA", "SC", 42],
[4215109, "RODEIO", "SANTA CATARINA", "SC", 42],
[4215208, "ROMELANDIA", "SANTA CATARINA", "SC", 42],
[4215307, "SALETE", "SANTA CATARINA", "SC", 42],
[4215356, "SALTINHO", "SANTA CATARINA", "SC", 42],
[4215406, "SALTO VELOSO", "SANTA CATARINA", "SC", 42],
[4215455, "SANGAO", "SANTA CATARINA", "SC", 42],
[4215505, "SANTA CECILIA", "SANTA CATARINA", "SC", 42],
[4215554, "SANTA HELENA", "SANTA CATARINA", "SC", 42],
[4215604, "SANTA ROSA DE LIMA", "SANTA CATARINA", "SC", 42],
[4215653, "SANTA ROSA DO SUL", "SANTA CATARINA", "SC", 42],
[4215679, "SANTA TEREZINHA", "SANTA CATARINA", "SC", 42],
[4215687, "SANTA TEREZINHA DO PROGRE", "SANTA CATARINA", "SC", 42],
[4215695, "SANTIAGO DO SUL", "SANTA CATARINA", "SC", 42],
[4215703, "SANTO AMARO DA IMPERATRIZ", "SANTA CATARINA", "SC", 42],
[4215752, "SAO BERNARDINO", "SANTA CATARINA", "SC", 42],
[4215802, "SAO BENTO DO SUL", "SANTA CATARINA", "SC", 42],
[4215901, "SAO BONIFACIO", "SANTA CATARINA", "SC", 42],
[4216008, "SAO CARLOS", "SANTA CATARINA", "SC", 42],
[4216057, "SAO CRISTOVAO DO SUL", "SANTA CATARINA", "SC", 42],
[4216107, "SAO DOMINGOS", "SANTA CATARINA", "SC", 42],
[4216206, "SAO FRANCISCO DO SUL", "SANTA CATARINA", "SC", 42],
[4216255, "SAO JOAO DO OESTE", "SANTA CATARINA", "SC", 42],
[4216305, "SAO JOAO BATISTA", "SANTA CATARINA", "SC", 42],
[4216354, "SAO JOAO DO ITAPERIU", "SANTA CATARINA", "SC", 42],
[4216404, "SAO JOAO DO SUL", "SANTA CATARINA", "SC", 42],
[4216503, "SAO JOAQUIM", "SANTA CATARINA", "SC", 42],
[4216602, "SAO JOSE", "SANTA CATARINA", "SC", 42],
[4216701, "SAO JOSE DO CEDRO", "SANTA CATARINA", "SC", 42],
[4216800, "SAO JOSE DO CERRITO", "SANTA CATARINA", "SC", 42],
[4216909, "SAO LOURENCO DO OESTE", "SANTA CATARINA", "SC", 42],
[4217006, "SAO LUDGERO", "SANTA CATARINA", "SC", 42],
[4217105, "SAO MARTINHO", "SANTA CATARINA", "SC", 42],
[4217154, "SAO MIGUEL DA BOA VISTA", "SANTA CATARINA", "SC", 42],
[4217204, "SAO MIGUEL DO OESTE", "SANTA CATARINA", "SC", 42],
[4217253, "SAO PEDRO DE ALCANTARA", "SANTA CATARINA", "SC", 42],
[4217303, "SAUDADES", "SANTA CATARINA", "SC", 42],
[4217402, "SCHROEDER", "SANTA CATARINA", "SC", 42],
[4217501, "SEARA", "SANTA CATARINA", "SC", 42],
[4217550, "SERRA ALTA", "SANTA CATARINA", "SC", 42],
[4217600, "SIDEROPOLIS", "SANTA CATARINA", "SC", 42],
[4217709, "SOMBRIO", "SANTA CATARINA", "SC", 42],
[4217758, "SUL BRASIL", "SANTA CATARINA", "SC", 42],
[4217808, "TAIO", "SANTA CATARINA", "SC", 42],
[4217907, "TANGARA", "SANTA CATARINA", "SC", 42],
[4217956, "TIGRINHOS", "SANTA CATARINA", "SC", 42],
[4218004, "TIJUCAS", "SANTA CATARINA", "SC", 42],
[4218103, "TIMBE DO SUL", "SANTA CATARINA", "SC", 42],
[4218202, "TIMBO", "SANTA CATARINA", "SC", 42],
[4218251, "TIMBO GRANDE", "SANTA CATARINA", "SC", 42],
[4218301, "TRES BARRAS", "SANTA CATARINA", "SC", 42],
[4218350, "TREVISO", "SANTA CATARINA", "SC", 42],
[4218400, "TREZE DE MAIO", "SANTA CATARINA", "SC", 42],
[4218509, "TREZE TILIAS", "SANTA CATARINA", "SC", 42],
[4218608, "TROMBUDO CENTRAL", "SANTA CATARINA", "SC", 42],
[4218707, "TUBARAO", "SANTA CATARINA", "SC", 42],
[4218756, "TUNAPOLIS", "SANTA CATARINA", "SC", 42],
[4218806, "TURVO", "SANTA CATARINA", "SC", 42],
[4218855, "UNIAO DO OESTE", "SANTA CATARINA", "SC", 42],
[4218905, "URUBICI", "SANTA CATARINA", "SC", 42],
[4218954, "URUPEMA", "SANTA CATARINA", "SC", 42],
[4219002, "URUSSANGA", "SANTA CATARINA", "SC", 42],
[4219101, "VARGEAO", "SANTA CATARINA", "SC", 42],
[4219150, "VARGEM", "SANTA CATARINA", "SC", 42],
[4219176, "VARGEM BONITA", "SANTA CATARINA", "SC", 42],
[4219200, "VIDAL RAMOS", "SANTA CATARINA", "SC", 42],
[4219309, "VIDEIRA", "SANTA CATARINA", "SC", 42],
[4219358, "VITOR MEIRELES", "SANTA CATARINA", "SC", 42],
[4219408, "WITMARSUM", "SANTA CATARINA", "SC", 42],
[4219507, "XANXERE", "SANTA CATARINA", "SC", 42],
[4219606, "XAVANTINA", "SANTA CATARINA", "SC", 42],
[4219705, "XAXIM", "SANTA CATARINA", "SC", 42],
[4219853, "ZORTEA", "SANTA CATARINA", "SC", 42],
[4300034, "ACEGUA", "RIO GRANDE DO SUL", "RS", 43],
[4300059, "AGUA SANTA", "RIO GRANDE DO SUL", "RS", 43],
[4300109, "AGUDO", "RIO GRANDE DO SUL", "RS", 43],
[4300208, "AJURICABA", "RIO GRANDE DO SUL", "RS", 43],
[4300307, "ALECRIM", "RIO GRANDE DO SUL", "RS", 43],
[4300406, "ALEGRETE", "RIO GRANDE DO SUL", "RS", 43],
[4300455, "ALEGRIA", "RIO GRANDE DO SUL", "RS", 43],
[4300471, "ALMIRANTE TAMANDARE DO SU", "RIO GRANDE DO SUL", "RS", 43],
[4300505, "ALPESTRE", "RIO GRANDE DO SUL", "RS", 43],
[4300554, "ALTO ALEGRE", "RIO GRANDE DO SUL", "RS", 43],
[4300570, "ALTO FELIZ", "RIO GRANDE DO SUL", "RS", 43],
[4300604, "ALVORADA", "RIO GRANDE DO SUL", "RS", 43],
[4300638, "AMARAL FERRADOR", "RIO GRANDE DO SUL", "RS", 43],
[4300646, "AMETISTA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4300661, "ANDRE DA ROCHA", "RIO GRANDE DO SUL", "RS", 43],
[4300703, "ANTA GORDA", "RIO GRANDE DO SUL", "RS", 43],
[4300802, "ANTONIO PRADO", "RIO GRANDE DO SUL", "RS", 43],
[4300851, "ARAMBARE", "RIO GRANDE DO SUL", "RS", 43],
[4300877, "ARARICA", "RIO GRANDE DO SUL", "RS", 43],
[4300901, "ARATIBA", "RIO GRANDE DO SUL", "RS", 43],
[4301008, "ARROIO DO MEIO", "RIO GRANDE DO SUL", "RS", 43],
[4301057, "ARROIO DO SAL", "RIO GRANDE DO SUL", "RS", 43],
[4301073, "ARROIO DO PADRE", "RIO GRANDE DO SUL", "RS", 43],
[4301107, "ARROIO DOS RATOS", "RIO GRANDE DO SUL", "RS", 43],
[4301206, "ARROIO DO TIGRE", "RIO GRANDE DO SUL", "RS", 43],
[4301305, "ARROIO GRANDE", "RIO GRANDE DO SUL", "RS", 43],
[4301404, "ARVOREZINHA", "RIO GRANDE DO SUL", "RS", 43],
[4301503, "AUGUSTO PESTANA", "RIO GRANDE DO SUL", "RS", 43],
[4301552, "AUREA", "RIO GRANDE DO SUL", "RS", 43],
[4301602, "BAGE", "RIO GRANDE DO SUL", "RS", 43],
[4301636, "BALNEARIO PINHAL", "RIO GRANDE DO SUL", "RS", 43],
[4301651, "BARAO", "RIO GRANDE DO SUL", "RS", 43],
[4301701, "BARAO DE COTEGIPE", "RIO GRANDE DO SUL", "RS", 43],
[4301750, "BARAO DO TRIUNFO", "RIO GRANDE DO SUL", "RS", 43],
[4301800, "BARRACAO", "RIO GRANDE DO SUL", "RS", 43],
[4301859, "BARRA DO GUARITA", "RIO GRANDE DO SUL", "RS", 43],
[4301875, "BARRA DO QUARAI", "RIO GRANDE DO SUL", "RS", 43],
[4301909, "BARRA DO RIBEIRO", "RIO GRANDE DO SUL", "RS", 43],
[4301925, "BARRA DO RIO AZUL", "RIO GRANDE DO SUL", "RS", 43],
[4301958, "BARRA FUNDA", "RIO GRANDE DO SUL", "RS", 43],
[4302006, "BARROS CASSAL", "RIO GRANDE DO SUL", "RS", 43],
[4302055, "BENJAMIN CONSTANT DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4302105, "BENTO GONCALVES", "RIO GRANDE DO SUL", "RS", 43],
[4302154, "BOA VISTA DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4302204, "BOA VISTA DO BURICA", "RIO GRANDE DO SUL", "RS", 43],
[4302220, "BOA VISTA DO CADEADO", "RIO GRANDE DO SUL", "RS", 43],
[4302238, "BOA VISTA DO INCRA", "RIO GRANDE DO SUL", "RS", 43],
[4302253, "BOA VISTA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4302303, "BOM JESUS", "RIO GRANDE DO SUL", "RS", 43],
[4302352, "BOM PRINCIPIO", "RIO GRANDE DO SUL", "RS", 43],
[4302378, "BOM PROGRESSO", "RIO GRANDE DO SUL", "RS", 43],
[4302402, "BOM RETIRO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4302451, "BOQUEIRAO DO LEAO", "RIO GRANDE DO SUL", "RS", 43],
[4302501, "BOSSOROCA", "RIO GRANDE DO SUL", "RS", 43],
[4302584, "BOZANO", "RIO GRANDE DO SUL", "RS", 43],
[4302600, "BRAGA", "RIO GRANDE DO SUL", "RS", 43],
[4302659, "BROCHIER", "RIO GRANDE DO SUL", "RS", 43],
[4302709, "BUTIA", "RIO GRANDE DO SUL", "RS", 43],
[4302808, "CACAPAVA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4302907, "CACEQUI", "RIO GRANDE DO SUL", "RS", 43],
[4303004, "CACHOEIRA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4303103, "CACHOEIRINHA", "RIO GRANDE DO SUL", "RS", 43],
[4303202, "CACIQUE DOBLE", "RIO GRANDE DO SUL", "RS", 43],
[4303301, "CAIBATE", "RIO GRANDE DO SUL", "RS", 43],
[4303400, "CAICARA", "RIO GRANDE DO SUL", "RS", 43],
[4303509, "CAMAQUA", "RIO GRANDE DO SUL", "RS", 43],
[4303558, "CAMARGO", "RIO GRANDE DO SUL", "RS", 43],
[4303608, "CAMBARA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4303673, "CAMPESTRE DA SERRA", "RIO GRANDE DO SUL", "RS", 43],
[4303707, "CAMPINA DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4303806, "CAMPINAS DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4303905, "CAMPO BOM", "RIO GRANDE DO SUL", "RS", 43],
[4304002, "CAMPO NOVO", "RIO GRANDE DO SUL", "RS", 43],
[4304101, "CAMPOS BORGES", "RIO GRANDE DO SUL", "RS", 43],
[4304200, "CANDELARIA", "RIO GRANDE DO SUL", "RS", 43],
[4304309, "CANDIDO GODOI", "RIO GRANDE DO SUL", "RS", 43],
[4304358, "CANDIOTA", "RIO GRANDE DO SUL", "RS", 43],
[4304408, "CANELA", "RIO GRANDE DO SUL", "RS", 43],
[4304507, "CANGUCU", "RIO GRANDE DO SUL", "RS", 43],
[4304606, "CANOAS", "RIO GRANDE DO SUL", "RS", 43],
[4304614, "CANUDOS DO VALE", "RIO GRANDE DO SUL", "RS", 43],
[4304622, "CAPAO BONITO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4304630, "CAPAO DA CANOA", "RIO GRANDE DO SUL", "RS", 43],
[4304655, "CAPAO DO CIPO", "RIO GRANDE DO SUL", "RS", 43],
[4304663, "CAPAO DO LEAO", "RIO GRANDE DO SUL", "RS", 43],
[4304671, "CAPIVARI DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4304689, "CAPELA DE SANTANA", "RIO GRANDE DO SUL", "RS", 43],
[4304697, "CAPITAO", "RIO GRANDE DO SUL", "RS", 43],
[4304705, "CARAZINHO", "RIO GRANDE DO SUL", "RS", 43],
[4304713, "CARAA", "RIO GRANDE DO SUL", "RS", 43],
[4304804, "CARLOS BARBOSA", "RIO GRANDE DO SUL", "RS", 43],
[4304853, "CARLOS GOMES", "RIO GRANDE DO SUL", "RS", 43],
[4304903, "CASCA", "RIO GRANDE DO SUL", "RS", 43],
[4304952, "CASEIROS", "RIO GRANDE DO SUL", "RS", 43],
[4305009, "CATUIPE", "RIO GRANDE DO SUL", "RS", 43],
[4305108, "CAXIAS DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4305116, "CENTENARIO", "RIO GRANDE DO SUL", "RS", 43],
[4305124, "CERRITO", "RIO GRANDE DO SUL", "RS", 43],
[4305132, "CERRO BRANCO", "RIO GRANDE DO SUL", "RS", 43],
[4305157, "CERRO GRANDE", "RIO GRANDE DO SUL", "RS", 43],
[4305173, "CERRO GRANDE DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4305207, "CERRO LARGO", "RIO GRANDE DO SUL", "RS", 43],
[4305306, "CHAPADA", "RIO GRANDE DO SUL", "RS", 43],
[4305355, "CHARQUEADAS", "RIO GRANDE DO SUL", "RS", 43],
[4305371, "CHARRUA", "RIO GRANDE DO SUL", "RS", 43],
[4305405, "CHIAPETTA", "RIO GRANDE DO SUL", "RS", 43],
[4305439, "CHUI", "RIO GRANDE DO SUL", "RS", 43],
[4305447, "CHUVISCA", "RIO GRANDE DO SUL", "RS", 43],
[4305454, "CIDREIRA", "RIO GRANDE DO SUL", "RS", 43],
[4305504, "CIRIACO", "RIO GRANDE DO SUL", "RS", 43],
[4305587, "COLINAS", "RIO GRANDE DO SUL", "RS", 43],
[4305603, "COLORADO", "RIO GRANDE DO SUL", "RS", 43],
[4305702, "CONDOR", "RIO GRANDE DO SUL", "RS", 43],
[4305801, "CONSTANTINA", "RIO GRANDE DO SUL", "RS", 43],
[4305835, "COQUEIRO BAIXO", "RIO GRANDE DO SUL", "RS", 43],
[4305850, "COQUEIROS DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4305871, "CORONEL BARROS", "RIO GRANDE DO SUL", "RS", 43],
[4305900, "CORONEL BICACO", "RIO GRANDE DO SUL", "RS", 43],
[4305934, "CORONEL PILAR", "RIO GRANDE DO SUL", "RS", 43],
[4305959, "COTIPORA", "RIO GRANDE DO SUL", "RS", 43],
[4305975, "COXILHA", "RIO GRANDE DO SUL", "RS", 43],
[4306007, "CRISSIUMAL", "RIO GRANDE DO SUL", "RS", 43],
[4306056, "CRISTAL", "RIO GRANDE DO SUL", "RS", 43],
[4306072, "CRISTAL DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4306106, "CRUZ ALTA", "RIO GRANDE DO SUL", "RS", 43],
[4306130, "CRUZALTENSE", "RIO GRANDE DO SUL", "RS", 43],
[4306205, "CRUZEIRO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4306304, "DAVID CANABARRO", "RIO GRANDE DO SUL", "RS", 43],
[4306320, "DERRUBADAS", "RIO GRANDE DO SUL", "RS", 43],
[4306353, "DEZESSEIS DE NOVEMBRO", "RIO GRANDE DO SUL", "RS", 43],
[4306379, "DILERMANDO DE AGUIAR", "RIO GRANDE DO SUL", "RS", 43],
[4306403, "DOIS IRMAOS", "RIO GRANDE DO SUL", "RS", 43],
[4306429, "DOIS IRMAOS DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4306452, "DOIS LAJEADOS", "RIO GRANDE DO SUL", "RS", 43],
[4306502, "DOM FELICIANO", "RIO GRANDE DO SUL", "RS", 43],
[4306551, "DOM PEDRO DE ALCANTARA", "RIO GRANDE DO SUL", "RS", 43],
[4306601, "DOM PEDRITO", "RIO GRANDE DO SUL", "RS", 43],
[4306700, "DONA FRANCISCA", "RIO GRANDE DO SUL", "RS", 43],
[4306734, "DOUTOR MAURICIO CARDOSO", "RIO GRANDE DO SUL", "RS", 43],
[4306759, "DOUTOR RICARDO", "RIO GRANDE DO SUL", "RS", 43],
[4306767, "ELDORADO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4306809, "ENCANTADO", "RIO GRANDE DO SUL", "RS", 43],
[4306908, "ENCRUZILHADA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4306924, "ENGENHO VELHO", "RIO GRANDE DO SUL", "RS", 43],
[4306932, "ENTRE-IJUIS", "RIO GRANDE DO SUL", "RS", 43],
[4306957, "ENTRE RIOS DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4306973, "EREBANGO", "RIO GRANDE DO SUL", "RS", 43],
[4307005, "ERECHIM", "RIO GRANDE DO SUL", "RS", 43],
[4307054, "ERNESTINA", "RIO GRANDE DO SUL", "RS", 43],
[4307104, "HERVAL", "RIO GRANDE DO SUL", "RS", 43],
[4307203, "ERVAL GRANDE", "RIO GRANDE DO SUL", "RS", 43],
[4307302, "ERVAL SECO", "RIO GRANDE DO SUL", "RS", 43],
[4307401, "ESMERALDA", "RIO GRANDE DO SUL", "RS", 43],
[4307450, "ESPERANCA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4307500, "ESPUMOSO", "RIO GRANDE DO SUL", "RS", 43],
[4307559, "ESTACAO", "RIO GRANDE DO SUL", "RS", 43],
[4307609, "ESTANCIA VELHA", "RIO GRANDE DO SUL", "RS", 43],
[4307708, "ESTEIO", "RIO GRANDE DO SUL", "RS", 43],
[4307807, "ESTRELA", "RIO GRANDE DO SUL", "RS", 43],
[4307815, "ESTRELA VELHA", "RIO GRANDE DO SUL", "RS", 43],
[4307831, "EUGENIO DE CASTRO", "RIO GRANDE DO SUL", "RS", 43],
[4307864, "FAGUNDES VARELA", "RIO GRANDE DO SUL", "RS", 43],
[4307906, "FARROUPILHA", "RIO GRANDE DO SUL", "RS", 43],
[4308003, "FAXINAL DO SOTURNO", "RIO GRANDE DO SUL", "RS", 43],
[4308052, "FAXINALZINHO", "RIO GRANDE DO SUL", "RS", 43],
[4308078, "FAZENDA VILANOVA", "RIO GRANDE DO SUL", "RS", 43],
[4308102, "FELIZ", "RIO GRANDE DO SUL", "RS", 43],
[4308201, "FLORES DA CUNHA", "RIO GRANDE DO SUL", "RS", 43],
[4308250, "FLORIANO PEIXOTO", "RIO GRANDE DO SUL", "RS", 43],
[4308300, "FONTOURA XAVIER", "RIO GRANDE DO SUL", "RS", 43],
[4308409, "FORMIGUEIRO", "RIO GRANDE DO SUL", "RS", 43],
[4308433, "FORQUETINHA", "RIO GRANDE DO SUL", "RS", 43],
[4308458, "FORTALEZA DOS VALOS", "RIO GRANDE DO SUL", "RS", 43],
[4308508, "FREDERICO WESTPHALEN", "RIO GRANDE DO SUL", "RS", 43],
[4308607, "GARIBALDI", "RIO GRANDE DO SUL", "RS", 43],
[4308656, "GARRUCHOS", "RIO GRANDE DO SUL", "RS", 43],
[4308706, "GAURAMA", "RIO GRANDE DO SUL", "RS", 43],
[4308805, "GENERAL CAMARA", "RIO GRANDE DO SUL", "RS", 43],
[4308854, "GENTIL", "RIO GRANDE DO SUL", "RS", 43],
[4308904, "GETULIO VARGAS", "RIO GRANDE DO SUL", "RS", 43],
[4309001, "GIRUA", "RIO GRANDE DO SUL", "RS", 43],
[4309050, "GLORINHA", "RIO GRANDE DO SUL", "RS", 43],
[4309100, "GRAMADO", "RIO GRANDE DO SUL", "RS", 43],
[4309126, "GRAMADO DOS LOUREIROS", "RIO GRANDE DO SUL", "RS", 43],
[4309159, "GRAMADO XAVIER", "RIO GRANDE DO SUL", "RS", 43],
[4309209, "GRAVATAI", "RIO GRANDE DO SUL", "RS", 43],
[4309258, "GUABIJU", "RIO GRANDE DO SUL", "RS", 43],
[4309308, "GUAIBA", "RIO GRANDE DO SUL", "RS", 43],
[4309407, "GUAPORE", "RIO GRANDE DO SUL", "RS", 43],
[4309506, "GUARANI DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4309555, "HARMONIA", "RIO GRANDE DO SUL", "RS", 43],
[4309571, "HERVEIRAS", "RIO GRANDE DO SUL", "RS", 43],
[4309605, "HORIZONTINA", "RIO GRANDE DO SUL", "RS", 43],
[4309654, "HULHA NEGRA", "RIO GRANDE DO SUL", "RS", 43],
[4309704, "HUMAITA", "RIO GRANDE DO SUL", "RS", 43],
[4309753, "IBARAMA", "RIO GRANDE DO SUL", "RS", 43],
[4309803, "IBIACA", "RIO GRANDE DO SUL", "RS", 43],
[4309902, "IBIRAIARAS", "RIO GRANDE DO SUL", "RS", 43],
[4309951, "IBIRAPUITA", "RIO GRANDE DO SUL", "RS", 43],
[4310009, "IBIRUBA", "RIO GRANDE DO SUL", "RS", 43],
[4310108, "IGREJINHA", "RIO GRANDE DO SUL", "RS", 43],
[4310207, "IJUI", "RIO GRANDE DO SUL", "RS", 43],
[4310306, "ILOPOLIS", "RIO GRANDE DO SUL", "RS", 43],
[4310330, "IMBE", "RIO GRANDE DO SUL", "RS", 43],
[4310363, "IMIGRANTE", "RIO GRANDE DO SUL", "RS", 43],
[4310405, "INDEPENDENCIA", "RIO GRANDE DO SUL", "RS", 43],
[4310413, "INHACORA", "RIO GRANDE DO SUL", "RS", 43],
[4310439, "IPE", "RIO GRANDE DO SUL", "RS", 43],
[4310462, "IPIRANGA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4310504, "IRAI", "RIO GRANDE DO SUL", "RS", 43],
[4310538, "ITAARA", "RIO GRANDE DO SUL", "RS", 43],
[4310553, "ITACURUBI", "RIO GRANDE DO SUL", "RS", 43],
[4310579, "ITAPUCA", "RIO GRANDE DO SUL", "RS", 43],
[4310603, "ITAQUI", "RIO GRANDE DO SUL", "RS", 43],
[4310652, "ITATI", "RIO GRANDE DO SUL", "RS", 43],
[4310702, "ITATIBA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4310751, "IVORA", "RIO GRANDE DO SUL", "RS", 43],
[4310801, "IVOTI", "RIO GRANDE DO SUL", "RS", 43],
[4310850, "JABOTICABA", "RIO GRANDE DO SUL", "RS", 43],
[4310876, "JACUIZINHO", "RIO GRANDE DO SUL", "RS", 43],
[4310900, "JACUTINGA", "RIO GRANDE DO SUL", "RS", 43],
[4311007, "JAGUARAO", "RIO GRANDE DO SUL", "RS", 43],
[4311106, "JAGUARI", "RIO GRANDE DO SUL", "RS", 43],
[4311122, "JAQUIRANA", "RIO GRANDE DO SUL", "RS", 43],
[4311130, "JARI", "RIO GRANDE DO SUL", "RS", 43],
[4311155, "JOIA", "RIO GRANDE DO SUL", "RS", 43],
[4311205, "JULIO DE CASTILHOS", "RIO GRANDE DO SUL", "RS", 43],
[4311239, "LAGOA BONITA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4311254, "LAGOAO", "RIO GRANDE DO SUL", "RS", 43],
[4311270, "LAGOA DOS TRES CANTOS", "RIO GRANDE DO SUL", "RS", 43],
[4311304, "LAGOA VERMELHA", "RIO GRANDE DO SUL", "RS", 43],
[4311403, "LAJEADO", "RIO GRANDE DO SUL", "RS", 43],
[4311429, "LAJEADO DO BUGRE", "RIO GRANDE DO SUL", "RS", 43],
[4311502, "LAVRAS DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4311601, "LIBERATO SALZANO", "RIO GRANDE DO SUL", "RS", 43],
[4311627, "LINDOLFO COLLOR", "RIO GRANDE DO SUL", "RS", 43],
[4311643, "LINHA NOVA", "RIO GRANDE DO SUL", "RS", 43],
[4311700, "MACHADINHO", "RIO GRANDE DO SUL", "RS", 43],
[4311718, "MACAMBARA", "RIO GRANDE DO SUL", "RS", 43],
[4311734, "MAMPITUBA", "RIO GRANDE DO SUL", "RS", 43],
[4311759, "MANOEL VIANA", "RIO GRANDE DO SUL", "RS", 43],
[4311775, "MAQUINE", "RIO GRANDE DO SUL", "RS", 43],
[4311791, "MARATA", "RIO GRANDE DO SUL", "RS", 43],
[4311809, "MARAU", "RIO GRANDE DO SUL", "RS", 43],
[4311908, "MARCELINO RAMOS", "RIO GRANDE DO SUL", "RS", 43],
[4311981, "MARIANA PIMENTEL", "RIO GRANDE DO SUL", "RS", 43],
[4312005, "MARIANO MORO", "RIO GRANDE DO SUL", "RS", 43],
[4312054, "MARQUES DE SOUZA", "RIO GRANDE DO SUL", "RS", 43],
[4312104, "MATA", "RIO GRANDE DO SUL", "RS", 43],
[4312138, "MATO CASTELHANO", "RIO GRANDE DO SUL", "RS", 43],
[4312153, "MATO LEITAO", "RIO GRANDE DO SUL", "RS", 43],
[4312179, "MATO QUEIMADO", "RIO GRANDE DO SUL", "RS", 43],
[4312203, "MAXIMILIANO DE ALMEIDA", "RIO GRANDE DO SUL", "RS", 43],
[4312252, "MINAS DO LEAO", "RIO GRANDE DO SUL", "RS", 43],
[4312302, "MIRAGUAI", "RIO GRANDE DO SUL", "RS", 43],
[4312351, "MONTAURI", "RIO GRANDE DO SUL", "RS", 43],
[4312377, "MONTE ALEGRE DOS CAMPOS", "RIO GRANDE DO SUL", "RS", 43],
[4312385, "MONTE BELO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4312401, "MONTENEGRO", "RIO GRANDE DO SUL", "RS", 43],
[4312427, "MORMACO", "RIO GRANDE DO SUL", "RS", 43],
[4312443, "MORRINHOS DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4312450, "MORRO REDONDO", "RIO GRANDE DO SUL", "RS", 43],
[4312476, "MORRO REUTER", "RIO GRANDE DO SUL", "RS", 43],
[4312500, "MOSTARDAS", "RIO GRANDE DO SUL", "RS", 43],
[4312609, "MUCUM", "RIO GRANDE DO SUL", "RS", 43],
[4312617, "MUITOS CAPÕES", "RIO GRANDE DO SUL", "RS", 43],
[4312625, "MULITERNO", "RIO GRANDE DO SUL", "RS", 43],
[4312658, "NAO-ME-TOQUE", "RIO GRANDE DO SUL", "RS", 43],
[4312674, "NICOLAU VERGUEIRO", "RIO GRANDE DO SUL", "RS", 43],
[4312708, "NONOAI", "RIO GRANDE DO SUL", "RS", 43],
[4312757, "NOVA ALVORADA", "RIO GRANDE DO SUL", "RS", 43],
[4312807, "NOVA ARACA", "RIO GRANDE DO SUL", "RS", 43],
[4312906, "NOVA BASSANO", "RIO GRANDE DO SUL", "RS", 43],
[4312955, "NOVA BOA VISTA", "RIO GRANDE DO SUL", "RS", 43],
[4313003, "NOVA BRESCIA", "RIO GRANDE DO SUL", "RS", 43],
[4313011, "NOVA CANDELARIA", "RIO GRANDE DO SUL", "RS", 43],
[4313037, "NOVA ESPERANCA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4313060, "NOVA HARTZ", "RIO GRANDE DO SUL", "RS", 43],
[4313086, "NOVA PADUA", "RIO GRANDE DO SUL", "RS", 43],
[4313102, "NOVA PALMA", "RIO GRANDE DO SUL", "RS", 43],
[4313201, "NOVA PETROPOLIS", "RIO GRANDE DO SUL", "RS", 43],
[4313300, "NOVA PRATA", "RIO GRANDE DO SUL", "RS", 43],
[4313334, "NOVA RAMADA", "RIO GRANDE DO SUL", "RS", 43],
[4313359, "NOVA ROMA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4313375, "NOVA SANTA RITA", "RIO GRANDE DO SUL", "RS", 43],
[4313391, "NOVO CABRAIS", "RIO GRANDE DO SUL", "RS", 43],
[4313409, "NOVO HAMBURGO", "RIO GRANDE DO SUL", "RS", 43],
[4313425, "NOVO MACHADO", "RIO GRANDE DO SUL", "RS", 43],
[4313441, "NOVO TIRADENTES", "RIO GRANDE DO SUL", "RS", 43],
[4313466, "NOVO XINGU", "RIO GRANDE DO SUL", "RS", 43],
[4313490, "NOVO BARREIRO", "RIO GRANDE DO SUL", "RS", 43],
[4313508, "OSORIO", "RIO GRANDE DO SUL", "RS", 43],
[4313607, "PAIM FILHO", "RIO GRANDE DO SUL", "RS", 43],
[4313656, "PALMARES DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4313706, "PALMEIRA DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4313805, "PALMITINHO", "RIO GRANDE DO SUL", "RS", 43],
[4313904, "PANAMBI", "RIO GRANDE DO SUL", "RS", 43],
[4313953, "PANTANO GRANDE", "RIO GRANDE DO SUL", "RS", 43],
[4314001, "PARAI", "RIO GRANDE DO SUL", "RS", 43],
[4314027, "PARAISO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4314035, "PARECI NOVO", "RIO GRANDE DO SUL", "RS", 43],
[4314050, "PAROBE", "RIO GRANDE DO SUL", "RS", 43],
[4314068, "PASSA SETE", "RIO GRANDE DO SUL", "RS", 43],
[4314076, "PASSO DO SOBRADO", "RIO GRANDE DO SUL", "RS", 43],
[4314100, "PASSO FUNDO", "RIO GRANDE DO SUL", "RS", 43],
[4314134, "PAULO BENTO", "RIO GRANDE DO SUL", "RS", 43],
[4314159, "PAVERAMA", "RIO GRANDE DO SUL", "RS", 43],
[4314175, "PEDRAS ALTAS", "RIO GRANDE DO SUL", "RS", 43],
[4314209, "PEDRO OSORIO", "RIO GRANDE DO SUL", "RS", 43],
[4314308, "PEJUCARA", "RIO GRANDE DO SUL", "RS", 43],
[4314407, "PELOTAS", "RIO GRANDE DO SUL", "RS", 43],
[4314423, "PICADA CAFE", "RIO GRANDE DO SUL", "RS", 43],
[4314456, "PINHAL", "RIO GRANDE DO SUL", "RS", 43],
[4314464, "PINHAL DA SERRA", "RIO GRANDE DO SUL", "RS", 43],
[4314472, "PINHAL GRANDE", "RIO GRANDE DO SUL", "RS", 43],
[4314498, "PINHEIRINHO DO VALE", "RIO GRANDE DO SUL", "RS", 43],
[4314506, "PINHEIRO MACHADO", "RIO GRANDE DO SUL", "RS", 43],
[4314555, "PIRAPO", "RIO GRANDE DO SUL", "RS", 43],
[4314605, "PIRATINI", "RIO GRANDE DO SUL", "RS", 43],
[4314704, "PLANALTO", "RIO GRANDE DO SUL", "RS", 43],
[4314753, "POCO DAS ANTAS", "RIO GRANDE DO SUL", "RS", 43],
[4314779, "PONTAO", "RIO GRANDE DO SUL", "RS", 43],
[4314787, "PONTE PRETA", "RIO GRANDE DO SUL", "RS", 43],
[4314803, "PORTAO", "RIO GRANDE DO SUL", "RS", 43],
[4314902, "PORTO ALEGRE", "RIO GRANDE DO SUL", "RS", 43],
[4315008, "PORTO LUCENA", "RIO GRANDE DO SUL", "RS", 43],
[4315057, "PORTO MAUA", "RIO GRANDE DO SUL", "RS", 43],
[4315073, "PORTO VERA CRUZ", "RIO GRANDE DO SUL", "RS", 43],
[4315107, "PORTO XAVIER", "RIO GRANDE DO SUL", "RS", 43],
[4315131, "POUSO NOVO", "RIO GRANDE DO SUL", "RS", 43],
[4315149, "PRESIDENTE LUCENA", "RIO GRANDE DO SUL", "RS", 43],
[4315156, "PROGRESSO", "RIO GRANDE DO SUL", "RS", 43],
[4315172, "PROTASIO ALVES", "RIO GRANDE DO SUL", "RS", 43],
[4315206, "PUTINGA", "RIO GRANDE DO SUL", "RS", 43],
[4315305, "QUARAI", "RIO GRANDE DO SUL", "RS", 43],
[4315313, "QUATRO IRMAOS", "RIO GRANDE DO SUL", "RS", 43],
[4315321, "QUEVEDOS", "RIO GRANDE DO SUL", "RS", 43],
[4315354, "QUINZE DE NOVEMBRO", "RIO GRANDE DO SUL", "RS", 43],
[4315404, "REDENTORA", "RIO GRANDE DO SUL", "RS", 43],
[4315453, "RELVADO", "RIO GRANDE DO SUL", "RS", 43],
[4315503, "RESTINGA SECA", "RIO GRANDE DO SUL", "RS", 43],
[4315552, "RIO DOS INDIOS", "RIO GRANDE DO SUL", "RS", 43],
[4315602, "RIO GRANDE", "RIO GRANDE DO SUL", "RS", 43],
[4315701, "RIO PARDO", "RIO GRANDE DO SUL", "RS", 43],
[4315750, "RIOZINHO", "RIO GRANDE DO SUL", "RS", 43],
[4315800, "ROCA SALES", "RIO GRANDE DO SUL", "RS", 43],
[4315909, "RODEIO BONITO", "RIO GRANDE DO SUL", "RS", 43],
[4315958, "ROLADOR", "RIO GRANDE DO SUL", "RS", 43],
[4316006, "ROLANTE", "RIO GRANDE DO SUL", "RS", 43],
[4316105, "RONDA ALTA", "RIO GRANDE DO SUL", "RS", 43],
[4316204, "RONDINHA", "RIO GRANDE DO SUL", "RS", 43],
[4316303, "ROQUE GONZALES", "RIO GRANDE DO SUL", "RS", 43],
[4316402, "ROSARIO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4316428, "SAGRADA FAMILIA", "RIO GRANDE DO SUL", "RS", 43],
[4316436, "SALDANHA MARINHO", "RIO GRANDE DO SUL", "RS", 43],
[4316451, "SALTO DO JACUI", "RIO GRANDE DO SUL", "RS", 43],
[4316477, "SALVADOR DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4316501, "SALVADOR DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4316600, "SANANDUVA", "RIO GRANDE DO SUL", "RS", 43],
[4316709, "SANTA BARBARA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4316733, "SANTA CECILIA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4316758, "SANTA CLARA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4316808, "SANTA CRUZ DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4316907, "SANTA MARIA", "RIO GRANDE DO SUL", "RS", 43],
[4316956, "SANTA MARIA DO HERVAL", "RIO GRANDE DO SUL", "RS", 43],
[4316972, "SANTA MARGARIDA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4317004, "SANTANA DA BOA VISTA", "RIO GRANDE DO SUL", "RS", 43],
[4317103, "SANT'ANA DO LIVRAMENTO", "RIO GRANDE DO SUL", "RS", 43],
[4317202, "SANTA ROSA", "RIO GRANDE DO SUL", "RS", 43],
[4317251, "SANTA TEREZA", "RIO GRANDE DO SUL", "RS", 43],
[4317301, "SANTA VITORIA DO PALMAR", "RIO GRANDE DO SUL", "RS", 43],
[4317400, "SANTIAGO", "RIO GRANDE DO SUL", "RS", 43],
[4317509, "SANTO ANGELO", "RIO GRANDE DO SUL", "RS", 43],
[4317558, "SANTO ANTONIO DO PALMA", "RIO GRANDE DO SUL", "RS", 43],
[4317608, "SANTO ANTONIO DA PATRULHA", "RIO GRANDE DO SUL", "RS", 43],
[4317707, "SANTO ANTONIO DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4317756, "SANTO ANTONIO DO PLANALTO", "RIO GRANDE DO SUL", "RS", 43],
[4317806, "SANTO AUGUSTO", "RIO GRANDE DO SUL", "RS", 43],
[4317905, "SANTO CRISTO", "RIO GRANDE DO SUL", "RS", 43],
[4317954, "SANTO EXPEDITO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4318002, "SAO BORJA", "RIO GRANDE DO SUL", "RS", 43],
[4318051, "SAO DOMINGOS DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4318101, "SAO FRANCISCO DE ASSIS", "RIO GRANDE DO SUL", "RS", 43],
[4318200, "SAO FRANCISCO DE PAULA", "RIO GRANDE DO SUL", "RS", 43],
[4318309, "SAO GABRIEL", "RIO GRANDE DO SUL", "RS", 43],
[4318408, "SAO JERONIMO", "RIO GRANDE DO SUL", "RS", 43],
[4318424, "SAO JOAO DA URTIGA", "RIO GRANDE DO SUL", "RS", 43],
[4318432, "SAO JOAO DO POLESINE", "RIO GRANDE DO SUL", "RS", 43],
[4318440, "SAO JORGE", "RIO GRANDE DO SUL", "RS", 43],
[4318457, "SAO JOSE DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4318465, "SAO JOSE DO HERVAL", "RIO GRANDE DO SUL", "RS", 43],
[4318481, "SAO JOSE DO HORTENCIO", "RIO GRANDE DO SUL", "RS", 43],
[4318499, "SAO JOSE DO INHACORA", "RIO GRANDE DO SUL", "RS", 43],
[4318507, "SAO JOSE DO NORTE", "RIO GRANDE DO SUL", "RS", 43],
[4318606, "SAO JOSE DO OURO", "RIO GRANDE DO SUL", "RS", 43],
[4318614, "SAO JOSE DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4318622, "SAO JOSE DOS AUSENTES", "RIO GRANDE DO SUL", "RS", 43],
[4318705, "SAO LEOPOLDO", "RIO GRANDE DO SUL", "RS", 43],
[4318804, "SAO LOURENCO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4318903, "SAO LUIZ GONZAGA", "RIO GRANDE DO SUL", "RS", 43],
[4319000, "SAO MARCOS", "RIO GRANDE DO SUL", "RS", 43],
[4319109, "SAO MARTINHO", "RIO GRANDE DO SUL", "RS", 43],
[4319125, "SAO MARTINHO DA SERRA", "RIO GRANDE DO SUL", "RS", 43],
[4319158, "SAO MIGUEL DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4319208, "SAO NICOLAU", "RIO GRANDE DO SUL", "RS", 43],
[4319307, "SAO PAULO DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4319356, "SAO PEDRO DA SERRA", "RIO GRANDE DO SUL", "RS", 43],
[4319364, "SAO PEDRO DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4319372, "SAO PEDRO DO BUTIA", "RIO GRANDE DO SUL", "RS", 43],
[4319406, "SAO PEDRO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4319505, "SAO SEBASTIAO DO CAI", "RIO GRANDE DO SUL", "RS", 43],
[4319604, "SAO SEPE", "RIO GRANDE DO SUL", "RS", 43],
[4319703, "SAO VALENTIM", "RIO GRANDE DO SUL", "RS", 43],
[4319711, "SAO VALENTIM DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4319737, "SAO VALERIO DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4319752, "SAO VENDELINO", "RIO GRANDE DO SUL", "RS", 43],
[4319802, "SAO VICENTE DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4319901, "SAPIRANGA", "RIO GRANDE DO SUL", "RS", 43],
[4320008, "SAPUCAIA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4320107, "SARANDI", "RIO GRANDE DO SUL", "RS", 43],
[4320206, "SEBERI", "RIO GRANDE DO SUL", "RS", 43],
[4320230, "SEDE NOVA", "RIO GRANDE DO SUL", "RS", 43],
[4320263, "SEGREDO", "RIO GRANDE DO SUL", "RS", 43],
[4320305, "SELBACH", "RIO GRANDE DO SUL", "RS", 43],
[4320321, "SENADOR SALGADO FILHO", "RIO GRANDE DO SUL", "RS", 43],
[4320354, "SENTINELA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4320404, "SERAFINA CORREA", "RIO GRANDE DO SUL", "RS", 43],
[4320453, "SERIO", "RIO GRANDE DO SUL", "RS", 43],
[4320503, "SERTAO", "RIO GRANDE DO SUL", "RS", 43],
[4320552, "SERTAO SANTANA", "RIO GRANDE DO SUL", "RS", 43],
[4320578, "SETE DE SETEMBRO", "RIO GRANDE DO SUL", "RS", 43],
[4320602, "SEVERIANO DE ALMEIDA", "RIO GRANDE DO SUL", "RS", 43],
[4320651, "SILVEIRA MARTINS", "RIO GRANDE DO SUL", "RS", 43],
[4320677, "SINIMBU", "RIO GRANDE DO SUL", "RS", 43],
[4320701, "SOBRADINHO", "RIO GRANDE DO SUL", "RS", 43],
[4320800, "SOLEDADE", "RIO GRANDE DO SUL", "RS", 43],
[4320859, "TABAI", "RIO GRANDE DO SUL", "RS", 43],
[4320909, "TAPEJARA", "RIO GRANDE DO SUL", "RS", 43],
[4321006, "TAPERA", "RIO GRANDE DO SUL", "RS", 43],
[4321105, "TAPES", "RIO GRANDE DO SUL", "RS", 43],
[4321204, "TAQUARA", "RIO GRANDE DO SUL", "RS", 43],
[4321303, "TAQUARI", "RIO GRANDE DO SUL", "RS", 43],
[4321329, "TAQUARUCU DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4321352, "TAVARES", "RIO GRANDE DO SUL", "RS", 43],
[4321402, "TENENTE PORTELA", "RIO GRANDE DO SUL", "RS", 43],
[4321436, "TERRA DE AREIA", "RIO GRANDE DO SUL", "RS", 43],
[4321451, "TEUTONIA", "RIO GRANDE DO SUL", "RS", 43],
[4321469, "TIO HUGO", "RIO GRANDE DO SUL", "RS", 43],
[4321477, "TIRADENTES DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4321493, "TOROPI", "RIO GRANDE DO SUL", "RS", 43],
[4321501, "TORRES", "RIO GRANDE DO SUL", "RS", 43],
[4321600, "TRAMANDAI", "RIO GRANDE DO SUL", "RS", 43],
[4321626, "TRAVESSEIRO", "RIO GRANDE DO SUL", "RS", 43],
[4321634, "TRES ARROIOS", "RIO GRANDE DO SUL", "RS", 43],
[4321667, "TRES CACHOEIRAS", "RIO GRANDE DO SUL", "RS", 43],
[4321709, "TRES COROAS", "RIO GRANDE DO SUL", "RS", 43],
[4321808, "TRES DE MAIO", "RIO GRANDE DO SUL", "RS", 43],
[4321832, "TRES FORQUILHAS", "RIO GRANDE DO SUL", "RS", 43],
[4321857, "TRES PALMEIRAS", "RIO GRANDE DO SUL", "RS", 43],
[4321907, "TRES PASSOS", "RIO GRANDE DO SUL", "RS", 43],
[4321956, "TRINDADE DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4322004, "TRIUNFO", "RIO GRANDE DO SUL", "RS", 43],
[4322103, "TUCUNDUVA", "RIO GRANDE DO SUL", "RS", 43],
[4322152, "TUNAS", "RIO GRANDE DO SUL", "RS", 43],
[4322186, "TUPANCI DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4322202, "TUPANCIRETA", "RIO GRANDE DO SUL", "RS", 43],
[4322251, "TUPANDI", "RIO GRANDE DO SUL", "RS", 43],
[4322301, "TUPARENDI", "RIO GRANDE DO SUL", "RS", 43],
[4322327, "TURUCU", "RIO GRANDE DO SUL", "RS", 43],
[4322343, "UBIRETAMA", "RIO GRANDE DO SUL", "RS", 43],
[4322350, "UNIAO DA SERRA", "RIO GRANDE DO SUL", "RS", 43],
[4322376, "UNISTALDA", "RIO GRANDE DO SUL", "RS", 43],
[4322400, "URUGUAIANA", "RIO GRANDE DO SUL", "RS", 43],
[4322509, "VACARIA", "RIO GRANDE DO SUL", "RS", 43],
[4322525, "VALE VERDE", "RIO GRANDE DO SUL", "RS", 43],
[4322533, "VALE DO SOL", "RIO GRANDE DO SUL", "RS", 43],
[4322541, "VALE REAL", "RIO GRANDE DO SUL", "RS", 43],
[4322558, "VANINI", "RIO GRANDE DO SUL", "RS", 43],
[4322608, "VENANCIO AIRES", "RIO GRANDE DO SUL", "RS", 43],
[4322707, "VERA CRUZ", "RIO GRANDE DO SUL", "RS", 43],
[4322806, "VERANOPOLIS", "RIO GRANDE DO SUL", "RS", 43],
[4322855, "VESPASIANO CORREA", "RIO GRANDE DO SUL", "RS", 43],
[4322905, "VIADUTOS", "RIO GRANDE DO SUL", "RS", 43],
[4323002, "VIAMAO", "RIO GRANDE DO SUL", "RS", 43],
[4323101, "VICENTE DUTRA", "RIO GRANDE DO SUL", "RS", 43],
[4323200, "VICTOR GRAEFF", "RIO GRANDE DO SUL", "RS", 43],
[4323309, "VILA FLORES", "RIO GRANDE DO SUL", "RS", 43],
[4323358, "VILA LANGARO", "RIO GRANDE DO SUL", "RS", 43],
[4323408, "VILA MARIA", "RIO GRANDE DO SUL", "RS", 43],
[4323457, "VILA NOVA DO SUL", "RIO GRANDE DO SUL", "RS", 43],
[4323507, "VISTA ALEGRE", "RIO GRANDE DO SUL", "RS", 43],
[4323606, "VISTA ALEGRE DO PRATA", "RIO GRANDE DO SUL", "RS", 43],
[4323705, "VISTA GAUCHA", "RIO GRANDE DO SUL", "RS", 43],
[4323754, "VITORIA DAS MISSÕES", "RIO GRANDE DO SUL", "RS", 43],
[4323770, "WESTFALIA", "RIO GRANDE DO SUL", "RS", 43],
[4323804, "XANGRI-LA", "RIO GRANDE DO SUL", "RS", 43],
[5000203, "AGUA CLARA", "MATO GROSSO DO SUL", "MS", 50],
[5000252, "ALCINOPOLIS", "MATO GROSSO DO SUL", "MS", 50],
[5000609, "AMAMBAI", "MATO GROSSO DO SUL", "MS", 50],
[5000708, "ANASTACIO", "MATO GROSSO DO SUL", "MS", 50],
[5000807, "ANAURILANDIA", "MATO GROSSO DO SUL", "MS", 50],
[5000856, "ANGELICA", "MATO GROSSO DO SUL", "MS", 50],
[5000906, "ANTONIO JOAO", "MATO GROSSO DO SUL", "MS", 50],
[5001003, "APARECIDA DO TABOADO", "MATO GROSSO DO SUL", "MS", 50],
[5001102, "AQUIDAUANA", "MATO GROSSO DO SUL", "MS", 50],
[5001243, "ARAL MOREIRA", "MATO GROSSO DO SUL", "MS", 50],
[5001508, "BANDEIRANTES", "MATO GROSSO DO SUL", "MS", 50],
[5001904, "BATAGUASSU", "MATO GROSSO DO SUL", "MS", 50],
[5002001, "BATAYPORA", "MATO GROSSO DO SUL", "MS", 50],
[5002100, "BELA VISTA", "MATO GROSSO DO SUL", "MS", 50],
[5002159, "BODOQUENA", "MATO GROSSO DO SUL", "MS", 50],
[5002209, "BONITO", "MATO GROSSO DO SUL", "MS", 50],
[5002308, "BRASILANDIA", "MATO GROSSO DO SUL", "MS", 50],
[5002407, "CAARAPO", "MATO GROSSO DO SUL", "MS", 50],
[5002605, "CAMAPUA", "MATO GROSSO DO SUL", "MS", 50],
[5002704, "CAMPO GRANDE", "MATO GROSSO DO SUL", "MS", 50],
[5002803, "CARACOL", "MATO GROSSO DO SUL", "MS", 50],
[5002902, "CASSILANDIA", "MATO GROSSO DO SUL", "MS", 50],
[5002951, "CHAPADAO DO SUL", "MATO GROSSO DO SUL", "MS", 50],
[5003108, "CORGUINHO", "MATO GROSSO DO SUL", "MS", 50],
[5003157, "CORONEL SAPUCAIA", "MATO GROSSO DO SUL", "MS", 50],
[5003207, "CORUMBA", "MATO GROSSO DO SUL", "MS", 50],
[5003256, "COSTA RICA", "MATO GROSSO DO SUL", "MS", 50],
[5003306, "COXIM", "MATO GROSSO DO SUL", "MS", 50],
[5003454, "DEODAPOLIS", "MATO GROSSO DO SUL", "MS", 50],
[5003488, "DOIS IRMAOS DO BURITI", "MATO GROSSO DO SUL", "MS", 50],
[5003504, "DOURADINA", "MATO GROSSO DO SUL", "MS", 50],
[5003702, "DOURADOS", "MATO GROSSO DO SUL", "MS", 50],
[5003751, "ELDORADO", "MATO GROSSO DO SUL", "MS", 50],
[5003801, "FATIMA DO SUL", "MATO GROSSO DO SUL", "MS", 50],
[5003900, "FIGUEIRAO", "MATO GROSSO DO SUL", "MS", 50],
[5004007, "GLORIA DE DOURADOS", "MATO GROSSO DO SUL", "MS", 50],
[5004106, "GUIA LOPES DA LAGUNA", "MATO GROSSO DO SUL", "MS", 50],
[5004304, "IGUATEMI", "MATO GROSSO DO SUL", "MS", 50],
[5004403, "INOCENCIA", "MATO GROSSO DO SUL", "MS", 50],
[5004502, "ITAPORA", "MATO GROSSO DO SUL", "MS", 50],
[5004601, "ITAQUIRAI", "MATO GROSSO DO SUL", "MS", 50],
[5004700, "IVINHEMA", "MATO GROSSO DO SUL", "MS", 50],
[5004809, "JAPORA", "MATO GROSSO DO SUL", "MS", 50],
[5004908, "JARAGUARI", "MATO GROSSO DO SUL", "MS", 50],
[5005004, "JARDIM", "MATO GROSSO DO SUL", "MS", 50],
[5005103, "JATEI", "MATO GROSSO DO SUL", "MS", 50],
[5005152, "JUTI", "MATO GROSSO DO SUL", "MS", 50],
[5005202, "LADARIO", "MATO GROSSO DO SUL", "MS", 50],
[5005251, "LAGUNA CARAPA", "MATO GROSSO DO SUL", "MS", 50],
[5005400, "MARACAJU", "MATO GROSSO DO SUL", "MS", 50],
[5005608, "MIRANDA", "MATO GROSSO DO SUL", "MS", 50],
[5005681, "MUNDO NOVO", "MATO GROSSO DO SUL", "MS", 50],
[5005707, "NAVIRAI", "MATO GROSSO DO SUL", "MS", 50],
[5005806, "NIOAQUE", "MATO GROSSO DO SUL", "MS", 50],
[5006002, "NOVA ALVORADA DO SUL", "MATO GROSSO DO SUL", "MS", 50],
[5006200, "NOVA ANDRADINA", "MATO GROSSO DO SUL", "MS", 50],
[5006259, "NOVO HORIZONTE DO SUL", "MATO GROSSO DO SUL", "MS", 50],
[5006309, "PARANAIBA", "MATO GROSSO DO SUL", "MS", 50],
[5006358, "PARANHOS", "MATO GROSSO DO SUL", "MS", 50],
[5006408, "PEDRO GOMES", "MATO GROSSO DO SUL", "MS", 50],
[5006606, "PONTA PORA", "MATO GROSSO DO SUL", "MS", 50],
[5006903, "PORTO MURTINHO", "MATO GROSSO DO SUL", "MS", 50],
[5007109, "RIBAS DO RIO PARDO", "MATO GROSSO DO SUL", "MS", 50],
[5007208, "RIO BRILHANTE", "MATO GROSSO DO SUL", "MS", 50],
[5007307, "RIO NEGRO", "MATO GROSSO DO SUL", "MS", 50],
[5007406, "RIO VERDE DE MATO GROSSO", "MATO GROSSO DO SUL", "MS", 50],
[5007505, "ROCHEDO", "MATO GROSSO DO SUL", "MS", 50],
[5007554, "SANTA RITA DO PARDO", "MATO GROSSO DO SUL", "MS", 50],
[5007695, "SAO GABRIEL DO OESTE", "MATO GROSSO DO SUL", "MS", 50],
[5007703, "SETE QUEDAS", "MATO GROSSO DO SUL", "MS", 50],
[5007802, "SELVIRIA", "MATO GROSSO DO SUL", "MS", 50],
[5007901, "SIDROLANDIA", "MATO GROSSO DO SUL", "MS", 50],
[5007935, "SONORA", "MATO GROSSO DO SUL", "MS", 50],
[5007950, "TACURU", "MATO GROSSO DO SUL", "MS", 50],
[5007976, "TAQUARUSSU", "MATO GROSSO DO SUL", "MS", 50],
[5008008, "TERENOS", "MATO GROSSO DO SUL", "MS", 50],
[5008305, "TRES LAGOAS", "MATO GROSSO DO SUL", "MS", 50],
[5008404, "VICENTINA", "MATO GROSSO DO SUL", "MS", 50],
[5100102, "ACORIZAL", "MATO GROSSO", "MT", 51],
[5100201, "AGUA BOA", "MATO GROSSO", "MT", 51],
[5100250, "ALTA FLORESTA", "MATO GROSSO", "MT", 51],
[5100300, "ALTO ARAGUAIA", "MATO GROSSO", "MT", 51],
[5100359, "ALTO BOA VISTA", "MATO GROSSO", "MT", 51],
[5100409, "ALTO GARCAS", "MATO GROSSO", "MT", 51],
[5100508, "ALTO PARAGUAI", "MATO GROSSO", "MT", 51],
[5100607, "ALTO TAQUARI", "MATO GROSSO", "MT", 51],
[5100805, "APIACAS", "MATO GROSSO", "MT", 51],
[5101001, "ARAGUAIANA", "MATO GROSSO", "MT", 51],
[5101209, "ARAGUAINHA", "MATO GROSSO", "MT", 51],
[5101258, "ARAPUTANGA", "MATO GROSSO", "MT", 51],
[5101308, "ARENAPOLIS", "MATO GROSSO", "MT", 51],
[5101407, "ARIPUANA", "MATO GROSSO", "MT", 51],
[5101605, "BARAO DE MELGACO", "MATO GROSSO", "MT", 51],
[5101704, "BARRA DO BUGRES", "MATO GROSSO", "MT", 51],
[5101803, "BARRA DO GARCAS", "MATO GROSSO", "MT", 51],
[5101852, "BOM JESUS DO ARAGUAIA", "MATO GROSSO", "MT", 51],
[5101902, "BRASNORTE", "MATO GROSSO", "MT", 51],
[5102504, "CACERES", "MATO GROSSO", "MT", 51],
[5102603, "CAMPINAPOLIS", "MATO GROSSO", "MT", 51],
[5102637, "CAMPO NOVO DO PARECIS", "MATO GROSSO", "MT", 51],
[5102678, "CAMPO VERDE", "MATO GROSSO", "MT", 51],
[5102686, "CAMPOS DE JULIO", "MATO GROSSO", "MT", 51],
[5102694, "CANABRAVA DO NORTE", "MATO GROSSO", "MT", 51],
[5102702, "CANARANA", "MATO GROSSO", "MT", 51],
[5102793, "CARLINDA", "MATO GROSSO", "MT", 51],
[5102850, "CASTANHEIRA", "MATO GROSSO", "MT", 51],
[5103007, "CHAPADA DOS GUIMARAES", "MATO GROSSO", "MT", 51],
[5103056, "CLAUDIA", "MATO GROSSO", "MT", 51],
[5103106, "COCALINHO", "MATO GROSSO", "MT", 51],
[5103205, "COLIDER", "MATO GROSSO", "MT", 51],
[5103254, "COLNIZA", "MATO GROSSO", "MT", 51],
[5103304, "COMODORO", "MATO GROSSO", "MT", 51],
[5103353, "CONFRESA", "MATO GROSSO", "MT", 51],
[5103361, "CONQUISTA D'OESTE", "MATO GROSSO", "MT", 51],
[5103379, "COTRIGUACU", "MATO GROSSO", "MT", 51],
[5103403, "CUIABA", "MATO GROSSO", "MT", 51],
[5103437, "CURVELANDIA", "MATO GROSSO", "MT", 51],
[5103452, "DENISE", "MATO GROSSO", "MT", 51],
[5103502, "DIAMANTINO", "MATO GROSSO", "MT", 51],
[5103601, "DOM AQUINO", "MATO GROSSO", "MT", 51],
[5103700, "FELIZ NATAL", "MATO GROSSO", "MT", 51],
[5103809, "FIGUEIROPOLIS D'OESTE", "MATO GROSSO", "MT", 51],
[5103858, "GAUCHA DO NORTE", "MATO GROSSO", "MT", 51],
[5103908, "GENERAL CARNEIRO", "MATO GROSSO", "MT", 51],
[5103957, "GLORIA D'OESTE", "MATO GROSSO", "MT", 51],
[5104104, "GUARANTA DO NORTE", "MATO GROSSO", "MT", 51],
[5104203, "GUIRATINGA", "MATO GROSSO", "MT", 51],
[5104500, "INDIAVAI", "MATO GROSSO", "MT", 51],
[5104526, "IPIRANGA DO NORTE", "MATO GROSSO", "MT", 51],
[5104542, "ITANHANGA", "MATO GROSSO", "MT", 51],
[5104559, "ITAUBA", "MATO GROSSO", "MT", 51],
[5104609, "ITIQUIRA", "MATO GROSSO", "MT", 51],
[5104807, "JACIARA", "MATO GROSSO", "MT", 51],
[5104906, "JANGADA", "MATO GROSSO", "MT", 51],
[5105002, "JAURU", "MATO GROSSO", "MT", 51],
[5105101, "JUARA", "MATO GROSSO", "MT", 51],
[5105150, "JUINA", "MATO GROSSO", "MT", 51],
[5105176, "JURUENA", "MATO GROSSO", "MT", 51],
[5105200, "JUSCIMEIRA", "MATO GROSSO", "MT", 51],
[5105234, "LAMBARI D'OESTE", "MATO GROSSO", "MT", 51],
[5105259, "LUCAS DO RIO VERDE", "MATO GROSSO", "MT", 51],
[5105309, "LUCIARA", "MATO GROSSO", "MT", 51],
[5105507, "VILA BELA DA SANTISSIMA T", "MATO GROSSO", "MT", 51],
[5105580, "MARCELANDIA", "MATO GROSSO", "MT", 51],
[5105606, "MATUPA", "MATO GROSSO", "MT", 51],
[5105622, "MIRASSOL D'OESTE", "MATO GROSSO", "MT", 51],
[5105903, "NOBRES", "MATO GROSSO", "MT", 51],
[5106000, "NORTELANDIA", "MATO GROSSO", "MT", 51],
[5106109, "NOSSA SENHORA DO LIVRAMEN", "MATO GROSSO", "MT", 51],
[5106158, "NOVA BANDEIRANTES", "MATO GROSSO", "MT", 51],
[5106174, "NOVA NAZARE", "MATO GROSSO", "MT", 51],
[5106182, "NOVA LACERDA", "MATO GROSSO", "MT", 51],
[5106190, "NOVA SANTA HELENA", "MATO GROSSO", "MT", 51],
[5106208, "NOVA BRASILANDIA", "MATO GROSSO", "MT", 51],
[5106216, "NOVA CANAA DO NORTE", "MATO GROSSO", "MT", 51],
[5106224, "NOVA MUTUM", "MATO GROSSO", "MT", 51],
[5106232, "NOVA OLIMPIA", "MATO GROSSO", "MT", 51],
[5106240, "NOVA UBIRATA", "MATO GROSSO", "MT", 51],
[5106257, "NOVA XAVANTINA", "MATO GROSSO", "MT", 51],
[5106265, "NOVO MUNDO", "MATO GROSSO", "MT", 51],
[5106273, "NOVO HORIZONTE DO NORTE", "MATO GROSSO", "MT", 51],
[5106281, "NOVO SAO JOAQUIM", "MATO GROSSO", "MT", 51],
[5106299, "PARANAITA", "MATO GROSSO", "MT", 51],
[5106307, "PARANATINGA", "MATO GROSSO", "MT", 51],
[5106315, "NOVO SANTO ANTONIO", "MATO GROSSO", "MT", 51],
[5106372, "PEDRA PRETA", "MATO GROSSO", "MT", 51],
[5106422, "PEIXOTO DE AZEVEDO", "MATO GROSSO", "MT", 51],
[5106455, "PLANALTO DA SERRA", "MATO GROSSO", "MT", 51],
[5106505, "POCONE", "MATO GROSSO", "MT", 51],
[5106653, "PONTAL DO ARAGUAIA", "MATO GROSSO", "MT", 51],
[5106703, "PONTE BRANCA", "MATO GROSSO", "MT", 51],
[5106752, "PONTES E LACERDA", "MATO GROSSO", "MT", 51],
[5106778, "PORTO ALEGRE DO NORTE", "MATO GROSSO", "MT", 51],
[5106802, "PORTO DOS GAUCHOS", "MATO GROSSO", "MT", 51],
[5106828, "PORTO ESPERIDIAO", "MATO GROSSO", "MT", 51],
[5106851, "PORTO ESTRELA", "MATO GROSSO", "MT", 51],
[5107008, "POXOREO", "MATO GROSSO", "MT", 51],
[5107040, "PRIMAVERA DO LESTE", "MATO GROSSO", "MT", 51],
[5107065, "QUERENCIA", "MATO GROSSO", "MT", 51],
[5107107, "SAO JOSE DOS QUATRO MARCO", "MATO GROSSO", "MT", 51],
[5107156, "RESERVA DO CABACAL", "MATO GROSSO", "MT", 51],
[5107180, "RIBEIRAO CASCALHEIRA", "MATO GROSSO", "MT", 51],
[5107198, "RIBEIRAOZINHO", "MATO GROSSO", "MT", 51],
[5107206, "RIO BRANCO", "MATO GROSSO", "MT", 51],
[5107248, "SANTA CARMEM", "MATO GROSSO", "MT", 51],
[5107263, "SANTO AFONSO", "MATO GROSSO", "MT", 51],
[5107297, "SAO JOSE DO POVO", "MATO GROSSO", "MT", 51],
[5107305, "SAO JOSE DO RIO CLARO", "MATO GROSSO", "MT", 51],
[5107354, "SAO JOSE DO XINGU", "MATO GROSSO", "MT", 51],
[5107404, "SAO PEDRO DA CIPA", "MATO GROSSO", "MT", 51],
[5107578, "RONDOLANDIA", "MATO GROSSO", "MT", 51],
[5107602, "RONDONOPOLIS", "MATO GROSSO", "MT", 51],
[5107701, "ROSARIO OESTE", "MATO GROSSO", "MT", 51],
[5107743, "SANTA CRUZ DO XINGU", "MATO GROSSO", "MT", 51],
[5107750, "SALTO DO CEU", "MATO GROSSO", "MT", 51],
[5107768, "SANTA RITA DO TRIVELATO", "MATO GROSSO", "MT", 51],
[5107776, "SANTA TEREZINHA", "MATO GROSSO", "MT", 51],
[5107792, "SANTO ANTONIO DO LESTE", "MATO GROSSO", "MT", 51],
[5107800, "SANTO ANTONIO DO LEVERGER", "MATO GROSSO", "MT", 51],
[5107859, "SAO FELIX DO ARAGUAIA", "MATO GROSSO", "MT", 51],
[5107875, "SAPEZAL", "MATO GROSSO", "MT", 51],
[5107883, "SERRA NOVA DOURADA", "MATO GROSSO", "MT", 51],
[5107909, "SINOP", "MATO GROSSO", "MT", 51],
[5107925, "SORRISO", "MATO GROSSO", "MT", 51],
[5107941, "TABAPORA", "MATO GROSSO", "MT", 51],
[5107958, "TANGARA DA SERRA", "MATO GROSSO", "MT", 51],
[5108006, "TAPURAH", "MATO GROSSO", "MT", 51],
[5108055, "TERRA NOVA DO NORTE", "MATO GROSSO", "MT", 51],
[5108105, "TESOURO", "MATO GROSSO", "MT", 51],
[5108204, "TORIXOREU", "MATO GROSSO", "MT", 51],
[5108303, "UNIAO DO SUL", "MATO GROSSO", "MT", 51],
[5108352, "VALE DE SAO DOMINGOS", "MATO GROSSO", "MT", 51],
[5108402, "VARZEA GRANDE", "MATO GROSSO", "MT", 51],
[5108501, "VERA", "MATO GROSSO", "MT", 51],
[5108600, "VILA RICA", "MATO GROSSO", "MT", 51],
[5108808, "NOVA GUARITA", "MATO GROSSO", "MT", 51],
[5108857, "NOVA MARILANDIA", "MATO GROSSO", "MT", 51],
[5108907, "NOVA MARINGA", "MATO GROSSO", "MT", 51],
[5108956, "NOVA MONTE VERDE", "MATO GROSSO", "MT", 51],
[5200050, "ABADIA DE GOIAS", "GOIAS", "GO", 52],
[5200100, "ABADIANIA", "GOIAS", "GO", 52],
[5200134, "ACREUNA", "GOIAS", "GO", 52],
[5200159, "ADELANDIA", "GOIAS", "GO", 52],
[5200175, "AGUA FRIA DE GOIAS", "GOIAS", "GO", 52],
[5200209, "AGUA LIMPA", "GOIAS", "GO", 52],
[5200258, "AGUAS LINDAS DE GOIAS", "GOIAS", "GO", 52],
[5200308, "ALEXANIA", "GOIAS", "GO", 52],
[5200506, "ALOANDIA", "GOIAS", "GO", 52],
[5200555, "ALTO HORIZONTE", "GOIAS", "GO", 52],
[5200605, "ALTO PARAISO DE GOIAS", "GOIAS", "GO", 52],
[5200803, "ALVORADA DO NORTE", "GOIAS", "GO", 52],
[5200829, "AMARALINA", "GOIAS", "GO", 52],
[5200852, "AMERICANO DO BRASIL", "GOIAS", "GO", 52],
[5200902, "AMORINOPOLIS", "GOIAS", "GO", 52],
[5201108, "ANAPOLIS", "GOIAS", "GO", 52],
[5201207, "ANHANGUERA", "GOIAS", "GO", 52],
[5201306, "ANICUNS", "GOIAS", "GO", 52],
[5201405, "APARECIDA DE GOIANIA", "GOIAS", "GO", 52],
[5201454, "APARECIDA DO RIO DOCE", "GOIAS", "GO", 52],
[5201504, "APORE", "GOIAS", "GO", 52],
[5201603, "ARACU", "GOIAS", "GO", 52],
[5201702, "ARAGARCAS", "GOIAS", "GO", 52],
[5201801, "ARAGOIANIA", "GOIAS", "GO", 52],
[5202155, "ARAGUAPAZ", "GOIAS", "GO", 52],
[5202353, "ARENOPOLIS", "GOIAS", "GO", 52],
[5202502, "ARUANA", "GOIAS", "GO", 52],
[5202601, "AURILANDIA", "GOIAS", "GO", 52],
[5202809, "AVELINOPOLIS", "GOIAS", "GO", 52],
[5203104, "BALIZA", "GOIAS", "GO", 52],
[5203203, "BARRO ALTO", "GOIAS", "GO", 52],
[5203302, "BELA VISTA DE GOIAS", "GOIAS", "GO", 52],
[5203401, "BOM JARDIM DE GOIAS", "GOIAS", "GO", 52],
[5203500, "BOM JESUS DE GOIAS", "GOIAS", "GO", 52],
[5203559, "BONFINOPOLIS", "GOIAS", "GO", 52],
[5203575, "BONOPOLIS", "GOIAS", "GO", 52],
[5203609, "BRAZABRANTES", "GOIAS", "GO", 52],
[5203807, "BRITANIA", "GOIAS", "GO", 52],
[5203906, "BURITI ALEGRE", "GOIAS", "GO", 52],
[5203939, "BURITI DE GOIAS", "GOIAS", "GO", 52],
[5203962, "BURITINOPOLIS", "GOIAS", "GO", 52],
[5204003, "CABECEIRAS", "GOIAS", "GO", 52],
[5204102, "CACHOEIRA ALTA", "GOIAS", "GO", 52],
[5204201, "CACHOEIRA DE GOIAS", "GOIAS", "GO", 52],
[5204250, "CACHOEIRA DOURADA", "GOIAS", "GO", 52],
[5204300, "CACU", "GOIAS", "GO", 52],
[5204409, "CAIAPONIA", "GOIAS", "GO", 52],
[5204508, "CALDAS NOVAS", "GOIAS", "GO", 52],
[5204557, "CALDAZINHA", "GOIAS", "GO", 52],
[5204607, "CAMPESTRE DE GOIAS", "GOIAS", "GO", 52],
[5204656, "CAMPINACU", "GOIAS", "GO", 52],
[5204706, "CAMPINORTE", "GOIAS", "GO", 52],
[5204805, "CAMPO ALEGRE DE GOIAS", "GOIAS", "GO", 52],
[5204854, "CAMPO LIMPO DE GOIAS", "GOIAS", "GO", 52],
[5204904, "CAMPOS BELOS", "GOIAS", "GO", 52],
[5204953, "CAMPOS VERDES", "GOIAS", "GO", 52],
[5205000, "CARMO DO RIO VERDE", "GOIAS", "GO", 52],
[5205059, "CASTELANDIA", "GOIAS", "GO", 52],
[5205109, "CATALAO", "GOIAS", "GO", 52],
[5205208, "CATURAI", "GOIAS", "GO", 52],
[5205307, "CAVALCANTE", "GOIAS", "GO", 52],
[5205406, "CERES", "GOIAS", "GO", 52],
[5205455, "CEZARINA", "GOIAS", "GO", 52],
[5205471, "CHAPADAO DO CEU", "GOIAS", "GO", 52],
[5205497, "CIDADE OCIDENTAL", "GOIAS", "GO", 52],
[5205513, "COCALZINHO DE GOIAS", "GOIAS", "GO", 52],
[5205521, "COLINAS DO SUL", "GOIAS", "GO", 52],
[5205703, "CORREGO DO OURO", "GOIAS", "GO", 52],
[5205802, "CORUMBA DE GOIAS", "GOIAS", "GO", 52],
[5205901, "CORUMBAIBA", "GOIAS", "GO", 52],
[5206206, "CRISTALINA", "GOIAS", "GO", 52],
[5206305, "CRISTIANOPOLIS", "GOIAS", "GO", 52],
[5206404, "CRIXAS", "GOIAS", "GO", 52],
[5206503, "CROMINIA", "GOIAS", "GO", 52],
[5206602, "CUMARI", "GOIAS", "GO", 52],
[5206701, "DAMIANOPOLIS", "GOIAS", "GO", 52],
[5206800, "DAMOLANDIA", "GOIAS", "GO", 52],
[5206909, "DAVINOPOLIS", "GOIAS", "GO", 52],
[5207105, "DIORAMA", "GOIAS", "GO", 52],
[5207253, "DOVERLANDIA", "GOIAS", "GO", 52],
[5207352, "EDEALINA", "GOIAS", "GO", 52],
[5207402, "EDEIA", "GOIAS", "GO", 52],
[5207501, "ESTRELA DO NORTE", "GOIAS", "GO", 52],
[5207535, "FAINA", "GOIAS", "GO", 52],
[5207600, "FAZENDA NOVA", "GOIAS", "GO", 52],
[5207808, "FIRMINOPOLIS", "GOIAS", "GO", 52],
[5207907, "FLORES DE GOIAS", "GOIAS", "GO", 52],
[5208004, "FORMOSA", "GOIAS", "GO", 52],
[5208103, "FORMOSO", "GOIAS", "GO", 52],
[5208152, "GAMELEIRA DE GOIAS", "GOIAS", "GO", 52],
[5208301, "DIVINOPOLIS DE GOIAS", "GOIAS", "GO", 52],
[5208400, "GOIANAPOLIS", "GOIAS", "GO", 52],
[5208509, "GOIANDIRA", "GOIAS", "GO", 52],
[5208608, "GOIANESIA", "GOIAS", "GO", 52],
[5208707, "GOIANIA", "GOIAS", "GO", 52],
[5208806, "GOIANIRA", "GOIAS", "GO", 52],
[5208905, "GOIAS", "GOIAS", "GO", 52],
[5209101, "GOIATUBA", "GOIAS", "GO", 52],
[5209150, "GOUVELANDIA", "GOIAS", "GO", 52],
[5209200, "GUAPO", "GOIAS", "GO", 52],
[5209291, "GUARAITA", "GOIAS", "GO", 52],
[5209408, "GUARANI DE GOIAS", "GOIAS", "GO", 52],
[5209457, "GUARINOS", "GOIAS", "GO", 52],
[5209606, "HEITORAI", "GOIAS", "GO", 52],
[5209705, "HIDROLANDIA", "GOIAS", "GO", 52],
[5209804, "HIDROLINA", "GOIAS", "GO", 52],
[5209903, "IACIARA", "GOIAS", "GO", 52],
[5209937, "INACIOLANDIA", "GOIAS", "GO", 52],
[5209952, "INDIARA", "GOIAS", "GO", 52],
[5210000, "INHUMAS", "GOIAS", "GO", 52],
[5210109, "IPAMERI", "GOIAS", "GO", 52],
[5210158, "IPIRANGA DE GOIAS", "GOIAS", "GO", 52],
[5210208, "IPORA", "GOIAS", "GO", 52],
[5210307, "ISRAELANDIA", "GOIAS", "GO", 52],
[5210406, "ITABERAI", "GOIAS", "GO", 52],
[5210562, "ITAGUARI", "GOIAS", "GO", 52],
[5210604, "ITAGUARU", "GOIAS", "GO", 52],
[5210802, "ITAJA", "GOIAS", "GO", 52],
[5210901, "ITAPACI", "GOIAS", "GO", 52],
[5211008, "ITAPIRAPUA", "GOIAS", "GO", 52],
[5211206, "ITAPURANGA", "GOIAS", "GO", 52],
[5211305, "ITARUMA", "GOIAS", "GO", 52],
[5211404, "ITAUCU", "GOIAS", "GO", 52],
[5211503, "ITUMBIARA", "GOIAS", "GO", 52],
[5211602, "IVOLANDIA", "GOIAS", "GO", 52],
[5211701, "JANDAIA", "GOIAS", "GO", 52],
[5211800, "JARAGUA", "GOIAS", "GO", 52],
[5211909, "JATAI", "GOIAS", "GO", 52],
[5212006, "JAUPACI", "GOIAS", "GO", 52],
[5212055, "JESUPOLIS", "GOIAS", "GO", 52],
[5212105, "JOVIANIA", "GOIAS", "GO", 52],
[5212204, "JUSSARA", "GOIAS", "GO", 52],
[5212253, "LAGOA SANTA", "GOIAS", "GO", 52],
[5212303, "LEOPOLDO DE BULHÕES", "GOIAS", "GO", 52],
[5212501, "LUZIANIA", "GOIAS", "GO", 52],
[5212600, "MAIRIPOTABA", "GOIAS", "GO", 52],
[5212709, "MAMBAI", "GOIAS", "GO", 52],
[5212808, "MARA ROSA", "GOIAS", "GO", 52],
[5212907, "MARZAGAO", "GOIAS", "GO", 52],
[5212956, "MATRINCHA", "GOIAS", "GO", 52],
[5213004, "MAURILANDIA", "GOIAS", "GO", 52],
[5213053, "MIMOSO DE GOIAS", "GOIAS", "GO", 52],
[5213087, "MINACU", "GOIAS", "GO", 52],
[5213103, "MINEIROS", "GOIAS", "GO", 52],
[5213400, "MOIPORA", "GOIAS", "GO", 52],
[5213509, "MONTE ALEGRE DE GOIAS", "GOIAS", "GO", 52],
[5213707, "MONTES CLAROS DE GOIAS", "GOIAS", "GO", 52],
[5213756, "MONTIVIDIU", "GOIAS", "GO", 52],
[5213772, "MONTIVIDIU DO NORTE", "GOIAS", "GO", 52],
[5213806, "MORRINHOS", "GOIAS", "GO", 52],
[5213855, "MORRO AGUDO DE GOIAS", "GOIAS", "GO", 52],
[5213905, "MOSSAMEDES", "GOIAS", "GO", 52],
[5214002, "MOZARLANDIA", "GOIAS", "GO", 52],
[5214051, "MUNDO NOVO", "GOIAS", "GO", 52],
[5214101, "MUTUNOPOLIS", "GOIAS", "GO", 52],
[5214408, "NAZARIO", "GOIAS", "GO", 52],
[5214507, "NEROPOLIS", "GOIAS", "GO", 52],
[5214606, "NIQUELANDIA", "GOIAS", "GO", 52],
[5214705, "NOVA AMERICA", "GOIAS", "GO", 52],
[5214804, "NOVA AURORA", "GOIAS", "GO", 52],
[5214838, "NOVA CRIXAS", "GOIAS", "GO", 52],
[5214861, "NOVA GLORIA", "GOIAS", "GO", 52],
[5214879, "NOVA IGUACU DE GOIAS", "GOIAS", "GO", 52],
[5214903, "NOVA ROMA", "GOIAS", "GO", 52],
[5215009, "NOVA VENEZA", "GOIAS", "GO", 52],
[5215207, "NOVO BRASIL", "GOIAS", "GO", 52],
[5215231, "NOVO GAMA", "GOIAS", "GO", 52],
[5215256, "NOVO PLANALTO", "GOIAS", "GO", 52],
[5215306, "ORIZONA", "GOIAS", "GO", 52],
[5215405, "OURO VERDE DE GOIAS", "GOIAS", "GO", 52],
[5215504, "OUVIDOR", "GOIAS", "GO", 52],
[5215603, "PADRE BERNARDO", "GOIAS", "GO", 52],
[5215652, "PALESTINA DE GOIAS", "GOIAS", "GO", 52],
[5215702, "PALMEIRAS DE GOIAS", "GOIAS", "GO", 52],
[5215801, "PALMELO", "GOIAS", "GO", 52],
[5215900, "PALMINOPOLIS", "GOIAS", "GO", 52],
[5216007, "PANAMA", "GOIAS", "GO", 52],
[5216304, "PARANAIGUARA", "GOIAS", "GO", 52],
[5216403, "PARAUNA", "GOIAS", "GO", 52],
[5216452, "PEROLANDIA", "GOIAS", "GO", 52],
[5216809, "PETROLINA DE GOIAS", "GOIAS", "GO", 52],
[5216908, "PILAR DE GOIAS", "GOIAS", "GO", 52],
[5217104, "PIRACANJUBA", "GOIAS", "GO", 52],
[5217203, "PIRANHAS", "GOIAS", "GO", 52],
[5217302, "PIRENOPOLIS", "GOIAS", "GO", 52],
[5217401, "PIRES DO RIO", "GOIAS", "GO", 52],
[5217609, "PLANALTINA", "GOIAS", "GO", 52],
[5217708, "PONTALINA", "GOIAS", "GO", 52],
[5218003, "PORANGATU", "GOIAS", "GO", 52],
[5218052, "PORTEIRAO", "GOIAS", "GO", 52],
[5218102, "PORTELANDIA", "GOIAS", "GO", 52],
[5218300, "POSSE", "GOIAS", "GO", 52],
[5218391, "PROFESSOR JAMIL", "GOIAS", "GO", 52],
[5218508, "QUIRINOPOLIS", "GOIAS", "GO", 52],
[5218607, "RIALMA", "GOIAS", "GO", 52],
[5218706, "RIANAPOLIS", "GOIAS", "GO", 52],
[5218789, "RIO QUENTE", "GOIAS", "GO", 52],
[5218805, "RIO VERDE", "GOIAS", "GO", 52],
[5218904, "RUBIATABA", "GOIAS", "GO", 52],
[5219001, "SANCLERLANDIA", "GOIAS", "GO", 52],
[5219100, "SANTA BARBARA DE GOIAS", "GOIAS", "GO", 52],
[5219209, "SANTA CRUZ DE GOIAS", "GOIAS", "GO", 52],
[5219258, "SANTA FE DE GOIAS", "GOIAS", "GO", 52],
[5219308, "SANTA HELENA DE GOIAS", "GOIAS", "GO", 52],
[5219357, "SANTA ISABEL", "GOIAS", "GO", 52],
[5219407, "SANTA RITA DO ARAGUAIA", "GOIAS", "GO", 52],
[5219456, "SANTA RITA DO NOVO DESTIN", "GOIAS", "GO", 52],
[5219506, "SANTA ROSA DE GOIAS", "GOIAS", "GO", 52],
[5219605, "SANTA TEREZA DE GOIAS", "GOIAS", "GO", 52],
[5219704, "SANTA TEREZINHA DE GOIAS", "GOIAS", "GO", 52],
[5219712, "SANTO ANTONIO DA BARRA", "GOIAS", "GO", 52],
[5219738, "SANTO ANTONIO DE GOIAS", "GOIAS", "GO", 52],
[5219753, "SANTO ANTONIO DO DESCOBER", "GOIAS", "GO", 52],
[5219803, "SAO DOMINGOS", "GOIAS", "GO", 52],
[5219902, "SAO FRANCISCO DE GOIAS", "GOIAS", "GO", 52],
[5220009, "SAO JOAO D'ALIANCA", "GOIAS", "GO", 52],
[5220058, "SAO JOAO DA PARAUNA", "GOIAS", "GO", 52],
[5220108, "SAO LUIS DE MONTES BELOS", "GOIAS", "GO", 52],
[5220157, "SAO LUIZ DO NORTE", "GOIAS", "GO", 52],
[5220207, "SAO MIGUEL DO ARAGUAIA", "GOIAS", "GO", 52],
[5220264, "SAO MIGUEL DO PASSA QUATR", "GOIAS", "GO", 52],
[5220280, "SAO PATRICIO", "GOIAS", "GO", 52],
[5220405, "SAO SIMAO", "GOIAS", "GO", 52],
[5220454, "SENADOR CANEDO", "GOIAS", "GO", 52],
[5220504, "SERRANOPOLIS", "GOIAS", "GO", 52],
[5220603, "SILVANIA", "GOIAS", "GO", 52],
[5220686, "SIMOLANDIA", "GOIAS", "GO", 52],
[5220702, "SITIO D'ABADIA", "GOIAS", "GO", 52],
[5221007, "TAQUARAL DE GOIAS", "GOIAS", "GO", 52],
[5221080, "TERESINA DE GOIAS", "GOIAS", "GO", 52],
[5221197, "TEREZOPOLIS DE GOIAS", "GOIAS", "GO", 52],
[5221304, "TRES RANCHOS", "GOIAS", "GO", 52],
[5221403, "TRINDADE", "GOIAS", "GO", 52],
[5221452, "TROMBAS", "GOIAS", "GO", 52],
[5221502, "TURVANIA", "GOIAS", "GO", 52],
[5221551, "TURVELANDIA", "GOIAS", "GO", 52],
[5221577, "UIRAPURU", "GOIAS", "GO", 52],
[5221601, "URUACU", "GOIAS", "GO", 52],
[5221700, "URUANA", "GOIAS", "GO", 52],
[5221809, "URUTAI", "GOIAS", "GO", 52],
[5221858, "VALPARAISO DE GOIAS", "GOIAS", "GO", 52],
[5221908, "VARJAO", "GOIAS", "GO", 52],
[5222005, "VIANOPOLIS", "GOIAS", "GO", 52],
[5222054, "VICENTINOPOLIS", "GOIAS", "GO", 52],
[5222203, "VILA BOA", "GOIAS", "GO", 52],
[5222302, "VILA PROPICIO", "GOIAS", "GO", 52],
[5300108, "BRASILIA", "DISTRITO FEDERAL", "DF", 53],
[9999999, "EXTERIOR", "EX", "EX", 99],
[2605905, "GAMELEIRA", "PERNAMBUCO", "PE", 26]
];

export default {
  vhost(s){
      const isDevelop = process.env.VUE_APP_ISDEVELOP;
        if (isDevelop==='true') {
          return `http://localhost:3001/${s}`
        } else {
          return `https://serve.sribank.com.br/${s}`
        }
  },
  stateToUF(state) {
    var uf
    for (let i = 0; i < tabelaIBGE.length; ++i) {
        if (tabelaIBGE[i][2].toUpperCase() == state.toUpperCase()) {
            uf =  tabelaIBGE[i][3]
            break
        }
    }   
    return uf
  },
  UFtoState(uf) {
    var state
    for (let i = 0; i < tabelaIBGE.length; ++i) {
        if (tabelaIBGE[i][3].toUpperCase() == uf.toUpperCase()) {
          state =  tabelaIBGE[i][2]
            break
        }
    }   
    return state
  },
  validatorCNPJ(cnpj) { 
    cnpj = cnpj.replace(/[^\d]+/g,''); 
    if(cnpj == '') return false;      
    if (cnpj.length != 14) return false;
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;         
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0,tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) return false;         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) return false;            
    return true;    
  },
  validatorCPF(strCPF) {      
    strCPF = String(strCPF).replace('.','').replace('.','').replace('-','').replace('/','')
    var Soma = 0;
    var Resto;
    if (strCPF == "00000000000") return false;
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
  },
  validator (val) {
    return val ? val.length >= 4 : false
  },
  validatorEmail(email){
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  validatorDate(date) {
    try {
      new Date(date).toISOString();
      return true;
    } catch (e) { 
      return false; 
    }
  },
  validatorPhone (phone) {
    return phone ? phone.length >= 13 : false
  },
  validatorPhoneMobile (phone) {
    return phone ? phone.length === 14 : false
  },
  validatorUF (uf) {
    return uf ? (uf.length === 2) : false
  },
  getAddressZipCode(zipcode){
    const axios = require("axios").default;
    return axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
  },
  handlingCPF(cpf){
      return cpf.replace('.','').replace('.','').replace('-','')
  },
  handlingCNPJ(cnpj){
    return cnpj.replace('.','').replace('.','').replace('-','').replace('/','')
  },
  handlingZipCode(cep){
    if (cep) 
      return cep.replace('.','').replace('-','')
    return ''   
  },
  handlingPhone(phone){
    return phone.replace('.','').replace('(','').replace(')','').replace('-','')
  },
  Bancos(){
    return  [
      {
        value: "000",
        name: "Selecione..."
      },
      {
        value: "001",
        name: "Banco do Brasil"
      },
      {
        value: "003",
        name: "Banco da Amazônia"
      },
      {
        value: "004",
        name: "Banco do Nordeste"
      },
      {
        value: "021",
        name: "Banestes"
      },
      {
        value: "025",
        name: "Banco Alfa"
      },
      {
        value: "027",
        name: "Besc"
      },
      {
        value: "029",
        name: "Banerj"
      },
      {
        value: "031",
        name: "Banco Beg"
      },
      {
        value: "033",
        name: "Banco Santander Banespa"
      },
      {
        value: "036",
        name: "Banco Bem"
      },
      {
        value: "037",
        name: "Banpará"
      },
      {
        value: "038",
        name: "Banestado"
      },
      {
      value: "039",
      name: "BEP"
      },
      {
      value: "040",
      name: "Banco Cargill"
      },
      {
      value: "041",
      name: "Banrisul"
      },
      {
        value: "044",
        name: "BVA"
      },
      {
        value: "045",
        name: "Banco Opportunity"
      },
      {
        value: "047",
        name: "Banese"
      },
      {
        value: "062",
        name: "Hipercard"
      },
      {
        value: "063",
        name: "Ibibank"
      },
      {
        value: "065",
        name: "Lemon Bank"
      },
      {
        value: "066",
        name: "Banco Morgan Stanley Dean Witter"
      },
      {
        value: "069",
        name: "BPN Brasil"
      },
      {
        value: "070",
        name: "Banco de Brasília – BRB"
      },
      {
        value: "072",
        name: "Banco Rural"
      },
      {
        value: "073",
        name: "Banco Popular"
      },
      {
        value: "074",
        name: "Banco J. Safra"
      },
      {
        value: "075",
        name: "Banco CR2"
      },
      {
        value: "076",
        name: "Banco KDB"
      },
      {
        value: "077",
        name: "Banco Inter"
      },
      {
        value: "096",
        name: "Banco BMF"
      },
      {
        value: "104",
        name: "Caixa Econômica Federal"
      },
      {
        value: "107",
        name: "Banco BBM"
      },
      {
        value: "116",
        name: "Banco Único"
      },
      {
        value: "151",
        name: "Nossa Caixa"
      },
      {
        value: "175",
        name: "Banco Finasa"
      },
      {
        value: "184",
        name: "Banco Itaú BBA"
      },
      {
        value: "204",
        name: "American Express Bank"
      },
      {
        value: "208",
        name: "Banco Pactual"
      },
      {
        value: "212",
        name: "Banco Matone"
      },
      {
        value: "213",
        name: "Banco Arbi"
      },
      {
        value: "214",
        name: "Banco Dibens"
      },
      {
        value: "217",
        name: "Banco Joh Deere"
      },
      {
        value: "218",
        name: "Banco Bonsucesso"
      },
      {
        value: "222",
        name: "Banco Calyon Brasil"
      },
      {
        value: "224",
        name: "Banco Fibra"
      },
      {
        value: "225",
        name: "Banco Brascan"
      },
      {
        value: "229",
        name: "Banco Cruzeiro"
      },
      {
        value: "230",
        name: "Unicard"
      },
      {
        value: "233",
        name: "Banco GE Capital"
      },
      {
        value: "237",
        name: "Bradesco"
      },
      {
        value: "237",
        name: "Next"
      },
      {
        value: "241",
        name: "Banco Clássico"
      },
      {
        value: "243",
        name: "Banco Stock Máxima"
      },
      {
        value: "246",
        name: "Banco ABC Brasil"
      },
      {
        value: "248",
        name: "Banco Boavista Interatlântico"
      },
      {
        value: "249",
        name: "Investcred Unibanco"
      },
      {
        value: "250",
        name: "Banco Schahin"
      },
      {
        value: "252",
        name: "Fininvest"
      },
      {
        value: "254",
        name: "Paraná Banco"
      },
      {
        value: "263",
        name: "Banco Cacique"
      },
      {
        value:"260",
        name: "Nubank"
      },
      {
        value: "265",
        name: "Banco Fator"
      },
      {
        value: "266",
        name: "Banco Cédula"
      },
      {
        value: "290",
        name: "Pagseguro Internet Instituição de Pagamento S/A"
      },
      {
        value: "300",
        name: "Banco de la Nación Argentina"
      },
      {
        value: "318",
        name: "Banco BMG"
      },
      {
        value: "320",
        name: "Banco Industrial e Comercial"
      },
      {
        value: "356",
        name: "ABN Amro Real"
      },
      {
        value: "341",
        name: "Itau"
      },
      {
        value: "347",
        name: "Sudameris"
      },
      {
        value: "351",
        name: "Banco Santander"
      },
      {
        value: "353",
        name: "Banco Santander Brasil"
      },
      {
        value: "366",
        name: "Banco Societe Generale Brasil"
      },
      {
        value: "370",
        name: "Banco WestLB"
      },
      {
        value: "376",
        name: "JP Morgan"
      },
      {
        value: "389",
        name: "Banco Mercantil do Brasil"
      },
      {
        value: "394",
        name: "Banco Mercantil de Crédito"
      },
      {
        value: "399",
        name: "HSBC"
      },
      {
        value: "409",
        name: "Unibanco"
      },
      {
        value: "412",
        name: "Banco Capital"
      },
      {
        value: "422",
        name: "Banco Safra"
      },
      {
        value: "453",
        name: "Banco Rural"
      },
      {
        value: "456",
        name: "Banco Tokyo Mitsubishi UFJ"
      },
      {
        value: "464",
        name: "Banco Sumitomo Mitsui Brasileiro"
      },
      {
        value: "477",
        name: "Citibank"
      },
      {
        value: "479",
        name: "Itaubank (antigo Bank Boston)"
      },
      {
        value: "487",
        name: "Deutsche Bank"
      },
      {
        value: "488",
        name: "Banco Morgan Guaranty"
      },
      {
        value: "492",
        name: "Banco NMB Postbank"
      },
      {
        value: "494",
        name: "Banco la República Oriental del Uruguay"
      },
      {
        value: "495",
        name: "Banco La Provincia de Buenos Aires"
      },
      {
        value: "505",
        name: "Banco Credit Suisse"
      },
      {
        value: "600",
        name: "Banco Luso Brasileiro"
      },
      {
        value: "604",
        name: "Banco Industrial"
      },
      {
        value: "610",
        name: "Banco VR"
      },
      {
        value: "611",
        name: "Banco Paulista"
      },
      {
        value: "612",
        name: "Banco Guanabara"
      },
      {
        value: "613",
        name: "Banco Pecunia"
      },
      {
        value: "623",
        name: "Banco Panamericano"
      },
      {
        value: "626",
        name: "Banco Ficsa"
      },
      {
        value: "630",
        name: "Banco Intercap"
      },
      {
        value: "633",
        name: "Banco Rendimento"
      },
      {
        value: "634",
        name: "Banco Triângulo"
      },
      {
        value: "637",
        name: "Banco Sofisa"
      },
      {
        value: "638",
        name: "Banco Prosper"
      },
      {
        value: "643",
        name: "Banco Pine"
      },
      {
        value: "652",
        name: "Itaú Holding Financeira"
      },
      {
        value: "653",
        name: "Banco Indusval"
      },
      {
        value: "654",
        name: "Banco A.J. Renner"
      },
      {
        value: "655",
        name: "Banco Votorantim"
      },
      {
        value: "707",
        name: "Banco Daycoval"
      },
      {
        value: "719",
        name: "Banif"
      },
      {
        value: "721",
        name: "Banco Credibel"
      },
      {
        value: "734",
        name: "Banco Gerdau"
      },
      {
        value: "735",
        name: "Banco Neon"
      },
      {
        value: "738",
        name: "Banco Morada"
      },
      {
        value: "739",
        name: "Banco Galvão de Negócios"
      },
      {
        value: "740",
        name: "Banco Barclays"
      },
      {
        value: "741",
        name: "BRP"
      },
      {
        value: "743",
        name: "Banco Semear"
      },
      {
        value: "745",
        name: "Banco Citibank"
      },
      {
        value: "746",
        name: "Banco Modal"
      },
      {
        value: "747",
        name: "Banco Rabobank International"
      },
      {
        value: "748",
        name: "Banco Cooperativo Sicredi"
      },
      {
        value: "749",
        name: "Banco Simples"
      },
      {
        value: "751",
        name: "Dresdner Bank"
      },
      {
        value: "752",
        name: "BNP Paribas"
      },
      {
        value: "753",
        name: "Banco Comercial Uruguai"
      },
      {
        value: "755",
        name: "Banco Merrill Lynch"
      },
      {
        value: "756",
        name: "Banco Cooperativo do Brasil"
      },
      {
        value: "757",
        name: "KEB"
      }
    ]
  },
  getNameBank(codBank){
    return this.Bancos().find(e=> (codBank==e.value)).name.toString();
  },
  formatCPF(cpf){
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  },
  formatCNPJ(cnpj){
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
  },
}



